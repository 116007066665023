import { BaseMap } from './mapa';
  
const Mapa = () => {
    return ( 
        <>
           <BaseMap/>
        </>
     );
}
 
export default Mapa;