import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    DESARROLLO_CREATE,
    DESARROLLO_CREATE_SUCCESS,
    DESARROLLO_CREATE_ERROR,
    DESARROLLO_CREATE_INIT,
    DESARROLLOS_LOADING,
    DESARROLLOS_LOADING_SUCCESS,
    DESARROLLOS_LOADING_ERROR,
    DESARROLLO_DESACTIVE,
    DESARROLLO_DESACTIVE_ERROR,
    DESARROLLO_LOAD,
    DESARROLLO_LOAD_SUCCESS,
    DESARROLLO_LOAD_ERROR,
    DESARROLLO_UPDATE_SUCCESS,
    DESARROLLO_UPDATE_ERROR,
    DESARROLLO_UPDATE_INIT,
} from '../types';



/* Create DESARROLLO*/ 
    export function createDesarrolloAction(desarrollo) {

        return async ( dispatch ) => {
            
            dispatch( createDesarrollo() );

            try{
                await clientAxios.post('/desarrollo/crear/', desarrollo);
                dispatch( createDesarrolloSuccess(desarrollo) );

            }catch( error ){
                dispatch( createDesarrolloError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el desarrollo");
            }
        }

    }

    // Create Desarrollo init action
    export function createDesarrolloInitFn(flag){
        return ( dispatch ) => {
            dispatch( createDesarrolloInit(flag));
        }
    }

    const createDesarrollo = ( ) => ({
        type    : DESARROLLO_CREATE
    });

    const createDesarrolloSuccess = (desarrollo) => ({
        type    : DESARROLLO_CREATE_SUCCESS,
        payload : desarrollo,
        flag    : true,
    })

    const createDesarrolloError = () => ({
        type    : DESARROLLO_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createDesarrolloInit = (flag) =>({
        type    : DESARROLLO_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* GRID DESARROLLO */
    export function getAllDesarrollosAction() {
        return async ( dispatch ) => {
            dispatch( downloadDesarrollo() );

            try{
                const responseDesarrollos = await clientAxios.get('/desarrollo' );
                dispatch( downloadDesarrolloSuccess (responseDesarrollos.data.data) );

            }catch( error ){
                dispatch( downloadDesarrolloError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los desarrollo");   
            }
        }
    }

    const downloadDesarrollo = () => ({
        type: DESARROLLOS_LOADING,
        payload: true
    })

    const downloadDesarrolloSuccess = ( desarrollos ) => ({
        type: DESARROLLOS_LOADING_SUCCESS,
        payload: desarrollos
    })

    const downloadDesarrolloError = (error) => ({
        type: DESARROLLOS_LOADING_ERROR,
        error: error
    })

/* DESACTIVE DESARROLLO */
    export const desactiveDesarrolloAction = (id) => {

        return async (dispatch)=>{
            dispatch( getDesarrolloDesactive(id) );

            try{
                let response = await clientAxios.post(`/desarrollo/change-active/?id_desarrollo=${id}`);
              
                // ALL DESARROLLOS UPDATE ACTIVE
                if(response.status === 200){
                    dispatch( downloadDesarrollo() );
                    let responseDesarrollosActive = await clientAxios.get('/desarrollo', );
                    dispatch( downloadDesarrolloSuccess (responseDesarrollosActive.data.data) );
                }
                
            }catch( error ){
                dispatch( desactiveDesarrolloError() );
                SweetAlertBasic("error","Ups","Hubo un error al desactivar/activar el desarrollo");  
            }
        }

    }

    const getDesarrolloDesactive = (id)=>({
        type: DESARROLLO_DESACTIVE,
        payload: id
    })

    const desactiveDesarrolloError = ( ) =>({
        type: DESARROLLO_DESACTIVE_ERROR,
        error: true,
        flag: false
    }) 

/* EDIT DESARROLLO */
    export const getDesarrolloAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadDesarrollo() );

            try{
                let response = await clientAxios.get(`/desarrollo/${id}`);
                dispatch( loadDesarrolloSuccess (response.data.data) );
            }catch( error ){
                dispatch( loadDesarrolloError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar el desarrollo");  
            }
        }   
    }

    const getLoadDesarrollo = () => ({
        type: DESARROLLO_LOAD,
        payload: true
    })

    const loadDesarrolloSuccess = (desarrollo) => ({
        type: DESARROLLO_LOAD_SUCCESS,
        editarUsuario: desarrollo
    })

    const loadDesarrolloError = () => ({
        type: DESARROLLO_LOAD_ERROR,
        error: true
    })
    

/* UPDATE DESARROLLO */
    export const updateDesarrolloAction = (desarrollo,id) => {
       
        return async (dispatch)=>{
            dispatch( updateDesarrolloInit(desarrollo) );

            try{
                await clientAxios.put(`/desarrollo/${id}`, desarrollo);
                dispatch( updateDesarrolloSuccess(desarrollo) );

            }catch( error ){
                dispatch( updateDesarrolloError() );
                SweetAlertBasic("error","Ups",`Hubo un error al actualizar el desarrollo ${error}`);
            }
        }

    }

    const updateDesarrolloInit = () => ({
        type: DESARROLLO_UPDATE_INIT,
        payload: true
    })

    const updateDesarrolloSuccess = (desarrollo)=>({
        type: DESARROLLO_UPDATE_SUCCESS,
        desarrolloActualizado: desarrollo
    })

    const updateDesarrolloError = ( ) =>({
        type: DESARROLLO_UPDATE_ERROR,
        error: true,
        flag: false
    }) 