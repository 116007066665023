import  React, { useEffect }                    from 'react';
import  { useDispatch, useSelector }            from 'react-redux';
import  { getLoginUserFn }                      from '../../actions/loginActions';
import  { useNavigate }                         from 'react-router-dom';
import  ProgressBar                             from '../Progress/ProgressBar.component';

// TABLE
import { DataGrid }                             from '@mui/x-data-grid';
import { ColumnsHouse }                         from '../../utils/rowData';
import Button                                   from '@mui/material/Button';
import AddCircleIcon                            from '@mui/icons-material/AddCircle';

import{ 
    createHouseInitFn, 
    getAllHousesAction 
}                               from '../../actions/houseActions';

import{    
    DivGrid, 
    TitleAndButton, 
    DivButton, 
    TitleHouseGrid 
}                           from './styles/Inventario.styles';


const DashboardInventario = () => {

    const dispatch          = useDispatch();
    const addCreateInit     = (house) => dispatch( createHouseInitFn(house) );
    const navigate          = useNavigate();
    const houseSuccess      = useSelector( state => state.casas.casas);
    const loading           = useSelector( state => state.casas?.loading);
    const houseTableFilter  =  houseSuccess.filter(house => house.id_desarrollo === 1 )

    // USE EFFECT LOGIN
    useEffect( () => {
        const loginUser         = () => dispatch( getLoginUserFn() );
        const loadingHouses     = () => dispatch( getAllHousesAction() );
        loginUser();
        loadingHouses();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    // Onclick User Init action
    function newHouse() {
        addCreateInit(false);
        navigate('/casas/crear');
    }


    return (  
        <>
        {
            loading ? 
                <ProgressBar title="Cargando Casas..."/>
            :
                <>
                        <TitleAndButton>
                            <TitleHouseGrid>Casas</TitleHouseGrid>

                            <DivButton>
                                <Button
                                    variant="contained"
                                    onClick={newHouse}
                                    startIcon={<AddCircleIcon />}
                                    size="large"
                                >
                                    Crear Casa
                                </Button>
                            </DivButton>
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={houseTableFilter}
                                columns             ={ColumnsHouse}
                                pageSize            ={15}
                                rowsPerPageOptions  ={[15]}
                                getRowId            ={houseSuccess => houseSuccess.id_casa}
                                initialState={{
                                    sorting: {
                                      sortModel: [
                                        {
                                          field: 'created_at',
                                          sort: 'desc',
                                        },
                                      ],
                                    },
                                  }}
                            />
                        </DivGrid>
                </>
        }

        </>
    );
}
 
export default DashboardInventario;