import React,{ useEffect }  from 'react';
import Button                       from '@mui/material/Button';
import AddCircleIcon                from '@mui/icons-material/AddCircle';
import ProgressBar                  from '../Progress/ProgressBar.component';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsCalendarys }           from '../../utils/rowData';
import{ 
        createCalendaryInitFn, 
        getAllCalendarysAction 
    }                               from '../../actions/calendaryActions';
import{    
        DivGrid, 
        TitleAndButton, 
        DivButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';
const Calendarys = () => {

    const dispatch          = useDispatch();
    const addCreateInit     = (calendary) => dispatch( createCalendaryInitFn(calendary) );
    const navigate          = useNavigate();
    const calendarySuccess  = useSelector( state => state.calendarios?.calendarios);
    const loading           = useSelector( state => state.calendarios?.loading);

    // USE EFFECT LOADER
    useEffect( () => {
        const loadingCalendarys = () => dispatch( getAllCalendarysAction() );
        loadingCalendarys();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    // Onclick Calendary Init action
    function newCalendary() {
        addCreateInit(false);
        navigate('/calendarios/crear');
    }


    return ( 
        <>
            {
                loading ? 
                    <ProgressBar title="Cargando Calendarios..."/>
                :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Calendarios</TitleUserGrid>

                            <DivButton>
                                <Button
                                    variant="contained"
                                    onClick={newCalendary}
                                    startIcon={<AddCircleIcon />}
                                    size="large"
                                >
                                    Crear Calendario
                                </Button>
                            </DivButton>
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={calendarySuccess}
                                columns             ={ColumnsCalendarys}
                                pageSize            ={10}
                                rowsPerPageOptions  ={[10]}
                                getRowId            ={calendarySuccess => calendarySuccess.id_calendario}
                            />
                        </DivGrid>
                </>
            }
        </>
     );
}
 
export default Calendarys;