
export const status_button = [
    {
           "name": "Cliente Nuevo",
           "value": 1,
           "count_status": 6,
           "description": "Cliente nuevos para contactar",
           "status_positive": 2,
           "status_negative": 4,
           "status_button":[
               {
                 "name":"Cliente No contactado",
                 "value":2,
                 "description":"Cliente que se le marco y no pudo ser localizado" ,
                 "status_flag": "negative",
                 "status_active": true
               },
               {
                   "name":"Cliente Contactado",
                   "value":3,
                   "description":"Cliente contactado mediante llamada o mensaje",
                   "status_flag": "positive",
                   "status_active": true,

               },
               {
                 "name":"Cliente Sin Perfil",
                 "value":4,
                 "description":"Cliente que no cumple con caracteriticas para comprar o vender una propuedad",
                 "status_flag": "negative",
                 "status_active": true
               },
               {
                   "name":"Cliente Futuro",
                   "value":5,
                   "description": "Cliente que cumple con caracteriticas pero no tiene financiamiento",
                  "status_flag": "negative",
                   "status_active": true
               },
               {
                   "name":"Cliente Prospecto",
                   "value":6,
                   "description": "Cliente que cumple con caracteriticas ideales para comprar o rentar una casa",
                   "status_flag":"positive",
                    "status_active": true
               }
               
           ],
    },
    {
            "name": "Cliente No Contactado",
            "value": 1,
            "count_status": 4,
            "description": "Cliente que se le marco y no pudo ser localizado" ,
            "status_positive": 2,
            "status_negative": 2,
            "status_button":[
                {
                    "name":"Cliente Contactado",
                    "value":3,
                    "description":"Cliente contactado mediante llamada o mensaje",
                    "status_flag": "positive",
                    "status_active": true,
                },
                {
                    "name":"Cliente Sin Perfil",
                    "value":4,
                    "description":"Cliente que no cumple con caracteriticas para comprar o rentar.",
                    "status_flag": "negative",
                    "status_active": true
                },
                {
                    "name":"Cliente Futuro",
                    "value":5,
                    "description": "Cliente que cumple con caracteriticas pero no tiene financiamiento",
                    "status_flag": "negative",
                    "status_active": true
                },
                {
                    "name":"Cliente Prospecto",
                    "value":6,
                    "description": "Cliente que cumple con caracteriticas ideales para comprar o rentar una casa",
                    "status_flag":"positive",
                    "status_active": true
                }
               
           ],
    },

    {
            "name":"Cliente Contactado",
            "value":3,
            "count_status": 3,
            "description":"Cliente contactado mediante llamada o mensaje",
            "status_positive": 1,
            "status_negative": 2,
            "status_button":[
                {
                    "name":"Cliente Sin Perfil",
                    "value":4,
                    "description":"Cliente que no cumple con caracteriticas para comprar o rentar.",
                    "status_flag": "negative",
                    "status_active": true
                },
                {
                    "name":"Cliente Futuro",
                    "value":5,
                    "description": "Cliente que cumple con caracteriticas pero no tiene financiamiento",
                    "status_flag": "negative",
                    "status_active": true
                },
                {
                    "name":"Cliente Prospecto",
                    "value":6,
                    "description": "Cliente que cumple con caracteriticas ideales para comprar o rentar una casa",
                    "status_flag":"positive",
                    "status_active": true
                },
           ],
    },
    {
        "name":"Cliente Sin Perfil",
        "value":4,
        "count_status": 1,
        "description":"Cliente que no cumple con caracteriticas para comprar o rentar.",
        "status_positive": 1,
        "status_negative": 0,
        "status_button":[
            {
                "name":"Cliente Contactado",
                "value":3,
                "description":"Cliente contactado mediante llamada o mensaje",
                "status_flag": "positive",
                "status_active": true,
            },
        ],  
    },
    {
        "name":"Cliente Futuro",
        "value":5,
        "count_status": 2,
        "description": "Cliente que cumple con caracteriticas pero no tiene financiamiento",
        "status_positive": 1,
        "status_negative": 1,
        "status_button":[
            {
                "name":"Cliente Prospecto",
                "value":6,
                "description": "Cliente que cumple con caracteriticas ideales para comprar o rentar una casa",
                "status_flag":"positive",
                "status_active": true
            },
            {
                "name":"Cliente Perdido",
                "value":8,
                "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda",
                "status_flag":"negative",
                "status_active": true
            }
        ],  
    
    },
    {
        "name":"Cliente Prospecto",
        "value":6,
        "count_status": 3,
        "description": "Cliente que cumple con caracteriticas ideales para comprar o rentar una casa",
        "status_positive": 1,
        "status_negative": 2,
        "status_button":[
            {
                "name":"Cliente Futuro",
                "value":5,
                "description": "Cliente que cumple con caracteriticas pero no tiene financiamiento",
                "status_flag": "negative",
                "status_active": true
            },
            {
                "name":"Cliente Con Opciones",
                "value":7,
                "description": "Cliente con opciones enviadas para agendar una visita.",
                "status_flag":"positive",
                "status_active": true
            },
            {
                "name":"Cliente Perdido",
                "value":8,
                "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda",
                "status_flag":"negative",
                "status_active": true
            }
       ],  
    },
    {
        "name":"Cliente Con Opciones",
        "value":7,
        "count_status": 2,
        "description": "Cliente con opciones enviadas para agendar una visita.",
        "status_positive": 1,
        "status_negative": 1,
        "status_button":[
            {
                "name":"Cliente Con Agenda",
                "value":9,
                "description": "Cliente que ya se le agendo una visita a una propiedad",
                "status_flag": "positive",
                "status_active": true
            },
            {
                "name":"Cliente Perdido",
                "value":8,
                "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda",
                "status_flag":"negative",
                "status_active": true
            }
        ],  
    },
    {
        "name":"Cliente Perdido",
        "value":8,
        "count_status": 1,
         "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda",
        "status_positive": 1,
        "status_negative": 0,
        "status_button":[
            {
                "name":"Cliente Contactado",
                "value":3,
                "description":"Cliente contactado mediante llamada o mensaje",
                "status_flag": "positive",
                "status_active": true,
            },
       ],  
    },
    {
        "name":"Cliente Con Agenda",
        "value":9,
        "count_status": 4,
        "description": "Cliente que ya se le agendo una visita a una propiedad",
        "status_positive": 2,
        "status_negative": 2,
        "status_button":[
            {
                "name":"Cliente Con Opciones",
                "value":7,
                "description": "Cliente con opciones enviadas para agendar una visita.",
                "status_flag":"positive",
                "status_active": true
            },
            {
                "name":"Cliente confirma visita",
                "value":10,
                "description": "Cliente que ya confirmo la visita de la agenda",
                "status_flag": "positive",
                "status_active": true
            },
            {
                "name":"Cliente sin confirmar visita",
                "value":10,
                "description": "Cliente que no ha confirmo la visita de la agenda",
                "status_flag": "negative",
                "status_active": true
            },
            {
                "name":"Cliente Perdido",
                "value":8,
                "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda",
                "status_flag":"negative",
                "status_active": true
            }
       ],  
    },
    {
        "name":"Cliente confirma visita",
        "value":10,
        "count_status": 4,
        "description": "Cliente que ya confirmo la visita de la agenda",
        "status_positive": 2,
        "status_negative": 2,
        "status_button":[
            {
                "name":"Cliente Con Opciones",
                "value":7,
                "description": "Cliente con opciones enviadas para agendar una visita.",
                "status_flag":"positive",
                "status_active": true
            },
            {
                "name":"Cliente Visita Exitosa",
                "value":12,
                "description": "Cliente que fue a la propiedad y quiere iniciar tramite.",
                "status_flag":"positive",
                "status_active": true
            },
            {
                "name":"Cliente Visita No Exitosa",
                "value":13,
                "description": "Cliente que fue a la propiedad y no quiere iniciar trámite.",
                "status_flag":"negative",
                "status_active": true
            },
            {
                "name":"Cliente Perdido",
                "value":8,
                "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda.",
                "status_flag":"negative",
                "status_active": true
            }
       ],  
    },
    {
        "name":"Cliente sin confirmar visita",
        "value":11,
        "count_status": 3,
        "description": "Cliente que no ha confirmado la visita de la agenda",
        "status_positive": 2,
        "status_negative": 1,
        "status_button":[
            {
                "name":"Cliente Con Opciones",
                "value":7,
                "description": "Cliente con opciones enviadas para agendar una visita.",
                "status_flag":"positive",
                "status_active": true
            },
            {
                "name":"Cliente Con Agenda",
                "value":9,
                "description": "Cliente que ya se le agendo una visita a una propiedad",
                "status_flag": "positive",
                "status_active": true
            },
           
            {
                "name":"Cliente Perdido",
                "value":8,
                "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda.",
                "status_flag":"negative",
                "status_active": true
            }
       ],  
    },
    {
        "name":"Cliente Visita Exitosa",
        "value":12,
        "count_status": 2,
        "description": "Cliente que fue a la propiedad y quiere iniciar tramite.",
        "status_positive": 1,
        "status_negative": 1,
        "status_button":[
            {
                "name":"Cliente Precontrato",
                "value":14,
                "description": "Cliente que inicia su trámite para rentar o comprar una vivienda.",
                "status_flag":"positive",
                "status_active": true
            },  
            {
                "name":"Cliente Perdido",
                "value":8,
                "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda.",
                "status_flag":"negative",
                "status_active": true
            }
       ],  
    },
    {
        "name":"Cliente Visita No Exitosa",
        "value":13,
        "description": "Cliente que fue a la propiedad y no quiere iniciar trámite.",
        "count_status": 3,
        "status_positive": 1,
        "status_negative": 2,
        "status_button":[
            {
                "name":"Cliente Futuro",
                "value":5,
                "description": "Cliente que cumple con caracteriticas pero no tiene financiamiento",
                "status_flag": "negative",
                "status_active": true
            },
            {
                "name":"Cliente Con Opciones",
                "value":7,
                "description": "Cliente con opciones enviadas para agendar una visita.",
                "status_flag":"positive",
                "status_active": true
            },
            {
                "name":"Cliente Perdido",
                "value":8,
                "description": "Cliente que cumple con las caracteriticas pero no le interesa comprar o rentar una vivienda.",
                "status_flag":"negative",
                "status_active": true
            }
       ],  
    },
    {
        "name":"Cliente Precontrato",
        "value":14,
        "description": "Cliente que inicia su trámite para rentar o comprar una vivienda.",
        "count_status": 3,
        "status_positive": 1,
        "status_negative": 2,
        "status_advanced": true,
        "status_button":[
            {
                "name":"Cliente Expediente OK",
                "value":15,
                "description": "Cliente que tiene su expediente completo",
                "status_flag": "positive",
                "status_active": true
            },
            {
                "name":"Cliente Expediente No OK",
                "value":16,
                "description": "Cliente que tiene su expediente incompleto",
                "status_flag": "negative",
                "status_active": true
            },
            {
                "name":"Cliente Cancelado",
                "value":17,
                "description": "Cliente que no tiene su documentación en regla para comprar o rentar una propiedad",
                "status_flag": "negative",
                "status_active": true
            },    
       ],  
    },
    {
        "name":"Cliente Expediente OK",
        "value":15,
        "description": "Cliente que tiene su expediente completo",
        "count_status": 2,
        "status_positive": 1,
        "status_negative": 1,
        "status_advanced": true,
        "status_button":[
            {
                "name":"Cliente Agenda Firma",
                "value":18,
                "description": "Cliente que agenda para firmar contrato.",
                "status_flag": "positive",
                "status_active": true
            },
            {
                "name":"Cliente Cancelado",
                "value":17,
                "description": "Cliente que no tiene su documentación en regla para comprar o rentar una propiedad",
                "status_flag": "negative",
                "status_active": true
            },    
       ],  
    },
    {
        "name":"Cliente Expediente No OK",
        "value":16,
        "description": "Cliente que tiene su expediente incompleto",
        "count_status": 3,
        "status_positive": 1,
        "status_negative": 2,
        "status_advanced": true,
        "status_button":[
            {
                "name":"Cliente Expediente OK",
                "value":15,
                "description": "Cliente que tiene su expediente completo",
                "status_flag": "positive",
                "status_active": true
            },
            {
                "name":"Cliente Cancelado",
                "value":17,
                "description": "Cliente que no tiene su documentación en regla para comprar o rentar una propiedad",
                "status_flag": "negative",
                "status_active": true
            },    
       ],  
    },
    {
        "name":"Cliente Cancelado",
        "value":17,
        "description": "Cliente que no tiene su documentación en regla para comprar o rentar una propiedad o tuvo un imprevisto.",
        "count_status": 2,
        "status_positive": 2,
        "status_negative": 0,
        "status_advanced": true,
        "status_button":[
            {
                "name":"Cliente Precontrato",
                "value":14,
                "description": "Cliente que inicia su trámite para rentar o comprar una vivienda.",
                "status_flag":"positive",
                "status_active": true
            }, 
            {
                "name":"Cliente Con Opciones",
                "value":7,
                "description": "Cliente con opciones enviadas para agendar una visita.",
                "status_flag":"positive",
                "status_active": true
            }, 
       ],  
    },
    {
        "name":"Cliente Agenda Firma",
        "value":18,
        "description": "Cliente que agenda para firmar contrato.",
        "count_status": 3,
        "status_positive": 1,
        "status_negative": 2,
        "status_advanced": true,
        "status_button":[
            {
                "name":"Cliente Agenda Firma OK",
                "value":19,
                "description": "Cliente que firma contrato y todo su expediente esta completo.",
                "status_flag":"positive",
                "status_active": true
            }, 
            {
                "name":"Cliente Agenda Firma No OK",
                "value":20,
                "description": "Cliente que no puede firma contrato.",
                "status_flag":"negative",
                "status_active": true
            }, 
            {
                "name":"Cliente Cancelado",
                "value":17,
                "description": "Cliente que no tiene su documentación en regla para comprar o rentar una propiedad",
                "status_flag": "negative",
                "status_active": true
            }, 
       ],  
    },
    {
        "name":"Cliente Agenda Firma OK",
        "value":19,
        "description": "Cliente que firma contrato y todo su expediente esta completo.",
        "count_status": 2,
        "status_positive": 1,
        "status_negative": 1,
        "status_advanced": true,
        "status_button":[
            {
                "name":"Cliente Venta",
                "value":21,
                "description": "Cliente que firmo contrato y se entrego todo el expediente.",
                "status_flag":"positive",
                "status_active": true
            }, 
            {
                "name":"Cliente Cancelado",
                "value":17,
                "description": "Cliente que no tiene su documentación en regla para comprar o rentar una propiedad",
                "status_flag": "negative",
                "status_active": true
            }, 
       ],  
    },
    {
        "name":"Cliente Agenda Firma No OK",
        "value":20,
        "description": "Cliente que no puede firma contrato.",
        "count_status": 2,
        "status_positive": 1,
        "status_negative": 1,
        "status_advanced": true,
        "status_button":[
            {
                "name":"Cliente Agenda Firma",
                "value":18,
                "description": "Cliente que agenda para firmar contrato.",
                "status_flag": "positive",
                "status_active": true
            },
            {
                "name":"Cliente Cancelado",
                "value":17,
                "description": "Cliente que no tiene su documentación en regla para comprar o rentar una propiedad",
                "status_flag": "negative",
                "status_active": true
            }, 
       ],  
    },
    {
        "name":"Cliente Venta",
        "value":21,
        "description": "Cliente que firmo contrato y se entrego todo el expediente.",
        "count_status": 2,
        "status_positive": 1,
        "status_negative": 1,
        "status_advanced": true,
        "status_button":[
            {
                "name":"Cliente PostVenta",
                "value":22,
                "description": "Cliente que se le da un seguimiento postventa.",
                "status_flag": "positive",
                "status_active": true
            }
       ],  
    },

]   

