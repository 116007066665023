import React from 'react';

const NewFilter = () => {
    return ( 
        <>
            <h1>New Filter</h1>
        </>
     );
}
 
export default NewFilter;