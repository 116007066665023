import { 
    FILTER_CREATE,
    FILTER_CREATE_SUCCESS,
    FILTER_CREATE_ERROR,
    FILTER_CREATE_INIT,
    FILTERS_LOADING,
    FILTERS_LOADING_SUCCESS,
    FILTERS_LOADING_ERROR,
    FILTER_LOAD,
    FILTER_LOAD_SUCCESS,
    FILTER_LOAD_ERROR,
    FILTER_DESACTIVE,
    FILTER_DESACTIVE_ERROR
} from '../types';

/*
    FLAG
        Revisamos si esta ok  lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
*/
const initialState = {
    filtros: [],
    error: null,
    loading: false,
    flag: false, 
    desactiveFiltro: null,
    editarFiltro: null,
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case FILTER_CREATE:
        case FILTER_LOAD:
        case FILTERS_LOADING:
            return{
                ...state,
                loading: true,
                flag: false,
            }

        case FILTER_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case FILTER_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                filtros: [ ...state.filtros, action.payload ]
            }

        case FILTER_CREATE_ERROR:
        case FILTER_DESACTIVE_ERROR:
        case FILTERS_LOADING_ERROR:
        case FILTER_LOAD_ERROR:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case FILTER_DESACTIVE:
            return{
                ...state,
                desactiveFiltro: action.payload
            }
        
        case FILTERS_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                filtros: action.payload,
                flag: false,
                desactiveFiltro: null
            }

        case FILTER_LOAD_SUCCESS:
            return{
                ...state,
                filtros: [...state.filtros],
                editarFiltro: action.editarFiltro,
                flag: true,
                loading: false
            }
        default:
            return state;
    }
}