import React                        from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    ImageUserSuccess,
    TitleUserSuccess,
    TitleUserGreen,
    DivUserSuccess,
    ButtonUserSuccess
}                                   from '../Usuarios/Users.styles';
import {
    DivButton
}                                   from './Houses.styles';
import CasaSuccess                  from '../../Images/casa_success.png';
import{ getAllHousesAction }        from '../../actions/houseActions';


const HouseUpdateSuccess = () => {

    const navigate          = useNavigate();
    const dispatch          = useDispatch();
    const loadingHouses     = () => dispatch( getAllHousesAction() );
    const idHouse           = useSelector( state => state.casas.idCasa);
    
    console.log(idHouse);
    function goHouses() {
        loadingHouses();
        navigate('/dashboard/inventario');
    }

    return ( 
        <>
           
            <DivUserSuccess>
                <ImageUserSuccess  src={CasaSuccess} alt="logo" />
                <TitleUserSuccess>Se ha 
                    <TitleUserGreen>
                    actualizado
                    </TitleUserGreen> 
                    la 
                    <TitleUserGreen>
                        casa
                    </TitleUserGreen> 
                    exitosamente.
                </TitleUserSuccess> 
                <DivButton>
                    <ButtonUserSuccess
                        onClick={goHouses}
                    >
                        Ir al inicio 
                    </ButtonUserSuccess>
                </DivButton>
            </DivUserSuccess>
        </>
     );
}
 
export default HouseUpdateSuccess;