import styled from 'styled-components';

export const DivGrid = styled.div`
    display: flex;
    height:700px;
    justify-content: center;
    width:90%;
    margin: 0 auto;
`

export const TitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
`

export const DivButton = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 1rem 2rem 1rem;
    width:80%;
`

export const TitleHouseGrid = styled.h1`
    font-size: 36px;
    font-weight: bold;
    color: #004274;
    margin-left:0%;
`
export const TitleGrid = styled.h1`
    font-size: 36px;
    font-weight: bold;
    color: #004274;
    margin-left:0%;
`

