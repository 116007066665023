import { 
    DESARROLLO_CREATE,
    DESARROLLO_CREATE_SUCCESS,
    DESARROLLO_CREATE_ERROR,
    DESARROLLO_CREATE_INIT,
    DESARROLLOS_LOADING,
    DESARROLLOS_LOADING_SUCCESS,
    DESARROLLOS_LOADING_ERROR,
    DESARROLLO_DESACTIVE,
    DESARROLLO_DESACTIVE_ERROR,
    DESARROLLO_LOAD,
    DESARROLLO_LOAD_SUCCESS,
    DESARROLLO_LOAD_ERROR,
    DESARROLLO_UPDATE_SUCCESS,
    DESARROLLO_UPDATE_ERROR,
    DESARROLLO_UPDATE_INIT,
} from '../types';

/*
    FLAG
        Revisamos si esta ok el desarrollo y lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
        Loading: false(enseña el cargando)
    
    LOADING
        True: Quitamos el componente loading(cargando) 
        False: Se enseña el componente cargando
*/
const initialState = {
    desarrollos             : [],
    error                   : null,
    loading                 : false,
    loadingUpdate           : false,  
    flag                    : false, 
    desactiveDesarrollo     : null,
    editarDesarrollo        : null,
    desarrolloActualizado   : null,
    flagUpdate              : false
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case    DESARROLLO_CREATE:
        case    DESARROLLO_LOAD:
        case    DESARROLLOS_LOADING:
        case    DESARROLLO_UPDATE_INIT:

            return{
                ...state,
                loading: true,
                loadingUpdate: true,
                flag: false,
            }

        case DESARROLLO_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case DESARROLLO_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                desarrollos: [ ...state.desarrollos, action.payload ]
            }

        case DESARROLLO_CREATE_ERROR:
        case DESARROLLOS_LOADING_ERROR:
        case DESARROLLO_DESACTIVE_ERROR:
        case DESARROLLO_LOAD_ERROR:
        case DESARROLLO_UPDATE_ERROR:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case DESARROLLOS_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                desarrollos: action.payload,
                flag: false,
                desactiveDesarrollo: null
            }

        case DESARROLLO_DESACTIVE:
            return{
                ...state,
                desactiveDesarrollo: action.payload
            }

        case DESARROLLO_LOAD_SUCCESS:
            return{
                ...state,
                desarrollos: [...state.desarrollos],
                editarDesarrollo: action.editarDesarrollo,
                flag: true,
                loading: false
            }
        
        case DESARROLLO_UPDATE_SUCCESS:
            return{
                ...state,
                desarrolloActualizado: action.desarrolloActualizado,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
        default:
            return state;
    }
}