import React            from 'react';
import OpcionesMobile   from './OpcionesMobile.component';
import OpcionesDesktop  from './OpcionesDesktop.component';

const OpcionesCasa = () => {

    const width = window.screen.width;

    return width <= 768 ?  <OpcionesMobile/> :  <OpcionesDesktop/> ;

}
 
export default OpcionesCasa;