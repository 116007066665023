import React                        from 'react';
import { useNavigate }              from 'react-router-dom';
import { useSelector }             from 'react-redux';
import {
    ImageUserSuccess,
    TitleUserSuccess,
    TitleUserGreen,
    DivUserSuccess,
    ButtonUserSuccess
} from '../Usuarios/Users.styles';
import ImageSuccess                    from '../../Images/seguimiento_success.png';



const UserSuccess = () => {

    const navigate              = useNavigate();
    const id_rol                = useSelector( state => state.login.user?.rol);


    function goClients() {
        if(id_rol === 2) navigate('/clientes/especialista');
        if(id_rol === 4) navigate('/dashboard/recepcion');
        if(id_rol === 6) navigate('/clientes/lider');
        if(id_rol === 1) navigate('/clientes');
    }

    return ( 
        <>
           
            <DivUserSuccess>
                <ImageUserSuccess  src={ImageSuccess} alt="Seguimiento exitoso" />
                <TitleUserSuccess>Se ha 
                    <TitleUserGreen>
                    creado
                    </TitleUserGreen> 
                    el 
                    <TitleUserGreen>
                        seguimiento
                    </TitleUserGreen> 
                    exitosamente.
                </TitleUserSuccess> 
                <ButtonUserSuccess
                    onClick={goClients}
                >
                    ir con Clientes
                </ButtonUserSuccess>
            </DivUserSuccess>
        </>
     );
}
 
export default UserSuccess;