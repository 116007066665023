import styled from 'styled-components';

export const DivGridBox = styled.div`
    width:90%;
    height:100%;
    margin: 2rem 1rem 0rem 10rem;

    @media only screen and (max-width: 768px) {
        margin: 1rem;
    }
`

export const DivGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 1;
    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 25px;
    }
`

export const TitleGoal = styled.h1`
    font-size: 36px;
    font-weight: bold;
    color: #004274;
    margin: 0;
    margin-bottom:1rem;
`

export const BoxGoals = styled.div`
    width: 200px;
    height: 120px;
    border-radius:10px;
    margin-top: 2rem;
    margin-bottom: 3rem;
    color: #004274;
    background-color:white;
    border: 2px solid #004274;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`

export const UsersGoals = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 64px;
    width: 64px;

`
export const Ventas = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    margin-left: 70px;
    height: 64px;
    width: 64px;
    @media only screen and (max-width: 768px) {
        margin-left: 0px;
        margin-top: 0px;
    }
`
export const ScheduleImg = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5px;
    height: 64px;
    width: 64px;
`

export const UsersProspect = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5px;
    margin-left: 75px;
    height: 64px;
    width: 64px;
    @media only screen and (max-width: 768px) {
        margin-left: 0px;
        margin-top: 0px;
        margin: 0 auto;
    }
`

export const SubtitleGoal = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: #004274;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ThreeGoal = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: #004274;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

