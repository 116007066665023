import { 
    TEAM_CREATE,
    TEAM_CREATE_SUCCESS,
    TEAM_CREATE_ERROR,
    TEAM_CREATE_INIT,
    TEAMS_LOADING,
    TEAMS_LOADING_SUCCESS,
    TEAMS_LOADING_ERROR,
    TEAM_LOAD,
    TEAM_LOAD_SUCCESS,
    TEAM_LOAD_ERROR,
    TEAM_DESACTIVE,
    TEAM_DESACTIVE_ERROR
} from '../types';

/*
    FLAG
        Revisamos si esta ok  lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
*/
const initialState = {
    equipos: [],
    error: null,
    loading: false,
    flag: false, 
    desactiveEquipo: null,
    editarEquipo: null,
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case TEAM_CREATE:
        case TEAM_LOAD:
        case TEAMS_LOADING:
            return{
                ...state,
                loading: true,
                flag: false,
            }

        case TEAM_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case TEAM_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                equipos: [ ...state.equipos, action.payload ]
            }

        case TEAM_CREATE_ERROR:
        case TEAM_DESACTIVE_ERROR:
        case TEAMS_LOADING_ERROR:
        case TEAM_LOAD_ERROR:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case TEAM_DESACTIVE:
            return{
                ...state,
                desactiveEquipo: action.payload
            }
        
        case TEAMS_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                equipos: action.payload,
                flag: false,
                desactiveEquipo: null
            }

        case TEAM_LOAD_SUCCESS:
            return{
                ...state,
                equipos: [...state.equipos],
                editarEquipo: action.editarEquipo,
                flag: true,
                loading: false
            }
        default:
            return state;
    }
}