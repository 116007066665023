import React            from 'react';
import Moment           from 'react-moment';
import Card             from '@mui/material/Card';
import CardContent      from '@mui/material/CardContent';
import CardMedia        from '@mui/material/CardMedia';
import Box              from '@mui/material/Box';
import Typography       from '@mui/material/Typography';
import Button           from '@mui/material/Button';
import HomeIcon         from '@mui/icons-material/Home';
import ImageOptions     from '../../Images/bienes_raices.png';
import {
    DivCasas,
    ButtonsCasa,
}                       from '../Opciones/Opciones.styles';

const OpcionesResponsive = ({data}) => {

    function goOption(id_casa){
        window.open( `/opciones/${id_casa}` );
    }


    return ( 
        <>
            <div style={{ height: '100%', width: '100%' }}>
                {
                    data.map( (option) => (
                            <DivCasas>
                                <Card sx={{ 
                                        m: 3, 
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }} >
                                    <CardMedia
                                        component="img"
                                        sx={{ 
                                            width: 65, 
                                            height: 65,
                                        }}
                                        image={ImageOptions}
                                        alt="imagen de opciones"
                                        style={{ 
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '10px',
                                            marginLeft: '20px'
                                        }}
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography 
                                                component="div" 
                                                variant="h5"
                                                style={{
                                                    width: '100%',
                                                    fontSize: '21px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {option.tipo_opcion}
                                            </Typography>
                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                            <strong>Descripción: <br/></strong> {option.descripcion_opcion_casa}
                                            </Typography>
                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                            <strong>Tipo de opción:<br/> </strong> {option.tipo_opcion}
                                            </Typography>
                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                            <strong>Fecha de creación: </strong> <br/>
                                            <Moment format="YYYY-MM-DD HH:mm">
                                                {option.created_at}
                                            </Moment>
                                            </Typography>
                    
                                        </CardContent>
                                    </Box>
                                    <ButtonsCasa >
                                        <Button 
                                            variant     ="contained" 
                                            size        ="small" 
                                            onClick     ={() =>goOption(option.id_casa)}
                                            startIcon   ={<HomeIcon />}
                                        >
                                            Ver casa
                                        </Button>

                                        {/* <Button 
                                            variant     ="contained" 
                                            size        ="small" 
                                            color       ="success" 
                                            style       ={{marginLeft:10}}
                                            disabled    ={house.activo_casa ? false : true}
                                            onClick={ () => clickWhatsapp(house.id_casa)}
                                        >
                                        Compartir
                                        </Button>  */}
                                    </ButtonsCasa>
                                </Card>
                            </DivCasas>
                        
                        ))
                    
                }
                
            </div>
        </>
     );
}
 
export default OpcionesResponsive;

