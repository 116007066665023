import React from 'react';
import { Roller } from 'react-spinners-css';
import { ProgresReload, TitleProgress } from './ProgressBar.styles';

const ProgressBarReload = ( props ) => {
    return ( 
        <ProgresReload>
            <Roller color="#004274" />
            <TitleProgress>{props.title}</TitleProgress>
        </ProgresReload>
     );
}
 
export default ProgressBarReload;