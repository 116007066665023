import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    CALENDARY_CREATE,
    CALENDARY_CREATE_SUCCESS,
    CALENDARY_CREATE_ERROR,
    CALENDARY_CREATE_INIT,
    CALENDARYS_LOADING,
    CALENDARYS_LOADING_SUCCESS,
    CALENDARYS_LOADING_ERROR,
    CALENDARY_LOAD,
    CALENDARY_LOAD_SUCCESS,
    CALENDARY_LOAD_ERROR,
    CALENDARY_DESACTIVE,
    CALENDARY_DESACTIVE_ERROR
} from '../types';



/*  Create Calendary
    function que crea el calendario
    Nota: El slash al final del endpoint es para redireccionar al server
*/ 
    export function createCalendaryActionFn(calendary) {

        return async ( dispatch ) => {
            
            dispatch( createCalendary(calendary) );

            try{
                let responseCalendary = await clientAxios.post('/calendarios/crear/', calendary);
                dispatch( createCalendarySuccess(responseCalendary.data.data) );
            }catch( error ){
                dispatch( createCalendaryError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el calendario");
            }
        }

    }

    // Create Calendary init action(se activa el flag a true para iniciar la creación)
    export function createCalendaryInitFn(flag){
        return ( dispatch ) => {
            dispatch( createCalendaryInit(flag));
        }
    }

    const createCalendary = ( ) => ({
        type    : CALENDARY_CREATE
    });

    const createCalendarySuccess = (calendary) => ({
        type    : CALENDARY_CREATE_SUCCESS,
        payload : calendary,
        flag    : true,
    })

    const createCalendaryError = () => ({
        type    : CALENDARY_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createCalendaryInit = (flag) =>({
        type    : CALENDARY_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* 
    DESACTIVE Calendarys 
    function para desactivar el calendario
*/
    export const desactiveCalendaryAction = (id) => {

        return async (dispatch)=>{
            dispatch( getCalendaryDesactive(id) );

            try{
                
                let responseDesactiveCalendary = await clientAxios.post(`/calendarios/change-active/?id_calendario=${id}`);

                if(responseDesactiveCalendary.status === 200){
                    dispatch( downloadCalendary() );
                    let responseCalendaryActive = await clientAxios.get('/calendarios', );
                    dispatch( downloadCalendarySuccess (responseCalendaryActive.data.data) );
                }
                
            }catch( error ){
                dispatch( desactiveCalendaryError() );
                SweetAlertBasic("error","Ups","Hubo un error al desactivar/activar el calendario");  
            }
        }

    }

    const getCalendaryDesactive = (id)=>({
        type: CALENDARY_DESACTIVE,
        payload: id
    })

    const desactiveCalendaryError = ( ) =>({
        type: CALENDARY_DESACTIVE_ERROR,
        error: true,
        flag: false
    }) 

/*  
    GRID CALENDARYS 
    function que trae todos los calendarios activos y desactivos
*/
    export function getAllCalendarysAction() {
        return async ( dispatch ) => {
            dispatch( downloadCalendary() );

            try{
                const responseCalendarys = await clientAxios.get('/calendarios', );
                dispatch( downloadCalendarySuccess (responseCalendarys.data.data) );

            }catch( error ){
                dispatch( downloadCalendaryError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los calendarios");   
            }
        }
    }

    const downloadCalendary = () => ({
        type: CALENDARYS_LOADING,
        payload: true
    })

    const downloadCalendarySuccess = ( calendarys ) => ({
        type: CALENDARYS_LOADING_SUCCESS,
        payload: calendarys
    })

    const downloadCalendaryError = (error) => ({
        type: CALENDARYS_LOADING_ERROR,
        error: error
    })

/*  
    EDIT CALENDARYS 
    function que trae la información del un solo calendario para visualizarlo
*/
    export const getCalendaryAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadCalendary() );

            try{
                let responseCalendaryId = await clientAxios.get(`/calendarios/${id}`);
                dispatch( loadCalendarySuccess (responseCalendaryId.data.data) );
            }catch( error ){
                dispatch( loadCalendaryError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar el calendario");  
            }
        }   
    }

    const getLoadCalendary = () => ({
        type: CALENDARY_LOAD,
        payload: true
    })

    const loadCalendarySuccess = (calendary) => ({
        type: CALENDARY_LOAD_SUCCESS,
        editarEquipo: calendary
    })

    const loadCalendaryError = () => ({
        type: CALENDARY_LOAD_ERROR,
        error: true
    })
    