import { 
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_LOGOUT,
    LOGIN_INIT,
    LOGIN_ACCESS,
    LOGIN_DENEGED,
    LOGOUT_INIT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
} from '../types';

const initialState = {
    error: null,
    loading: false,
    login: null,
    token: null,
    user: null
}   

export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case LOGIN_INIT:
            return{
                ...state,
                loading: true
            }

        case LOGIN_SUCCESS:
            return{
                ...state,
                login: action.login,
                loading: false,
                user: action.user
            }
        case LOGIN_ERROR:
        case LOGIN_DENEGED:
        case LOGOUT_ERROR:
            return{
                ...state,
                error: action.error,
                loading: false
            }

        case LOGIN_LOGOUT:
            return{
                ...state,
                login: null,
                loading: false,
                token: null,
                user: null
            }
        
        case LOGIN_ACCESS:
            return{
                ...state,
                user: action.user,
                loading: false
            }
        
        case LOGOUT_INIT:
            return{
                ...state,
                loading: true
            }
        
        case LOGOUT_SUCCESS:
            return{
                ...state,
                loading: false,
                token: null,
                login: null
            }
        default:
            return state;
    }
}