import React, { useState }          from 'react'
import { createDesarrolladorAction }         from '../../actions/desarrolladorActions';
import { useDispatch, useSelector } from 'react-redux';
import { SweetAlertBasic }          from '../../utils/sweetAlert';
import { useNavigate, Navigate }    from 'react-router-dom';
import ProgressBar                  from '../Progress/ProgressBar.component';
import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import InputLabel                   from '@mui/material/InputLabel';
import MenuItem                     from '@mui/material/MenuItem';
import Select                       from '@mui/material/Select';
import FormControl                  from '@mui/material/FormControl';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import Stack                        from '@mui/material/Stack';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
} from '../Usuarios/Users.styles';

const NewDesarrollador = () => {

    const [ nombreDesarrollador, setNombreDesarrollador ]               = useState('');
    const [ emailDesarrollador, setEmailDesarrollador ]                 = useState('');
    const [ telefonoDesarrollador, setTelefonoDesarrollador ]           = useState('')    
    const [ cpDesarrollador, setCPDesarrollador ]                       = useState('');
    const [ tipoDesarrollador, setTipoDesarrollador ]                   = useState('');
    const [ prioridadDesarrollador, setPrioridadDesarrollador ]         = useState('');
    const [ extraDesarrollador, setExtraDesarrollador ]                 = useState('');
    const [ descripcionDesarrollador, setDescripcionDesarrollador ]     = useState('');
    const [ estadoDesarrollador, setEstadoDesarrollador ]               =  useState('');
    

    // Dispatch
    const dispatch      = useDispatch();

    // Call Action
    const addCreate     = (house) => dispatch( createDesarrolladorAction(house) );

    // Loading
    const loading                   = useSelector( state    => state.desarrollador?.loading);
    const desarrolladorSuccess      = useSelector( state    => state.desarrollador?.flag);
  
    // SELECTS
    const handleEstado = ( event ) => {
        setEstadoDesarrollador(event.target.value);
    };

    const handleTipoDesarrollador = ( event ) => {
        setTipoDesarrollador(event.target.value);
    };

    const handlePrioridadDesarrollador = ( event ) => {
        setPrioridadDesarrollador(event.target.value);
    };

    //History
    const navigate = useNavigate();

    function goDesarrolladores() {
        navigate('/desarrolladores');
    }
  
    // Create of User
    const createDesarrollador = e => {
        e.preventDefault();

        //Validate form
        if( nombreDesarrollador.trim() === '' ||  emailDesarrollador.trim() === '' ){
            SweetAlertBasic("error","Ups","Le falta llenar campos");
            return;
        }

        // CreateUser
        addCreate({
            nombre_desarrollador: nombreDesarrollador,
            email_desarrollador: emailDesarrollador,
            telefono_cel_desarrollador: telefonoDesarrollador,
            cp_desarrollador: cpDesarrollador,
            tipo_desarrollador: tipoDesarrollador,
            prioridad_desarrollador: prioridadDesarrollador,
            imagen_desarrollador: "string",
            extra_desarrollador: extraDesarrollador,
            descripcion_desarrollador: descripcionDesarrollador,
            id_estado: estadoDesarrollador,
            id_delegacion: 1,
            activo_desarrollador: true
        });

    }


    return ( 
        <DivCenter >
            {
                loading ? 
                    <ProgressBar title="Creando Desarrollador..."/>
                :
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)':{},
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={createDesarrollador}
                    
                >
                    <TitleUser>Crear Desarrollador</TitleUser>

                            <RowUser>

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el nombre del desarrollador" 
                                    variant="outlined"
                                    type="text"
                                    name="nombreDesarrollador"
                                    value={nombreDesarrollador}
                                    onChange={ e => setNombreDesarrollador(e.target.value) }
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el email del desarrollador" 
                                    variant="outlined"
                                    type="text"
                                    name="emailDesarrollador"
                                    value={emailDesarrollador}
                                    onChange={ e => setEmailDesarrollador(e.target.value) }
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el telefono del desarrollador" 
                                    variant="outlined"
                                    name="telefonoDesarrollador"
                                    value={telefonoDesarrollador}
                                    type="number"
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    onChange={ e => setTelefonoDesarrollador( Number(e.target.value) ) }
                                    fullWidth
                                />


                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el código postal del desarrollador" 
                                    variant="outlined"
                                    name="cpDesarrollador"
                                    value={cpDesarrollador}
                                    onChange={ e => setCPDesarrollador(e.target.value) }
                                    fullWidth
                                />
                                
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa algo extra que quieras agregar al desarrollador" 
                                    variant="outlined"
                                    name="extraDesarrollador"
                                    value={extraDesarrollador}
                                    onChange={ e => setExtraDesarrollador(e.target.value) }
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa la descripcion del desarrollador" 
                                    variant="outlined"
                                    name="descripcionDesarrollador"
                                    value={descripcionDesarrollador}
                                    onChange={ e => setDescripcionDesarrollador(e.target.value) }
                                    fullWidth
                                />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                    <Select
                                        labelId="estadoDesarrollador"
                                        id="demo-simple-select"
                                        label="estadoDesarrollador"
                                        value={estadoDesarrollador}
                                        onChange={handleEstado}
                                    >
                                        <MenuItem value={1}>Aguascalientes</MenuItem>
                                        <MenuItem value={2}>Baja California</MenuItem>
                                        <MenuItem value={3}>Baja California Sur</MenuItem>
                                        <MenuItem value={4}>Campeche</MenuItem>
                                        <MenuItem value={5}>Coahuila</MenuItem>
                                        <MenuItem value={6}>Colima</MenuItem>
                                        <MenuItem value={7}>Chiapas</MenuItem>
                                        <MenuItem value={8}>Chihuahua</MenuItem>
                                        <MenuItem value={9}>Durango</MenuItem>
                                        <MenuItem value={10}>Ciudad de México</MenuItem>
                                        <MenuItem value={11}>Guanajuato</MenuItem>
                                        <MenuItem value={12}>Guerrero</MenuItem>
                                        <MenuItem value={13}>Hidalgo</MenuItem>
                                        <MenuItem value={14}>Jalisco</MenuItem>
                                        <MenuItem value={15}>Estado de México</MenuItem>
                                        <MenuItem value={16}>Michoacán</MenuItem>
                                        <MenuItem value={17}>Morelos</MenuItem>
                                        <MenuItem value={18}>Nayarit</MenuItem>
                                        <MenuItem value={19}>Nuevo León</MenuItem>
                                        <MenuItem value={20}>Oaxaca</MenuItem>
                                        <MenuItem value={21}>Puebla</MenuItem>
                                        <MenuItem value={22}>Querétaro</MenuItem>
                                        <MenuItem value={23}>Quintana Roo</MenuItem>
                                        <MenuItem value={24}>San Luis Potosí</MenuItem>
                                        <MenuItem value={25}>Sinaloa</MenuItem>
                                        <MenuItem value={26}>Sonora</MenuItem>
                                        <MenuItem value={27}>Tabasco</MenuItem>
                                        <MenuItem value={28}>Tamaulipas</MenuItem>
                                        <MenuItem value={29}>Tlaxcala</MenuItem>
                                        <MenuItem value={30}>Veracruz</MenuItem>
                                        <MenuItem value={31}>Yucatán</MenuItem>
                                        <MenuItem value={32}>Zacatecas</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tipo de Desarrolador</InputLabel>
                                    <Select
                                        labelId="tipoDesarrollador"
                                        id="demo-simple-select"
                                        label="tipoDesarrollador"
                                        value={tipoDesarrollador}
                                        onChange={handleTipoDesarrollador}
                                    >
                                        <MenuItem value="Propio">Propio</MenuItem>
                                        <MenuItem value="Convenio">Convenio</MenuItem>
                                        <MenuItem value="Extra">Extra</MenuItem>
                                        <MenuItem value="Otro">Otro</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Prioridad</InputLabel>
                                    <Select
                                        labelId="prioridadDesarrollador"
                                        id="demo-simple-select"
                                        label="prioridadDesarrollador"
                                        value={prioridadDesarrollador}
                                        onChange={handlePrioridadDesarrollador}
                                    >
                                        <MenuItem value={1}>Alta</MenuItem>
                                        <MenuItem value={2}>Media</MenuItem>
                                        <MenuItem value={3}>Baja</MenuItem>
                                        <MenuItem value={4}>Otro</MenuItem>
                                    </Select>
                                </FormControl>
                            </RowUser>
                           
                    <DivButtons >
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={goDesarrolladores}
                                variant="contained" 
                                size="large"
                                color="error"
                            >
                                Cancelar
                            </Button>
                        
                            <Button 
                                variant="contained" 
                                size="small" 
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Crear Desarrollador
                            </Button>
                        </Stack>
                    </DivButtons>
                </Box>
            }
            {
                desarrolladorSuccess ?
                    <Navigate to='/desarrolladores/exitoso'/>
                :
                null
            }
            
            
        </DivCenter>
     );
}
 
export default NewDesarrollador;