import React from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import{
    SidebarBox
} from './Sidebar.styles';

const SidebarAdmin = () => {
    return ( 
        <SidebarBox>
            <ProSidebar>
                <Menu iconShape="square">
                    <MenuItem
                        //  suffix={<span >3</span>}
                    >
                        Precontratos
                    </MenuItem>
                    <MenuItem
                        // suffix={<span>5</span>}
                    >
                        Contratos
                    </MenuItem>
                    <MenuItem
                        // suffix={<span>10</span>}
                    >
                        Contratos a firmar
                    </MenuItem>
                    <MenuItem
                        // suffix={<span>+99</span>}
                    >
                        Con escrituras
                    </MenuItem>
                    <MenuItem
                        // suffix={<span>+99</span>}
                    >
                        Visita a firma
                    </MenuItem>   
                    <MenuItem
                        // suffix={<span>+99</span>}
                    >
                       Tramite a doc
                    </MenuItem>   
                    <MenuItem
                        // suffix={<span>+99</span>}
                    >
                       Venta
                    </MenuItem>   
                    <MenuItem
                        // suffix={<span>+99</span>}
                    >
                        Perdido
                    </MenuItem>               
                </Menu>
            </ProSidebar>
        </SidebarBox>
     );
}
 
export default SidebarAdmin;