import React, { useEffect }         from 'react';
import  { useDispatch }             from 'react-redux';
import  { getLoginUserFn }          from '../../actions/loginActions';
import ImageUser                    from '../../Images/users_goals.png';
import ImageUserCheck               from '../../Images/user_check.png';
import ImageSchedule                from '../../Images/schedule_icon.png';
import StarIcon                     from '../../Images/star_icon.png';
import MoneyIcon                    from '../../Images/money_icon.png';
import EmptyGoals                   from '../Metas/EmptyGoals.component';
import {
    DivGrid,
    BoxGoals,
    TitleGoal,
    DivGridBox,
    UsersGoals,
    SubtitleGoal,
    ThreeGoal,
    UsersProspect,
    ScheduleImg,
    Ventas
} from './styles/Especialista.styles';

const DashboardLider = () => {

    const dispatch          = useDispatch();

    // USE EFFECT LOGIN
    useEffect( () => {
        const loginUser = () => dispatch( getLoginUserFn() );
        loginUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    let goal = false;

    return (  
        <>  
        {
            goal ? 
            <> 
            <DivGridBox>
                <>
                    <TitleGoal>Esta Semana</TitleGoal>
                    <DivGrid> 
                        <BoxGoals>
                            <UsersGoals  src={ImageUser} alt="Usuarios" />
                            <SubtitleGoal>Clientes Nuevos</SubtitleGoal>
                            <ThreeGoal>9</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals>
                            <UsersProspect  src={ImageUserCheck} alt="Prospectos" />
                            <SubtitleGoal>Prospectos</SubtitleGoal>
                            <ThreeGoal>2</ThreeGoal>
                        </BoxGoals>
                        
                        <BoxGoals>
                            <ScheduleImg  src={ImageSchedule} alt="Agendas" />
                            <SubtitleGoal>Agendados</SubtitleGoal>
                            <ThreeGoal>2</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals>
                            <ScheduleImg  src={StarIcon} alt="Precontrato" />
                            <SubtitleGoal>Precontrato</SubtitleGoal>
                            <ThreeGoal>1</ThreeGoal>
                        </BoxGoals>
                    </DivGrid>
                </>
                <>
                    <TitleGoal>Esta Mes</TitleGoal>
                    <DivGrid> 
                        <BoxGoals>
                            <UsersGoals  src={ImageUser} alt="Usuarios" />
                            <SubtitleGoal>Clientes Nuevos</SubtitleGoal>
                            <ThreeGoal>20</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals>
                            <UsersProspect  src={ImageUserCheck} alt="Usuarios" />
                            <SubtitleGoal>Prospectos</SubtitleGoal>
                            <ThreeGoal>15</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals>
                            <ScheduleImg  src={ImageSchedule} alt="Agendas" />
                            <SubtitleGoal>Agendados</SubtitleGoal>
                            <ThreeGoal>2</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals>
                            <ScheduleImg  src={StarIcon} alt="Precontrato" />
                            <SubtitleGoal>Precontrato</SubtitleGoal>
                            <ThreeGoal>5</ThreeGoal>
                        </BoxGoals>
                    </DivGrid>
                </>
                <>
                    <TitleGoal>Metas Mensuales</TitleGoal>
                    <DivGrid> 
                        <BoxGoals>
                            <UsersProspect  src={ImageUserCheck} alt="Usuarios" />
                            <SubtitleGoal>Prospectos</SubtitleGoal>
                            <ThreeGoal>9</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals>
                            <ScheduleImg  src={ImageSchedule} alt="Agendas" />
                            <SubtitleGoal>Agendados</SubtitleGoal>
                            <ThreeGoal>6</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals>
                            <ScheduleImg  src={StarIcon} alt="Precontrato" />
                            <SubtitleGoal>Precontrato</SubtitleGoal>
                            <ThreeGoal>10</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals>
                            <Ventas  src={MoneyIcon} alt="Ventas" />
                            <SubtitleGoal>Ventas</SubtitleGoal>
                            <ThreeGoal>10</ThreeGoal>
                        </BoxGoals>
                    </DivGrid>
                </>
            </DivGridBox>
        </>
        :
         <EmptyGoals
             message={'Vaya, no tenemos metas... por ahora.'}
         />

        }
        </>
    );
}
 
export default DashboardLider;