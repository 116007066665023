import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Redux
import { Provider }   from 'react-redux';
import store          from './store';

// MATERIAL UI
import FormTable  from './components/Form/FormTable.component'
import Menu       from './components/Form/FormMenu.component';
import FormText   from './components/Form/FormText.component';
import Tabs       from './components/Clientes/TabsMui.component';
import ModalForm  from './components/Form/ModalForm.component';
import Table      from './components/Form/Table.component';
import Accordion  from './components/Form/Accordion.component';

// ROUT PRIVATE
import RouterUsers              from './components/Routes/RouterUsers.component';
import RouterClients            from './components/Routes/RouterClients.component';
import RouterCalendarys         from './components/Routes/RouterCalendarys.component';
import RouterRoles              from './components/Routes/RouterRoles.component';
import RouterStatus             from './components/Routes/RouterStatus.component';
import RouterTeams              from './components/Routes/RouterTeams.component';
import RouterRegisters          from './components/Routes/RouterRegisters.component';
import RouterFilters            from './components/Routes/RouterFilters.component';
import RouterDashboard          from './components/Routes/RouterDashboard.component';
import RouterInventario          from './components/Routes/RouterInventario.component';
import RouterSpecialists          from './components/Routes/RouterSpecialists.component';

// ERROR
import ErrorRole        from './components/Routes/Errors/ErrorRole.component';


// LOGIN LOGOUT
import Login          from './components/login/Login.component';
import Logout         from './components/login/Logout.component';

// HEADER SIDEBAR
import Header         from './components/Header/Header.component';

// DASHBOARD 
import DashboardAdmin         from './components/Dashboards/DashboardAdmin.component';
import DashboardContratos     from './components/Dashboards/DashboardContratos.component';
import DashboardEspecialista  from './components/Dashboards/DashboardEspecialista.component';
import DashboardInventario    from './components/Dashboards/DashboardInventario.component';
import DashboardRecepcion     from './components/Dashboards/DashboardRecepcion.component';
import DashboardRH            from './components/Dashboards/DashboardRH.component';
import DashboardLider         from './components/Dashboards/DashboardLider.component';
import DashboardMarketing     from './components/Dashboards/DashboardMarketing.component';

// USERS
import NewUser                    from './components/Usuarios/NewUser.component';
import EditUser                   from './components/Usuarios/EditUser.component';
import UserSuccess                from './components/Usuarios/UserSuccess.component';
import UserUpdateSuccess          from './components/Usuarios/UserUpdateSuccess.component';
import Users                      from './components/Usuarios/Users.component';

// SCHEDULE
import ScheduleSpecialist          from './components/Agendas/ScheduleSpecialist.component';

// CLIENTS
import NewClient                  from './components/Clientes/NewClient.component';
import EditClient                 from './components/Clientes/EditClient.component';
import ClientSuccess              from './components/Clientes/ClientSuccess.component';
import Clients                    from './components/Clientes/Clients.component';
import ClientsSpecialist          from './components/Clientes/ClientsSpecialist.component';
import ClientsLider               from './components/Clientes/ClientsLider.component';
import ClientSuccessUpdate        from './components/Clientes/UpdateClientSuccess.component';
import EditClientSales            from './components/Clientes/EditClientSales.component';

// TEAMS
import NewTeam        from './components/Equipos/NewTeam.component';
import EditTeam       from './components/Equipos/EditTeam.component';
import TeamSuccess    from './components/Equipos/TeamSuccess.component';
import Teams          from './components/Equipos/Teams.component';

// REGISTER
import NewRegister        from './components/Registros/NewRegister.component';
import EditRegister       from './components/Registros/EditRegister.component';
import RegisterSuccess    from './components/Registros/RegisterSuccess.component';
import Registers          from './components/Registros/Registers.component';

// FILTER
import NewFilter        from './components/Filtros/NewFilter.component';
import EditFilter       from './components/Filtros/EditFilter.component';
import FilterSuccess    from './components/Filtros/FilterSuccess.component';
import Filters          from './components/Filtros/Filters.component';

// CALENDARY
import NewCalendary        from './components/Calendarios/NewCalendary.component';
import EditCalendary       from './components/Calendarios/EditCalendary.component';
import CalendarySuccess    from './components/Calendarios/CalendarySuccess.component';
import Calendarys          from './components/Calendarios/Calendarys.component';

//CASAS
import NewHouse                 from './components/Casas/NewHouse.component';
import HouseSuccess             from './components/Casas/HouseSuccess.component';
import EditHouse                from './components/Casas/EditHouse.component';
import HouseUpdateSuccess       from './components/Casas/HouseUpdateSuccess.component';

//DESARROLLO 
import Desarrollos              from './components/Desarrollos/Desarrollos.component';
import NewDesarrollo            from './components/Desarrollos/NewDesarrollo.component';
import DesarrolloSuccess        from './components/Desarrollos/DesarrolloSuccess.component';

//DESARROLLADORES
import Desarrolladores              from './components/Desarrolladores/Desarrolladores.component';
import NewDesarrolladores           from './components/Desarrolladores/NewDesarrollador.component';
import DesarrolladoresSuccess       from './components/Desarrolladores/DesarrolladoresSuccess.component';

// ROLES
import Roles            from './components/Roles/Roles.component';
import EditRoles        from './components/Roles/RoleId.component';   

// STATUS
import Status            from './components/Status/Status.component';
import EditStatus        from './components/Status/StatusId.component';   

// SEGUIMIENTO
import Seguimiento                from './components/Seguimientos/Seguimiento.component';
import SeguimientoSuccess         from './components/Seguimientos/SeguimientoSuccess.component';

// OPCIONES

import OpcionesCasa               from './components/Opciones/OpcionesCasas.component';
import OpcionesCasaId             from './components/Opciones/OpcionesCasaId.component';

// MAPA
import Mapa                       from './components/Mapa/Mapa.component';

// LANDING
import Landing                    from './components/Landing/Landing.component';

// NAVIGATION
import Navigation                 from './components/Navigation';


function App() {
  return (
    <Router>
      <Provider store={store}>
        <Header/>
  
        <Routes>
            {/* PUBLIC */}
            {/* MATERIAL UI */}
            <Route path="/table" element={<FormTable/>}/>
            <Route path="/input" element={<FormText/>}/>
            <Route path="/tabs" element={<Tabs/>}/>
            <Route path="/menu" element={<Menu/>}/>
            <Route path="/modalForm" element={<ModalForm/>}/>
            <Route path="/table" element={<Table/>}/>
            <Route path="/accordion" element={<Accordion/>}/>
            
            

            <Route    path="/opciones/casas"              element={<OpcionesCasa/>}/>
            <Route    path="/opciones/:id_casa"           element={<OpcionesCasaId/>}/>
            <Route    path="/mapa"                        element={<Mapa/>}/>
            <Route    path="/registro"                    element={<Landing/>}/>
            

          {/* ERROR */}
          <Route path="/errorRole" element={<ErrorRole/>}/>

          {/* Login */}
          <Route path="/" element={<Login/>}/>
          {/* Login */}
          <Route path="/logout" element={<Logout/>}/>

          {/* DASHBOARDS */}
          
          <Route          element={<RouterDashboard/> }>
            <Route        path="/dashboard"                         element={<DashboardAdmin/> }/>
            <Route        path="/dashboard/especialista"            element={<DashboardEspecialista/> }/>
            <Route        path="/dashboard/inventario"              element={<DashboardInventario/> }/>
            <Route        path="/dashboard/rh"                      element={<DashboardRH/> }/>
            <Route        path="/dashboard/recepcion"               element={<DashboardRecepcion/> }/>
            <Route        path="/dashboard/contratos"               element={<DashboardContratos/> }/>
            <Route        path="/dashboard/lider"                   element={<DashboardLider/> }/>
            <Route        path="/dashboard/marketing"              element={<DashboardMarketing/> }/>
          </Route>

          {/* User */}
          <Route          element={<RouterUsers/> }>
            <Route        path="/usuario/:id_usuario"               element={<EditUser/> }/>
            <Route        path="/usuarios"                          element={<Users/> }/>
            <Route        path="/usuarios/crear"                    element={<NewUser/> }/>
            <Route        path="/usuario/exitoso"                   element={<UserSuccess/> }/>
            <Route        path="/usuario/actualizado/exitoso"       element={<UserUpdateSuccess/> }/>
            
          </Route>

          {/* Clientes */}
          <Route          element={<RouterClients/> }>
            <Route path="/clientes/crear"                     element={<NewClient/> }/>
            <Route path="/clientes/:id_cliente"               element={<EditClient/> }/>
            <Route path="/cliente/ventas/:id_cliente"         element={<EditClientSales/> }/>
            <Route path="/cliente/exitoso"                    element={<ClientSuccess/> }/>
            <Route path="/cliente/actualizado/exitoso"        element={<ClientSuccessUpdate/> }/>
            <Route path="/clientes"                           element={<Clients/> }/>
            <Route path="/clientes/lider"                     element={<ClientsLider/> }/>
          </Route>
          
          {/* Navegacion Especialista */}
          <Route          element={<RouterSpecialists/> }>
            <Route path="/clientes/especialista"                  element={<ClientsSpecialist/> }/>
            <Route path="/clientes/agenda"                        element={<ScheduleSpecialist/> }/>
            <Route path="/seguimiento/crear/:id_cliente"          element={<Seguimiento/> }/>
            <Route path="/seguimiento/exitoso"                    element={<SeguimientoSuccess/> }/>
          </Route>

          {/* Roles */}
          <Route          element={<RouterRoles/> }>
            <Route path="/roles"                  element={<Roles/> }/>
            <Route path="/roles/:id_rol"          element={<EditRoles/> }/>
          </Route>

          {/* Status */}
          <Route          element={<RouterStatus/> }>
            <Route path="/status"                  element={<Status/> }/>
            <Route path="/status/:id_status"       element={<EditStatus/> }/>
          </Route>

          {/* Registros */}
          <Route          element={<RouterRegisters/> }>
            <Route path="/registros/crear"          element={<NewRegister/> }/>
            <Route path="/registros/:id_registro"   element={<EditRegister/> }/>
            <Route path="/registros/exitoso"        element={<RegisterSuccess/> }/>
            <Route path="/registros"                element={<Registers/> }/>
          </Route>

          {/* Filtros */}
          <Route          element={<RouterFilters/> }>
            <Route path="/filtros/crear"          element={<NewFilter/> }/>
            <Route path="/filtros/:id_filtro"     element={<EditFilter/> }/>
            <Route path="/filtros/exitoso"        element={<FilterSuccess/> }/>
            <Route path="/filtros"                element={<Filters/> }/>
          </Route>

          {/* Equipos */}
          <Route          element={<RouterTeams/> }>
            <Route path="/equipos/crear"        element={<NewTeam/> }/>
            <Route path="/equipos/:id_equipo"   element={<EditTeam/> }/>
            <Route path="/equipos/exitoso"      element={<TeamSuccess/> }/>
            <Route path="/equipos"              element={<Teams/> }/>
          </Route>

          {/* Calendarios */}
          <Route          element={<RouterCalendarys/> }>
            <Route path="/calendarios/crear"       element={<NewCalendary/> }/>
            <Route path="/calendarios/:id_equipo"  element={<EditCalendary/> }/>
            <Route path="/calendarios/exitoso"     element={<CalendarySuccess/> }/>
            <Route path="/calendarios"             element={<Calendarys/> }/>
          </Route>

          {/* ROLE Inventarario */}
          <Route          element={<RouterInventario/> }>
            <Route path="/desarrollos"              element={<Desarrollos/> }/>
            <Route path="/desarrollos/crear"        element={<NewDesarrollo/> }/>
            <Route path="/desarrollos/exitoso"      element={<DesarrolloSuccess/> }/>

            {/* Desarrolladores */}
            <Route path="/desarrolladores"          element={<Desarrolladores/> }/>
            <Route path="/desarrolladores/crear"    element={<NewDesarrolladores/> }/>
            <Route path="/desarrolladores/exitoso"  element={<DesarrolladoresSuccess/> }/>

            {/* Casas */}
            <Route path="/casas"                  element={<DashboardInventario/> }/>
            <Route        path="/casas/crear"                   element={<NewHouse/> }/>
            <Route        path="/casas/exitoso"                 element={<HouseSuccess/> }/>
            <Route        path="/casas/actualizada/exitoso"     element={<HouseUpdateSuccess/> }/>
            <Route        path="/casa/:id_casa"                 element={<EditHouse/> }/>
          </Route>

        </Routes>
        <Navigation/>
      </Provider>
    </Router>
  );
}

export default App;
