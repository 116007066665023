import React, {useState}                        from 'react';
import { useDispatch, useSelector }             from 'react-redux';
import Button                                   from '@mui/material/Button';
import TextField                                from '@mui/material/TextField';
import InputLabel                               from '@mui/material/InputLabel';
import MenuItem                                 from '@mui/material/MenuItem';
import Select                                   from '@mui/material/Select';
import Box                                      from '@mui/material/Box';
import FormControl                              from '@mui/material/FormControl';
import { SweetAlertBasic }                      from '../../utils/sweetAlert';
import { createClientActionFn }                 from '../../actions/clientActions';
import ImageFirst                               from '../../Images/image_register_1.jpg';
import ImageSecond                              from '../../Images/landing_section_1.png';
import ImageThree                               from '../../Images/landing_section_2.png';
import ImageFour                                from '../../Images/landing_section_3.png';
import ImageFive                                from '../../Images/landing_section_4.png';
import ImageSix                                 from '../../Images/image_register_3.jpg';
import {
    WrapperRegister,
    WrapperSection,
    WrapperFooter
}                                               from './landing.styles';
import {
    tipoClienteJson
}                                               from '../../utils/main.js';

const Landing = () => {

    const dispatch                                              = useDispatch();
    const addCreateClient                                       = (client) => dispatch( createClientActionFn(client) );
    const clientSuccess                                         = useSelector( state => state?.clientes?.flag);
    const [ nombreCliente, setNombreCliente ]                   = useState('');
    const [ telefonoCliente, setTelefonoCliente ]               = useState('');
    const [ emailCliente, setEmailCliente ]                     = useState('');
    const [ idTipoCliente, setIdTipoCliente]                    = useState(''); 
    const [ enabledButton, setEnabledButton ]                   = useState(false);


    console.log("CLIENTSUCCESS->", clientSuccess)

    // Create of Client
    const createClient = e => {
        e.preventDefault();

        //Validate form
        if( nombreCliente.trim() === '' || telefonoCliente.length === 0 || emailCliente.trim() === ''){
            SweetAlertBasic("error","Ups","Tdos los campos son obligatorios");
            return;
        }
        setEnabledButton(true);
        addCreateClient({
            nombre_cliente              :   nombreCliente,
            telefono_cliente            :   telefonoCliente,
            email_cliente               :   emailCliente,
            tipo_credito                :   4,
            id_usuario                  :   1,
            id_registro                 :   1,
            id_tipo_cliente             :   idTipoCliente,
            tipo_registro               :   'Landing',  
        });        
        if(clientSuccess){
            setNombreCliente('');
            setTelefonoCliente('');
            setEmailCliente('');
            setIdTipoCliente('');
            setEnabledButton(false);
        }
    }


    return ( 
    <>
        <WrapperRegister>
            <Box
                component   ="form"
                noValidate
                autoComplete="off"
                onSubmit={createClient}
                
            >
                <div
                    className="image_section"
                >
                    <div
                        className="first_section"
                    >
                        <h1
                            className="title_register"
                        >
                            ¿Quieres vender, comprar o rentar?
                        </h1>
                        <h3
                            className="information_register"
                        >
                            Registrate y uno de nuestros asesores te contactará.
                        </h3>
                            <div className="form_register">
                                <TextField  
                                    className="input_register"
                                    name="nombreCliente"
                                    value={nombreCliente}
                                    onChange={ e => setNombreCliente(e.target.value) }
                                    label="Ingrese su nombre completo"
                                    fullWidth
                                />
                                <TextField  
                                    className="input_register"
                                    name="emailCliente"
                                    value={emailCliente}
                                    onChange={ e => setEmailCliente(e.target.value) }
                                    label="Ingrese su email"
                                    fullWidth
                                />
                                <TextField  
                                    className="input_register"
                                    name="telefonoCliente"
                                        value={telefonoCliente}
                                        type="number"
                                        onInput = {(e) =>{
                                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                        }}
                                    onChange={ e => setTelefonoCliente( Number(e.target.value) ) }
                                    label="Ingrese su télefono celular"
                                    fullWidth
                                />
                            <FormControl 
                                fullWidth
                                className="input_register"
                            >
                                <InputLabel id="demo-simple-select-label">¿Que desas realizar?</InputLabel>
                                <Select
                                    labelId="estado_civil"
                                    id="demo-simple-select"
                                    label="Tipo Cliente"
                                    name="id_tipo_cliente"
                                    onChange={ e => setIdTipoCliente(e.target.value) }
                                >
                                    {tipoClienteJson.map((tipo) => (
                                        <MenuItem
                                            key={tipo.value}
                                            value={tipo.value}
                                        >
                                        {tipo.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            </div>

                            <div
                                className="button_register"
                            >
                                <Button 
                                    variant     ="contained" 
                                    size        ="small" 
                                    color       ="primary"
                                    type        ="submit"
                                    fullWidth
                                    disabled    ={enabledButton}
                                >
                                    Registrarme
                                </Button>

                            </div>
                    </div>
                    <img 
                        className="image_register"
                        src={ImageFirst}
                        alt="registrate"
                    />
                </div>
            </Box>
           
        </WrapperRegister>
        <WrapperSection>
            <div className="landing_section_first">
                <div className="landing_text">
                    <div className="landing_text_first">
                        ¿Por qué hacerlo con nosotros?
                    </div>
                        <br/>
                    <div 
                        className="landing_text_second"
                    >
                        Somos una empresa 100% mexicana que llevamos años en el mercado inmobiliario,
                        te vamos a buscar la mejor opción, te ayudaremos desde el inicio hasta el final.   
                    </div>
                    
                            
                </div>
                <img
                    className="image_landing"
                    src={ImageSecond}
                    alt="conoce a plusvarq"
                />

            </div>

            <div className="divider_section"></div>

            <div className="landing_section_first">
                <img
                    className="image_landing"
                    src={ImageThree}
                    alt="conoce a plusvarq"
                />
                <div className="landing_text">
                    
                    <div className="landing_text_first">
                        Asesoría gratis
                    </div>
                        <br/>
                    <div 
                        className="landing_text_second"
                    >
                       Nuestra asesoría estará encarga por nuestros asesores inmobiliarios, ellos
                       te buscarán la mejor opción, te mandara las mejores opciones del mercado.
                    </div>
                    
                            
                </div>
            </div>

            <div className="divider_section"></div>

            <div className="landing_section_first">
                <div className="landing_text">
                    
                    <div className="landing_text_first">
                        Las mejores opciones
                    </div>
                        <br/>
                    <div 
                        className="landing_text_second"
                    >
                        Tenemos las mejores opciones para que puedas comprar o rentar.
                    </div>        
                </div>
                <img
                    className="image_landing"
                    src={ImageFour}
                    alt="conoce a plusvarq"
                />
            </div>
            <div className="divider_section"></div>
            <div className="landing_section_first">
                <img
                    className="image_landing"
                    src={ImageFive}
                    alt="conoce a plusvarq"
                />
                <div className="landing_text">
                    <div className="landing_text_first">
                        Agenda
                    </div>
                        <br/>
                    <div 
                        className="landing_text_second"
                    >
                       Si te gusta una opción enviada, podrás agendar para poder visitarla.
                    </div>        
                </div>
            </div>
            <div className="divider_section"></div>
        </WrapperSection>
        <WrapperFooter>
            <div
                className="image_section_down"
            >
                <div
                    className="first_section"
                >
                    <h1
                        className="title_register"
                    >
                        La mejor inmobiliaria para adquirir tu propiedad.
                    </h1>
                    <h3
                        className="information_register"
                    >
                        Registrate y serás atendido por uno de nuestros asesores.
                    </h3>
                </div>
                <img 
                    className="image_register"
                    src={ImageSix}
                    alt="registrate"
                />
            </div>
           
        </WrapperFooter>
    </> 
    );
}
 
export default Landing;