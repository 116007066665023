import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    CLIENT_CREATE,
    CLIENT_CREATE_SUCCESS,
    CLIENT_CREATE_ERROR,
    CLIENT_CREATE_INIT,
    CLIENTS_LOADING,
    CLIENTS_LOADING_SUCCESS,
    CLIENTS_LOADING_ERROR,
    CLIENT_LOAD,
    CLIENT_LOAD_SUCCESS,
    CLIENT_LOAD_ERROR,
    CLIENT_UPDATE_INIT,
    CLIENT_UPDATE_SUCCESS,
    CLIENT_UPDATE_ERROR,
    CLIENTS_LOADING_BY_USER,
    CLIENTS_BY_USER_LOADING_SUCCESS,
    CLIENTS_BY_USER_LOADING_ERROR,
    CLIENTS_LOADING_BY_TEAM,
    CLIENTS_BY_TEAM_LOADING_SUCCESS,
    CLIENTS_BY_TEAM_LOADING_ERROR,
} from '../types';



/* Create CLIENT*/ 
    export function createClientActionFn(client) {

        return async ( dispatch ) => {
            
            dispatch( createClient(client) );

            try{
                let response = await clientAxios.post('/clientes/crear/', client);
                dispatch( createClientSuccess(response.data.data) );
                if(client.tipo_registro === 'Landing'){
                    SweetAlertBasic("success","Felicidades!","En unos momentos se comunicará uno de nuestros asesores.");
                }
            }catch( error ){
                dispatch( createClientError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el cliente");
            }
        }

    }

    // Create Client init action
    export function createClientInitFn(flag){
        return ( dispatch ) => {
            dispatch( createClientInit(flag));
        }
    }

    const createClient = ( ) => ({
        type    : CLIENT_CREATE
    });

    const createClientSuccess = (client) => ({
        type    : CLIENT_CREATE_SUCCESS,
        payload : client,
        flag    : true,
    })

    const createClientError = () => ({
        type    : CLIENT_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createClientInit = (flag) =>({
        type    : CLIENT_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* GRID CLIENTS */
    export function getAllClientsAction() {
        return async ( dispatch ) => {
            dispatch( downloadClient() );

            try{
                const responseClients = await clientAxios.get('/clientes', );
                dispatch( downloadClientSuccess (responseClients.data.data) );

            }catch( error ){
                dispatch( downloadClientError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los clientes");   
            }
        }
    }

    const downloadClient = () => ({
        type: CLIENTS_LOADING,
        payload: true
    })

    const downloadClientSuccess = ( clients ) => ({
        type: CLIENTS_LOADING_SUCCESS,
        payload: clients
    })

    const downloadClientError = (error) => ({
        type: CLIENTS_LOADING_ERROR,
        error: error
    })

/* EDIT CLIENT */
    export const getClientAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadClient() );

            try{
                let response = await clientAxios.get(`/clientes/${id}`);
                dispatch( loadClientSuccess (response.data) );
            }catch( error ){
                dispatch( loadClientError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar el cliente");  
            }
        }   
    }

    const getLoadClient = () => ({
        type: CLIENT_LOAD,
        payload: true
    })

    const loadClientSuccess = (client) => ({
        type: CLIENT_LOAD_SUCCESS,
        editarCliente: client
    })

    const loadClientError = () => ({
        type: CLIENT_LOAD_ERROR,
        error: true
    })
    

/* UPDATE CLIENT */
export const updateClientAction = (client,id) => {
   
    return async (dispatch)=>{
        dispatch( updateClientInit(client) );

        try{
            let response = await clientAxios.put(`/clientes/${id}`, client);
            SweetAlertBasic("success","Buen trabajo","Se ha actualizado exitosamente el cliente.");
            dispatch( updateClientSuccess(client) );
            console.log(response);
            // if(response.status === 200){
            //     await clientAxios.get(`/clientes/${id}`);
            //     dispatch( loadClientSuccess (response.data) );
            // }
          
        }catch( error ){
            dispatch( updateClientError() );
            SweetAlertBasic("error","Ups",`Hubo un error al actualizar el cliente ${error}`);
        }
    }

}

const updateClientInit = () => ({
    type: CLIENT_UPDATE_INIT,
    payload: true,
    flag: true,
})

const updateClientSuccess = (client)=>({
    type: CLIENT_UPDATE_SUCCESS,
    clienteActualizado: client
})

const updateClientError = ( ) =>({
    type: CLIENT_UPDATE_ERROR,
    error: true,
    flag: false
}) 



/* GRID CLIENTS BY CLIENT */
export function getAllClientsByUserAction(id_usuario) {
    return async ( dispatch ) => {
        dispatch( downloadClientByUser() );

        try{
            const responseClientsByUsers = await clientAxios.get(`/clientes/usuario/${id_usuario}`, );
            dispatch( downloadClientByUserSuccess (responseClientsByUsers.data.clients) );
        }catch( error ){
            dispatch( downloadClientByUserError(error) );
            SweetAlertBasic("error","Ups","Hubo un error al cargar los clientes");   
        }
    }
}

const downloadClientByUser = () => ({
    type: CLIENTS_LOADING_BY_USER,
    payload: true
})

const downloadClientByUserSuccess = ( clients ) => ({
    
    type: CLIENTS_BY_USER_LOADING_SUCCESS,
    clientesEspecialistas: clients
})

const downloadClientByUserError = (error) => ({
    type: CLIENTS_BY_USER_LOADING_ERROR,
    error: error
})

/* GRID CLIENTS BY CLIENT */
export function getAllClientsByTeamAction(id_usuario) {
    return async ( dispatch ) => {
        dispatch( downloadClientByTeam() );

        try{
            const responseClientsByTeams = await clientAxios.get(`/clientes/equipo/${id_usuario}`, );
            dispatch( downloadClientByTeamSuccess (responseClientsByTeams.data.clients) );
        }catch( error ){
            dispatch( downloadClientByTeamError(error) );
            SweetAlertBasic("error","Ups","Hubo un error al cargar los clientes");   
        }
    }
}

const downloadClientByTeam = () => ({
    type: CLIENTS_LOADING_BY_TEAM,
    payload: true,
    falg: true
})

const downloadClientByTeamSuccess = ( clients ) => ({
    
    type: CLIENTS_BY_TEAM_LOADING_SUCCESS,
    clientesEquipo: clients
})

const downloadClientByTeamError = (error) => ({
    type: CLIENTS_BY_TEAM_LOADING_ERROR,
    error: error
})