import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    FILTER_CREATE,
    FILTER_CREATE_SUCCESS,
    FILTER_CREATE_ERROR,
    FILTER_CREATE_INIT,
    FILTERS_LOADING,
    FILTERS_LOADING_SUCCESS,
    FILTERS_LOADING_ERROR,
    FILTER_LOAD,
    FILTER_LOAD_SUCCESS,
    FILTER_LOAD_ERROR,
    FILTER_DESACTIVE,
    FILTER_DESACTIVE_ERROR
} from '../types';



/*  Create Filter
    function que crea el filtro
    Nota: El slash al final del endpoint es para redireccionar al server
*/ 
    export function createFilterActionFn(filter) {

        return async ( dispatch ) => {
            
            dispatch( createFilter(filter) );

            try{
                let responseFilter = await clientAxios.post('/filtros/crear/', filter);
                dispatch( createFilterSuccess(responseFilter.data.data) );
            }catch( error ){
                dispatch( createFilterError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el filtro");
            }
        }

    }

    // Create Filter init action(se activa el flag a true para iniciar la creación)
    export function createFilterInitFn(flag){
        return ( dispatch ) => {
            dispatch( createFilterInit(flag));
        }
    }

    const createFilter = ( ) => ({
        type    : FILTER_CREATE
    });

    const createFilterSuccess = (filter) => ({
        type    : FILTER_CREATE_SUCCESS,
        payload : filter,
        flag    : true,
    })

    const createFilterError = () => ({
        type    : FILTER_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createFilterInit = (flag) =>({
        type    : FILTER_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* 
    DESACTIVE Filters 
    function para desactivar el filtro
*/
    export const desactiveFilterAction = (id) => {

        return async (dispatch)=>{
            dispatch( getFilterDesactive(id) );

            try{
                
                let responseDesactiveFilter = await clientAxios.post(`/filtros/change-active/?id_filtro=${id}`);

                if(responseDesactiveFilter.status === 200){
                    dispatch( downloadFilter() );
                    let responseFilterActive = await clientAxios.get('/filtros', );
                    dispatch( downloadFilterSuccess (responseFilterActive.data.data) );
                }
                
            }catch( error ){
                dispatch( desactiveFilterError() );
                SweetAlertBasic("error","Ups","Hubo un error al desactivar/activar el filtro");  
            }
        }

    }

    const getFilterDesactive = (id)=>({
        type: FILTER_DESACTIVE,
        payload: id
    })

    const desactiveFilterError = ( ) =>({
        type: FILTER_DESACTIVE_ERROR,
        error: true,
        flag: false
    }) 

/*  
    GRID FILTERS 
    function que trae todos los filtros activos y desactivos
*/
    export function getAllFiltersAction() {
        return async ( dispatch ) => {
            dispatch( downloadFilter() );

            try{
                const responseFilters = await clientAxios.get('/filtros', );
                dispatch( downloadFilterSuccess (responseFilters.data.data) );

            }catch( error ){
                dispatch( downloadFilterError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los filtros");   
            }
        }
    }

    const downloadFilter = () => ({
        type: FILTERS_LOADING,
        payload: true
    })

    const downloadFilterSuccess = ( filters ) => ({
        type: FILTERS_LOADING_SUCCESS,
        payload: filters
    })

    const downloadFilterError = (error) => ({
        type: FILTERS_LOADING_ERROR,
        error: error
    })

/*  
    EDIT FILTERS 
    function que trae la información de un solo filtro para visualizarlo
*/
    export const getFilterAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadFilter() );

            try{
                let responseFilterId = await clientAxios.get(`/filtros/${id}`);
                dispatch( loadFilterSuccess (responseFilterId.data.data) );
            }catch( error ){
                dispatch( loadFilterError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar el filtro");  
            }
        }   
    }

    const getLoadFilter = () => ({
        type: FILTER_LOAD,
        payload: true
    })

    const loadFilterSuccess = (filter) => ({
        type: FILTER_LOAD_SUCCESS,
        editarEquipo: filter
    })

    const loadFilterError = () => ({
        type: FILTER_LOAD_ERROR,
        error: true
    })
    