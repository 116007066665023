import React, { useState }          from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate,useParams }    from 'react-router-dom';
import ProgressBar                  from '../Progress/ProgressBar.component';
import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import InputLabel                   from '@mui/material/InputLabel';
import MenuItem                     from '@mui/material/MenuItem';
import Select                       from '@mui/material/Select';
import FormControl                  from '@mui/material/FormControl';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import Stack                        from '@mui/material/Stack';
import { createSeguimientoActionFn }         from '../../actions/seguimientoActions';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
} from '../Usuarios/Users.styles';


const Seguimiento = () => {

    const [ descripcion_nota_seguimiento_cliente, setDescripcionNota ]      = useState('');
    const [ descripcion_seguimiento_cliente, setDescripcionSeguimiento]     = useState('');
    const [ tipo_nota_seguimiento_cliente, setTipoNota ]                    = useState('');
    const [ tipo_seguimiento_cliente, setTipoSeguimiento ]                  = useState('');

    const handleTipoSeguimiento = (event) => {
        setTipoNota(event.target.value);
    };

    const handleTipoNota = (event) => {
        setTipoSeguimiento(event.target.value);
    };

    //History
    const navigate = useNavigate();

    function goClients() {
        navigate('/clientes/especialista');
    }

    // Dispatch
    const dispatch              = useDispatch();
    const { id_cliente }        = useParams();
    const loading               = useSelector( state => state.usuarios?.loading);
    const seguimientoSuccess    = useSelector( state => state.seguimiento?.flag);
    const id_user               = useSelector( state => state.login?.user.id ); 
    const addCreate             = (seguimiento) => dispatch( createSeguimientoActionFn(seguimiento) );

    // Create of User
    const createSeguimiento = e => {
        e.preventDefault();

        // CreateSeguimiento
        addCreate({
            descripcion_nota_seguimiento_cliente: descripcion_nota_seguimiento_cliente,
            tipo_nota_seguimiento_cliente: tipo_nota_seguimiento_cliente,
            status_anterior_cliente: 1,
            status_actual_cliente: 1,
            activo_seguimiento_cliente: true,
            descripcion_seguimiento_cliente: descripcion_seguimiento_cliente,
            tipo_seguimiento_cliente: tipo_seguimiento_cliente,
            fecha_seguimiento_cliente: "2022-02-21",
            fecha_cierre_cliente: "2022-02-21",
            id_cliente: id_cliente,
            id_usuario: id_user,
            datos_agenda: "Nota de seguimiento"
        });

    }
  

    return ( 
<DivCenter >
            {
                loading ? 
                    <ProgressBar title="Creando seguimiento..."/>
                :
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)':{},
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={createSeguimiento}
                    
                >
                    <TitleUser>Crear Seguimiento</TitleUser>

                            <RowUser>

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa la nota del cliente" 
                                    variant="outlined"
                                    type="text"
                                    name="descripcion_nota_seguimiento_cliente"
                                    value={descripcion_nota_seguimiento_cliente}
                                    onChange={ e => setDescripcionNota(e.target.value) }
                                    fullWidth
                                />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tipo de Prioridad</InputLabel>
                                    <Select
                                        labelId="tipo_nota_seguimiento_cliente"
                                        id="demo-simple-select"
                                        label="tipo_nota_seguimiento_cliente"
                                        value={tipo_nota_seguimiento_cliente}
                                        onChange={handleTipoSeguimiento}
                                    >
                                        <MenuItem value={'Ninguna'}>Ninguna</MenuItem>
                                        <MenuItem value={'Baja'}>Baja</MenuItem>
                                        <MenuItem value={'Media'}>Media</MenuItem>
                                        <MenuItem value={'Alta'}>Alta</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el seguimiento posterior al cliente" 
                                    variant="outlined"
                                    type="text"
                                    name="descripcion_seguimiento_cliente"
                                    value={descripcion_seguimiento_cliente}
                                    onChange={ e => setDescripcionSeguimiento(e.target.value) }
                                    fullWidth
                                />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tipo de Prioridad</InputLabel>
                                    <Select
                                        labelId="tipo_seguimiento_cliente"
                                        id="demo-simple-select"
                                        label="tipo_seguimiento_cliente"
                                        value={tipo_seguimiento_cliente}
                                        onChange={handleTipoNota}
                                    >
                                        <MenuItem value={'Ninguna'}>Ninguna</MenuItem>
                                        <MenuItem value={'Baja'}>Baja</MenuItem>
                                        <MenuItem value={'Media'}>Media</MenuItem>
                                        <MenuItem value={'Alta'}>Alta</MenuItem>
                                    </Select>
                                </FormControl>
                            </RowUser>
                           
                    <DivButtons >
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={goClients}
                                variant="contained" 
                                size="large"
                                color="error"
                            >
                                Cancelar
                            </Button>
                        
                            <Button 
                                variant="contained" 
                                size="small" 
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Crear seguimiento
                            </Button>
                        </Stack>
                    </DivButtons>
                </Box>
            }
            {
                seguimientoSuccess ?
                    <Navigate to='/seguimiento/exitoso'/>
                :
                null
            }
            
            
        </DivCenter>
     );
}
 
export default Seguimiento;