import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    ROLES_LOADING,
    ROLES_LOADING_SUCCESS,
    ROLES_LOADING_ERROR,
    ROL_LOAD,
    ROL_LOAD_SUCCESS,
    ROL_LOAD_ERROR,
} from '../types';

/* GRID ROLES */
export function getAllRolesAction() {
    return async ( dispatch ) => {
        dispatch( downloadRoles() );

        try{
            const responseRoles = await clientAxios.get('/roles', );
            dispatch( downloadRolesSuccess (responseRoles.data.data) );
        }catch( error ){
            dispatch( downloadRolesError(error) );
            SweetAlertBasic("error","Ups","Hubo un error al cargar los roles");   
        }
    }
}

const downloadRoles = () => ({
    type: ROLES_LOADING,
    payload: true
})

const downloadRolesSuccess = ( roles ) => ({
    type: ROLES_LOADING_SUCCESS,
    payload: roles
})

const downloadRolesError = (error) => ({
    type: ROLES_LOADING_ERROR,
    error: error
})

/* EDIT ROLES */
export const getRolAction = ( id ) =>{

    return async (dispatch)=>{
        dispatch( getLoadRol() );

        try{
            let responseRol = await clientAxios.get(`/roles/${id}`);
            dispatch( loadRolSuccess (responseRol.data.data) );
        }catch( error ){
            dispatch( loadRolError() );
            SweetAlertBasic("error","Ups","Hubo un error al cargar el rol");  
        }
    }   
}

const getLoadRol = () => ({
    type: ROL_LOAD,
    payload: true
})

const loadRolSuccess = (rol) => ({
    type: ROL_LOAD_SUCCESS,
    editarUsuario: rol
})

const loadRolError = () => ({
    type: ROL_LOAD_ERROR,
    error: true
})