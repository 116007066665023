import  React, { useEffect }          from 'react';
import  { useDispatch, useSelector }            from 'react-redux';
import  ProgressBar                             from '../Progress/ProgressBar.component';
import ImageSearchImg                           from '../../Images/plusvarq-image.jpg';
import Box                                      from '@mui/material/Box';
import Card                                     from '@mui/material/Card';
import CardContent                              from '@mui/material/CardContent';
import CardMedia                                from '@mui/material/CardMedia';
import Typography                               from '@mui/material/Typography';
import Button                                   from '@mui/material/Button';
import{ 
    getAllHousesAction 
}                                               from '../../actions/houseActions';
import {
    createOptionAction
}                                               from '../../actions/optionsActions';
import{    
 
}                                               from '../Dashboards/styles/Inventario.styles';
import {
    DivOpciones,
    DivCasas,
    ButtonsCasa,
    TitleRed,
    TitleGreen,
}                                       from './Opciones.styles';

const OpcionesMobile = () => {

    const dispatch          = useDispatch();
    const houseSuccess      = useSelector( state => state.casas.casas || []);
    const loading           = useSelector( state => state.casas?.loading);
    const loadingHouses     = () => dispatch( getAllHousesAction() );
    const addCreateOption   = (option) => dispatch( createOptionAction(option) );
    let userLocal           = JSON.parse(localStorage.getItem('user'))

    // USE EFFECT LOGIN
    useEffect( () => {
        loadingHouses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    function goOption(id){
        window.open(`/opciones/${id}` );
    }
  
    const clickWhatsapp = ( id_casa) => {

        addCreateOption({
            tipo_opcion: "Envió de Opciones vía CRM",
            tipo_opcion_casa: "Opciones de Casa",
            descripcion_opcion_casa: "Opción enviada desde opciones de casa",
            ruta_opcion_casa: "sin ruta",
            id_casa: parseInt(id_casa),
            id_usuario: userLocal?.id ? userLocal?.id : 1,
            id_cliente: 1
        })

        window.open(`https://api.whatsapp.com/send?text=${window.location.hostname+'/opciones/' + id_casa}` )
    }


    return ( 
        <>
          {
                loading ? 
                    <ProgressBar title="Cargando Casas..."/>
                :
                
                    <DivOpciones>
                        {houseSuccess
                            .slice()
                            .sort((a,b) =>  b.activo_casa - a.activo_casa)
                            .map( (house) => (
                                <DivCasas>
                                    <Card sx={{ display: 'flex', m: 3, width: '100%',  flexDirection: 'column'}} >
                                        <CardMedia
                                            component="img"
                                            sx={{ width: '100%', height: 180 }}
                                            image={ImageSearchImg}
                                            alt="Live from space album cover"
                                        />
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography component="div" variant="h6">
                                            {house.nombre_casa}
                                            CASA
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            <strong>Dirección:</strong> 
                                            {house.calle_casa}
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            <strong>Tipo de casa:</strong> 
                                            {house.tipo_casa}
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            <strong>CP:</strong> 
                                            {house.cp_casa}
                                            </Typography>
                                            {
                                                house.activo_casa ? 
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    <TitleGreen>Casa Activada</TitleGreen>
                                                </Typography> 
                                                :
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    <TitleRed>Casa Desactivada</TitleRed>
                                                </Typography>
                                            }
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            <strong>ID:</strong>
                                            {house.id_casa}
                                            </Typography>
                                        
                                            </CardContent>
                                        </Box>
                                        <ButtonsCasa >
                                            <Button 
                                                variant     ="contained" 
                                                size        ="small" 
                                                style={{width:"99%", margin:'1%'}}
                                                onClick     ={() =>goOption(house.id_casa)}
                                            >
                                                Ver más
                                            </Button>

                                            <Button 
                                                variant     ="contained" 
                                                size        ="small" 
                                                color       ="success" 
                                                style={{width:"99%", margin:'1%'}}
                                                disabled    ={house.activo_casa ? false : true}
                                                onClick={ () => clickWhatsapp(house.id_casa)}
                                            >
                                            Compartir
                                            </Button> 
                                        </ButtonsCasa>
                                    </Card>
                                </DivCasas>
                            ))
                        }
                    </DivOpciones>
        }
        </>
     );
}
 
export default OpcionesMobile;