export const tipoCasaJson = [
    {
    name: "Venta - Usada",
    value:"Venta - Usada",
    }, 
    {
        name: "Venta - Venta Nueva",
        value: "Venta - Venta Nueva",
    },
    {
        name: "Renta",
        value: "Renta",
    },
    {
        name: "Otro",
        value: "Otro",
    }
];

export const monedaJson = [
    {
    name: "MX",
    value: "MX",
    }, 
    {
        name: "USD",
        value: "USD",
    }
];


export const EstadosJson =[
    {
        name: "MX",
        value: "MX",
    }, 
]