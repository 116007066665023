

import React                        from 'react';
import Button                       from '@mui/material/Button';
import Link                         from "@material-ui/core/Link";
import { 
    SweetAlertConfirm,
    SweetAlertConfirmTeam,
    SweetAlertConfirmCalendary,
    SweetAlertConfirmRegister,
    SweetAlertConfirmFilter,
    SweetAlertConfirmHouse,
    SweetAlertConfirmDesarrollo,
    SweetAlertConfirmDesarrollador
 }        from '../utils/sweetAlert';


let active;

export const ColumnsUser = [
    { field: 'nombre_usuario' ,                     headerName: 'Nombre',                   width: 150 },
    { field: 'apellido_paterno_usuario' ,           headerName: 'Apellido Paterno',         width: 150 },
    { field: 'apellido_materno_usuario' ,           headerName: 'Apellido Materno',         width: 150 },
    { field: 'email_usuario',                       headerName: 'Email',                    width: 250 },
    { field: 'telefono_usuario',                    headerName: 'Telefono Celular',         width: 200 },
    {
        field           : "telefono_usuario",
        headerName      : "Telefono Celular",
        sortable        : false,
        width           : 150,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return <Link href={`tel:${params.row.telefono_usuario}`}>{params.row.telefono_usuario}</Link>
            ;
        }
    },
    { field: 'nombre_equipo',                           headerName: 'Equipo',                   width: 200 },
    { field: 'nombre_rol',                              headerName: 'Rol',                      width: 200 },
    { field: 'last_login_usuario',                      headerName: 'Ultima conexión',          width: 150 },
    {
        field: "",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/usuario/${params.row.id_usuario}`}
                    >
                        Editar
                    </Link>
                </Button>;
        }
    },
    {
        field           : "activo_usuario",
        headerName      : "Activo",
        sortable        : false,
        width           : 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
            active = params.row.activo_usuario === true  ? 
                <Button
                    variant     ="contained" 
                    color       ="info"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveUser(params.row, "Quieres desactivar al usuario: ") }
                > 
                    Activo 
                </Button> 
                : 
                <Button
                    variant     ="contained" 
                    color       ="error"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveUser(params.row, "Quieres activar al usuario: ") }
                > Desactivo 
                </Button> 
            return  active
        }
    },
];


export const ColumnsHouse = [
    { field: 'id_casa' ,                            headerName: 'Id de la casa',                        width: 150 },
    { field: 'imagen_principal_casa' ,              headerName: 'Id Easy Broker',                       width: 150 },
    { field: 'created_at' ,                         headerName: 'Fecha de creación',                    width: 150 },
    { field: 'nombre_casa' ,                        headerName: 'Nombre',                               width: 500 },
    { field: 'calle_casa' ,                         headerName: 'Calle',                                width: 200 },
    { field: 'telefono_cel_casa',                   headerName: 'Telefono Celular',                     width: 200 },
    // {
    //     field           : "telefono_cel_casa",
    //     headerName      : "Telefono Celular",
    //     sortable        : false,
    //     width           : 150,
    //     disableClickEventBubbling: true,
    //     renderCell: ( params ) => {
    //     return <Link href={`tel:${params.row.telefono_usuario}`}>{params.row.telefono_usuario}</Link>
    //         ;
    //     }
    // },
    { field: 'cp_casa',                                 headerName: 'Código Postal',                   width: 150 },
    { field: 'tipo_casa',                               headerName: 'Tipo',                      width: 150 },
    {
        field: "",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/casa/${params.row.id_casa}`}
                    >
                        Editar
                    </Link>
                </Button>;
        }
    },
    {
        field           : "activo_casa",
        headerName      : "Activo",
        sortable        : false,
        width           : 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
            active = params.row.activo_casa === true  ? 
                <Button
                    variant     ="contained" 
                    color       ="info"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveHouse(params.row, "Quieres desactivar la casa: ") }
                > 
                    Activo 
                </Button> 
                : 
                <Button
                    variant     ="contained" 
                    color       ="error"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveHouse(params.row, "Quieres activar la casa: ") }
                > Desactivo 
                </Button> 
            return  active
        }
    },
];



export const ColumnsDesarrollos = [
    { field: 'created_at' ,                            headerName: 'Fecha de creación',                   width: 150 },
    { field: 'nombre_desarrollo' ,                     headerName: 'Nombre',         width: 150 },
    { field: 'email_desarrollo' ,                      headerName: 'Email',         width: 150 },
    { field: 'tipo_desarrollo',                        headerName: 'Tipo',                    width: 250 },
    { field: 'prioridad_desarrollo',                   headerName: 'Prioridad',         width: 200 },
    { field: 'cp_desarrollo',                          headerName: 'Código Postal',                   width: 200 },
    {
        field: "",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/desarrollo/${params.row.id_desarrollo}`}
                    >
                        Editar
                    </Link>
                </Button>;
        }
    },
    {
        field           : "activo_desarrollo",
        headerName      : "Activo",
        sortable        : false,
        width           : 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
            active = params.row.activo_desarrollo === true  ? 
                <Button
                    variant     ="contained" 
                    color       ="info"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveDesarrollo(params.row, "Quieres desactivar el desarrollo: ") }
                > 
                    Activo 
                </Button> 
                : 
                <Button
                    variant     ="contained" 
                    color       ="error"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveDesarrollo(params.row, "Quieres activar el desarrollo: ") }
                > Desactivo 
                </Button> 
            return  active
        }
    },
];



export const ColumnsDesarrolladores = [
    { field: 'created_at' ,                               headerName: 'Fecha de creación',                   width: 150 },
    { field: 'nombre_desarrollador' ,                     headerName: 'Nombre',         width: 150 },
    { field: 'email_desarrollador' ,                      headerName: 'Email',         width: 150 },
    { field: 'tipo_desarrollador',                        headerName: 'Tipo',                    width: 250 },
    { field: 'prioridad_desarrollador',                   headerName: 'Prioridad',         width: 200 },
    { field: 'cp_desarrollador',                          headerName: 'Código Postal',                   width: 200 },
    {
        field: "",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/desarrollador/${params.row.id_desarrollador}`}
                    >
                        Editar
                    </Link>
                </Button>;
        }
    },
    {
        field           : "activo_desarrollador",
        headerName      : "Activo",
        sortable        : false,
        width           : 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
            active = params.row.activo_desarrollador === true  ? 
                <Button
                    variant     ="contained" 
                    color       ="info"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveDesarrollador(params.row, "Quieres desactivar el desarrollador: ") }
                > 
                    Activo 
                </Button> 
                : 
                <Button
                    variant     ="contained" 
                    color       ="error"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveDesarrollador(params.row, "Quieres activar el desarrollador: ") }
                > Desactivo 
                </Button> 
            return  active
        }
    },
];

export const ColumnsClients = [
    { field: 'nombre_cliente' ,                     headerName: 'Nombre',                   width: 250 },
    { field: 'apellido_paterno_cliente' ,           headerName: 'Apellido Paterno',         width: 200 },
    { field: 'apellido_materno_cliente' ,           headerName: 'Apellido Materno',         width: 200 },
    { field: 'email_cliente',                       headerName: 'Email',                    width: 300 },
    { field: 'telefono_cliente',                    headerName: 'Telefono Celular',         width: 250 },
    {
        field           : "telefono_cliente",
        headerName      : "Telefono Celular",
        sortable        : false,
        width           : 300,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return <Link href={`tel:${params.row.telefono_cliente}`}>{params.row.telefono_cliente}</Link>
            ;
        }
    },
    {
        field: "",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/clientes/${params.row.id_cliente}`}
                    >
                        Editar
                    </Link>
                </Button>;
        }
    },
];

export const ColumnsClientsSales = [
    { field: 'nombre_cliente' ,                     headerName: 'Nombre',                   width: 250 },
    { field: 'apellido_paterno_cliente' ,           headerName: 'Apellido Paterno',         width: 200 },
    { field: 'apellido_materno_cliente' ,           headerName: 'Apellido Materno',         width: 200 },
    { field: 'email_cliente',                       headerName: 'Email',                    width: 300 },
    { field: 'telefono_cliente',                    headerName: 'Telefono Celular',         width: 250 },
    {
        field           : "telefono_cliente",
        headerName      : "Telefono Celular",
        sortable        : false,
        width           : 300,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return <Link href={`tel:${params.row.telefono_cliente}`}>{params.row.telefono_cliente}</Link>
            ;
        }
    },
    {
        field: "",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/cliente/ventas/${params.row.id_cliente}`}
                    >
                        Editar
                    </Link>
                </Button>;
        }
    },
];

export const ColumnsClientsSalesLider = [
    { field: 'nombre_cliente' ,                     headerName: 'Nombre',                   width: 250 },
    { field: 'apellido_paterno_cliente' ,           headerName: 'Apellido Paterno',         width: 200 },
    { field: 'apellido_materno_cliente' ,           headerName: 'Apellido Materno',         width: 200 },
    { field: 'email_cliente',                       headerName: 'Email',                    width: 300 },
    { field: 'telefono_cliente',                    headerName: 'Telefono Celular',         width: 250 },
    {
        field           : "telefono_cliente",
        headerName      : "Telefono Celular",
        sortable        : false,
        width           : 300,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return <Link href={`tel:${params.row.telefono_cliente}`}>{params.row.telefono_cliente}</Link>
            ;
        }
    },
    {
        field: "",
        headerName: "Visualizar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="primary"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/cliente/ventas/${params.row.id_cliente}`}
                    >
                        Ver
                    </Link>
                </Button>;
        }
    },
    {
        field: "Visualizar",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/clientes/${params.row.id_cliente}`}
                    >
                        Editar
                    </Link>
                </Button>;
        }
    },
];

export const ColumnsClientsVisualize = [
    { field: 'nombre_cliente' ,                     headerName: 'Nombre',                   width: 250 },
    { field: 'apellido_paterno_cliente' ,           headerName: 'Apellido Paterno',         width: 200 },
    { field: 'apellido_materno_cliente' ,           headerName: 'Apellido Materno',         width: 200 },
    { field: 'email_cliente',                       headerName: 'Email',                    width: 250 },
    { field: 'telefono_cliente',                    headerName: 'Telefono Celular',         width: 250 },
    {
        field           : "telefono_cliente",
        headerName      : "Telefono Celular",
        sortable        : false,
        width           : 150,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return <Link href={`tel:${params.row.telefono_cliente}`}>{params.row.telefono_cliente}</Link>
            ;
        }
    }
];

export const ColumnsTeams = [
    { field: 'nombre_equipo' ,                      headerName: 'Nombre',                       width: 150 },
    { field: 'tipo_equipo' ,                        headerName: 'Tipo de Equipo',               width: 150 },
    { field: 'prioridad_equipo' ,                   headerName: 'Prioridad de Equipo',          width: 150 },
    {
        field: "",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/equipos/${params.row.id_equipo}`}
                    >
                        Editar
                    </Link>
                </Button>;
        },
        
    },
    {
        field           : "activo_equipo",
        headerName      : "Activo",
        sortable        : false,
        width           : 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
            active = params.row.activo_equipo === true  ? 
                <Button
                    variant     ="contained" 
                    color       ="info"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveTeam(params.row, "Quieres desactivar al equipo: ") }
                > 
                    Activo 
                </Button> 
                : 
                <Button
                    variant     ="contained" 
                    color       ="error"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveTeam(params.row, "Quieres activar al equipo: ") }
                > Desactivo 
                </Button> 
            return  active
        }
    },
];

export const ColumnsCalendarys = [
    { field: 'nombre_calendario' ,                      headerName: 'Calendario',                               width: 150 },
    { field: 'tipo_calendario' ,                        headerName: 'Tipo de Calendario',                       width: 150 },
    { field: 'horario_inicio_calendario' ,              headerName: 'Horario de Inicio de Calendario',          width: 150 },
    { field: 'horario_final_calendario' ,               headerName: 'Fin de horario de Calendario',             width: 150 },
    { field: 'prioridad_calendario' ,                   headerName: 'Prioridad de Calendario',                  width: 150 },
    { field: 'login_calendario' ,                       headerName: 'Login Calendario',                  width: 150 },
    {
        field: "",
        headerName: "Editar",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
        return  <Button
                    variant     ="contained" 
                    color       ="warning"
                    size        ="small"
                >
                    <Link 
                        style   ={ {color:"#ffff", textDecoration:"none"} } 
                        href    ={`/equipos/${params.row.id_calendario}`}
                    >
                        Editar
                    </Link>
                </Button>;
        },
        
    },
    {
        field           : "activo_equipo",
        headerName      : "Activo",
        sortable        : false,
        width           : 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
            active = params.row.activo_calendario === true  ? 
                <Button
                    variant     ="contained" 
                    color       ="info"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveCalendary(params.row, "Quieres desactivar al calendario: ") }
                > 
                    Activo 
                </Button> 
                : 
                <Button
                    variant     ="contained" 
                    color       ="error"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveCalendary(params.row, "Quieres activar al calendario: ") }
                > Desactivo 
                </Button> 
            return  active
        }
    },
];


export const ColumnsRegisters = [
    { field: 'nombre_registro' ,                        headerName: 'Nombre del Registro',              width: 150 },
    { field: 'tipo_registro' ,                          headerName: 'Tipo de Registro',                 width: 150 },
    // {
    //     field: "",
    //     headerName: "Editar",
    //     sortable: false,
    //     width: 100,
    //     disableClickEventBubbling: true,
    //     renderCell: ( params ) => {
    //     return  <Button
    //                 variant     ="contained" 
    //                 color       ="warning"
    //                 size        ="small"
    //             >
    //                 <Link 
    //                     style   ={ {color:"#ffff", textDecoration:"none"} } 
    //                     href    ={`/registros/${params.row.id_registro}`}
    //                 >
    //                     Editar
    //                 </Link>
    //             </Button>;
    //     },
        
    // },
    {
        field           : "activo_registro",
        headerName      : "Activo",
        sortable        : false,
        width           : 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
            active = params.row.activo_registro === true  ? 
                <Button
                    variant     ="contained" 
                    color       ="info"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveRegister(params.row, "Quieres desactivar el registro: ") }
                > 
                    Activo 
                </Button> 
                : 
                <Button
                    variant     ="contained" 
                    color       ="error"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveRegister(params.row, "Quieres activar al registro: ") }
                > Desactivo 
                </Button> 
            return  active
        }
    },
];


export const ColumnsFilters = [
    { field: 'nombre_filtro' ,                          headerName: 'Nombre del Filtro',              width: 150 },
    { field: 'tipo_filtro' ,                            headerName: 'Tipo de Filtro',                 width: 150 },
    { field: 'descripcion_filtro' ,                     headerName: 'Descripcion',                      width: 150 },
    { field: 'origen_filtro' ,                          headerName: 'Origen del Filtro',              width: 150 },
    // {
    //     field: "",
    //     headerName: "Editar",
    //     sortable: false,
    //     width: 100,
    //     disableClickEventBubbling: true,
    //     renderCell: ( params ) => {
    //     return  <Button
    //                 variant     ="contained" 
    //                 color       ="warning"
    //                 size        ="small"
    //             >
    //                 <Link 
    //                     style   ={ {color:"#ffff", textDecoration:"none"} } 
    //                     href    ={`/filtros/${params.row.if_filtro}`}
    //                 >
    //                     Editar
    //                 </Link>
    //             </Button>;
    //     },
        
    // },
    {
        field           : "activo_filtro",
        headerName      : "Activo",
        sortable        : false,
        width           : 100,
        disableClickEventBubbling: true,
        renderCell: ( params ) => {
            active = params.row.activo_filtro === true  ? 
                <Button
                    variant     ="contained" 
                    color       ="info"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveFilter(params.row, "Quieres desactivar el filtro: ") }
                > 
                    Activo 
                </Button> 
                : 
                <Button
                    variant     ="contained" 
                    color       ="error"
                    size        ="medium"
                    style       ={{ fontSize: '11px'}}
                    onClick     = { (  )=> desactiveFilter(params.row, "Quieres activar al filtro: ") }
                > Desactivo 
                </Button> 
            return  active
        }
    },
];


export const ColumnsRoles = [
    { field: 'nombre_rol' ,                         headerName: 'Rol',                      width: 150 },
    { field: 'descripcion_rol' ,                    headerName: 'Descripcion',              width: 150 },
];

export const ColumnsStatus = [
    { field: 'nombre_status' ,                         headerName: 'Status',                      width: 150 },
];

const desactiveUser = (user, title) =>{
    SweetAlertConfirm(user,title,"¿Estas Seguro?");
}

const desactiveTeam= (team, title) =>{
    SweetAlertConfirmTeam(team,title,"¿Estas Seguro?");
}

const desactiveCalendary= (team, title) =>{
    SweetAlertConfirmCalendary(team,title,"¿Estas Seguro?");
}

const desactiveRegister = (team, title) =>{
    SweetAlertConfirmRegister(team,title,"¿Estas Seguro?");
}

const desactiveFilter = (team, title) =>{
    SweetAlertConfirmFilter(team,title,"¿Estas Seguro?");
}

const desactiveHouse = (team, title) =>{
    SweetAlertConfirmHouse(team,title,"¿Estas Seguro?");
}

const desactiveDesarrollo = (team, title) =>{
    SweetAlertConfirmDesarrollo(team,title,"¿Estas Seguro?");
}

const desactiveDesarrollador = (team, title) =>{
    SweetAlertConfirmDesarrollador(team,title,"¿Estas Seguro?");
}

