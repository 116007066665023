import React, { useState }          from 'react'
import { createHouseAction }         from '../../actions/houseActions';
import { useDispatch, useSelector } from 'react-redux';
import { SweetAlertBasic }          from '../../utils/sweetAlert';
import { useNavigate, Navigate }    from 'react-router-dom';
import ProgressBar                  from '../Progress/ProgressBar.component';
import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import InputLabel                   from '@mui/material/InputLabel';
import MenuItem                     from '@mui/material/MenuItem';
import Select                       from '@mui/material/Select';
import FormControl                  from '@mui/material/FormControl';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import Stack                        from '@mui/material/Stack';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
} from '../Usuarios/Users.styles';

const NewHouse = () => {

    const [ nombreCasa, setNombreCasa ]                 = useState('');
    const [ calleCasa, setCalleCasa ]                   = useState('');
    const [ cpCasa, setCpCasa ]                         = useState('');
    const [ correoCasa, setCorreoCasa ]                 = useState('');
    const [ telefonoCasa, setTelefonoCasa ]             = useState('');
    const [ estado, setIdEstado ]                       = useState('');
    const [ tipoCasa, setTipoCasa ]                     = useState('');
    const [ idEasyBroker, setIdEasyBroker ]             = useState('');

    // Dispatch
    const dispatch      = useDispatch();

    // Call Action
    const addCreate     = (house) => dispatch( createHouseAction(house) );

    // Loading
    const loading           = useSelector( state    => state.casas?.loading);
    const houseSuccess      = useSelector( state    => state.casas?.flag);
  
    // SELECTS
    const handleEstado = ( event ) => {
        setIdEstado(event.target.value);
    };

    const handleTipoCasa = ( event ) => {
        setTipoCasa(event.target.value);
    };

    //History
    const navigate = useNavigate();

    function goHouses() {
        navigate('/dashboard/inventario');
    }
  
    // Create of User
    const createHouse = e => {
        e.preventDefault();

        //Validate form
        if( calleCasa.trim() === '' || cpCasa.length === 0 || tipoCasa.length === 0){
            SweetAlertBasic("error","Ups","Le falta llenar campos");
            return;
        }

        // CreateUser
        addCreate({
            nombre_casa             : nombreCasa,
            calle_casa              : calleCasa,
            cp_casa                 : cpCasa,
            correo_casa             : correoCasa,
            telefono_cel_casa       : telefonoCasa,
            imagen_principal_casa   : idEasyBroker,
            tipo_casa               : tipoCasa,
            id_desarrollo           : 1,
            id_desarrollador        : 1 ,
            id_estado               : estado,
            id_delegacion           : 1,
        });

    }


    return ( 
        <DivCenter >
            {
                loading ? 
                    <ProgressBar title="Creando Casa..."/>
                :
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)':{},
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={createHouse}
                    
                >
                    <TitleUser>Crear Casa</TitleUser>

                            <RowUser>

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el nombre de la casa" 
                                    variant="outlined"
                                    type="text"
                                    name="nombreCasa"
                                    value={nombreCasa}
                                    onChange={ e => setNombreCasa(e.target.value) }
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa la calle de la casa" 
                                    variant="outlined"
                                    type="text"
                                    name="calleCasa"
                                    value={calleCasa}
                                    onChange={ e => setCalleCasa(e.target.value) }
                                    fullWidth
                                />
                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el código postal de la casa" 
                                    variant="outlined"
                                    name="cpCasa"
                                    value={cpCasa}
                                    onChange={ e => setCpCasa(e.target.value) }
                                    fullWidth
                                />
                                
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el correo de la casa" 
                                    variant="outlined"
                                    name="correoCasa"
                                    value={correoCasa}
                                    onChange={ e => setCorreoCasa(e.target.value) }
                                    fullWidth
                                />

                           
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el telefono de la casa" 
                                    variant="outlined"
                                    name="telefonoCasa"
                                    value={telefonoCasa}
                                    type="number"
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    onChange={ e => setTelefonoCasa( Number(e.target.value) ) }
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el ID de la Easy broker" 
                                    variant="outlined"
                                    type="text"
                                    name="imagen_principal_casa"
                                    value={idEasyBroker}
                                    onChange={ e => setIdEasyBroker(e.target.value) }
                                    fullWidth
                                />      

                                          

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                    <Select
                                        labelId="estado"
                                        id="demo-simple-select"
                                        label="estado"
                                        value={estado}
                                        onChange={handleEstado}
                                    >
                                        <MenuItem value={1}>Aguascalientes</MenuItem>
                                        <MenuItem value={2}>Baja California</MenuItem>
                                        <MenuItem value={3}>Baja California Sur</MenuItem>
                                        <MenuItem value={4}>Campeche</MenuItem>
                                        <MenuItem value={5}>Coahuila</MenuItem>
                                        <MenuItem value={6}>Colima</MenuItem>
                                        <MenuItem value={7}>Chiapas</MenuItem>
                                        <MenuItem value={8}>Chihuahua</MenuItem>
                                        <MenuItem value={9}>Durango</MenuItem>
                                        <MenuItem value={10}>Ciudad de México</MenuItem>
                                        <MenuItem value={11}>Guanajuato</MenuItem>
                                        <MenuItem value={12}>Guerrero</MenuItem>
                                        <MenuItem value={13}>Hidalgo</MenuItem>
                                        <MenuItem value={14}>Jalisco</MenuItem>
                                        <MenuItem value={15}>Estado de México</MenuItem>
                                        <MenuItem value={16}>Michoacán</MenuItem>
                                        <MenuItem value={17}>Morelos</MenuItem>
                                        <MenuItem value={18}>Nayarit</MenuItem>
                                        <MenuItem value={19}>Nuevo León</MenuItem>
                                        <MenuItem value={20}>Oaxaca</MenuItem>
                                        <MenuItem value={21}>Puebla</MenuItem>
                                        <MenuItem value={22}>Querétaro</MenuItem>
                                        <MenuItem value={23}>Quintana Roo</MenuItem>
                                        <MenuItem value={24}>San Luis Potosí</MenuItem>
                                        <MenuItem value={25}>Sinaloa</MenuItem>
                                        <MenuItem value={26}>Sonora</MenuItem>
                                        <MenuItem value={27}>Tabasco</MenuItem>
                                        <MenuItem value={28}>Tamaulipas</MenuItem>
                                        <MenuItem value={29}>Tlaxcala</MenuItem>
                                        <MenuItem value={30}>Veracruz</MenuItem>
                                        <MenuItem value={31}>Yucatán</MenuItem>
                                        <MenuItem value={32}>Zacatecas</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tipo de Casa</InputLabel>
                                    <Select
                                        labelId="tipoCasa"
                                        id="demo-simple-select"
                                        label="tipoCasa"
                                        value={tipoCasa}
                                        onChange={handleTipoCasa}
                                    >
                                        <MenuItem value="Venta - Usada">Venta - Usada</MenuItem>
                                        <MenuItem value="Venta - Nueva">Venta - Nueva</MenuItem>
                                        <MenuItem value="Renta">Renta</MenuItem>
                                        <MenuItem value="Otro">Otro</MenuItem>
                                    </Select>
                                </FormControl>
                            </RowUser>
                           
                    <DivButtons >
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={goHouses}
                                variant="contained" 
                                size="large"
                                color="error"
                            >
                                Cancelar
                            </Button>
                        
                            <Button 
                                variant="contained" 
                                size="small" 
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Crear Casa
                            </Button>
                        </Stack>
                    </DivButtons>
                </Box>
            }
            {
                houseSuccess ?
                    <Navigate to='/casas/exitoso'/>
                :
                null
            }
            
            
        </DivCenter>
     );
}
 
export default NewHouse;