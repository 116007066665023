import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    USER_CREATE,
    USER_CREATE_SUCCESS,
    USER_CREATE_ERROR,
    USER_CREATE_INIT,
    USERS_LOADING,
    USERS_LOADING_SUCCESS,
    USERS_LOADING_ERROR,
    USER_DESACTIVE,
    USER_DESACTIVE_ERROR,
    USER_LOAD,
    USER_LOAD_SUCCESS,
    USER_LOAD_ERROR,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_ERROR,
    USER_UPDATE_INIT,
    USER_PASSWORD_SUCCESS,
    USER_PASSWORD_ERROR,
    USER_PASSWORD_INIT,
    TEAMS_LOADING_SUCCESS
} from '../types';

/* Create USER*/ 
    export function createUserAction(user) {

        return async ( dispatch ) => {
            
            dispatch( createUser() );

            try{
                await clientAxios.post('/usuarios/crear/', user);
                dispatch( createUserSuccess(user) );

            }catch( error ){
                dispatch( createUserError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el usuario");
            }
        }

    }

    // Create User init action
    export function createUserInitFn(flag){
        return ( dispatch ) => {
            dispatch( createUserInit(flag));
        }
    }

    const createUser = ( ) => ({
        type    : USER_CREATE
    });

    const createUserSuccess = (user) => ({
        type    : USER_CREATE_SUCCESS,
        payload : user,
        flag    : true,
    })

    const createUserError = () => ({
        type    : USER_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createUserInit = (flag) =>({
        type    : USER_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* GRID USER */
    export function getAllUsersAction() {
        return async ( dispatch ) => {
            dispatch( downloadUser() );

            try{
                const responseUsers = await clientAxios.get('/usuarios' );
                dispatch( downloadUserSuccess (responseUsers.data.data) );

            }catch( error ){
                dispatch( downloadUserError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los usuarios");   
            }
        }
    }

    const downloadUser = () => ({
        type: USERS_LOADING,
        payload: true
    })

    const downloadUserSuccess = ( users ) => ({
        type: USERS_LOADING_SUCCESS,
        payload: users
    })

    const downloadUserError = (error) => ({
        type: USERS_LOADING_ERROR,
        error: error
    })

/* DESACTIVE USER */
    export const desactiveUserAction = (id) => {

        return async (dispatch)=>{
            dispatch( getUserDesactive(id) );

            try{
                let response = await clientAxios.post(`/usuarios/change-active/?id_usuario=${id}`);
              
                // ALL USERS UPDATE ACTIVE
                if(response.status === 200){
                    dispatch( downloadUser() );
                    let responseUsersActive = await clientAxios.get('/usuarios', );
                    dispatch( downloadUserSuccess (responseUsersActive.data.data) );
                }
                
            }catch( error ){
                dispatch( desactiveUserError() );
                SweetAlertBasic("error","Ups","Hubo un error al desactivar/activar el usuario");  
            }
        }

    }

    const getUserDesactive = (id)=>({
        type: USER_DESACTIVE,
        payload: id
    })

    const desactiveUserError = ( ) =>({
        type: USER_DESACTIVE_ERROR,
        error: true,
        flag: false
    }) 

/* EDIT USER */
    export const getUserAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadUser() );

            try{
                let response = await clientAxios.get(`/usuarios/${id}/`);
                let responseTeams = await clientAxios.get('/equipos', );
                dispatch( loadUserSuccess (response.data.data) );
                dispatch( downloadTeamSuccess (responseTeams.data.data) );
            }catch( error ){
                dispatch( loadUserError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar el usuario");  
            }
        }   
    }

    const getLoadUser = () => ({
        type: USER_LOAD,
        payload: true
    })

    const loadUserSuccess = (user) => ({
        type: USER_LOAD_SUCCESS,
        editarUsuario: user
    })

    const loadUserError = () => ({
        type: USER_LOAD_ERROR,
        error: true
    })

    const downloadTeamSuccess = ( teams ) => ({
        type: TEAMS_LOADING_SUCCESS,
        payload: teams
    })
    

/* UPDATE USER */
    export const updateUserAction = (user,id) => {
       
        return async (dispatch)=>{
            dispatch( updateUserInit(user) );

            try{
                await clientAxios.put(`/usuarios/${id}`, user);
                dispatch( updateUserSuccess(user) );

            }catch( error ){
                dispatch( updateUserError() );
                SweetAlertBasic("error","Ups",`Hubo un error al actualizar el usuario ${error}`);
            }
        }

    }

    const updateUserInit = () => ({
        type: USER_UPDATE_INIT,
        payload: true
    })

    const updateUserSuccess = (user)=>({
        type: USER_UPDATE_SUCCESS,
        usuarioActualizado: user
    })

    const updateUserError = ( ) =>({
        type: USER_UPDATE_ERROR,
        error: true,
        flag: false
    });

/* UPDATE PASSWORD USER */
export const updatePasswordAction = (user) => {
    console.log(user);
    return async (dispatch)=>{
        dispatch( updatePasswordInit(user) );

        try{
            let response = await clientAxios.put(`/usuarios/change-pw/`, user);

            dispatch( updatePasswordSuccess(response.data) );

        }catch( error ){
            dispatch( updatePasswordError() );
            SweetAlertBasic("error","Ups",`Hubo un error al actualizar el password ${error}`);
        }
    }

}

    const updatePasswordInit = () => ({
        type: USER_PASSWORD_INIT,
        payload: true
    })

    const updatePasswordSuccess = (user)=>({
        type: USER_PASSWORD_SUCCESS,
        passwordActualizado: user
    })

    const updatePasswordError = ( ) =>({
        type: USER_PASSWORD_ERROR,
        error: true,
        flag: false
    }) 