import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';



export const TableCellModal = {
    "client" : [
        { id: 1, name: 'Fecha de Creacion' }, 
        { id: 2, name: 'Nombre'},
        { id: 3, name: 'Télefono' }, 
        { id: 4, name: 'Email'},
        { id: 5, name: 'Status'},
        { id: 6, name: 'Editar'},
    ],
    "options":[
        { id: 1, name: 'Fecha de Creacion' }, 
        { id: 2, name: 'Id de la Casa' },  
        { id: 3, name: 'Nombre de la Casa' },
        { id: 4, name: 'Email' },  
        { id: 5, name: 'CP ' },  
        { id: 6, name: ' ' },  
    ],
    "houses": [ 
        { id: 1, name: 'Fecha de Creacion' }, 
        { id: 2, name: 'Id de la Casa' },  
        { id: 3, name: 'Dirección' },
        { id: 4, name: 'Télefono' },  
        { id: 5, name: 'CP' },  
    ],
    "schedules": [
        { id: 1, name: 'Fecha de Creacion' }, 
        { id: 2, name: 'Id de la Casa' },  
        { id: 3, name: 'Dia de la Agenda' },
        { id: 4, name: 'Nombre del Cliente' },  
        { id: 5, name: 'Datos de la Agenda ' },  
    ],
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#004274',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));