import React,{ useEffect } from 'react'
import Button                       from '@mui/material/Button';
import AddCircleIcon                from '@mui/icons-material/AddCircle';
import AutorenewIcon                from '@mui/icons-material/Autorenew';
import ProgressBar                  from '../Progress/ProgressBar.component';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsClientsSales }           from '../../utils/rowData';
import{ 
        createClientInitFn, 
        getAllClientsByUserAction 
    }                               from '../../actions/clientActions';
import{    
        TitleAndButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';
import { 
    DivGridSpecialist
} from './Clients.styles';
import { 
    DivButtonUpdate
}                                   from './Clients.styles';


const ClientsSpecialist = () => {


    const dispatch          = useDispatch();
    const addCreateInit     = (client) => dispatch( createClientInitFn(client) );
    const navigate          = useNavigate();
    const clientSuccess     = useSelector( state => state.clientes?.clientesEspecialistas);
    const loading           = useSelector( state => state.clientes?.loading);
    const id_user           = useSelector( state => state.login.user?.id );

    
    function loadGrid(){
        if( id_user){
            const loadingClientsByUser = () => dispatch( getAllClientsByUserAction(id_user) );
            loadingClientsByUser();
        }else{
            let id_user_token = JSON.parse(localStorage.getItem('user'));
            const loadingClientsByUser = () => dispatch( getAllClientsByUserAction(id_user_token.id) );
            loadingClientsByUser();
        }
    }

    // USE EFFECT LOADER
    useEffect( () => {
        loadGrid();
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    // Onclick User Init action
    function newClient() {
        addCreateInit(false);
        navigate('/clientes/crear');
    }

    function updateGrid(){
        loadGrid();
    }

    return ( 
        <>
              {
                loading ? 
                    <ProgressBar title="Cargando Clientes..."/>
                :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Clientes</TitleUserGrid>

                            <DivButtonUpdate>
                                <Button
                                    variant="contained"
                                    onClick={updateGrid}
                                    startIcon={<AutorenewIcon />}
                                    size="small"
                                    color="secondary"
                                    style={{
                                        marginRight:'10px'
                                    }}
                                >
                                    Actualizar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={newClient}
                                    startIcon={<AddCircleIcon />}
                                    size="small"
                                >
                                    Crear Cliente
                                </Button>
                            </DivButtonUpdate>
                        </TitleAndButton>

                        <DivGridSpecialist>
                            <DataGrid
                                rows                ={clientSuccess}
                                columns             ={ColumnsClientsSales}
                                pageSize            ={10}
                                rowsPerPageOptions  ={[10]}
                                getRowId            ={clientSuccess => clientSuccess.id_cliente}
                            />
                        </DivGridSpecialist>
                </>
            }
        </>
     );
}
 
export default ClientsSpecialist;