import React from 'react';
import { ProSidebar, Menu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import{
    SidebarBox
} from './Sidebar.styles';

const Sidebar = () => {
    return ( 
        <SidebarBox>
            <ProSidebar>
                <Menu iconShape="square">
                </Menu>
            </ProSidebar>
        </SidebarBox>
     );
}
 
export default Sidebar;