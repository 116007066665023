import { 
    result_month,
    result_week,
} from '../date';
// let date_get    = new Date();

// export const formateador = new Intl.DateTimeFormat('es-MX', { dateStyle: 'long', });
export const numeroDeSemana = fecha => {
    const day_of_seconds = 1000 * 60 * 60 * 24,
        days_of_week = 7,
        thursday = 4;
    fecha = new Date(Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate()));

    let diaDeLaSemana = fecha.getUTCDay(); // Domingo es 0, sábado es 6
    if (diaDeLaSemana === 0) {
        diaDeLaSemana = 7;
    }
    fecha.setUTCDate(fecha.getUTCDate() - diaDeLaSemana + thursday);
    const inicioDelAño = new Date(Date.UTC(fecha.getUTCFullYear(), 0, 1));
    const diferenciaDeFechasEnMilisegundos = fecha - inicioDelAño;
    return Math.ceil(((diferenciaDeFechasEnMilisegundos / day_of_seconds) + 1) / days_of_week);
};

export const result_month_success  = (dataSuccess, filter) =>{
    let response;

    response = dataSuccess.filter(function(table){
        let table_data    = new Date(table[filter]);
        let table_response = table_data.getMonth();
        return table_response === result_month;
    });

    return response;
} 


export const result_week_success  = (dataSuccess, filter) =>{

    let response;

    response = dataSuccess.filter(function(table){
        let table_data   = new Date(table[filter]);
        let table_response = numeroDeSemana(table_data);
        return table_response === result_week;
    })

    return response;
} 

