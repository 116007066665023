import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    DESARROLLADOR_CREATE,
    DESARROLLADOR_CREATE_SUCCESS,
    DESARROLLADOR_CREATE_ERROR,
    DESARROLLADOR_CREATE_INIT,
    DESARROLLADOR_LOADING,
    DESARROLLADOR_LOADING_SUCCESS,
    DESARROLLADOR_LOADING_ERROR,
    DESARROLLADOR_DESACTIVE,
    DESARROLLADOR_DESACTIVE_ERROR,
    DESARROLLADOR_LOAD,
    DESARROLLADOR_LOAD_SUCCESS,
    DESARROLLADOR_LOAD_ERROR,
    DESARROLLADOR_UPDATE_SUCCESS,
    DESARROLLADOR_UPDATE_ERROR,
    DESARROLLADOR_UPDATE_INIT,
} from '../types';



/* Create DESARROLLADOR*/ 
    export function createDesarrolladorAction(desarrollador) {

        return async ( dispatch ) => {
            
            dispatch( createDesarrollador() );

            try{
                await clientAxios.post('/desarrollador/crear/', desarrollador);
                dispatch( createDesarrolladorSuccess(desarrollador) );

            }catch( error ){
                dispatch( createDesarrolladorError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el desarrollador");
            }
        }

    }

    // Create Desarrollador init action
    export function createDesarrolladorInitFn(flag){
        return ( dispatch ) => {
            dispatch( createDesarrolladorInit(flag));
        }
    }

    const createDesarrollador = ( ) => ({
        type    : DESARROLLADOR_CREATE
    });

    const createDesarrolladorSuccess = (desarrollador) => ({
        type    : DESARROLLADOR_CREATE_SUCCESS,
        payload : desarrollador,
        flag    : true,
    })

    const createDesarrolladorError = () => ({
        type    : DESARROLLADOR_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createDesarrolladorInit = (flag) =>({
        type    : DESARROLLADOR_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* GRID DESARROLLADOR */
    export function getAllDesarrolladorAction() {
        return async ( dispatch ) => {
            dispatch( downloadDesarrollador() );

            try{
                const responseDesarrollador = await clientAxios.get('/desarrollador' );
                dispatch( downloadDesarrolladorSuccess (responseDesarrollador.data.data) );

            }catch( error ){
                dispatch( downloadDesarrolladorError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los desarrolladores");   
            }
        }
    }

    const downloadDesarrollador = () => ({
        type: DESARROLLADOR_LOADING,
        payload: true
    })

    const downloadDesarrolladorSuccess = ( desarrollador ) => ({
        type: DESARROLLADOR_LOADING_SUCCESS,
        payload: desarrollador
    })

    const downloadDesarrolladorError = (error) => ({
        type: DESARROLLADOR_LOADING_ERROR,
        error: error
    })

/* DESACTIVE DESARROLLADOR */
    export const desactiveDesarrolladorAction = (id) => {

        return async (dispatch)=>{
            dispatch( getDesarrolladorDesactive(id) );

            try{
                let response = await clientAxios.post(`/desarrollador/change-active/?id_desarrollador=${id}`);
              
                // ALL DESARROLLADOR UPDATE ACTIVE
                if(response.status === 200){
                    dispatch( downloadDesarrollador() );
                    let responseDesarrolladorsActive = await clientAxios.get('/desarrollador', );
                    dispatch( downloadDesarrolladorSuccess (responseDesarrolladorsActive.data.data) );
                }
                
            }catch( error ){
                dispatch( desactiveDesarrolladorError() );
                SweetAlertBasic("error","Ups","Hubo un error al desactivar/activar el desarrollador");  
            }
        }

    }

    const getDesarrolladorDesactive = (id)=>({
        type: DESARROLLADOR_DESACTIVE,
        payload: id
    })

    const desactiveDesarrolladorError = ( ) =>({
        type: DESARROLLADOR_DESACTIVE_ERROR,
        error: true,
        flag: false
    }) 

/* EDIT DESARROLLADOR */
    export const getDesarrolladorAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadDesarrollador() );

            try{
                let response = await clientAxios.get(`/desarrollador/${id}`);
                dispatch( loadDesarrolladorSuccess (response.data.data) );
            }catch( error ){
                dispatch( loadDesarrolladorError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar el desarrollador");  
            }
        }   
    }

    const getLoadDesarrollador = () => ({
        type: DESARROLLADOR_LOAD,
        payload: true
    })

    const loadDesarrolladorSuccess = (desarrollador) => ({
        type: DESARROLLADOR_LOAD_SUCCESS,
        editarUsuario: desarrollador
    })

    const loadDesarrolladorError = () => ({
        type: DESARROLLADOR_LOAD_ERROR,
        error: true
    })
    

/* UPDATE DESARROLLADOR */
    export const updateDesarrolladorAction = (desarrollador,id) => {
       
        return async (dispatch)=>{
            dispatch( updateDesarrolladorInit(desarrollador) );

            try{
                await clientAxios.put(`/desarrollador/${id}`, desarrollador);
                dispatch( updateDesarrolladorSuccess(desarrollador) );

            }catch( error ){
                dispatch( updateDesarrolladorError() );
                SweetAlertBasic("error","Ups",`Hubo un error al actualizar el desarrollador ${error}`);
            }
        }

    }

    const updateDesarrolladorInit = () => ({
        type: DESARROLLADOR_UPDATE_INIT,
        payload: true
    })

    const updateDesarrolladorSuccess = (desarrollador)=>({
        type: DESARROLLADOR_UPDATE_SUCCESS,
        desarrolladorActualizado: desarrollador
    })

    const updateDesarrolladorError = ( ) =>({
        type: DESARROLLADOR_UPDATE_ERROR,
        error: true,
        flag: false
    }) 