import React, { useState, useEffect }          from 'react'
import { createUserAction }         from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { SweetAlertBasic }          from '../../utils/sweetAlert';
import { useNavigate, Navigate }    from 'react-router-dom';
import ProgressBar                  from '../Progress/ProgressBar.component';
import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import InputLabel                   from '@mui/material/InputLabel';
import MenuItem                     from '@mui/material/MenuItem';
import Select                       from '@mui/material/Select';
import FormControl                  from '@mui/material/FormControl';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import Stack                        from '@mui/material/Stack';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
} from './Users.styles';
import{ 
    getAllTeamsAction 
}                               from '../../actions/teamActions';

const NewUser = () => {

    const [ nombreUsuario, setNombreUsuario ]                   = useState('');
    const [ apellidoMaternoUsuario, setApellidoMaternoUsuario ] = useState('');
    const [ apellidoPaternoUsuario, setApellidoPaternoUsuario]  = useState('');
    const [ telefonoUsuario, setTelefonoUsuario ]               = useState('');
    const [ emailUsuario, setEmailUsuario ]                     = useState('');
    const [ passwordUsuario, setPasswordUsuario ]               = useState('');
    const [ repeatPasswordUsuario, setRepeatPasswordUsuario ]   = useState('');
    const [equipo, setEquipo]                                   = useState('');
    const [ rol, setRol]                                        = useState('');

    // Dispatch
    const dispatch      = useDispatch();

    // Call Action
    const addCreate     = (user) => dispatch( createUserAction(user) );



    // Loading
    const loading       = useSelector( state => state.usuarios?.loading);
    const userSuccess   = useSelector( state => state.usuarios?.flag);
    const teamsUser     = useSelector( state => state.equipos?.equipos);
    const loadingTeams  = () => dispatch( getAllTeamsAction() );
   
    useEffect( () => {
        loadingTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );


    // SELECTS
    const handleTeam = (event) => {
        setEquipo(event.target.value);
    };

    const handleRol = (event) => {
        setRol(event.target.value);
    };

    //History
    const navigate = useNavigate();

    function goUsers() {
        navigate('/usuarios');
    }
  
    // Create of User
    const createUser = e => {
        e.preventDefault();

        //Validate form
        if( nombreUsuario.trim() === '' || telefonoUsuario.length === 0 || emailUsuario.trim() === ''){
            SweetAlertBasic("error","Ups","Le falta llenar campos");
            return;
        }

        if ( (passwordUsuario.trim() === '' || repeatPasswordUsuario.trim() === '') || ( passwordUsuario !== repeatPasswordUsuario) ) {
            SweetAlertBasic("error","Ups","El password no coincide");
            return;
        }


        // CreateUser
        addCreate({
            nombre_usuario:nombreUsuario,
            apellido_materno_usuario: apellidoMaternoUsuario,
            apellido_paterno_usuario: apellidoPaternoUsuario,
            password_usuario: passwordUsuario,
            repeat_password_usuario: repeatPasswordUsuario,
            telefono_usuario:telefonoUsuario,
            email_usuario:emailUsuario,
            id_equipo: equipo,
            id_rol: rol,
        });

    }


    return ( 
        <DivCenter >
            {
                loading ? 
                    <ProgressBar title="Creando Usuario..."/>
                :
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)':{},
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={createUser}
                    
                >
                    <TitleUser>Crear usuario</TitleUser>

                            <RowUser>
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el nombre del usuario" 
                                    variant="outlined"
                                    type="text"
                                    name="nombreUsuario"
                                    value={nombreUsuario}
                                    onChange={ e => setNombreUsuario(e.target.value) }
                                    fullWidth
                                />
                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el apellido paterno del usuario" 
                                    variant="outlined"
                                    name="apellidoPaternoUsuario"
                                    value={apellidoPaternoUsuario}
                                    onChange={ e => setApellidoPaternoUsuario(e.target.value) }
                                    fullWidth
                                />
                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el apellido materno del usuario" 
                                    variant="outlined"
                                    name="apellidoMaternoUsuario"
                                    value={apellidoMaternoUsuario}
                                    onChange={ e => setApellidoMaternoUsuario(e.target.value) }
                                    fullWidth
                                />
                           
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el telefono del usuario" 
                                    variant="outlined"
                                    name="telefonoUsuario"
                                    value={telefonoUsuario}
                                    type="number"
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    onChange={ e => setTelefonoUsuario( Number(e.target.value) ) }
                                    fullWidth
                                />

                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el email del usuario" 
                                    variant="outlined"
                                    name="emailUsuario"
                                    value={emailUsuario}
                                    onChange={ e => setEmailUsuario(e.target.value) }
                                    fullWidth
                                />
                          
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el password del usuario" 
                                    variant="outlined"
                                    name="passwordUsuario"
                                    value={passwordUsuario}
                                    onChange={ e => setPasswordUsuario(e.target.value) }
                                    fullWidth
                                />  
                                   
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa nuevamente el password del usuario" 
                                    variant="outlined"
                                    name="repeatPasswordUsuario"
                                    value={repeatPasswordUsuario}
                                    onChange={ e => setRepeatPasswordUsuario(e.target.value) }
                                    fullWidth
                                />    
                          

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                    <Select
                                        labelId="equipo_rol"
                                        id="demo-simple-select"
                                        label="Rol"
                                        value={rol}
                                        onChange={handleRol}
                                    >
                                        <MenuItem value={1}>Administrativos</MenuItem>
                                        <MenuItem value={2}>Especialista</MenuItem>
                                        <MenuItem value={3}>Inventario</MenuItem>
                                        <MenuItem value={4}>RH</MenuItem>
                                        <MenuItem value={5}>Recepcion</MenuItem>
                                        <MenuItem value={6}>Lider de Equipo</MenuItem>
                                        <MenuItem value={7}>Contratos</MenuItem>
                                        <MenuItem value={8}>Marketing</MenuItem>
                                    </Select>
                                </FormControl>
                    
                                {
                                    teamsUser ? 
                                    <FormControl >
                                        <InputLabel id="demo-controlled-open-select-label">Equipo</InputLabel>
                                        <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        name="id_equipo"
                                        label="Equipo"
                                        defaultValue={equipo}
                                        onChange={handleTeam}
                                        >
                                        {teamsUser.map((team) => (
                                            <MenuItem
                                                key={team.id_equipo}
                                                value={team.id_equipo}
                                            >
                                            {team.nombre_equipo}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    : null

                                }
                            
                            </RowUser>
                           
                    <DivButtons >
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={goUsers}
                                variant="contained" 
                                size="large"
                                color="error"
                            >
                                Cancelar
                            </Button>
                        
                            <Button 
                                variant="contained" 
                                size="small" 
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Crear Usuario
                            </Button>
                        </Stack>
                    </DivButtons>
                </Box>
            }
            {
                userSuccess ?
                    <Navigate to='/usuario/exitoso'/>
                :
                null
            }
            
            
        </DivCenter>
     );
}
 
export default NewUser;