import { 
    USER_CREATE,
    USER_CREATE_SUCCESS,
    USER_CREATE_ERROR,
    USER_CREATE_INIT,
    USERS_LOADING,
    USERS_LOADING_SUCCESS,
    USERS_LOADING_ERROR,
    USER_DESACTIVE,
    USER_DESACTIVE_ERROR,
    USER_LOAD,
    USER_LOAD_SUCCESS,
    USER_LOAD_ERROR,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_ERROR,
    USER_UPDATE_INIT,
    USER_PASSWORD_SUCCESS,
    USER_PASSWORD_ERROR,
    USER_PASSWORD_INIT,
} from '../types';

/*
    FLAG
        Revisamos si esta ok el usuario y lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
        Loading: false(enseña el cargando)
    
    LOADING
        True: Quitamos el componente loading(cargando) 
        False: Se enseña el componente cargando
*/
const initialState = {
    usuarios            : [],
    error               : null,
    loading             : false,
    loadingUpdate       : false,  
    flag                : false, 
    desactiveUsuario    : null,
    editarUsuario       : null,
    usuarioActializado  : null,
    flagUpdate          : false,
    passwordActualizado : null
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case    USER_CREATE:
        case    USER_LOAD:
        case    USERS_LOADING:
        case    USER_UPDATE_INIT:
        case    USER_PASSWORD_INIT:


            return{
                ...state,
                loading: true,
                loadingUpdate: true,
                flag: false,
            }

        case USER_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case USER_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                usuarios: [ ...state.usuarios, action.payload ]
            }

        case USER_CREATE_ERROR:
        case USERS_LOADING_ERROR:
        case USER_DESACTIVE_ERROR:
        case USER_LOAD_ERROR:
        case USER_UPDATE_ERROR:
        case USER_PASSWORD_ERROR:

            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case USERS_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                usuarios: action.payload,
                flag: false,
                desactiveUsuario: null
            }

        case USER_DESACTIVE:
            return{
                ...state,
                desactiveUsuario: action.payload
            }

        case USER_LOAD_SUCCESS:
            return{
                ...state,
                usuarios: [...state.usuarios],
                editarUsuario: action.editarUsuario,
                flag: true,
                loading: false
            }
        
        case USER_UPDATE_SUCCESS:
            return{
                ...state,
                usuarioActializado: action.usuarioActualizado,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }

        case USER_PASSWORD_SUCCESS:
            return{
                ...state,
                passwordActualizado: action.passwordActualizado,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
        default:
            return state;
    }
}