import React                        from 'react';
import {
    ImageUserSuccess,
    TitleUserSuccess,
    DivUserSuccess
}                                       from '../../Usuarios/Users.styles';
import Error             from '../../../Images/error.png';


const ErrorRole = () => {

    return ( 
        <>
           
            <DivUserSuccess>
                <ImageUserSuccess  src={Error} alt="logo" />
                <TitleUserSuccess>
                    No Tienes los permisos suficientes para ingresar a este módulo.
                </TitleUserSuccess> 
            </DivUserSuccess>
        </>
     );
}
 
export default ErrorRole;