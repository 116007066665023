import React,{ useEffect }          from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar                  from '../Progress/ProgressBar.component';

// TABLE
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsDesarrollos }   from '../../utils/rowData';
import Button                       from '@mui/material/Button';
import AddCircleIcon                from '@mui/icons-material/AddCircle';

import{ 
        createDesarrolloInitFn, 
        getAllDesarrollosAction 
    }                               from '../../actions/desarrolloActions';

import{    
        DivGrid, 
        TitleAndButton, 
        DivButton, 
        TitleGrid 
    }                               from '../Dashboards/styles/Inventario.styles';




const Desarrollos = () => {

    const dispatch                      = useDispatch();
    const addCreateInit                 = (desarrollos) => dispatch( createDesarrolloInitFn(desarrollos) );
    const navigate                      = useNavigate();
    const desarrollosSuccess            = useSelector( state => state.desarrollo.desarrollos || {});
    const loading                       = useSelector( state => state.desarrollo?.loading);

    // USE EFFECT LOADER
    useEffect( () => {
        const loadingDesarrollos = () => dispatch( getAllDesarrollosAction() );
        loadingDesarrollos();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    
    // Onclick Desarrollos Init action
    function newDesarrollos() {
        addCreateInit(false);
        navigate('/desarrollos/crear');
    }

    return ( 
        <>
      
        {
            loading ? 
                <ProgressBar title="Cargando Desarrollos..."/>
            :
                <>
                        <TitleAndButton>
                            <TitleGrid>Desarrollos</TitleGrid>

                            <DivButton>
                                <Button
                                    variant="contained"
                                    onClick={newDesarrollos}
                                    startIcon={<AddCircleIcon />}
                                    size="large"
                                >
                                    Crear Desarrollo
                                </Button>
                            </DivButton>
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={desarrollosSuccess}
                                columns             ={ColumnsDesarrollos}
                                pageSize            ={10}
                                rowsPerPageOptions  ={[10]}
                                getRowId            ={desarrollosSuccess => desarrollosSuccess.id_desarrollo}
                            />
                        </DivGrid>
                </>
        }

        </>
     );
}
 
export default Desarrollos;