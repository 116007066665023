import * as React       from 'react';
import Box              from '@mui/material/Box';
import Dialog           from '@mui/material/Dialog';
import TableModal       from '../Table'

const ModalGlobal = ({ setIsOpen, data,tableCell }) => {

    const handleClose = () => {
        setIsOpen(false);
    };


    return ( 
        <>           
            <Dialog 
                open={true} 
                onClose={handleClose}  
                fullWidth  
                maxWidth={"lg"} 
            >
                <Box>
                    <TableModal 
                        data        = {data}
                        tableCell   = {tableCell}
                    />
                </Box>
            </Dialog>
           
        </>
     );
}
 
export default ModalGlobal;