import React, { useEffect }         from 'react';
import  { useDispatch }             from 'react-redux';
import  { getLoginUserFn }          from '../../actions/loginActions';
import ImageSearchImg               from '../../Images/search_1.png';
import { useNavigate }              from 'react-router-dom';
import {
    ImageSearch,
    DivSearch,
    TitleSearch,
    TitleCreateGreen
} from './styles/Rh.styles';

const DashboardRH = () => {

    const dispatch          = useDispatch();
    const navigate          = useNavigate();

    // USE EFFECT LOGIN
    useEffect( () => {
        const loginUser = () => dispatch( getLoginUserFn() );
        loginUser();
         
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    function goUserCreate(){
        navigate('/usuarios/crear');
    }

    

    return (  
        <>
              <DivSearch>
                <ImageSearch  src={ImageSearchImg} alt="logo" />
                <TitleSearch>Empieza a buscar un usuario</TitleSearch>
                
                <TitleCreateGreen
                    onClick={goUserCreate}
                    >
                        o crea un usuario
                </TitleCreateGreen>
            </DivSearch>
        </>
    );
}
 
export default DashboardRH;