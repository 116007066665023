import React                            from 'react';
import {
    ImageUserSuccess,
    TitleUserSuccess,
    DivUserSuccess
}                                       from '../Usuarios/Users.styles';
import GoalsEmpty                       from '../../Images/goals_empty.png';


const EmptyGoals = ({ message}) => {

    return ( 
        <>
            <DivUserSuccess>
                <ImageUserSuccess  src={GoalsEmpty} alt="logo" /><br/>
                <TitleUserSuccess>
                    {message}
                </TitleUserSuccess> 
            </DivUserSuccess>
        </>
     );
}
 
export default EmptyGoals;