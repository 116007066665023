import React, { useState }              from 'react';
import { useParams }                    from 'react-router-dom';
import { useDispatch, useSelector }     from 'react-redux';
import InputLabel                       from '@mui/material/InputLabel';
import Select                           from '@mui/material/Select';
import FormControl                      from '@mui/material/FormControl';
import SaveIcon                         from '@mui/icons-material/Save';
import TextareaAutosize                 from '@mui/material/TextareaAutosize';
import Box                              from '@mui/material/Box';
import Dialog                           from '@mui/material/Dialog';
import DialogActions                    from '@mui/material/DialogActions';
import DialogContent                    from '@mui/material/DialogContent';
import DialogContentText                from '@mui/material/DialogContentText';
import DialogTitle                      from '@mui/material/DialogTitle';
import MenuItem                         from '@mui/material/MenuItem';
import Button                           from '@mui/material/Button';
import Divider                          from '@mui/material/Divider';
import  { 
    updateClientAction,
    getClientAction 
}                                       from '../../actions/clientActions';
import  { createSeguimientoActionFn }   from '../../actions/seguimientoActions';


const ModalStatus = ({setOpenModal, titleModal, editClient, idStatus }) => {

    const [ descripcion_nota_seguimiento_cliente, setDescripcionNota ]      = useState('');
    const [ descripcion_seguimiento_cliente, setDescripcionSeguimiento]     = useState('');
    const [ tipo_nota_seguimiento_cliente, setTipoNota ]                    = useState('');
    const [ tipo_seguimiento_cliente, setTipoSeguimiento ]                  = useState('');
    const { id_cliente }                                                    = useParams();
    const id_user                                                           = useSelector( state => state.login?.user?.id ); 
    const dispatch                                                          = useDispatch();

    const handleTipoSeguimiento = (event) => {
        setTipoNota(event.target.value);
    };

    const handleTipoNota = (event) => {
        setTipoSeguimiento(event.target.value);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    


    const updateClientInit      = (client)      => dispatch( updateClientAction( client, id_cliente ) );
    const addCreateSeguimiento  = (seguimiento) => dispatch( createSeguimientoActionFn(seguimiento) );
    const loadingClient         = () => dispatch( getClientAction(id_cliente) );


    const { id_status } = editClient;
    
    // Create seguimiento
    const createSeguimiento = e => {
        e.preventDefault();
     
        let dataClient ={
            id_status : idStatus
        }   
       
        updateClientInit(dataClient, id_cliente);

        addCreateSeguimiento({
            descripcion_nota_seguimiento_cliente: descripcion_nota_seguimiento_cliente,
            tipo_nota_seguimiento_cliente: tipo_nota_seguimiento_cliente,
            status_anterior_cliente: id_status,
            status_actual_cliente: idStatus,
            activo_seguimiento_cliente: true,
            descripcion_seguimiento_cliente: descripcion_seguimiento_cliente,
            tipo_seguimiento_cliente: tipo_seguimiento_cliente,
            fecha_seguimiento_cliente: '2020-03-22',
            fecha_cierre_cliente: '2020-03-22',
            id_cliente: id_cliente,
            id_usuario: id_user,
            datos_agenda: "Seguimiento"
        });

        setOpenModal(false);
        loadingClient();
    }

    return ( 
        <>
            {/* MODAL */}
            <Dialog open={true} onClose={handleCloseModal}  fullWidth  maxWidth={"lg"} >
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={createSeguimiento}
                    
                >
                    <DialogTitle>{titleModal === 'Seguimiento' ? "Crear un: " : "Pasar al status: "}<strong>{titleModal}</strong></DialogTitle>
                    <Divider sx={{ my: 0.5 }} />
                    <DialogContentText style={{ marginTop:'20px', marginLeft: '25px', marginBottom: '10px'}}>
                        <strong> Selecciona el tipo de nota:</strong>
                    </DialogContentText>
                    <FormControl sx={{ width:'86%', marginLeft: 3,}} >
                        <InputLabel id="demo-simple-select-label">Tipo de Prioridad(Nota)</InputLabel>
                        <Select
                            labelId="tipo_nota_seguimiento_cliente"
                            id="demo-simple-select"
                            label="tipo_nota_seguimiento_cliente"
                            value={tipo_nota_seguimiento_cliente}
                            onChange={handleTipoSeguimiento}
                        >
                            <MenuItem value={'Ninguna'}>Ninguna</MenuItem>
                            <MenuItem value={'Baja'}>Baja</MenuItem>
                            <MenuItem value={'Media'}>Media</MenuItem>
                            <MenuItem value={'Alta'}>Alta</MenuItem>
                        </Select>
                    </FormControl>

                    <DialogContent>
                        <DialogContentText>
                        <strong> Agrega la descripción de la actividad:</strong>
                        </DialogContentText><br/>
                        <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Escribe una breve descripción."
                            value={descripcion_nota_seguimiento_cliente}
                            onChange={ e => setDescripcionNota(e.target.value) }
                            style={{ width: '100%', height: 120 }}
                        />
                    </DialogContent>

                    <Divider sx={{ my: 0.5 }} />
                    <DialogContentText style={{ marginTop:'20px', marginLeft: '25px', marginBottom: '10px'}}>
                        <strong> Selecciona el tipo de seguimiento:</strong>
                    </DialogContentText>
                    <FormControl sx={{ width:'86%', marginLeft: 3,}}>
                        <InputLabel id="demo-simple-select-label">Tipo de Prioridad(Seguimiento)</InputLabel>
                        <Select
                            labelId="tipo_seguimiento_cliente"
                            id="demo-simple-select"
                            label="tipo_seguimiento_cliente"
                            value={tipo_seguimiento_cliente}
                            onChange={handleTipoNota}
                        >
                            <MenuItem value={'Ninguna'}>Ninguna</MenuItem>
                            <MenuItem value={'Baja'}>Baja</MenuItem>
                            <MenuItem value={'Media'}>Media</MenuItem>
                            <MenuItem value={'Alta'}>Alta</MenuItem>
                        </Select>
                    </FormControl>
                    <DialogContent>
                        <DialogContentText>
                        <strong> ¿Qué se le hará al cliente?</strong>
                        </DialogContentText><br/>
                        <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Escribe una breve descripción que se le hará al cliente."
                            value={descripcion_seguimiento_cliente}
                            onChange={ e => setDescripcionSeguimiento(e.target.value) }
                            style={{ width: '100%', height: 120 }}
                        />
                    </DialogContent>
                    
                    <br/>
                    <Divider sx={{ my: 0.5 }} />
                    
                    <DialogActions>
                        <Button 
                            onClick ={handleCloseModal}
                            variant ="contained" 
                            size    ="large"
                            color   ="error"
                        >Cancelar</Button>
                        <Button
                            variant     ="contained" 
                            size        ="large"
                            type="submit"
                            startIcon   ={<SaveIcon />}
                        >
                            Enviar
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
            
        
        </>
    );
}
 
export default ModalStatus;