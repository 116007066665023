import React, { useState,useEffect }            from 'react'
import { useParams }                            from 'react-router-dom';
import ProgressBar                              from '../Progress/ProgressBar.component';
import { useDispatch, useSelector }             from 'react-redux';
import Moment                                   from 'react-moment';
import { SweetAlertBasic }                      from '../../utils/sweetAlert';
import EditClient                               from './EditForm/EditClient.component';
import Sidebar                                  from '../../components/Sidebar/Sidebar.component';
import Link                                     from "@material-ui/core/Link";
import TextField                                from '@mui/material/TextField';
import InputLabel                               from '@mui/material/InputLabel';
import MenuItem                                 from '@mui/material/MenuItem';
import Select                                   from '@mui/material/Select';
import FormControl                              from '@mui/material/FormControl';
import TelIcon                                  from '../../Images/llamada_icon.png';
import WhatsIcon                                from '../../Images/whatsapp_icon.png';
import EmailIcon                                from '../../Images/email_icon.png';
import Button                                   from '@mui/material/Button';
import SaveIcon                                 from '@mui/icons-material/Save';
import HomeIcon                                 from '@mui/icons-material/Home';
import TextareaAutosize                         from '@mui/material/TextareaAutosize';
import { makeStyles }                           from '@material-ui/core/styles';
// TABS
import PropTypes                                from 'prop-types';
import Tabs                                     from '@mui/material/Tabs';
import Tab                                      from '@mui/material/Tab';
import Typography                               from '@mui/material/Typography';
import Box                                      from '@mui/material/Box';
import SwipeableViews                           from 'react-swipeable-views';
import { useTheme }                             from '@mui/material/styles';
import AppBar                                   from '@mui/material/AppBar';
// TABS
// CARDS
import Card                                     from '@mui/material/Card';
import CardActions                              from '@mui/material/CardActions';
import CardContent                              from '@mui/material/CardContent';
import CardMedia                                from '@mui/material/CardMedia';
import ImageSearchImg                           from '../../Images/plusvarq-image.jpg';
import ImageOptions                             from '../../Images/bienes_raices.png';
import ScheduleImg                             from '../../Images/agenda_casa.png';
// CARDS
// MENU
import Divider                                  from '@mui/material/Divider';
import FileCopyIcon                             from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon                    from '@mui/icons-material/KeyboardArrowDown';
// MENU
// MODAL
import Dialog                                   from '@mui/material/Dialog';
import DialogActions                            from '@mui/material/DialogActions';
import DialogContent                            from '@mui/material/DialogContent';
import DialogContentText                        from '@mui/material/DialogContentText';
import DialogTitle                              from '@mui/material/DialogTitle';
// MODAL
import { 
    status_button
}                                               from '../../utils/status.js';
import { StyledMenu }                           from '../../utils/menu.js';
import  { getAllUsersAction     }               from '../../actions/userActions';
import  { getAllStatusAction    }               from '../../actions/statusActions';
import  { getSeguimientoAction  }               from '../../actions/seguimientoActions';
import  { getAllHousesAction    }               from '../../actions/houseActions';
import  { getAllSchedulesAction }               from '../../actions/agendaActions';
import  { createSeguimientoActionFn }           from '../../actions/seguimientoActions';
import{  
    getClientAction,
    updateClientAction
}                                               from '../../actions/clientActions';
import  { 
    createOptionAction,
    getOptionClientAction    
}                                               from '../../actions/optionsActions';
import {
    createScheduleAction
}                                               from '../../actions/agendaActions';

import {
    DivCenter
}                                               from '../Usuarios/Users.styles';

import {
    DivClientOne,
    DivClientChild,
    DivClientThree,
    DivClientFour,
    Image,
    DivClientBox,
    TitleDetail,
    DateClient,
    DetailClient,
    ClientP,
    StatusClientSales,
    TitleHouse,
    DetailHouse
}                                               from './Clients.styles';

import {
    DivOpcionesClient,
    DivCasas,
    ButtonsCasa,
    TitleRed,
    TitleGreen
}                                       from '../Opciones/Opciones.styles';


const EditClientDesktop = () => {

    let userLocal               = JSON.parse(localStorage.getItem('user'))

    const dispatch              = useDispatch();
    const { id_cliente }        = useParams();
    const loading               = useSelector( state => state.clientes?.loading);
    const editClient            = useSelector( state => state.clientes?.editarCliente || {} );
    const seguimientos          = useSelector( state => state.seguimiento?.seguimientos || {});
    // const statusSuccess         = useSelector( state   => state.status?.status || {});
    const id_user               = useSelector( state => state.login?.user?.id ); 
    const houseSuccess          = useSelector( state => state.casas.casas);
    const optionsClientSuccess  = useSelector( state => state?.opciones?.opcionClienteSales);
    const scheduleSuccess       = useSelector( state => state?.agendas?.agendas);
    const loadingClient         = () => dispatch( getClientAction(id_cliente) );
    const loadingSeguimiento    = () => dispatch( getSeguimientoAction(id_cliente) );
    const loadingStatus         = () => dispatch( getAllStatusAction() );
    const loadingUsers          = () => dispatch( getAllUsersAction() );
    const loadingHouses         = () => dispatch( getAllHousesAction() );
    const loadingSchedules      = () => dispatch( getAllSchedulesAction() );
    const loadingOptionClient   = (client) => dispatch( getOptionClientAction(client) );
    const addCreateSeguimiento  = (seguimiento) => dispatch( createSeguimientoActionFn(seguimiento) );
    const addCreateOption       = (option)      => dispatch( createOptionAction(option) );
    const updateClientInit      = (client)      => dispatch( updateClientAction( client, id_cliente ) );
    const addCreateSchedule     = (schedule)    => dispatch( createScheduleAction(schedule) );

    const [ descripcion_nota_seguimiento_cliente, setDescripcionNota ]      = useState('');
    const [ descripcion_seguimiento_cliente, setDescripcionSeguimiento]     = useState('');
    const [ tipo_nota_seguimiento_cliente, setTipoNota ]                    = useState('');
    const [ tipo_seguimiento_cliente, setTipoSeguimiento ]                  = useState('');

   

    useEffect( () => {
        loadingClient();
        loadingUsers();
        loadingSeguimiento();
        loadingStatus(); 
        loadingHouses();
        loadingSchedules();
        loadingOptionClient(id_cliente);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );


    const{ 
        id_status,
        nombre_cliente,
        telefono_cliente,
        email_cliente,
        tipo_credito,
        nombre_status,
        especialista,
        referencia_cliente,
    } = editClient;

    function goOption(id_casa){
        // navigate(`/opciones/${id}`);
        window.open(`/opciones/${id_casa}` )
    }


    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
            <>
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                    )}
                </div>
            </>
        );
    }
      
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
      
    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        console.log(event)
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    // MENU 
    const [anchorEl, setAnchorEl]   = useState(null);
    const open                      = Boolean(anchorEl);

    const handleClick               = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose               = () => {
      setAnchorEl(null);
    };

    // MODAL
    const [openModal, setOpenModal]     = useState(false);
    const [titleModal, setTitleModal]   = useState(null);
    const [idStatus, setIdStatus]       = useState(null);

    const handleClickOpenModal = ( status, id ) => {
        setIdStatus(id)
        setTitleModal(status);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    
    // ADD SEGUIMIENTO
        const handleTipoSeguimiento = (event) => {
            setTipoNota(event.target.value);
        };

        const handleTipoNota = (event) => {
            setTipoSeguimiento(event.target.value);
        };
    
    // Create seguimiento
    const createSeguimiento = e => {
        e.preventDefault();
     
        let dataClient ={
            id_status : idStatus
        }

     
        // UPDATE CLIENT
        updateClientInit(dataClient, id_cliente);

        // CreateSeguimiento
        addCreateSeguimiento({
            descripcion_nota_seguimiento_cliente: descripcion_nota_seguimiento_cliente,
            tipo_nota_seguimiento_cliente: tipo_nota_seguimiento_cliente,
            status_anterior_cliente: id_status,
            status_actual_cliente: idStatus,
            activo_seguimiento_cliente: true,
            descripcion_seguimiento_cliente: descripcion_seguimiento_cliente,
            tipo_seguimiento_cliente: tipo_seguimiento_cliente,
            fecha_seguimiento_cliente: '2020-03-22',
            fecha_cierre_cliente: '2020-03-22',
            id_cliente: id_cliente,
            id_usuario: id_user,
            datos_agenda: "Seguimiento"
        });

        setOpenModal(false);
        setAnchorEl(null);
        loadingClient();
    }

    const clickWhatsapp = ( id_casa) => {

        addCreateOption({
            tipo_opcion: "Envió de Opciones vía CRM",
            tipo_opcion_casa: "Opciones de Casa",
            descripcion_opcion_casa: "Opción enviada desde opciones de casa",
            ruta_opcion_casa: "sin ruta",
            id_casa: parseInt(id_casa),
            id_usuario: userLocal?.id ? userLocal?.id : 1,
            id_cliente: parseInt(id_cliente)
        })

        window.open(`https://wa.me/52${telefono_cliente}?text=${window.location.hostname+'/opciones/' + id_casa}` )
    }
   
    // MODAL SCHEDULE
    const [openModalSchedule, setOpenModalSchedule]     = useState(false);
    const [ clickSchedule, setClickSchedule]            = useState(false);
    const [ disabledSchedule, setDisabledSchedule]      = useState(false);
    const [ idHouseClick, setIdHouseClick]              = useState(null);
    const [ scheduleTime, setScheduleTime]              = useState({
        day: '',
        time:'',
    })
    const handleClickOpenModalSchedule = ( ) => {
        setOpenModalSchedule(true);
    };

    const handleCloseModalSchedule = () => {
        setOpenModalSchedule(false);
    };

    // Create of User
    const createClient = (e, tipo) => {
        e.preventDefault();

        //Validate form
        if( idHouseClick === null ){
            SweetAlertBasic("error","Ups","Debes de seleccionar un inmueble.");
            setOpenModalSchedule(false);
            return;
        }
        if( scheduleTime.time.trim() === '' ||  scheduleTime.day.trim() === ''  ){
            SweetAlertBasic("error","Ups","Debes de seleccionar un día y una hora para agendar.");
            setOpenModalSchedule(false);
            return;
        }

    
        addCreateSchedule({
            datos_agenda: `Agenda de: ${nombre_cliente} - ${email_cliente} - ${telefono_cliente} `,
            horario_agenda_casa: scheduleTime.time,
            dia_agenda_casa: scheduleTime.day,
            tipo_agenda_casa: tipo,
            origen_agenda_casa: "CRM AGENDA OPCIONES",
            id_casa: idHouseClick,
            nombre_cliente: nombre_cliente,
            email_cliente: email_cliente,
            telefono_cliente: telefono_cliente
        })
        setOpenModalSchedule(false);
    }

    const clickButtonSchedule   = ( id_casa ) => {
        console.log(disabledSchedule);
        setClickSchedule(true); 
        setDisabledSchedule(true)
        setIdHouseClick(id_casa)
    }
    const disableButtonSchedule = ( ) => {setClickSchedule(false);  setDisabledSchedule(false)}

    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            marginTop: '2rem',
            marginBottom: '2rem',
            width: '80%',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: '2rem',
            marginBottom: '2rem'
        },
    }));

    const classes       = useStyles();
    const data_status   = status_button.find(btn => btn.value === id_status);


    return ( 
        <>
                              
            <Sidebar/>
            <DivCenter >
            {/* MODAL SCHEDULE */}
            <Dialog open={openModalSchedule} onClose={handleCloseModalSchedule} fullWidth  maxWidth={"lg"} >
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => createClient(e, "Desktop")}       
                >
               
                    <Divider sx={{ my: 0.5 }} />
                    <DialogContentText style={{ marginTop:'20px', marginLeft: '25px', marginBottom: '10px'}}>
                        <strong></strong>
                    </DialogContentText>
                    <FormControl sx={{ width:'100%', margin: '0 auto',marginTop:'30px', display: 'flex'}} >
                        {/* OPCIONES */}
                        <Typography 
                            component="div" 
                            variant="h5"
                            style={{
                                marginLeft: '7rem',
                                marginBottom: '2rem',
                            }}
                        >
                           Elige el inmueble agendar:
                        </Typography>
                        <div>
                            <DivOpcionesClient
                                style={{ 
                                    height: '20rem',
                                    width: '81%',
                                    marginBottom: '3rem'
                                }}
                            >
                                <div 
                                    style={{ 
                                        height: '100%', 
                                        width: '100%',
                                        filter: clickSchedule ? 'opacity(30%)' : null
                                    }}
                                >
                                    {houseSuccess
                                    .sort((a,b) =>  b.activo_casa - a.activo_casa)
                                    .map( (house) => (
                                        <DivCasas>
                                            <Card sx={{ display: 'flex', m: 3, width: 1500}} >
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: 180, height: 215 }}
                                                    image={ImageSearchImg}
                                                    alt="Imagen de casas"
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '215px' }}>
                                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                                        <Typography 
                                                            component="div" 
                                                            variant="h5"
                                                            style={{
                                                                width:'450px',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {house.nombre_casa}
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        <strong>Dirección:</strong> {house.calle_casa}
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        <strong>Tipo de casa:</strong> {house.tipo_casa}
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        <strong>CP:</strong> {house.cp_casa}
                                                        </Typography>
                                                        {
                                                            house.activo_casa ? 
                                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                <TitleGreen>Casa Activada</TitleGreen>
                                                            </Typography> 
                                                            :
                                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                <TitleRed>Casa Desactivada</TitleRed>
                                                            </Typography>
                                                        }
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        <strong>ID:</strong> {house.id_casa}
                                                        </Typography>
                                                
                                                    </CardContent>
                                                </Box>
                                                <ButtonsCasa >
                                                    <Button 
                                                        variant     ="contained" 
                                                        size        ="small" 
                                                        onClick     ={() =>goOption(house.id_casa)}
                                                        disabled    ={clickSchedule ? true : false}
                                                    >
                                                        Ver más
                                                    </Button>

                                                    <Button 
                                                        variant     ="contained" 
                                                        size        ="small" 
                                                        color       ="success" 
                                                        style       ={{marginLeft:10}}
                                                        onClick={ () => clickButtonSchedule(house.id_casa)}
                                                        disabled= {clickSchedule && house.activo_casa ? true : false}
                                                    >
                                                    Agendar
                                                    </Button> 
                                                </ButtonsCasa>
                                            </Card>
                                        </DivCasas>
                                    
                                    ))
                                    }  
                                </div>
                            </DivOpcionesClient>
                        </div>
                        {/* OPCIONES */}
                        {
                            clickSchedule ? 
                            <div
                               style={{ display: 'flex'}}
                            >
                                <Button
                                    variant     ="contained" 
                                    size        ="large"
                                    color       ="warning"
                                    fullWidth
                                    onClick={ () => disableButtonSchedule()}
                                    style={{
                                        width:'300px',
                                        margin: 'auto', 
                                        fontWeight:"bold"
                                    }}
                                >
                                    Agendar otro inmueble
                                </Button>
                            </div>
                            : null
                        }
                        <div
                            style ={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: 'column',
                            }}
                        >
                            <form className={classes.container} noValidate>
                                <TextField
                                    id="date"
                                    name="day"
                                    label="Ingrese el día de la agenda"
                                    type="date"
                                    defaultValue={Date.now()}
                                    className={classes.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    value={scheduleTime.day}
                                    onChange={(e) => {
                                        setScheduleTime({
                                            ...scheduleTime,
                                            [e.target.name]:e.target.value
                                        });
                                    }}
                                    fullWidth
                                />
                            </form>

                            <form className={classes.container} noValidate>
                                <TextField
                                    id="time"
                                    label="Ingrese la hora"
                                    type="time"
                                    name="time"
                                    defaultValue={Date.now()}
                                    className={classes.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    inputProps={{
                                    step: 300, // 5 min
                                    }}
                                    value={scheduleTime.time}
                                    onChange={(e) => {
                                        setScheduleTime({
                                            ...scheduleTime,
                                            [e.target.name]:e.target.value
                                        });
                                    }}
                                    fullWidth
                                />
                            </form>
                        </div>
                    </FormControl>
                        <>
                            <Divider sx={{ my: 0.5 }} style={{ marginBottom: '10px'}}/>
                            <DialogActions
                                style={{ 
                                    marginBottom: '20px',
                                    marginTop: '20px',
                                    width: '40%',
                                    margin: 'auto',
                                }}
                            >
                                <Button
                                    variant     ="contained" 
                                    size        ="large"
                                    type        ="submit"
                                    color       ="success"
                                    fullWidth
                                    style={{fontWeight:"bold"}}
                                    startIcon   ={<SaveIcon />}
                                >
                                    Agendar Visita
                                </Button>
                            </DialogActions>
                        </>
                </Box>
            </Dialog>
            {/* MODAL SCHEDULE */}
            {   
        
                // LOADING CLIENT
                loading ? 
                    <ProgressBar title="Cargando Cliente..."/>
                :
                <>
                <DivClientOne>
                    
                    <DivClientChild>
                        {nombre_cliente} 
                    </DivClientChild>
                    <DivClientChild>
                       <StatusClientSales></StatusClientSales>
                    </DivClientChild>
                    <DivClientThree>
                        <Link
                            href={"tel:"+telefono_cliente}
                        >
                            <Image  src={TelIcon} alt="logo" />
                        </Link>
                        <Link
                            href={`https://wa.me/52${telefono_cliente}?text=Hola%20te%20contacto%20por%20este%20medio%20somos%20la%20inmobiliaria%20plusvarq`}
                            target="_blank"
                        >
                            <Image  src={WhatsIcon} alt="logo" />
                        </Link>
                        <Link
                            href={"mailto:"+email_cliente}
                        >
                            <Image  src={EmailIcon} alt="logo" />
                        </Link>
                    </DivClientThree>
                    {/* <DivClientFour
                        onClick={seguimientoNote}
                    >
                        <TitleNote>    
                            Seguimiento
                        </TitleNote>
                    </DivClientFour> */}
                    <DivClientFour>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            style ={{ backgroundColor:" #004274"}}
                        >
                            Acciones
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            fullWidth  
                            maxWidth={"lg"} 
                        >
                            <MenuItem onClick={ () => handleClickOpenModal("Seguimiento", id_status)} disableRipple>
                                <FileCopyIcon />
                                Seguimiento
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                          
                          
                                    <MenuItem><strong>Pasarlo al status:</strong></MenuItem>
                                    {data_status?.status_button.map((status) => (
                                        <MenuItem
                                            key={status.value}
                                            value={status.value}
                                            disableRipple
                                            onClick={ () =>  handleClickOpenModal(status.name, status.value)}
                                        >
                                        {status.name} 
                                        </MenuItem>
                                    ))
                                    }
                                
                        </StyledMenu>
                    </DivClientFour>
                    {/* MODAL */}
                    <Dialog open={openModal} onClose={handleCloseModal}  fullWidth  maxWidth={"lg"} >
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={createSeguimiento}
                            
                        >
                            <DialogTitle>{titleModal === 'Seguimiento' ? "Crear un: " : "Pasar al status: "}<strong>{titleModal}</strong></DialogTitle>
                            <Divider sx={{ my: 0.5 }} />
                            <DialogContentText style={{ marginTop:'20px', marginLeft: '25px', marginBottom: '10px'}}>
                                <strong> Selecciona el tipo de nota:</strong>
                            </DialogContentText>
                            <FormControl sx={{ width:300, marginLeft: 3,}} >
                                <InputLabel id="demo-simple-select-label">Tipo de Prioridad(Nota)</InputLabel>
                                <Select
                                    labelId="tipo_nota_seguimiento_cliente"
                                    id="demo-simple-select"
                                    label="tipo_nota_seguimiento_cliente"
                                    value={tipo_nota_seguimiento_cliente}
                                    onChange={handleTipoSeguimiento}
                                >
                                    <MenuItem value={'Ninguna'}>Ninguna</MenuItem>
                                    <MenuItem value={'Baja'}>Baja</MenuItem>
                                    <MenuItem value={'Media'}>Media</MenuItem>
                                    <MenuItem value={'Alta'}>Alta</MenuItem>
                                </Select>
                            </FormControl>

                            <DialogContent>
                                <DialogContentText>
                                <strong> Agrega la descripción de la actividad:</strong>
                                </DialogContentText><br/>
                                <TextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="Escribe una breve descripción."
                                    value={descripcion_nota_seguimiento_cliente}
                                    onChange={ e => setDescripcionNota(e.target.value) }
                                    style={{ width: '100%', height: 120 }}
                                />
                            </DialogContent>
                        
                            <Divider sx={{ my: 0.5 }} />
                            <DialogContentText style={{ marginTop:'20px', marginLeft: '25px', marginBottom: '10px'}}>
                                <strong> Selecciona el tipo de seguimiento:</strong>
                            </DialogContentText>
                            <FormControl sx={{ width:300, marginLeft: 3,}}>
                                <InputLabel id="demo-simple-select-label">Tipo de Prioridad(Seguimiento)</InputLabel>
                                <Select
                                    labelId="tipo_seguimiento_cliente"
                                    id="demo-simple-select"
                                    label="tipo_seguimiento_cliente"
                                    value={tipo_seguimiento_cliente}
                                    onChange={handleTipoNota}
                                >
                                    <MenuItem value={'Ninguna'}>Ninguna</MenuItem>
                                    <MenuItem value={'Baja'}>Baja</MenuItem>
                                    <MenuItem value={'Media'}>Media</MenuItem>
                                    <MenuItem value={'Alta'}>Alta</MenuItem>
                                </Select>
                            </FormControl>
                            <DialogContent>
                                <DialogContentText>
                                <strong> ¿Qué se le hará al cliente?</strong>
                                </DialogContentText><br/>
                                <TextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="Escribe una breve descripción que se le hará al cliente."
                                    value={descripcion_seguimiento_cliente}
                                    onChange={ e => setDescripcionSeguimiento(e.target.value) }
                                    style={{ width: '100%', height: 120 }}
                                />
                            </DialogContent>
                            
                            <br/>
                            <Divider sx={{ my: 0.5 }} />
                            
                            <DialogActions>
                                <Button 
                                    onClick ={handleCloseModal}
                                    variant ="contained" 
                                    size    ="large"
                                    color   ="error"
                                >Cancelar</Button>
                                <Button
                                    variant     ="contained" 
                                    size        ="large"
                                    type="submit"
                                    startIcon   ={<SaveIcon />}
                                >
                                    Enviar
                                </Button>
                            </DialogActions>
                        </Box>
                    </Dialog>
                </DivClientOne>
                <DivClientBox>
                    <TitleDetail>Detalles</TitleDetail>

                    {/* TAB */}
                    <DateClient>
                            <Box sx={{ bgcolor: 'background.paper', width: 1000 }} >
                                <AppBar position="static" style ={{ backgroundColor:" #004274"}}> 
                                    <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                    >
                                    <Tab label="Datos Personales" {...a11yProps(0)} />
                                    <Tab label="Seguimiento" {...a11yProps(1)} />
                                    <Tab label="Opciones" {...a11yProps(2)} />
                                    <Tab label="Opciones Enviadas" {...a11yProps(3)} />
                                    <Tab label="Agendas" {...a11yProps(4)} />
                                    </Tabs>
                                </AppBar>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    <TabPanel value={value} index={0} dir={theme.direction}>
                                        <EditClient data={editClient}/>
                                    </TabPanel>
                                    <TabPanel style={{height: 600, overflowY: 'auto', overflowX: 'hidden'}} value={value} index={1} dir={theme.direction}>
                                        {seguimientos.map((seguimiento, i) => (
                                            <Card 
                                                sx={{ maxWidth: 1000, marginBottom: 5}}
                                            >
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Creado por: {seguimiento?.nombre_usuario}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <strong>Descripción: </strong><br/>
                                                        {seguimiento?.descripcion_nota_seguimiento_cliente}
                                                    </Typography><br/>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <strong>Actualización: </strong><br/>
                                                        {seguimiento?.descripcion_seguimiento_cliente}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button size="small">Fecha de creación:  {seguimiento?.created_at}</Button>
                                                    <Button size="small">Tipo de seguimiento: {seguimiento?.tipo_seguimiento_cliente}</Button><br/>
                                                </CardActions>
                                            </Card>  
                                         ))
                                        }                
                                    </TabPanel>
                                    <TabPanel value={value} index={2} dir={theme.direction}>
                                       <DivOpcionesClient>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                {houseSuccess
                                                .sort((a,b) =>  b.activo_casa - a.activo_casa)
                                                .map( (house) => (
                                                    <DivCasas>
                                                        <Card sx={{ display: 'flex', m: 3, width: 1500}} >
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ width: 180, height: 215 }}
                                                                image={ImageSearchImg}
                                                                alt="Imagen de casas"
                                                            />
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '215px' }}>
                                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                                    <Typography 
                                                                        component="div" 
                                                                        variant="h5"
                                                                        style={{
                                                                            width:'450px',
                                                                            whiteSpace: 'nowrap',
                                                                            textOverflow: 'ellipsis',
                                                                            overflow: 'hidden'
                                                                        }}
                                                                    >
                                                                        {house.nombre_casa}
                                                                    </Typography>
                                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                    <strong>Dirección:</strong> {house.calle_casa}
                                                                    </Typography>
                                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                    <strong>Tipo de casa:</strong> {house.tipo_casa}
                                                                    </Typography>
                                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                    <strong>CP:</strong> {house.cp_casa}
                                                                    </Typography>
                                                                    {
                                                                        house.activo_casa ? 
                                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                            <TitleGreen>Casa Activada</TitleGreen>
                                                                        </Typography> 
                                                                        :
                                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                            <TitleRed>Casa Desactivada</TitleRed>
                                                                        </Typography>
                                                                    }
                                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                    <strong>ID:</strong> {house.id_casa}
                                                                    </Typography>
                                                            
                                                                </CardContent>
                                                            </Box>
                                                            <ButtonsCasa >
                                                                <Button 
                                                                    variant     ="contained" 
                                                                    size        ="small" 
                                                                    onClick     ={() =>goOption(house.id_casa)}
                                                                >
                                                                    Ver más
                                                                </Button>

                                                                <Button 
                                                                    variant     ="contained" 
                                                                    size        ="small" 
                                                                    color       ="success" 
                                                                    style       ={{marginLeft:10}}
                                                                    disabled    ={house.activo_casa ? false : true}
                                                                    onClick={ () => clickWhatsapp(house.id_casa)}
                                                                >
                                                                Compartir
                                                                </Button> 
                                                            </ButtonsCasa>
                                                        </Card>
                                                    </DivCasas>
                                                
                                                ))
                                                }  
                                            </div>
                                       </DivOpcionesClient>
                                    </TabPanel>


                                    <TabPanel value={value} index={3} dir={theme.direction}>
                                       <DivOpcionesClient>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                {
                                                    optionsClientSuccess ? 
                                                    optionsClientSuccess.map( (option) => (
                                                            <DivCasas>
                                                                <Card sx={{ 
                                                                        m: 3, 
                                                                        width: 1500,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        height: '150px',
                                                                    }} >
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ 
                                                                            width: 120, 
                                                                            height: 65,
                                                                        }}
                                                                        image={ImageOptions}
                                                                        alt="imagen de opciones"
                                                                        style={{ 
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            margin: '10px',
                                                                            marginLeft: '20px'
                                                                        }}
                                                                    />
                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                                                            <Typography 
                                                                                component="div" 
                                                                                variant="h5"
                                                                                style={{
                                                                                    width:'450px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    textOverflow: 'ellipsis',
                                                                                    overflow: 'hidden'
                                                                                }}
                                                                            >
                                                                                {option.tipo_opcion}
                                                                            </Typography>
                                                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                            <strong>Descripción:</strong> {option.descripcion_opcion_casa}
                                                                            </Typography>
                                                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                            <strong>Tipo de opción:</strong> {option.tipo_opcion}
                                                                            </Typography>
                                                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                            <strong>Fecha de creación:</strong> 
                                                                            <Moment format="YYYY-MM-DD HH:mm">
                                                                                {option.created_at}
                                                                            </Moment>
                                                                            </Typography>
                                                    
                                                                        </CardContent>
                                                                    </Box>
                                                                    <ButtonsCasa >
                                                                        <Button 
                                                                            variant     ="contained" 
                                                                            size        ="small" 
                                                                            onClick     ={() =>goOption(option.id_casa)}
                                                                            startIcon   ={<HomeIcon />}
                                                                        >
                                                                            Ver casa
                                                                        </Button>
        
                                                                        {/* <Button 
                                                                            variant     ="contained" 
                                                                            size        ="small" 
                                                                            color       ="success" 
                                                                            style       ={{marginLeft:10}}
                                                                            disabled    ={house.activo_casa ? false : true}
                                                                            onClick={ () => clickWhatsapp(house.id_casa)}
                                                                        >
                                                                        Compartir
                                                                        </Button>  */}
                                                                    </ButtonsCasa>
                                                                </Card>
                                                            </DivCasas>
                                                        
                                                        ))
                                                    
                                                    : 
                                                    null
                                                }
                                               
                                            </div>
                                       </DivOpcionesClient>
                                    </TabPanel>
                                    <TabPanel style={{height: 600, overflowY: 'auto', overflowX: 'hidden'}} value={value} index={4} dir={theme.direction}>
                                            
                                        {   scheduleSuccess
                                            .filter(schedule => parseInt(schedule.id_cliente) === parseInt(id_cliente) )
                                            .map((agenda) => (
                                            <Card 
                                                sx={{ maxWidth: 1000, marginBottom: 5}}
                                            >
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Casa: {agenda?.nombre_casa}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <strong>Día de la agenda: </strong><br/>
                                                        {agenda?.dia_agenda_casa}
                                                    </Typography><br/>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <strong>Hora de la agenda: </strong><br/>
                                                        {agenda?.horario_agenda_casa}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button size="small">Fecha de creación:  {agenda?.created_at}</Button>
                                                    <Button size="small" onClick={() =>goOption(agenda?.id_casa)} >Id casa: {agenda?.id_casa}</Button><br/>
                                                </CardActions>
                                            </Card>  
                                         ))
                                        }                
                                    </TabPanel>
                                </SwipeableViews>

                                    
                            </Box>
                                                  
                    </DateClient>

                    <DetailClient>
                        <ClientP>Status: {nombre_status}</ClientP>
                        <ClientP>Télefono Celular: {telefono_cliente}</ClientP>
                        <ClientP>Email: {email_cliente}</ClientP>
                        <ClientP>Tipo Crédito: {tipo_credito}</ClientP>
                        <ClientP>Asesor: {especialista}</ClientP>
                        <ClientP>Ref: {referencia_cliente}</ClientP>
                    </DetailClient>
                    
                    <TitleHouse>
                      Agendas
                    </TitleHouse>
                    <DetailHouse>
                        <img
                            src={ScheduleImg}
                            alt='Agendar Casa'
                            style={{
                                width:'128px',
                                height:'128px',
                                display:'flex', 
                                flexDirection: 'column'
                            }}
                            
                        />
                        <Button
                            variant     ="contained" 
                            size        ="small"
                            style={{
                                marginTop:'10px'
                            }}
                            onClick={ () =>  handleClickOpenModalSchedule() }
                        >Agendar Visita</Button>
                      
                    </DetailHouse>
                </DivClientBox>
                </>
            }
            </DivCenter>
        </>
     );
}
 
export default EditClientDesktop;