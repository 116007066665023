import React                        from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch }  from 'react-redux';
import { 
    getAllClientsAction
 }      from '../../actions/clientActions';
import {
    ImageUserSuccess,
    TitleUserSuccess,
    TitleUserGreen,
    DivUserSuccess,
    ButtonUserSuccess
}                                   from '../Usuarios/Users.styles';
import ImageUser                    from '../../Images/update_user_success.png';


const UpdateClientSuccess = () => {

    const dispatch          = useDispatch();
    const navigate          = useNavigate();
    let id_rol_token        =  JSON.parse(localStorage.getItem('user'));


    function goClients() {
       
        if(id_rol_token.rol === 2) navigate('/clientes/especialista');
        if(id_rol_token.rol === 6) navigate('/clientes/lider');

        if(id_rol_token.rol === 1){
            const loadingClients    = () => dispatch( getAllClientsAction() );
            loadingClients();
            navigate('/clientes');
        }

        if(id_rol_token.rol === 5){
            const loadingClients    = () => dispatch( getAllClientsAction() );
            loadingClients();
            navigate('/dashboard/recepcion');
        }

    }

    return ( 
        <>
            <DivUserSuccess>
                <ImageUserSuccess  src={ImageUser} alt="logo" />
                <TitleUserSuccess>Se ha 
                    <TitleUserGreen>
                        actualizado
                    </TitleUserGreen> 
                    el 
                    <TitleUserGreen>
                        cliente
                    </TitleUserGreen> 
                    exitosamente.
                </TitleUserSuccess> 
                <ButtonUserSuccess
                    onClick={goClients}
                >
                    ir al Inicio
                </ButtonUserSuccess>
            </DivUserSuccess>
        </>
     );
}
 
export default UpdateClientSuccess;