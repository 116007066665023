import React from 'react';
import { Roller } from 'react-spinners-css';
import { Progress, TitleProgress } from './ProgressBar.styles';

const ProgressBar = ( props ) => {
    return ( 
        <Progress>
            <Roller color="#004274" />
            <TitleProgress>{props.title}</TitleProgress>
        </Progress>
     );
}
 
export default ProgressBar;