import React from 'react';

const EditFilter = () => {
    return ( 
        <>
            <h1>Edit Filter</h1>
        </>
     );
}
 
export default EditFilter;