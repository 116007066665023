import React,{ useEffect }          from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar                  from '../Progress/ProgressBar.component';

// TABLE
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsDesarrolladores }   from '../../utils/rowData';
import Button                       from '@mui/material/Button';
import AddCircleIcon                from '@mui/icons-material/AddCircle';

import{ 
        createDesarrolladorInitFn, 
        getAllDesarrolladorAction 
    }                               from '../../actions/desarrolladorActions';

import{    
        DivGrid, 
        TitleAndButton, 
        DivButton, 
        TitleGrid 
    }                               from '../Dashboards/styles/Inventario.styles';




const Desarrolladores = () => {

    const dispatch                      = useDispatch();
    const addCreateInit                 = (desarrolladores) => dispatch( createDesarrolladorInitFn(desarrolladores) );
    const navigate                      = useNavigate();
    const desarrolladoresSuccess        = useSelector( state => state.desarrollador.desarrolladores || {} );
    const loading                       = useSelector( state => state.desarrollador?.loading);

    // USE EFFECT LOADER
    useEffect( () => {
        const loadingDesarrolladores = () => dispatch( getAllDesarrolladorAction() );
        loadingDesarrolladores();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    // Onclick Desarrolladores Init action
    function newDesarrolladores() {
        addCreateInit(false);
        navigate('/desarrolladores/crear');
    }

    return ( 
        <>
        {
            loading ? 
                <ProgressBar title="Cargando Desarrolladores..."/>
            :
                <>
                        <TitleAndButton>
                            <TitleGrid>Desarrolladores</TitleGrid>

                            <DivButton>
                                <Button
                                    variant="contained"
                                    onClick={newDesarrolladores}
                                    startIcon={<AddCircleIcon />}
                                    size="large"
                                >
                                    Crear Desarrollador
                                </Button>
                            </DivButton>
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={desarrolladoresSuccess}
                                columns             ={ColumnsDesarrolladores}
                                pageSize            ={10}
                                rowsPerPageOptions  ={[10]}
                                getRowId            ={desarrolladoresSuccess => desarrolladoresSuccess.id_desarrollador}
                            />
                        </DivGrid>
                </>
        }

        </>
     );
}
 
export default Desarrolladores;