import React,{ useEffect }          from 'react';
import ProgressBar                  from '../Progress/ProgressBar.component';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsStatus }             from '../../utils/rowData';
import { getAllStatusAction }        from '../../actions/statusActions';
import{    
        DivGrid, 
        TitleAndButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';

const Status = () => {

    const dispatch          = useDispatch();
    const statusSuccess     = useSelector( state   => state.status?.status || {});
    const loading           = useSelector( state    => state.status?.loading);
    
    useEffect( () => {
        const loadingStatus = () => dispatch( getAllStatusAction() );
        loadingStatus();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    return ( 
        <>
            {
                loading ? 
                    <ProgressBar title="Cargando Status..."/>
            :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Status</TitleUserGrid>
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={statusSuccess}
                                columns             ={ColumnsStatus}
                                pageSize            ={10}
                                rowsPerPageOptions  ={[10]}
                                getRowId            ={statusSuccess => statusSuccess.id_status}
                            />
                        </DivGrid>
                </>
            }
        </>
     );
}
 
export default Status;