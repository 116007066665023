import React                    from 'react';
import EditClientResponsive     from './EditClientResponsive.component';
import EditClientDesktop        from './EditClientDesktop.component';

const EditClientSales = () => {

    const width = window.screen.width;

    return width <= 768 ?  <EditClientResponsive/> :  <EditClientDesktop/> ;

}
 
export default EditClientSales;