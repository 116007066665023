import React from 'react';

const EditStatus = () => {
    return ( 
        <>
            <h1>Edit Status</h1>
        </>
     );
}
 
export default EditStatus;