import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

  
const FormText = () => {

    return ( 
        <>
       <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 3, width: '300px' },
            }}
            noValidate
            autoComplete="off"
        >
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      <TextField id="filled-basic" label="Filled" variant="filled" />
      <TextField id="standard-basic" label="Standard" variant="standard" />
    </Box>


        </>
     );
}
 
export default FormText;