import styled from 'styled-components';


export const ImageSearch = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 128px;
    margin: 0 auto;
`


export const DivSearch = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    margin-top:15%;
    justify-content: center;
    align-items: center;
`

export const TitleSearch = styled.h1`
    display: flex;
    justify-content:center;
    align-items: center;
    font-size: 36px;
    color: #000000;
    margin-top: 0px;
`
export const TitleCreateGreen = styled.a`
    display: flex;
    justify-content:center;
    align-items: center;
    font-size: 36px;
    color: #02B74A;
    text-decoration: underline;
    cursor: pointer;
`