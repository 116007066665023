
import React, { useEffect } from 'react';
import mapboxgl from "mapbox-gl";
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {
    WrapperMap
} from './mapa.styles';

export const BaseMap = () => {

    mapboxgl.accessToken = 'pk.eyJ1Ijoic2F1bDE0MjAiLCJhIjoiY2wybmMwc2M1MHY5dTNvczNhdTVpdmdxcyJ9.YVLeIdAMsR-MHWJcUIxcfQ';

    useEffect(() => {
        
        let map = new mapboxgl.Map({
            container: "mapContainer",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [-99.12766, 19.42847],
            zoom: 10,
        });
        
        const geocoder = new MapboxGeocoder({
            accessToken: 'pk.eyJ1Ijoic2F1bDE0MjAiLCJhIjoiY2wybmMwc2M1MHY5dTNvczNhdTVpdmdxcyJ9.YVLeIdAMsR-MHWJcUIxcfQ', // Set the access token
            mapboxgl: mapboxgl, // Set the mapbox-gl instance
            marker: false // Do not use the default marker style
        });
          
        // Add the geocoder to the map
        map.addControl(geocoder);

        const nav = new mapboxgl.NavigationControl();
        map.addControl(nav, "top-right");

        const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        });

        map.addControl(geolocate, "top-right")
          
    
        
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/3" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([null, null]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/12" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([null, null]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/13" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([null, null]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/14" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([null, null]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/15" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([null, null]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/16" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/17" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/18" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/19" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/20" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/28" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/29" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/30" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/31" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/32" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/33" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/34" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/35" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/36" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/37" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/38" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/39" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/40" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/41" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/42" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/43" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/44" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/45" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/46" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/47" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/48"target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/49" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2909795, 19.3887028]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/50" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.13295619816144, 19.432343165444294]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/51" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.13295619816144, 19.432343165444294]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/52" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.13295619816144, 19.432343165444294]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/53" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.27795727365373, 19.413230894333847]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/54" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2698838, 19.3619413]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/55" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.28805, 19.430297]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/232" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.29242, 19.404702]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/233" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.18714, 19.433639]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/234" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.21344, 19.424937]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/235" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.26331, 19.392937]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/236" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.21344, 19.424937]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/237" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.17985, 19.281075]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/238" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.22175, 19.283129]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/239" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.22175, 19.283129]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/240" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.22175, 19.283129]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/241" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2266, 19.305708]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/242" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.18021, 19.35592]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/243" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.30189, 19.36054]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/244" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.21016, 19.436795]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/245" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.21898, 19.339229]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/246" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.15969, 19.433101]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/247" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.23254, 19.420338]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/248" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.10242, 19.401427]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/249" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.17293, 19.367521]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/250" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.21409, 19.459311]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/251" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.28826, 19.391661]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/252" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.21413, 19.447739]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/253" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.1689, 19.667162]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/254" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.26331, 19.392937]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/255" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.26331, 19.392937]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/256" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.25995, 19.392712]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/257" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.25995, 19.392712]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/258" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.1872, 19.373141]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/259" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.15253, 19.510533]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/260" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.25199, 19.395716]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/261" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.23699, 19.419319]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/262" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.28878, 19.368993]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/263" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.16771, 19.379715]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/264" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2736, 19.3611]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/265" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.28826, 19.391661]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/266" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.21344, 19.424937]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/267" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.21344, 19.424937]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/268" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.71491, 19.264861]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/269" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.28583, 19.352393]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/270" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.18298, 19.402915]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/271" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.28583, 19.352393]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/272" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.17502, 19.304265]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/273" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.13731, 19.430268]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/274" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.29242, 19.404702]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/275" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.19198, 19.300096]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/276" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.26622, 19.361988]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/277" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.16255, 19.419526]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/278" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.18783, 19.409298]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/279" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.13385, 19.283874]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/280" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.28162, 19.396958]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/281" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.16255, 19.419526]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/282" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.18411, 19.358131]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/283" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.17122, 19.305561]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/284" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.18368, 19.372684]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/285" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.15431, 19.375445]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/286" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2736, 19.3611]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/287" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.24807, 19.401079]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/288" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2736, 19.3611]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/289" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2736, 19.3611]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/290" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.2736, 19.3611]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/291" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.1956, 19.43402]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/292" target="_blank">ver casa</a>`)).addTo(map)
        new mapboxgl.Marker().setLngLat([-99.26962, 19.377213]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<a href="/opciones/293" target="_blank">ver casa</a>`)).addTo(map)
        
        map.on('click', (e) => {
            console.log(e)
        });


    }, []);

    return <WrapperMap id="mapContainer"  className="map" />;
};