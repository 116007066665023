import React,{ useEffect }  from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar                  from '../Progress/ProgressBar.component';

// TABLE
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsUser }              from '../../utils/rowData';
import Button                       from '@mui/material/Button';
import AddCircleIcon                from '@mui/icons-material/AddCircle';

import{ 
        createUserInitFn, 
        getAllUsersAction 
    }                               from '../../actions/userActions';

import{    
        DivGrid, 
        TitleAndButton, 
        DivButton, 
        TitleUserGrid 
    }                           from './Users.styles';




const Users = () => {

    const dispatch          = useDispatch();
    const addCreateInit     = (user) => dispatch( createUserInitFn(user) );
    const navigate          = useNavigate();
    const userSuccess       = useSelector( state => state.usuarios.usuarios);
    const loading           = useSelector( state => state.usuarios?.loading);

    // USE EFFECT LOADER
    useEffect( () => {
        const loadingUsers = () => dispatch( getAllUsersAction() );
        loadingUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    // Onclick User Init action
    function newUser() {
        addCreateInit(false);
        navigate('/usuarios/crear');
    }

    return ( 
        <>
        {
            loading ? 
                <ProgressBar title="Cargando Usuarios..."/>
            :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Usuarios</TitleUserGrid>

                            <DivButton>
                                <Button
                                    variant="contained"
                                    onClick={newUser}
                                    startIcon={<AddCircleIcon />}
                                    size="large"
                                >
                                    Crear Usuario
                                </Button>
                            </DivButton>
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={userSuccess}
                                columns             ={ColumnsUser}
                                pageSize            ={12}
                                rowsPerPageOptions  ={[12]}
                                getRowId            ={userSuccess => userSuccess.id_usuario}
                            />
                        </DivGrid>
                </>
        }

        </>
     );
}
 
export default Users;