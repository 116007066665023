

export const tipoCreditoJson = [
    {
        name: "Infonavit",
        value: 1,
    }, 
    {
        name: "Bancario",
        value: 2,
    },
    {
        name: "Fovissste",
        value: 3,
    },
    {
        name: "Otro",
        value: 4,
    }
];

export const tipoClienteJson = [
    {
    name: "Compra",
    value: 1,
    }, 
    {
        name: "Venta",
        value: 2,
    },
    {
        name: "Renta",
        value: 3,
    }
];


export const estadoCivilJson = [
    {
        name: "Soltero",
        value: 1,
    }, 
    {
        name: "Casado",
        value: 2,
    },
    {
        name: "Divorciado",
        value: 3,
    },
    {
        name: "Otro",
        value: 4,
    }
];

export const sexoJson = [
    {
        name: "Hombre",
        value: 1,
    }, 
    {
        name: "Mujer",
        value: 2,
    }
];



