import React, { useState }  from 'react';
import TextField            from '@mui/material/TextField';
import FormControl          from '@mui/material/FormControl';
import Stack                from '@mui/material/Stack';
import Box                  from '@mui/material/Box';
import Button               from '@mui/material/Button';
import SaveIcon             from '@mui/icons-material/Save';
import { useParams }        from 'react-router-dom';
import { useDispatch }      from 'react-redux';
import { SweetAlertBasic }  from '../../../utils/sweetAlert';
import{  
    getClientAction,
    updateClientAction
}                           from '../../../actions/clientActions';
import {
    DivButtonsClientSales
}                           from '../../Usuarios/Users.styles';

import {
    RowClient
}                           from '../Clients.styles';
import {
    tipoCreditoJson,
    estadoCivilJson,
    sexoJson
}                           from '../../../utils/main.js';




const EditClient = ( props ) => {

    const { 
        nombre_cliente , 
        apellido_paterno_cliente,
        apellido_materno_cliente,
        telefono_cliente,
        email_cliente,
        tipo_credito,
        curp_cliente,
        sexo_cliente,
        estado_civil_cliente
    }    = props.data;
    
    const { id_cliente }        = useParams();
    const dispatch              = useDispatch();
    const [client, setClient]   = useState(props.data);
    const loadingClient         = () => dispatch( getClientAction(id_cliente) );
    const updateClientInit      = (client)      => dispatch( updateClientAction( client, id_cliente ) );
    const name_credit_text      = Object.values(tipoCreditoJson.filter(tipo => tipo.name        === client.tipo_credito));
    const name_sex_text         = Object.values(sexoJson.filter(tipo        => tipo.value        === parseInt(client.sexo_cliente)));
    const name_state_civil_text = Object.values(estadoCivilJson.filter(tipo => tipo.value        === parseInt(client.estado_civil_cliente)));


    const onChangeForm = ( event ) => {
        if(event.target.name === 'estado_civil_cliente' || event.target.name === 'tipo_credito' || event.target.name === 'sexo_cliente'){
            setClient({
                ...client,
                [event.target.name]: parseInt(event.target.value)
            })
        }else{
            setClient({
                ...client,
                [event.target.name]: event.target.value
            })
        }
    }

    const updateClient = e => {

        e.preventDefault();

        //Se eliminan para no enviarlo en el updated
        const {created_at , updated_at, especialista, nombre_calendario,nombre_filtro, nombre_registro, nombre_status,nombre_cliente, ...rest_data} = client;
        
        // Quita los valores vacios ""
        let dataClient = Object.entries(rest_data).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
    
        if(typeof(dataClient.tipo_credito) === "string") dataClient.tipo_credito                    =  name_credit_text[0].value;
        if(typeof(dataClient.sexo_cliente) === "string") dataClient.sexo_cliente                    =  name_sex_text[0].value;
        if(typeof(dataClient.estado_civil_cliente) === "string") dataClient.estado_civil_cliente    =  name_state_civil_text[0].value;

        if( nombre_cliente.trim() === '' || rest_data.telefono_cliente.length === 0 || rest_data.email_cliente.trim() === ''){
            SweetAlertBasic("error","Ups","Unos campos son obligatorios(nombre,telefono,email)");
            return;
        }
        console.log(dataClient);
        updateClientInit(dataClient, id_cliente);
        setTimeout(function(){
            loadingClient();
        }, 500);
    }
    

    return (
        <>
             <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={updateClient}
            >
            <RowClient style={{height: 500, overflowY: 'auto', overflowX: 'hidden'}}  >
               
                    <TextField  
                        id              ="outlined-basic" 
                        variant         ="outlined"
                        name            ="nombre_cliente"
                        label           ="Ingresa el nombre del cliente"
                        defaultValue    ={nombre_cliente}
                        onChange        ={ onChangeForm }
                        fullWidth
                        disabled
                    />

                    <TextField  
                        id              ="outlined-basic" 
                        label           ="Ingresa el apellido paterno del cliente" 
                        variant         ="outlined"
                        name            ="apellido_paterno_cliente"
                        defaultValue    ={apellido_paterno_cliente}
                        onChange        ={ onChangeForm }
                        fullWidth
                    />

                    <TextField  
                        id              ="outlined-basic" 
                        label           ="Ingresa el apellido materno del cliente" 
                        variant         ="outlined"
                        name            ="apellido_materno_cliente"
                        defaultValue    ={apellido_materno_cliente}
                        onChange        ={ onChangeForm }
                        fullWidth
                    />

                    <TextField  
                        id              ="outlined-basic" 
                        label           ="Ingresa el telefono del cliente" 
                        variant         ="outlined"
                        name            ="telefono_cliente"
                        defaultValue    ={telefono_cliente }
                        type            ="number"
                        onInput         = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                        }}
                        onChange        ={ onChangeForm }
                        fullWidth
                    />

                    <TextField  
                        id              ="outlined-basic" 
                        label           ="Ingresa el email del cliente" 
                        variant         ="outlined"
                        name            ="email_cliente"
                        defaultValue    ={ email_cliente }
                        onChange        ={ onChangeForm }
                        fullWidth
                    />

                    <TextField  
                        id              ="outlined-basic" 
                        label           ="Ingresa el curp del cliente" 
                        variant         ="outlined"
                        name            ="curp_cliente"
                        defaultValue    ={ curp_cliente }
                        onChange        ={ onChangeForm }
                        fullWidth
                    />

                    <FormControl fullWidth>
                        <label className="text_select">Tipo de Crédito</label>   
                        <select 
                            label       ="tipo_credito"
                            name        ="tipo_credito"
                            className   ="selectClient"
                            onChange    ={onChangeForm}
                        >
                            <option className="optionInput" selected >
                                {tipo_credito ?
                                    name_credit_text[0]?.name
                                    :'Selecciona el tipo de credito'
                                } 
                            </option>

                            {tipoCreditoJson.map((tipo) => (
                                <option
                                    key     ={tipo.value}
                                    value   ={parseInt(tipo.value)}
                                >
                                    {tipo.name}
                                </option>
                            ))}
                        </select>
                    </FormControl>

                    <FormControl fullWidth>
                        <label className="text_select">Estado Civil</label>   
                        <select 
                            label       ="estado_civil_cliente"
                            name        ="estado_civil_cliente"
                            className   ="selectClient"
                            onChange    ={onChangeForm}
                        >
                            <option className="optionInput" selected >
                                 {estado_civil_cliente ?
                                    name_state_civil_text[0]?.name  
                                    :'Selecciona el estado civil'
                                } 
                            </option>

                            {estadoCivilJson.map((tipo) => (
                                <option
                                    key     ={tipo.value}
                                    value   ={parseInt(tipo.value)}
                                >
                                    {tipo.name}
                                </option>
                            ))}
                        </select>
                    </FormControl>

                    <FormControl fullWidth>
                        <label className="text_select">Sexo</label>   
                        <select 
                            label       ="sexo_cliente"
                            name        ="sexo_cliente"
                            className   ="selectClient"
                            onChange    ={onChangeForm}
                        >
                            <option className="optionInput" selected >
                                {sexo_cliente ?
                                    name_sex_text[0]?.name
                                    :'Selecciona el sexo'
                                } 
                            </option>

                            {sexoJson.map((tipo) => (
                                <option
                                    key     ={tipo.value}
                                    value   ={parseInt(tipo.value)}
                                >
                                    {tipo.name}
                                </option>
                            ))}
                        </select>
                    </FormControl>
    
            </RowClient>
            <DivButtonsClientSales >
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant     ="contained" 
                        size        ="small" 
                        type        ="submit"
                        startIcon   ={<SaveIcon />}
                        disabled    = {false}
                    >
                        Actualizar Cliente
                    </Button>
                </Stack>
            </DivButtonsClientSales>
            </Box>
        </>
      );
}
 
export default EditClient;