import React,{ useEffect }          from 'react';
import ProgressBar                  from '../Progress/ProgressBar.component';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsRegisters }         from '../../utils/rowData';
import{ 
        getAllRegistersAction 
    }                               from '../../actions/registerActions';
import{    
        DivGrid, 
        TitleAndButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';
const Registers = () => {

    const dispatch          = useDispatch();
    const registerSuccess  = useSelector( state => state.registros?.registros);
    const loading           = useSelector( state => state.registros?.loading);

    // USE EFFECT LOADER
    useEffect( () => {
        const loadingRegisters = () => dispatch( getAllRegistersAction() );
        loadingRegisters();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    

    return ( 
        <>
            {
                loading ? 
                    <ProgressBar title="Cargando Registros..."/>
                :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Registros</TitleUserGrid>

                            {/* <DivButton>
                                <Button
                                    variant="contained"
                                    onClick={newRegister}
                                    startIcon={<AddCircleIcon />}
                                    size="large"
                                >
                                    Crear Registro
                                </Button>
                            </DivButton> */}
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={registerSuccess}
                                columns             ={ColumnsRegisters}
                                pageSize            ={12}
                                rowsPerPageOptions  ={[12]}
                                getRowId            ={registerSuccess => registerSuccess.id_registro}
                            />
                        </DivGrid>
                </>
            }
        </>
     );
}
 
export default Registers;