
import React,{ useEffect }                      from 'react';
import { Link }                                 from 'react-router-dom';
import Logo                                     from '../../Images/logo.png';
import  { useDispatch, useSelector }            from 'react-redux';
import  { getLoginUserFn,logoutUserFn }         from '../../actions/loginActions';
import Avatar                                   from '@mui/material/Avatar';
import Menu                                     from '@mui/material/Menu';
import MenuItem                                 from '@mui/material/MenuItem';
import Box                                      from '@mui/material/Box';
import Tooltip                                  from '@mui/material/Tooltip';
import IconButton                               from '@mui/material/IconButton';
import {
    DivHeader,
    Image,
    DivLink,
    TitleHeader,
    DivAvatar
}                                               from './Header.styles';

const Header = () => {

    const dispatch          = useDispatch();

    // USE EFFECT LOGIN
    useEffect( () => {
        const loginUser = () => dispatch( getLoginUserFn() );
        loginUser();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    const   user_local              = JSON.parse(localStorage.getItem('user'));
    const   loginSuccess            = useSelector( state => state.login.user || user_local );
    const   logoutUser              = () => dispatch( logoutUserFn() );

    function stringAvatar(name) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

 
    // MENU 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
 
    const logout = () => {
        setTimeout(() => {
            logoutUser();
            window.location.reload();
        }, 200);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    

    return ( 
        <>
            {
                loginSuccess?.id != null ?
                    null
                :
                <DivHeader>
                    <Link to={'/'}>
                        <Image src={Logo} alt="logo" />
                    </Link> 
                </DivHeader>
            }
        
            <>
                {
                    [1].includes(loginSuccess?.rol) ?  
                    <DivHeader>
                        <Link to={'/dashboard'}>
                            <Image src={Logo} alt="logo" />
                        </Link> 
                            <DivLink>
                                <Link to={'/mapa'}>
                                    <TitleHeader>Mapa</TitleHeader> 
                                </Link>
                                <Link to={'/usuarios'}>
                                    <TitleHeader>Usuarios</TitleHeader> 
                                </Link>
                                <Link to={'/clientes'}>
                                    <TitleHeader>Clientes</TitleHeader> 
                                </Link>
                                <Link to={'/calendarios'}>
                                    <TitleHeader>Calendarios</TitleHeader> 
                                </Link>
                                <Link to={'/equipos'}>
                                    <TitleHeader>Equipos</TitleHeader> 
                                </Link>
                                <Link to={'/registros'}>
                                    <TitleHeader>Registros</TitleHeader> 
                                </Link>
                                <Link to={'/filtros'}>
                                    <TitleHeader>Filtros</TitleHeader> 
                                </Link>
                                <Link to={'/roles'}>
                                    <TitleHeader>Roles</TitleHeader> 
                                </Link>
                                <Link to={'/status'}>
                                    <TitleHeader>Status</TitleHeader> 
                                </Link>
                                <Link to={'/casas'}>
                                    <TitleHeader>Casas</TitleHeader> 
                                </Link>
                                <Link to={'/opciones/casas'}>
                                    <TitleHeader>Opciones</TitleHeader> 
                                </Link>
                                <Link to={'/desarrollos'}>
                                    <TitleHeader>Desarrollos</TitleHeader> 
                                </Link>
                                <Link to={'/desarrolladores'}>
                                    <TitleHeader>Desarrolladores</TitleHeader> 
                                </Link>
                                <DivAvatar >
                                    <Box >
                                        <Tooltip title="Cuenta">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <Avatar {...stringAvatar(loginSuccess?.nombre)}></Avatar>
                                        </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Menu
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                        }}
                                    >
                                        <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
                                    </Menu>
                                </DivAvatar>
                            </DivLink>
                    </DivHeader>
                    :
                    null
                }
                {
                    [2].includes(loginSuccess?.rol) ?   
                    <DivHeader>
                        <Link to={'/dashboard/especialista'}>
                            <Image src={Logo} alt="logo" />
                        </Link> 
                        <DivLink>
                            {/* <Link to={'/clientes/agenda'}>
                                <TitleHeader>Agendas</TitleHeader> 
                            </Link> */}
                            <Link to={'/mapa'}>
                                    <TitleHeader>Mapa</TitleHeader> 
                            </Link>
                            <Link to={'/clientes/especialista'}>
                                <TitleHeader>Clientes</TitleHeader> 
                            </Link>
                            <Link to={'/opciones/casas'}>
                                <TitleHeader>Opciones</TitleHeader> 
                            </Link>
                            <DivAvatar >
                                <Box >
                                    <Tooltip title="Cuenta">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar {...stringAvatar(loginSuccess?.nombre)}></Avatar>
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
                                </Menu>
                            </DivAvatar>
                        </DivLink>
                    </DivHeader>
                    :
                    null
                }
                
                {
                    [3].includes(loginSuccess?.rol) ?   
                    <DivHeader>
                        <Link to={'/dashboard/inventario'}>
                            <Image src={Logo} alt="logo" />
                        </Link> 
                        <DivLink>
                            <Link to={'/mapa'}>
                                <TitleHeader>Mapa</TitleHeader> 
                            </Link>
                            <Link to={'/dashboard/inventario'}>
                                <TitleHeader>Casas</TitleHeader> 
                            </Link>
                            <Link to={'/desarrollos'}>
                                <TitleHeader>Desarrollos</TitleHeader> 
                            </Link>
                            <Link to={'/desarrolladores'}>
                                <TitleHeader>Desarrolladores</TitleHeader> 
                            </Link>
                            <Link to={'/opciones/casas'}>
                                <TitleHeader>Opciones</TitleHeader> 
                            </Link>
                            <DivAvatar >
                                <Box >
                                    <Tooltip title="Cuenta">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar {...stringAvatar(loginSuccess?.nombre)}></Avatar>
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
                                </Menu>
                            </DivAvatar>
                        </DivLink>
                    </DivHeader>
                    :
                    null
                }
                {
                    [4].includes(loginSuccess?.rol) ?   
                    <DivHeader>
                        <Link to={'/dashboard/rh'}>
                            <Image src={Logo} alt="logo" />
                        </Link> 
                        <DivLink>
                            <Link to={'/mapa'}>
                                <TitleHeader>Mapa</TitleHeader> 
                            </Link>
                            <Link to={'/usuarios'}>
                            <TitleHeader>Usuarios</TitleHeader> 
                            </Link>
                            <Link to={'/equipos'}>
                                <TitleHeader>Equipos</TitleHeader> 
                            </Link>
                            <DivAvatar >
                                <Box >
                                    <Tooltip title="Cuenta">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar {...stringAvatar(loginSuccess?.nombre)}></Avatar>
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
                                </Menu>
                            </DivAvatar>
                        </DivLink>
                    </DivHeader>
                    :
                    null
                }
                {
                    [5].includes(loginSuccess?.rol) ?   
                    <DivHeader>
                        <Link to={'/dashboard/recepcion'}>
                            <Image src={Logo} alt="logo" />
                        </Link> 
                        <DivLink>
                            <Link to={'/mapa'}>
                                <TitleHeader>Mapa</TitleHeader> 
                            </Link>
                            <Link to={'/clientes'}>
                                <TitleHeader>Clientes</TitleHeader> 
                            </Link>
                            <Link to={'/opciones/casas'}>
                                <TitleHeader>Opciones</TitleHeader> 
                            </Link>
                            <DivAvatar >
                                <Box >
                                    <Tooltip title="Cuenta">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar {...stringAvatar(loginSuccess?.nombre)}></Avatar>
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
                                </Menu>
                            </DivAvatar>
                        </DivLink>
                    </DivHeader>
                    :
                    null
                }  
                {
                    [6].includes(loginSuccess?.rol) ?   
                    <DivHeader>
                        <Link to={'/dashboard/lider'}>
                            <Image src={Logo} alt="logo" />
                        </Link> 
                        <DivLink>
                            {/* <Link to={'/lider/agenda'}>
                                <TitleHeader>Agendas</TitleHeader> 
                            </Link> */}
                            <Link to={'/mapa'}>
                                <TitleHeader>Mapa</TitleHeader> 
                            </Link>
                            <Link to={'/lider/metas'}>
                                <TitleHeader>Metas</TitleHeader> 
                            </Link>
                            <Link to={'/clientes/lider'}>
                                <TitleHeader>Clientes</TitleHeader> 
                            </Link>
                            <Link to={'/opciones/casas'}>
                                <TitleHeader>Opciones</TitleHeader> 
                            </Link>
                            {/* <Link to={'/lider/precontrato'}>
                                <TitleHeader>Precontratos</TitleHeader> 
                            </Link> */}
                            <DivAvatar >
                                <Box >
                                    <Tooltip title="Cuenta">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar {...stringAvatar(loginSuccess?.nombre)}></Avatar>
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
                                </Menu>
                            </DivAvatar>
                        </DivLink>
                    </DivHeader>
                    :
                    null
                }
                
                {
                    [8].includes(loginSuccess?.rol) ?   
                    <DivHeader>
                        <Link to={'/dashboard/markenting'}>
                            <Image src={Logo} alt="logo" />
                        </Link> 
                        <DivLink>
                            <Link to={'/registros'}>
                                <TitleHeader>Registros</TitleHeader> 
                            </Link>
                            <Link to={'/calendarios'}>
                                <TitleHeader>Calendarios</TitleHeader> 
                            </Link>
                            <Link to={'/casas'}>
                                <TitleHeader>Inventario</TitleHeader> 
                            </Link>
                            <Link to={'/Filtros'}>
                                <TitleHeader>Filtros</TitleHeader> 
                            </Link>
                            <Link to={'/clientes'}>
                                <TitleHeader>Clientes</TitleHeader> 
                            </Link>
                            <DivAvatar >
                                <Box >
                                    <Tooltip title="Cuenta">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar {...stringAvatar(loginSuccess?.nombre)}></Avatar>
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
                                </Menu>
                            </DivAvatar>
                            
                        </DivLink>
                    </DivHeader>
                    :
                    null
                }
            </>
        </>
    );
}
 
export default Header;