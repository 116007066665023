import React,{ useEffect }  from 'react';
// import Button                       from '@mui/material/Button';
// import AddCircleIcon                from '@mui/icons-material/AddCircle';
import ProgressBar                  from '../Progress/ProgressBar.component';
// import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsFilters }         from '../../utils/rowData';
import{ 
        // createFilterInitFn, 
        getAllFiltersAction 
    }                               from '../../actions/filterActions';
import{    
        DivGrid, 
        TitleAndButton, 
        // DivButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';
const Filters = () => {

    const dispatch          = useDispatch();
    // const addCreateInit     = (filter) => dispatch( createFilterInitFn(filter) );
    // const navigate          = useNavigate();
    const filterSuccess  = useSelector( state => state.filtros?.filtros);
    const loading           = useSelector( state => state.filtros?.loading);

    // USE EFFECT LOADER
    useEffect( () => {
        const loadingFilters = () => dispatch( getAllFiltersAction() );
        loadingFilters();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    // Onclick Filter Init action
    // function newFilter() {
    //     addCreateInit(false);
    //     navigate('/filtros/crear');
    // }


    return ( 
        <>
            {
                loading ? 
                    <ProgressBar title="Cargando Filtros..."/>
                :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Filtros</TitleUserGrid>

                            {/* <DivButton>
                                <Button
                                    variant="contained"
                                    onClick={newFilter}
                                    startIcon={<AddCircleIcon />}
                                    size="large"
                                >
                                    Crear Filtro
                                </Button>
                            </DivButton> */}
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={filterSuccess}
                                columns             ={ColumnsFilters}
                                pageSize            ={10}
                                rowsPerPageOptions  ={[10]}
                                getRowId            ={filterSuccess => filterSuccess.id_filtro}
                            />
                        </DivGrid>
                </>
            }
        </>
     );
}
 
export default Filters;