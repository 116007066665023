import React, { useState }          from 'react'
import { createDesarrolloAction }         from '../../actions/desarrolloActions';
import { useDispatch, useSelector } from 'react-redux';
import { SweetAlertBasic }          from '../../utils/sweetAlert';
import { useNavigate, Navigate }    from 'react-router-dom';
import ProgressBar                  from '../Progress/ProgressBar.component';
import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import InputLabel                   from '@mui/material/InputLabel';
import MenuItem                     from '@mui/material/MenuItem';
import Select                       from '@mui/material/Select';
import FormControl                  from '@mui/material/FormControl';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import Stack                        from '@mui/material/Stack';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
} from '../Usuarios/Users.styles';

const NewDesarrollo = () => {

    const [ nombreDesarrollo, setNombreDesarrollo ]               = useState('');
    const [ emailDesarrollo, setEmailDesarrollo ]                 = useState('');
    const [ telefonoDesarrollo, setTelefonoDesarrollo ]           = useState('')    
    const [ cpDesarrollo, setCPDesarrollo ]                       = useState('');
    const [ tipoDesarrollo, setTipoDesarrollo ]                   = useState('');
    const [ prioridadDesarrollo, setPrioridadDesarrollo ]         = useState('');
    const [ extraDesarrollo, setExtraDesarrollo ]                 = useState('');
    const [ descripcionDesarrollo, setDescripcionDesarrollo ]     = useState('');
    const [ estadoDesarrollo, setEstadoDesarrollo ]               =  useState('');
    

    // Dispatch
    const dispatch      = useDispatch();

    // Call Action
    const addCreate     = (house) => dispatch( createDesarrolloAction(house) );

    // Loading
    const loading                   = useSelector( state    => state.desarrollo?.loading);
    const desarrolloSuccess      = useSelector( state    => state.desarrollo?.flag);
  
    // SELECTS
    const handleEstado = ( event ) => {
        setEstadoDesarrollo(event.target.value);
    };

    const handleTipoDesarrollo = ( event ) => {
        setTipoDesarrollo(event.target.value);
    };

    const handlePrioridadDesarrollo = ( event ) => {
        setPrioridadDesarrollo(event.target.value);
    };

    //History
    const navigate = useNavigate();

    function goDesarrolloes() {
        navigate('/desarrolloes');
    }
  
    // Create of User
    const createDesarrollo = e => {
        e.preventDefault();

        //Validate form
        if( nombreDesarrollo.trim() === '' ||  emailDesarrollo.trim() === '' ){
            SweetAlertBasic("error","Ups","Le falta llenar campos");
            return;
        }

        // CreateUser
        addCreate({
            nombre_desarrollo: nombreDesarrollo,
            email_desarrollo: emailDesarrollo,
            telefono_cel_desarrollo: telefonoDesarrollo,
            cp_desarrollo: cpDesarrollo,
            tipo_desarrollo: tipoDesarrollo,
            prioridad_desarrollo: prioridadDesarrollo,
            imagen_desarrollo: "string",
            extra_desarrollo: extraDesarrollo,
            descripcion_desarrollo: descripcionDesarrollo,
            id_estado: estadoDesarrollo,
            id_delegacion: 1,
            id_desarrollador: 1,
            activo_desarrollo: true
        });

    }


    return ( 
        <DivCenter >
            {
                loading ? 
                    <ProgressBar title="Creando Desarrollo..."/>
                :
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)':{},
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={createDesarrollo}
                    
                >
                    <TitleUser>Crear Desarrollo</TitleUser>

                            <RowUser>

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el nombre del desarrollo" 
                                    variant="outlined"
                                    type="text"
                                    name="nombreDesarrollo"
                                    value={nombreDesarrollo}
                                    onChange={ e => setNombreDesarrollo(e.target.value) }
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el email del desarrollo" 
                                    variant="outlined"
                                    type="text"
                                    name="emailDesarrollo"
                                    value={emailDesarrollo}
                                    onChange={ e => setEmailDesarrollo(e.target.value) }
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el telefono del desarrollo" 
                                    variant="outlined"
                                    name="telefonoDesarrollo"
                                    value={telefonoDesarrollo}
                                    type="number"
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    onChange={ e => setTelefonoDesarrollo( Number(e.target.value) ) }
                                    fullWidth
                                />


                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el código postal del desarrollo" 
                                    variant="outlined"
                                    name="cpDesarrollo"
                                    value={cpDesarrollo}
                                    onChange={ e => setCPDesarrollo(e.target.value) }
                                    fullWidth
                                />
                                
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa algo extra que quieras agregar al desarrollo" 
                                    variant="outlined"
                                    name="extraDesarrollo"
                                    value={extraDesarrollo}
                                    onChange={ e => setExtraDesarrollo(e.target.value) }
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa la descripcion del desarrollo" 
                                    variant="outlined"
                                    name="descripcionDesarrollo"
                                    value={descripcionDesarrollo}
                                    onChange={ e => setDescripcionDesarrollo(e.target.value) }
                                    fullWidth
                                />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                    <Select
                                        labelId="estadoDesarrollo"
                                        id="demo-simple-select"
                                        label="estadoDesarrollo"
                                        value={estadoDesarrollo}
                                        onChange={handleEstado}
                                    >
                                        <MenuItem value={1}>Aguascalientes</MenuItem>
                                        <MenuItem value={2}>Baja California</MenuItem>
                                        <MenuItem value={3}>Baja California Sur</MenuItem>
                                        <MenuItem value={4}>Campeche</MenuItem>
                                        <MenuItem value={5}>Coahuila</MenuItem>
                                        <MenuItem value={6}>Colima</MenuItem>
                                        <MenuItem value={7}>Chiapas</MenuItem>
                                        <MenuItem value={8}>Chihuahua</MenuItem>
                                        <MenuItem value={9}>Durango</MenuItem>
                                        <MenuItem value={10}>Ciudad de México</MenuItem>
                                        <MenuItem value={11}>Guanajuato</MenuItem>
                                        <MenuItem value={12}>Guerrero</MenuItem>
                                        <MenuItem value={13}>Hidalgo</MenuItem>
                                        <MenuItem value={14}>Jalisco</MenuItem>
                                        <MenuItem value={15}>Estado de México</MenuItem>
                                        <MenuItem value={16}>Michoacán</MenuItem>
                                        <MenuItem value={17}>Morelos</MenuItem>
                                        <MenuItem value={18}>Nayarit</MenuItem>
                                        <MenuItem value={19}>Nuevo León</MenuItem>
                                        <MenuItem value={20}>Oaxaca</MenuItem>
                                        <MenuItem value={21}>Puebla</MenuItem>
                                        <MenuItem value={22}>Querétaro</MenuItem>
                                        <MenuItem value={23}>Quintana Roo</MenuItem>
                                        <MenuItem value={24}>San Luis Potosí</MenuItem>
                                        <MenuItem value={25}>Sinaloa</MenuItem>
                                        <MenuItem value={26}>Sonora</MenuItem>
                                        <MenuItem value={27}>Tabasco</MenuItem>
                                        <MenuItem value={28}>Tamaulipas</MenuItem>
                                        <MenuItem value={29}>Tlaxcala</MenuItem>
                                        <MenuItem value={30}>Veracruz</MenuItem>
                                        <MenuItem value={31}>Yucatán</MenuItem>
                                        <MenuItem value={32}>Zacatecas</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tipo de Desarrolador</InputLabel>
                                    <Select
                                        labelId="tipoDesarrollo"
                                        id="demo-simple-select"
                                        label="tipoDesarrollo"
                                        value={tipoDesarrollo}
                                        onChange={handleTipoDesarrollo}
                                    >
                                        <MenuItem value="Propio">Propio</MenuItem>
                                        <MenuItem value="Convenio">Convenio</MenuItem>
                                        <MenuItem value="Extra">Extra</MenuItem>
                                        <MenuItem value="Otro">Otro</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Prioridad</InputLabel>
                                    <Select
                                        labelId="prioridadDesarrollo"
                                        id="demo-simple-select"
                                        label="prioridadDesarrollo"
                                        value={prioridadDesarrollo}
                                        onChange={handlePrioridadDesarrollo}
                                    >
                                        <MenuItem value={1}>Alta</MenuItem>
                                        <MenuItem value={2}>Media</MenuItem>
                                        <MenuItem value={3}>Baja</MenuItem>
                                        <MenuItem value={4}>Otro</MenuItem>
                                    </Select>
                                </FormControl>
                            </RowUser>
                           
                    <DivButtons >
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={goDesarrolloes}
                                variant="contained" 
                                size="large"
                                color="error"
                            >
                                Cancelar
                            </Button>
                        
                            <Button 
                                variant="contained" 
                                size="small" 
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Crear Desarrollo
                            </Button>
                        </Stack>
                    </DivButtons>
                </Box>
            }
            {
                desarrolloSuccess ?
                    <Navigate to='/desarrollos/exitoso'/>
                :
                null
            }
            
            
        </DivCenter>
     );
}
 
export default NewDesarrollo;