import React,{ useEffect }  from 'react';
import Button                       from '@mui/material/Button';
import AddCircleIcon                from '@mui/icons-material/AddCircle';
import ProgressBar                  from '../Progress/ProgressBar.component';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsTeams }           from '../../utils/rowData';
import{ 
        createTeamInitFn, 
        getAllTeamsAction 
    }                               from '../../actions/teamActions';
import{    
        DivGrid, 
        TitleAndButton, 
        DivButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';

const Teams = () => {

    const dispatch          = useDispatch();
    const addCreateInit     = (team) => dispatch( createTeamInitFn(team) );
    const navigate          = useNavigate();
    const teamSuccess       = useSelector( state => state.equipos?.equipos);
    const loading           = useSelector( state => state.equipos?.loading);

    // USE EFFECT LOADER
    useEffect( () => {
        const loadingTeams = () => dispatch( getAllTeamsAction() );
        loadingTeams();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    // Onclick Team Init action
    function newTeam() {
        addCreateInit(false);
        navigate('/equipos/crear');
    }


    return ( 
        <>
            {
                loading ? 
                    <ProgressBar title="Cargando Equipos..."/>
                :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Equipos</TitleUserGrid>

                            <DivButton>
                                <Button
                                    variant="contained"
                                    onClick={newTeam}
                                    startIcon={<AddCircleIcon />}
                                    size="large"
                                >
                                    Crear Equipo
                                </Button>
                            </DivButton>
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={teamSuccess}
                                columns             ={ColumnsTeams}
                                pageSize            ={10}
                                rowsPerPageOptions  ={[10]}
                                getRowId            ={teamSuccess => teamSuccess.id_equipo}
                            />
                        </DivGrid>
                </>
            }
        </>
     );
}
 
export default Teams;