import React,{ useEffect }          from 'react';
import ProgressBar                  from '../Progress/ProgressBar.component';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { ColumnsRoles }             from '../../utils/rowData';
import { getAllRolesAction }        from '../../actions/rolAction';
import{    
        DivGrid, 
        TitleAndButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';

const Roles = () => {

    const dispatch          = useDispatch();
    const rolesSuccess      = useSelector( state => state.roles?.roles || {});
    const loading           = useSelector( state => state.roles?.loading);

    // USE EFFECT LOADER ROLES
    useEffect( () => {
        const loadingRoles = () => dispatch( getAllRolesAction() );
        loadingRoles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
       
    return (
        <>
            {
                loading ? 
                    <ProgressBar title="Cargando Roles..."/>
            :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Roles</TitleUserGrid>
                        </TitleAndButton>

                        <DivGrid>
                            <DataGrid
                                rows                ={rolesSuccess}
                                columns             ={ColumnsRoles}
                                pageSize            ={10}
                                rowsPerPageOptions  ={[10]}
                                getRowId            ={rolesSuccess => rolesSuccess.id_rol}
                            />
                        </DivGrid>
                </>
            }
        </>
      );
}
 
export default Roles;