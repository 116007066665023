var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './style.module.css';
export default function Roller(_a) {
    var _b = _a.color, color = _b === void 0 ? '#7f58af' : _b, className = _a.className, style = _a.style;
    var circles = __spreadArray([], Array(8)).map(function (_, index) {
        return (_jsx("div", { children: _jsx("div", { className: classNames(styles['div-after']), style: { background: color } }, void 0) }, index));
    });
    return (_jsx("div", __assign({ className: classNames(styles['lds-roller'], className), style: __assign({}, style) }, { children: circles }), void 0));
}
