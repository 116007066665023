import React from 'react';

const EditCalendary = () => {
    return ( 

        <>
            <h1>Edit Calendary</h1>
        </>
     );
}
 
export default EditCalendary;