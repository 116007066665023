
// LOGIN
export const LOGIN_SUCCESS                  = 'LOGIN_SUCCESS';
export const LOGIN_USER                     = 'LOGIN_USER';
export const LOGIN_ERROR                    = 'LOGIN_ERROR';
export const LOGIN_INIT                     = 'LOGIN_INIT';
export const LOGIN_LOGOUT                   = 'LOGIN_LOGOUT';
export const LOGIN_ACCESS                   = 'LOGIN_ACCESS';
export const LOGIN_DENEGED                  = 'LOGIN_DENEGED';
export const LOGOUT_INIT                    = 'LOGOUT_INIT';
export const LOGOUT_SUCCESS                 = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR                   = 'LOGOUT_ERROR';

//USER
export const USER_CREATE                    = 'USER_CREATE';
export const USER_CREATE_SUCCESS            = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR              = 'USER_CREATE_ERROR';
export const USER_CREATE_INIT               = 'USER_CREATE_INIT';

export const USERS_LOADING                  = 'USERS_LOADING';
export const USERS_LOADING_SUCCESS          = 'USERS_LOADING_SUCCESS';
export const USERS_LOADING_ERROR            = 'USERS_LOADING_ERROR';

export const USER_DESACTIVE                 = 'USER_DESACTIVE';  
export const USER_DESACTIVE_ERROR           = 'USER_DESACTIVE_ERROR';   

export const USER_LOAD                      = 'USER_LOAD';  
export const USER_LOAD_SUCCESS              = 'USER_LOAD_SUCCESS';    
export const USER_LOAD_ERROR                = 'USER_LOAD_ERROR';

export const USER_UPDATE_INIT               = 'USER_UPDATE_INIT';
export const USER_UPDATE_SUCCESS            = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR              = 'USER_UPDATE_ERROR';


export const USER_PASSWORD_INIT               = 'USER_PASSWORD_INIT';
export const USER_PASSWORD_SUCCESS            = 'USER_PASSWORD_SUCCESS';
export const USER_PASSWORD_ERROR              = 'USER_PASSWORD_ERROR';

//CLIENT
export const CLIENT_CREATE                  = 'CLIENT_CREATE';
export const CLIENT_CREATE_SUCCESS          = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_CREATE_ERROR            = 'CLIENT_CREATE_ERROR';
export const CLIENT_CREATE_INIT             = 'CLIENT_CREATE_INIT'; 

export const CLIENTS_LOADING                = 'CLIENTS_LOADING';
export const CLIENTS_LOADING_SUCCESS        = 'CLIENTS_LOADING_SUCCESS';
export const CLIENTS_LOADING_ERROR          = 'CLIENTS_LOADING_ERROR';

export const CLIENTS_LOADING_BY_USER        = 'CLIENTS_LOADING_BY_USER';
export const CLIENTS_BY_USER_LOADING_SUCCESS= 'CLIENTS_BY_USER_LOADING_SUCCESS';
export const CLIENTS_BY_USER_LOADING_ERROR  = 'CLIENTS_BY_USER_LOADING_ERROR';

export const CLIENTS_LOADING_BY_TEAM        = 'CLIENTS_LOADING_BY_TEAM';
export const CLIENTS_BY_TEAM_LOADING_SUCCESS= 'CLIENTS_BY_TEAM_LOADING_SUCCESS';
export const CLIENTS_BY_TEAM_LOADING_ERROR  = 'CLIENTS_BY_TEAM_LOADING_ERROR';

export const CLIENT_LOAD                    = 'CLIENT_LOAD';  
export const CLIENT_LOAD_SUCCESS            = 'CLIENT_LOAD_SUCCESS';    
export const CLIENT_LOAD_ERROR              = 'CLIENT_LOAD_ERROR'; 

export const CLIENT_UPDATE_INIT             = 'CLIENT_UPDATE_INIT';
export const CLIENT_UPDATE_SUCCESS          = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_ERROR            = 'CLIENT_UPDATE_ERROR';

// ROLES
export const ROLES_LOADING                  = 'ROLES_LOADING';
export const ROLES_LOADING_SUCCESS          = 'ROLES_LOADING_SUCCESS';
export const ROLES_LOADING_ERROR            = 'ROLES_LOADING_ERROR';

export const ROL_LOAD                       = 'ROL_LOAD';  
export const ROL_LOAD_SUCCESS               = 'ROL_LOAD_SUCCESS';    
export const ROL_LOAD_ERROR                 = 'ROL_LOAD_ERROR'; 

// STATUS
export const STATUS_LOADING                 = 'STATUS_LOADING';
export const STATUS_LOADING_SUCCESS         = 'STATUS_LOADING_SUCCESS';
export const STATUS_LOADING_ERROR           = 'STATUS_LOADING_ERROR';

export const STATUS_LOAD                    = 'STATUS_LOAD';  
export const STATUS_LOAD_SUCCESS            = 'STATUS_LOAD_SUCCESS';    
export const STATUS_LOAD_ERROR              = 'STATUS_LOAD_ERROR'; 

//TEAM
export const TEAM_CREATE                    = 'TEAM_CREATE';
export const TEAM_CREATE_SUCCESS            = 'TEAM_CREATE_SUCCESS';
export const TEAM_CREATE_ERROR              = 'TEAM_CREATE_ERROR';
export const TEAM_CREATE_INIT               = 'TEAM_CREATE_INIT'; 

export const TEAMS_LOADING                  = 'TEAMS_LOADING';
export const TEAMS_LOADING_SUCCESS          = 'TEAMS_LOADING_SUCCESS';
export const TEAMS_LOADING_ERROR            = 'TEAMS_LOADING_ERROR';

export const TEAM_LOAD                      = 'TEAM_LOAD';  
export const TEAM_LOAD_SUCCESS              = 'TEAM_LOAD_SUCCESS';    
export const TEAM_LOAD_ERROR                = 'TEAM_LOAD_ERROR'; 

export const TEAM_DESACTIVE                 = 'TEAM_DESACTIVE';  
export const TEAM_DESACTIVE_ERROR           = 'TEAM_DESACTIVE_ERROR';   

// SEGUIMIENTO
export const SEGUIMIENTO_CREATE                    = 'SEGUIMIENTO_CREATE';
export const SEGUIMIENTO_CREATE_SUCCESS            = 'SEGUIMIENTO_CREATE_SUCCESS';
export const SEGUIMIENTO_CREATE_ERROR              = 'SEGUIMIENTO_CREATE_ERROR';
export const SEGUIMIENTO_CREATE_INIT               = 'SEGUIMIENTO_CREATE_INIT'; 

export const SEGUIMIENTO_LOAD                      = 'SEGUIMIENTO_LOAD';  
export const SEGUIMIENTO_LOAD_SUCCESS              = 'SEGUIMIENTO_LOAD_SUCCESS';    
export const SEGUIMIENTO_LOAD_ERROR                = 'SEGUIMIENTO_LOAD_ERROR'; 


//CALENDARY
export const CALENDARY_CREATE                    = 'CALENDARY_CREATE';
export const CALENDARY_CREATE_SUCCESS            = 'CALENDARY_CREATE_SUCCESS';
export const CALENDARY_CREATE_ERROR              = 'CALENDARY_CREATE_ERROR';
export const CALENDARY_CREATE_INIT               = 'CALENDARY_CREATE_INIT'; 

export const CALENDARYS_LOADING                  = 'CALENDARYS_LOADING';
export const CALENDARYS_LOADING_SUCCESS          = 'CALENDARYS_LOADING_SUCCESS';
export const CALENDARYS_LOADING_ERROR            = 'CALENDARYS_LOADING_ERROR';

export const CALENDARY_LOAD                      = 'CALENDARY_LOAD';  
export const CALENDARY_LOAD_SUCCESS              = 'CALENDARY_LOAD_SUCCESS';    
export const CALENDARY_LOAD_ERROR                = 'CALENDARY_LOAD_ERROR'; 

export const CALENDARY_DESACTIVE                 = 'CALENDARY_DESACTIVE';  
export const CALENDARY_DESACTIVE_ERROR           = 'CALENDARY_DESACTIVE_ERROR';   

//REGISTER
export const REGISTER_CREATE                    = 'REGISTER_CREATE';
export const REGISTER_CREATE_SUCCESS            = 'REGISTER_CREATE_SUCCESS';
export const REGISTER_CREATE_ERROR              = 'REGISTER_CREATE_ERROR';
export const REGISTER_CREATE_INIT               = 'REGISTER_CREATE_INIT'; 

export const REGISTERS_LOADING                  = 'REGISTERS_LOADING';
export const REGISTERS_LOADING_SUCCESS          = 'REGISTERS_LOADING_SUCCESS';
export const REGISTERS_LOADING_ERROR            = 'REGISTERS_LOADING_ERROR';

export const REGISTER_LOAD                      = 'REGISTER_LOAD';  
export const REGISTER_LOAD_SUCCESS              = 'REGISTER_LOAD_SUCCESS';    
export const REGISTER_LOAD_ERROR                = 'REGISTER_LOAD_ERROR'; 

export const REGISTER_DESACTIVE                 = 'REGISTER_DESACTIVE';  
export const REGISTER_DESACTIVE_ERROR           = 'REGISTER_DESACTIVE_ERROR';   

//FILTERS
export const FILTER_CREATE                    = 'FILTER_CREATE';
export const FILTER_CREATE_SUCCESS            = 'FILTER_CREATE_SUCCESS';
export const FILTER_CREATE_ERROR              = 'FILTER_CREATE_ERROR';
export const FILTER_CREATE_INIT               = 'FILTER_CREATE_INIT'; 

export const FILTERS_LOADING                  = 'FILTERS_LOADING';
export const FILTERS_LOADING_SUCCESS          = 'FILTERS_LOADING_SUCCESS';
export const FILTERS_LOADING_ERROR            = 'FILTERS_LOADING_ERROR';

export const FILTER_LOAD                      = 'FILTER_LOAD';  
export const FILTER_LOAD_SUCCESS              = 'FILTER_LOAD_SUCCESS';    
export const FILTER_LOAD_ERROR                = 'FILTER_LOAD_ERROR'; 

export const FILTER_DESACTIVE                 = 'FILTER_DESACTIVE';  
export const FILTER_DESACTIVE_ERROR           = 'FILTER_DESACTIVE_ERROR';   

//HOUSE
export const HOUSE_CREATE                    = 'HOUSE_CREATE';
export const HOUSE_CREATE_SUCCESS            = 'HOUSE_CREATE_SUCCESS';
export const HOUSE_CREATE_ERROR              = 'HOUSE_CREATE_ERROR';
export const HOUSE_CREATE_INIT               = 'HOUSE_CREATE_INIT';

export const HOUSES_LOADING                  = 'HOUSES_LOADING';
export const HOUSES_LOADING_SUCCESS          = 'HOUSES_LOADING_SUCCESS';
export const HOUSES_LOADING_ERROR            = 'HOUSES_LOADING_ERROR';

export const HOUSE_DESACTIVE                 = 'HOUSE_DESACTIVE';  
export const HOUSE_DESACTIVE_ERROR           = 'HOUSE_DESACTIVE_ERROR';   

export const HOUSE_LOAD                      = 'HOUSE_LOAD';  
export const HOUSE_LOAD_SUCCESS              = 'HOUSE_LOAD_SUCCESS';    
export const HOUSE_LOAD_ERROR                = 'HOUSE_LOAD_ERROR';

export const HOUSE_UPDATE_INIT               = 'HOUSE_UPDATE_INIT';
export const HOUSE_UPDATE_SUCCESS            = 'HOUSE_UPDATE_SUCCESS';
export const HOUSE_UPDATE_ERROR              = 'HOUSE_UPDATE_ERROR';

//CASA UBICATION
export const HOUSE_CREATE_UBICATION                 = 'HOUSE_CREATE_UBICATION';
export const HOUSE_CREATE_SUCCESS_UBICATION         = 'HOUSE_CREATE_SUCCESS_UBICATION';
export const HOUSE_CREATE_ERROR_UBICATION           = 'HOUSE_CREATE_ERROR_UBICATION';

export const HOUSE_LOAD_UBICATION                   = 'HOUSE_LOAD_UBICATION';                 
export const HOUSE_LOAD_SUCCESS_UBICATION           = 'HOUSE_LOAD_SUCCESS_UBICATION';    
export const HOUSE_LOAD_ERROR_UBICATION             = 'HOUSE_LOAD_ERROR_UBICATION';

//CASA EXTRA
export const HOUSE_CREATE_EXTRA                     = 'HOUSE_CREATE_EXTRA';
export const HOUSE_CREATE_SUCCESS_EXTRA             = 'HOUSE_CREATE_SUCCESS_EXTRA';
export const HOUSE_CREATE_ERROR_EXTRA               = 'HOUSE_CREATE_ERROR_EXTRA';

export const HOUSE_LOAD_EXTRA                       = 'HOUSE_LOAD_EXTRA';                 
export const HOUSE_LOAD_SUCCESS_EXTRA               = 'HOUSE_LOAD_SUCCESS_EXTRA';    
export const HOUSE_LOAD_ERROR_EXTRA                 = 'HOUSE_LOAD_ERROR_EXTRA';

//CASA PRECIO
export const HOUSE_CREATE_PRICE                     = 'HOUSE_CREATE_PRICE';
export const HOUSE_CREATE_SUCCESS_PRICE             = 'HOUSE_CREATE_SUCCESS_PRICE';
export const HOUSE_CREATE_ERROR_PRICE               = 'HOUSE_CREATE_ERROR_PRICE';

export const HOUSE_LOAD_PRICE                       = 'HOUSE_LOAD_PRICE';                 
export const HOUSE_LOAD_SUCCESS_PRICE               = 'HOUSE_LOAD_SUCCESS_PRICE';    
export const HOUSE_LOAD_ERROR_PRICE                 = 'HOUSE_LOAD_ERROR_PRICE';

//CASA GENERAL
export const HOUSE_CREATE_GENERAL                   = 'HOUSE_CREATE_GENERAL';
export const HOUSE_CREATE_SUCCESS_GENERAL           = 'HOUSE_CREATE_SUCCESS_GENERAL';
export const HOUSE_CREATE_ERROR_GENERAL             = 'HOUSE_CREATE_ERROR_GENERAL';

export const HOUSE_LOAD_GENERAL                     = 'HOUSE_LOAD_GENERAL';                 
export const HOUSE_LOAD_SUCCESS_GENERAL             = 'HOUSE_LOAD_SUCCESS_GENERAL';    
export const HOUSE_LOAD_ERROR_GENERAL               = 'HOUSE_LOAD_ERROR_GENERAL';

//CASA IMAGE
export const HOUSE_CREATE_IMAGE                     = 'HOUSE_CREATE_IMAGE';
export const HOUSE_CREATE_SUCCESS_IMAGE             = 'HOUSE_CREATE_SUCCESS_IMAGE';
export const HOUSE_CREATE_ERROR_IMAGE               = 'HOUSE_CREATE_ERROR_IMAGE';

export const HOUSE_LOAD_IMAGE                     = 'HOUSE_LOAD_IMAGE';                 
export const HOUSE_LOAD_SUCCESS_IMAGE             = 'HOUSE_LOAD_SUCCESS_IMAGE';    
export const HOUSE_LOAD_ERROR_IMAGE               = 'HOUSE_LOAD_ERROR_IMAGE';

//DESARROLLO
export const DESARROLLO_CREATE                    = 'DESARROLLO_CREATE';
export const DESARROLLO_CREATE_SUCCESS            = 'DESARROLLO_CREATE_SUCCESS';
export const DESARROLLO_CREATE_ERROR              = 'DESARROLLO_CREATE_ERROR';
export const DESARROLLO_CREATE_INIT               = 'DESARROLLO_CREATE_INIT';

export const DESARROLLOS_LOADING                  = 'DESARROLLOS_LOADING';
export const DESARROLLOS_LOADING_SUCCESS          = 'DESARROLLOS_LOADING_SUCCESS';
export const DESARROLLOS_LOADING_ERROR            = 'DESARROLLOS_LOADING_ERROR';

export const DESARROLLO_DESACTIVE                 = 'DESARROLLO_DESACTIVE';  
export const DESARROLLO_DESACTIVE_ERROR           = 'DESARROLLO_DESACTIVE_ERROR';   

export const DESARROLLO_LOAD                      = 'DESARROLLO_LOAD';  
export const DESARROLLO_LOAD_SUCCESS              = 'DESARROLLO_LOAD_SUCCESS';    
export const DESARROLLO_LOAD_ERROR                = 'DESARROLLO_LOAD_ERROR';

export const DESARROLLO_UPDATE_INIT               = 'DESARROLLO_UPDATE_INIT';
export const DESARROLLO_UPDATE_SUCCESS            = 'DESARROLLO_UPDATE_SUCCESS';
export const DESARROLLO_UPDATE_ERROR              = 'DESARROLLO_UPDATE_ERROR';

//DESARROLLADOR
export const DESARROLLADOR_CREATE                    = 'DESARROLLADOR_CREATE';
export const DESARROLLADOR_CREATE_SUCCESS            = 'DESARROLLADOR_CREATE_SUCCESS';
export const DESARROLLADOR_CREATE_ERROR              = 'DESARROLLADOR_CREATE_ERROR';
export const DESARROLLADOR_CREATE_INIT               = 'DESARROLLADOR_CREATE_INIT';

export const DESARROLLADOR_LOADING                  = 'DESARROLLADOR_LOADING';
export const DESARROLLADOR_LOADING_SUCCESS          = 'DESARROLLADOR_LOADING_SUCCESS';
export const DESARROLLADOR_LOADING_ERROR            = 'DESARROLLADOR_LOADING_ERROR';

export const DESARROLLADOR_DESACTIVE                 = 'DESARROLLADOR_DESACTIVE';  
export const DESARROLLADOR_DESACTIVE_ERROR           = 'DESARROLLADOR_DESACTIVE_ERROR';   

export const DESARROLLADOR_LOAD                      = 'DESARROLLADOR_LOAD';  
export const DESARROLLADOR_LOAD_SUCCESS              = 'DESARROLLADOR_LOAD_SUCCESS';    
export const DESARROLLADOR_LOAD_ERROR                = 'DESARROLLADOR_LOAD_ERROR';

export const DESARROLLADOR_UPDATE_INIT               = 'DESARROLLADOR_UPDATE_INIT';
export const DESARROLLADOR_UPDATE_SUCCESS            = 'DESARROLLADOR_UPDATE_SUCCESS';
export const DESARROLLADOR_UPDATE_ERROR              = 'DESARROLLADOR_UPDATE_ERROR';


//SCHEDULE
export const SCHEDULE_CREATE                    = 'SCHEDULE_CREATE';
export const SCHEDULE_CREATE_SUCCESS            = 'SCHEDULE_CREATE_SUCCESS';
export const SCHEDULE_CREATE_ERROR              = 'SCHEDULE_CREATE_ERROR';
export const SCHEDULE_CREATE_INIT               = 'SCHEDULE_CREATE_INIT';

export const SCHEDULES_LOADING                  = 'SCHEDULES_LOADING';
export const SCHEDULES_LOADING_SUCCESS          = 'SCHEDULES_LOADING_SUCCESS';
export const SCHEDULES_LOADING_ERROR            = 'SCHEDULES_LOADING_ERROR';

export const SCHEDULE_LOAD                      = 'SCHEDULE_LOAD';  
export const SCHEDULE_LOAD_SUCCESS              = 'SCHEDULE_LOAD_SUCCESS';    
export const SCHEDULE_LOAD_ERROR                = 'SCHEDULE_LOAD_ERROR';

//OPTIONS
export const OPTION_CREATE                         = 'OPTION_CREATE';
export const OPTION_CREATE_SUCCESS                 = 'OPTION_CREATE_SUCCESS';
export const OPTION_CREATE_ERROR                   = 'OPTION_CREATE_ERROR';
export const OPTION_CREATE_INIT                    = 'OPTION_CREATE_INIT';

export const OPTIONS_LOADING                        = 'OPTIONS_LOADING';
export const OPTIONS_LOADING_SUCCESS                = 'OPTIONS_LOADING_SUCCESS';
export const OPTIONS_LOADING_ERROR                  = 'OPTIONS_LOADING_ERROR';

export const OPTION_USER_LOAD                       = 'OPTION_USER_LOAD';  
export const OPTION_USER_LOAD_SUCCESS               = 'OPTION_USER_LOAD_SUCCESS';    
export const OPTION_USER_LOAD_ERROR                 = 'OPTION_USER_LOAD_ERROR';

export const OPTION_CLIENT_LOAD                     = 'OPTION_CLIENT_LOAD';  
export const OPTION_CLIENT_LOAD_SUCCESS             = 'OPTION_CLIENT_LOAD_SUCCESS';    
export const OPTION_CLIENT_LOAD_ERROR               = 'OPTION_CLIENT_LOAD_ERROR';

export const OPTION_TEAM_LOAD                       = 'OPTION_TEAM_LOAD';  
export const OPTION_TEAM_LOAD_SUCCESS               = 'OPTION_TEAM_LOAD_SUCCESS';    
export const OPTION_TEAM_LOAD_ERROR                 = 'OPTION_TEAM_LOAD_ERROR';

export const OPTION_HOUSE_LOAD                      = 'OPTION_HOUSE_LOAD';  
export const OPTION_HOUSE_LOAD_SUCCESS              = 'OPTION_HOUSE_LOAD_SUCCESS';    
export const OPTION_HOUSE_LOAD_ERROR                = 'OPTION_HOUSE_LOAD_ERROR';