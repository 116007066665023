import styled from 'styled-components';

export const TitleUser = styled.h1`
    font-size: 36px;
    font-weight: bold;
    color: #004274;
    margin: 35px 0px 30px 12.5%;
    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        margin:0;
        margin-top: 10px;
        margin-bottom: 10px;
    }
`


export const DivCenter = styled.div`
  
`
export const RowUser = styled.div`
    
    width: 69%;
    height: 100%;
    border-radius:10px;
    border: 1px solid #004274;
    margin: 0 auto;
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-rows: 100px;
    grid-column-gap: 8rem;
    grid-row-gap: 5rem;
    padding:3%;
    padding-top: 5rem;


    @media only screen and (max-width: 768px) {
        height: 100%;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);

        width: 85%;
        height: 100%;
        border-radius: 10px;
        margin: 0 auto;
        grid-auto-rows: 48px;
        grid-column-gap: 0rem;
        grid-row-gap: 2rem;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 0rem;
        padding-top: 1rem;
        border: 0px solid;
        margin-bottom: .5rem;

    }
`

export const RowInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const RowWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    height: 25rem;
    width: 30rem;
    margin-left:5rem;
    margin-right:5rem;
    margin-top: 3rem;
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 860px) {
        height: 100%;
        row-gap: 10px;
        flex-direction: column;
        margin-top:10px;
        width: 90%;
    }

   
`


export const InputUser = styled.input`

    width: 300px;
    height:30px;
    border: 1px solid #004274;
    border-radius:5px;
    padding: 0px 0px 0px 10px;

    ::placeholder {
        color: #7C7C7C;
        font-weight: bold;
        font-size: 14px;
    }
`

export const SelectUser = styled.select`

    width: 300px;
    height:30px;
    border: 1px solid #004274;
    border-radius:5px;
    padding: 0px 0px 0px 10px;

    ::placeholder {
        color: #7C7C7C;
        font-weight: bold;
        font-size: 14px;
    }
`

export const NameInput = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: #004274;
    margin-bottom: 7px;
`
export const DivButtons = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    height: 70px;
    border: 1px solid #004274;
    margin: 10px auto;
    padding-right: 20px;
    margin-top: 1.5rem;
    width: 74.3%;
    border-radius: 10px;

    @media only screen and (max-width: 768px) {
        border: 0px solid;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
        margin-bottom: 4.5rem;
    }

`

export const DivButtonsClientSales = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    height: 70px;
    border: 1px solid #004274;
    margin: 10px auto;
    padding-right: 20px;
    margin-top: 1.5rem;
    width: 97.3%;
    border-radius: 10px;

    @media only screen and (max-width: 768px) {
        border: 0px;
        justify-content: center;
    }
`

export const ButtonSave = styled.button`
   background-color: #02B74A;
   color: #FFFFFF;
   width: 200px;
   height: 35px;
   border-radius: 15px;
   text-align: center;
   font-size: 18px;
   font-weight: bold;
   margin: 10px 10px 10px 10px;
   list-style: none;
   border: none;
   cursor: pointer;
`


export const ButtonCancel = styled.button`
   background-color: #A91930;
   color: #FFFFFF;
   width: 200px;
   height: 35px;
   border-radius: 15px;
   text-align: center;
   font-size: 18px;
   font-weight: bold;
   margin: 10px 10px 10px 10px;
   list-style: none;
   border: none;
   cursor: pointer;
`

export const DivUserSuccess = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    margin-top:7%;
    justify-content: center;
    align-items: center;
    text-align: center;
`

export const ImageUserSuccess = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`

export const TitleUserSuccess = styled.h1`
    display: flex;
    justify-content:center;
    align-items: center;
    font-size: 36px;
    color: #000000;
    margin-top: 0px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`

export const TitleUserGreen = styled.p`
    display: flex;
    padding:10px 10px 10px 10px;
    justify-content:center;
    align-items: center;
    font-size: 36px;
    color: #02B74A;
    @media only screen and (max-width: 768px) {
        margin: 0;
    }
`
export const ButtonUserSuccess = styled.button`
   background-color: #004274;
   color: #FFFFFF;
   width: 200px;
   height: 35px;
   border-radius: 15px;
   text-align: center;
   font-size: 18px;
   font-weight: bold;
   list-style: none;
   border: none;
   cursor: pointer;
`
export const TitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;

    /* DIV H1 */
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 1rem;
        margin-top: 0;
        justify-content: center;
        align-items: center;
    }
`
export const TitleUserGrid = styled.h1`
    font-size: 36px;
    font-weight: bold;
    color: #004274;
    margin-left:0%;
`


export const DivButton = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 1rem 2rem 1rem;
    width:80%;
`
export const DivGrid = styled.div`
    display: flex;
    height:700px;
    justify-content: center;
    width:90%;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
        height: 560px;
    }
`