import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    TEAM_CREATE,
    TEAM_CREATE_SUCCESS,
    TEAM_CREATE_ERROR,
    TEAM_CREATE_INIT,
    TEAMS_LOADING,
    TEAMS_LOADING_SUCCESS,
    TEAMS_LOADING_ERROR,
    TEAM_LOAD,
    TEAM_LOAD_SUCCESS,
    TEAM_LOAD_ERROR,
    TEAM_DESACTIVE,
    TEAM_DESACTIVE_ERROR
} from '../types';



/*  Create Team
    function que crea el equipo
*/ 
    export function createTeamActionFn(team) {

        return async ( dispatch ) => {
            
            dispatch( createTeam(team) );

            try{
                console.log(team);
                let responseTeam = await clientAxios.post('/equipos/crear/', team);
                console.log(responseTeam);
                dispatch( createTeamSuccess(responseTeam.data.data) );
            }catch( error ){
                dispatch( createTeamError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el equipo");
            }
        }

    }

    // Create Team init action(se activa el flag a true para iniciar la creación)
    export function createTeamInitFn(flag){
        return ( dispatch ) => {
            dispatch( createTeamInit(flag));
        }
    }

    const createTeam = ( ) => ({
        type    : TEAM_CREATE
    });

    const createTeamSuccess = (team) => ({
        type    : TEAM_CREATE_SUCCESS,
        payload : team,
        flag    : true,
    })

    const createTeamError = () => ({
        type    : TEAM_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createTeamInit = (flag) =>({
        type    : TEAM_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* 
    DESACTIVE Teams 
    function para desactivar el equipo
*/
    export const desactiveTeamAction = (id) => {

        return async (dispatch)=>{
            dispatch( getTeamDesactive(id) );

            try{
                let responseDesactiveTeam = await clientAxios.post(`/equipos/change-active/?id_equipo=${id}`);
                console.log(responseDesactiveTeam);
                // ALL USERS UPDATE ACTIVE
                if(responseDesactiveTeam.status === 200){
                    dispatch( downloadTeam() );
                    let responseTeamActive = await clientAxios.get('/equipos', );
                    dispatch( downloadTeamSuccess (responseTeamActive.data.data) );
                }
                
            }catch( error ){
                dispatch( desactiveTeamError() );
                SweetAlertBasic("error","Ups","Hubo un error al desactivar/activar el equipo");  
            }
        }

    }

    const getTeamDesactive = (id)=>({
        type: TEAM_DESACTIVE,
        payload: id
    })

    const desactiveTeamError = ( ) =>({
        type: TEAM_DESACTIVE_ERROR,
        error: true,
        flag: false
    }) 

/*  GRID TEAMS 
    function que trae todos los equipos activos y desactivos
*/
    export function getAllTeamsAction() {
        return async ( dispatch ) => {
            dispatch( downloadTeam() );

            try{
                const responseTeams = await clientAxios.get('/equipos', );
                dispatch( downloadTeamSuccess (responseTeams.data.data) );

            }catch( error ){
                dispatch( downloadTeamError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los equipos");   
            }
        }
    }

    const downloadTeam = () => ({
        type: TEAMS_LOADING,
        payload: true
    })

    const downloadTeamSuccess = ( teams ) => ({
        type: TEAMS_LOADING_SUCCESS,
        payload: teams
    })

    const downloadTeamError = (error) => ({
        type: TEAMS_LOADING_ERROR,
        error: error
    })

/*  EDIT TEAMS 
    function que trae la información del un solo equipo para visualizarla
*/
    export const getTeamAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadTeam() );

            try{
                let responseTeamId = await clientAxios.get(`/equipos/${id}`);
                dispatch( loadTeamSuccess (responseTeamId.data.data) );
            }catch( error ){
                dispatch( loadTeamError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar el equipo");  
            }
        }   
    }

    const getLoadTeam = () => ({
        type: TEAM_LOAD,
        payload: true
    })

    const loadTeamSuccess = (team) => ({
        type: TEAM_LOAD_SUCCESS,
        editarEquipo: team
    })

    const loadTeamError = () => ({
        type: TEAM_LOAD_ERROR,
        error: true
    })
    