import React, { useState,useEffect }            from 'react'
import { useNavigate, Navigate, useParams }     from 'react-router-dom';
import ProgressBar                              from '../Progress/ProgressBar.component';
import { useDispatch, useSelector }             from 'react-redux';
import { SweetAlertBasic }                      from '../../utils/sweetAlert';
import Box                                      from '@mui/material/Box';
import TextField                                from '@mui/material/TextField';
import InputLabel                               from '@mui/material/InputLabel';
import MenuItem                                 from '@mui/material/MenuItem';
import Select                                   from '@mui/material/Select';
import FormControl                              from '@mui/material/FormControl';
import Button                                   from '@mui/material/Button';
import SaveIcon                                 from '@mui/icons-material/Save';
import Stack                                    from '@mui/material/Stack';
import {
    tipoCreditoJson,
    estadoCivilJson,
    sexoJson,
    tipoClienteJson
}                                               from '../../utils/main.js';
import{  
    getClientAction,
    updateClientAction
}                                               from '../../actions/clientActions';
import  { getAllUsersAction }                   from '../../actions/userActions';
import  { getAllStatusAction }                  from '../../actions/statusActions';
import  { getAllFiltersAction }                 from '../../actions/filterActions';
import  { getAllRegistersAction }               from '../../actions/registerActions';
import  { getAllCalendarysAction }              from '../../actions/calendaryActions';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
}                                               from '../Usuarios/Users.styles';

import {
    CreatedandUpdated,
    TitleCreated,
    DivCreatedUpdated
} from './Clients.styles';

const EditClient = () => {

    const dispatch              = useDispatch();
    const navigate              = useNavigate();
    const { id_cliente }        = useParams();
    const loading               = useSelector( state => state.clientes?.loading);
    const clientUpdateSuccess   = useSelector( state => state.clientes?.flagUpdate);
    const updateLoadClient      = useSelector( state => state.clientes?.flag);
    const editClient            = useSelector( state => state.clientes?.editarCliente || {} );
    const userSuccess           = useSelector( state => state.usuarios.usuarios);
    const id_rol                = useSelector( state => state.login.user?.rol);
    const statusSuccess         = useSelector( state   => state.status?.status || {});
    const calendarySuccess      = useSelector( state => state.calendarios?.calendarios);
    const registerSuccess       = useSelector( state => state.registros?.registros);
    // const filterSuccess         = useSelector( state => state.filtros?.filtros);
    const loadingClient         = () => dispatch( getClientAction(id_cliente) );
    const updateClientInit      = (client) => dispatch( updateClientAction( client, id_cliente ) );
    const loadingUsers          = () => dispatch( getAllUsersAction() );
    const loadingStatus         = () => dispatch( getAllStatusAction() );
    const loadingFilters        = () => dispatch( getAllFiltersAction() );
    const loadingRegisters      = () => dispatch( getAllRegistersAction() );  
    const loadingCalendarys     = () => dispatch( getAllCalendarysAction() );
       
    const [client, setClient] = useState({
        nombre_cliente              : '',
        apellido_materno_cliente    : '',
        apellido_paterno_cliente    : '',
        email_cliente               : '',
        telefono_cliente            : '',
        tipo_credito                : '',
        id_usuario                  : '',
    });

    // Change Value TextField
    const onChangeForm = ( event ) => {
        setClient({
            ...client,
            [event.target.name]: event.target.value
        })
    }

    useEffect( () => {
        loadingClient();
        loadingUsers();
        loadingStatus();   
        loadingFilters();      
        loadingRegisters();
        loadingCalendarys();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    function goClients() {
        if(id_rol === 2) navigate('/clientes/especialista');
        if(id_rol === 4) navigate('/dashboard/recepcion');
        if(id_rol === 6) navigate('/clientes/lider');
        if(id_rol === 1) navigate('/clientes');
    }


    const updateClient = e => {
        e.preventDefault();
        // Quita los valores vacios ""
        
        let dataClient = Object.entries(client).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
        if( nombre_cliente.trim() === '' || telefono_cliente.length === 0 ){
            SweetAlertBasic("error","Ups","Unos campos son obligatorios(nombre y telefono)");
            return;
        }
        console.log(dataClient);
        updateClientInit(dataClient, id_cliente);
    }
    
    const{ 
        id_usuario, 
        created_at, 
        updated_at, 
        nombre_cliente,
        apellido_materno_cliente,
        apellido_paterno_cliente,
        telefono_cliente,
        email_cliente,
        curp_cliente,
        estado_civil_cliente,
        sexo_cliente,
        ingresos_cliente,
        id_status,
        id_calendario,
        id_registro,
        id_tipo_cliente,
        referencia_cliente,
        tipo_credito
    } = editClient;

    return ( 
        <DivCenter >
            {  
        
                // LOADING CLIENT
                loading ? 
                    <ProgressBar title="Cargando Cliente..."/>
                :
           
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={updateClient}
                            
                        >
                            <TitleUser>Editar cliente</TitleUser>
                                    <DivCreatedUpdated>
                                        <CreatedandUpdated>Fecha de Creación: 
                                            <TitleCreated>   {created_at}</TitleCreated>
                                        </CreatedandUpdated>
                                        
                                        <CreatedandUpdated>Ultima actualización:
                                            <TitleCreated>   {updated_at}</TitleCreated>
                                        </CreatedandUpdated>
                                        <CreatedandUpdated>Referencia:
                                            <TitleCreated>   {referencia_cliente}</TitleCreated>
                                        </CreatedandUpdated>
                                    </DivCreatedUpdated>

                                    <RowUser>
                                        {
                                            userSuccess ? 
                                            <>
                                            <FormControl >
                                                <InputLabel id="demo-controlled-open-select-label">Especialista</InputLabel>
                                                <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                name="id_usuario"
                                                label="Especialista"
                                                defaultValue={id_usuario}
                                                onChange={onChangeForm}
                                                >
                                                {userSuccess.map((user) => (
                                                    <MenuItem
                                                        key={user.id_usuario}
                                                        value={user.id_usuario}
                                                    >
                                                    {user.nombre_usuario} {''} {user.apellido_paterno_usuario} {'-'} {user.email_usuario}
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                            </>
                                            :
                                            null

                                        }
                                         {
                                            statusSuccess ? 
                                            <>
                                            <FormControl >
                                                <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                                                <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                name="id_status"
                                                label="Status"
                                                defaultValue={id_status}
                                                onChange={onChangeForm}
                                                >
                                                {statusSuccess.map((status) => (
                                                    <MenuItem
                                                        key={status.id_status}
                                                        value={status.id_status}
                                                    >
                                                    {status.nombre_status} 
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                            </>
                                            :
                                            null

                                        }
                                        {
                                            calendarySuccess ? 
                                            <>
                                            <FormControl >
                                                <InputLabel id="demo-controlled-open-select-label">Calendario</InputLabel>
                                                <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                name="id_calendario"
                                                label="Calendario"
                                                defaultValue={id_calendario}
                                                onChange={onChangeForm}
                                                >
                                                {calendarySuccess.map((calendary) => (
                                                    <MenuItem
                                                        key={calendary.id_calendario}
                                                        value={calendary.id_calendario}
                                                    >
                                                    {calendary.nombre_calendario} 
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                            </>
                                            :
                                            null

                                        }
                                        {
                                            registerSuccess ? 
                                            <>
                                            <FormControl >
                                                <InputLabel id="demo-controlled-open-select-label">Registro</InputLabel>
                                                <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                name="id_registro"
                                                label="Registro"
                                                defaultValue={id_registro}
                                                onChange={onChangeForm}
                                                >
                                                {registerSuccess.map((register) => (
                                                    <MenuItem
                                                        key={register.id_registro}
                                                        value={register.id_registro}
                                                    >
                                                    {register.nombre_registro} 
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                            </>
                                            :
                                            null

                                        }
                                       <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tipo Cliente</InputLabel>
                                            <Select
                                                labelId="estado_civil"
                                                id="demo-simple-select"
                                                label="Tipo Cliente"
                                                name="id_tipo_cliente"
                                                defaultValue={id_tipo_cliente || ""}
                                                onChange={onChangeForm}
                                            >
                                                {tipoClienteJson.map((tipo) => (
                                                    <MenuItem
                                                        key={tipo.value}
                                                        value={tipo.value}
                                                    >
                                                    {tipo.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        
                                    </RowUser><br></br>
                                    <RowUser>
                                    
                                        <TextField  
                                            id="outlined-basic" 
                                            label="Ingresa el nombre del cliente" 
                                            variant="outlined"
                                            type="text"
                                            name="nombre_cliente"
                                            defaultValue={nombre_cliente}
                                            onChange={ onChangeForm }
                                            fullWidth
                                        />

                                        <TextField  
                                            id="outlined-basic" 
                                            label="Ingresa el apellido paterno del cliente" 
                                            variant="outlined"
                                            name="apellido_paterno_cliente"
                                            defaultValue={apellido_paterno_cliente}
                                            onChange={ onChangeForm }
                                            fullWidth
                                        />
                                        <TextField  
                                            id="outlined-basic" 
                                            label="Ingresa el apellido materno del cliente" 
                                            variant="outlined"
                                            name="apellido_materno_cliente"
                                            defaultValue={apellido_materno_cliente}
                                            onChange={ onChangeForm }
                                            fullWidth
                                        />
                                
                                        <TextField  
                                            id="outlined-basic" 
                                            label="Ingresa el telefono del cliente" 
                                            variant="outlined"
                                            name="telefono_cliente"
                                            defaultValue={telefono_cliente || ''}
                                            type="number"
                                            onInput = {(e) =>{
                                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                            }}
                                            onChange={ onChangeForm }
                                            fullWidth
                                        />

                                        <TextField  
                                            id="outlined-basic" 
                                            label="Ingresa el email del cliente" 
                                            variant="outlined"
                                            name="email_cliente"
                                            defaultValue={email_cliente}
                                            onChange={ onChangeForm }
                                            fullWidth
                                        />

                                    
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tipo de Crédito</InputLabel>
                                            <Select
                                                labelId="equipo_rol"
                                                id="demo-simple-select"
                                                label="Tipo de Credito"
                                                name="tipo_credito"
                                                defaultValue={tipo_credito}
                                                onChange={onChangeForm}
                                            >
                                                {tipoCreditoJson.map((tipo) => (
                                                    <MenuItem
                                                        key={tipo.id}
                                                        value={tipo.name}
                                                    >
                                                    {tipo.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                           
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Estado Civil</InputLabel>
                                            <Select
                                                labelId="estado_civil"
                                                id="demo-simple-select"
                                                label="Estado Civil"
                                                name="estado_civil_cliente"
                                                defaultValue={estado_civil_cliente}
                                                onChange={onChangeForm}
                                            >
                                                {estadoCivilJson.map((tipo) => (
                                                    <MenuItem
                                                        key={tipo.value}
                                                        value={tipo.value}
                                                    >
                                                    {tipo.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                                            <Select
                                                labelId="estado_civil"
                                                id="demo-simple-select"
                                                label="Sexo"
                                                name="sexo_cliente"
                                                defaultValue={sexo_cliente}
                                                onChange={onChangeForm}
                                            >
                                                {sexoJson.map((tipo) => (
                                                    <MenuItem
                                                        key={tipo.value}
                                                        value={tipo.value}
                                                    >
                                                    {tipo.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField  
                                            id="outlined-basic" 
                                            label="Ingresa los ingresos del cliente" 
                                            variant="outlined"
                                            name="ingresos_cliente"
                                            defaultValue={ingresos_cliente}
                                            onChange={ onChangeForm }
                                            fullWidth
                                        />
                                        <TextField  
                                            id="outlined-basic" 
                                            label="Ingresa el curp del cliente" 
                                            variant="outlined"
                                            name="curp_cliente"
                                            defaultValue={curp_cliente}
                                            onChange={ onChangeForm }
                                            fullWidth
                                        />
                                       
                                    </RowUser>
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        onClick ={goClients}
                                        variant ="contained" 
                                        size    ="large"
                                        color   ="error"
                                    >
                                        Cancelar
                                    </Button>
                                
                                    <Button 
                                        variant     ="contained" 
                                        size        ="small" 
                                        type        ="submit"
                                        startIcon   ={<SaveIcon />}
                                    >
                                        Actualizar Cliente
                                    </Button>
                                </Stack>
                            </DivButtons>
                        </Box>   
            }
            {
                updateLoadClient ?
                    <ProgressBar title="Actualizando Cliente..."/>
                :null
            }
            {
                clientUpdateSuccess ?
                    <Navigate to='/cliente/actualizado/exitoso' />
                :
                null
            }
            
            
        </DivCenter>
    );
}
 
export default EditClient;