import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    REGISTER_CREATE,
    REGISTER_CREATE_SUCCESS,
    REGISTER_CREATE_ERROR,
    REGISTER_CREATE_INIT,
    REGISTERS_LOADING,
    REGISTERS_LOADING_SUCCESS,
    REGISTERS_LOADING_ERROR,
    REGISTER_LOAD,
    REGISTER_LOAD_SUCCESS,
    REGISTER_LOAD_ERROR,
    REGISTER_DESACTIVE,
    REGISTER_DESACTIVE_ERROR
} from '../types';



/*  Create Register
    function que crea el registro
    Nota: El slash al final del endpoint es para redireccionar al server
*/ 
    export function createRegisterActionFn(register) {

        return async ( dispatch ) => {
            
            dispatch( createRegister(register) );

            try{
                let responseRegister = await clientAxios.post('/registros/crear/', register);
                dispatch( createRegisterSuccess(responseRegister.data.data) );
            }catch( error ){
                dispatch( createRegisterError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el registro");
            }
        }

    }

    // Create Register init action(se activa el flag a true para iniciar la creación)
    export function createRegisterInitFn(flag){
        return ( dispatch ) => {
            dispatch( createRegisterInit(flag));
        }
    }

    const createRegister = ( ) => ({
        type    : REGISTER_CREATE
    });

    const createRegisterSuccess = (register) => ({
        type    : REGISTER_CREATE_SUCCESS,
        payload : register,
        flag    : true,
    })

    const createRegisterError = () => ({
        type    : REGISTER_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createRegisterInit = (flag) =>({
        type    : REGISTER_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* 
    DESACTIVE Registers 
    function para desactivar el registro
*/
    export const desactiveRegisterAction = (id) => {

        return async (dispatch)=>{
            dispatch( getRegisterDesactive(id) );

            try{
                
                let responseDesactiveRegister = await clientAxios.post(`/registros/change-active/?id_registro=${id}`);

                if(responseDesactiveRegister.status === 200){
                    dispatch( downloadRegister() );
                    let responseRegisterActive = await clientAxios.get('/registros', );
                    dispatch( downloadRegisterSuccess (responseRegisterActive.data.data) );
                }
                
            }catch( error ){
                dispatch( desactiveRegisterError() );
                SweetAlertBasic("error","Ups","Hubo un error al desactivar/activar el registro");  
            }
        }

    }

    const getRegisterDesactive = (id)=>({
        type: REGISTER_DESACTIVE,
        payload: id
    })

    const desactiveRegisterError = ( ) =>({
        type: REGISTER_DESACTIVE_ERROR,
        error: true,
        flag: false
    }) 

/*  
    GRID FILTERS 
    function que trae todos los registros activos y desactivos
*/
    export function getAllRegistersAction() {
        return async ( dispatch ) => {
            dispatch( downloadRegister() );

            try{
                const responseRegisters = await clientAxios.get('/registros', );
                dispatch( downloadRegisterSuccess (responseRegisters.data.data) );

            }catch( error ){
                dispatch( downloadRegisterError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los registros");   
            }
        }
    }

    const downloadRegister = () => ({
        type: REGISTERS_LOADING,
        payload: true
    })

    const downloadRegisterSuccess = ( registers ) => ({
        type: REGISTERS_LOADING_SUCCESS,
        payload: registers
    })

    const downloadRegisterError = (error) => ({
        type: REGISTERS_LOADING_ERROR,
        error: error
    })

/*  
    EDIT FILTERS 
    function que trae la información del un solo registro para visualizarlo
*/
    export const getRegisterAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadRegister() );

            try{
                let responseRegisterId = await clientAxios.get(`/registros/${id}`);
                dispatch( loadRegisterSuccess (responseRegisterId.data.data) );
            }catch( error ){
                dispatch( loadRegisterError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar el registro");  
            }
        }   
    }

    const getLoadRegister = () => ({
        type: REGISTER_LOAD,
        payload: true
    })

    const loadRegisterSuccess = (register) => ({
        type: REGISTER_LOAD_SUCCESS,
        editarRegistro: register
    })

    const loadRegisterError = () => ({
        type: REGISTER_LOAD_ERROR,
        error: true
    })
    