import React, { useState,useEffect }            from 'react'
import { Navigate, useParams }                  from 'react-router-dom';
import ProgressBarData                          from '../Progress/ProgressBarData.component';
import ProgressBarReload                        from '../Progress/ProgressBarReload.component';
import { useDispatch, useSelector }             from 'react-redux';
import Box                                      from '@mui/material/Box';
import TextField                                from '@mui/material/TextField';
import FormControl                              from '@mui/material/FormControl';
import Button                                   from '@mui/material/Button';
import SaveIcon                                 from '@mui/icons-material/Save';
import Stack                                    from '@mui/material/Stack';
import TextareaAutosize                         from '@mui/material/TextareaAutosize';
import { makeStyles }                           from '@material-ui/core/styles';
import{  
    getHouseAction,
    updateHouseAction,
    createHouseUbicationAction,
    getHouseUbicationAction,
    getHouseExtraAction,
    getHousePriceAction,
    getHouseGeneralAction,
    createHouseExtraAction,
    createHousePriceAction,
    createHouseGeneralAction,
    createHouseImageAction,
    getHouseImageAction
}                                               from '../../actions/houseActions';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
}                                               from '../Usuarios/Users.styles';
import {
    ImageDiv,
    TitleImage,
    Image,
    ImageNotFound,
    RowReload
} from './Houses.styles'
import {
    tipoCasaJson,
    monedaJson
}                                               from '../../utils/casas.js';


const EditHouse = ( ) => {

    const dispatch                      = useDispatch();
    const { id_casa }                   = useParams();
    // const loading                       = useSelector( state => state.casas?.loading);
    // const loadingUpdate                 = useSelector( state => state.casas?.loadingUpdate);
    const houseUpdateSuccess            = useSelector( state => state.casas?.flagUpdate);
    const editHouse                     = useSelector( state => state.casas?.editarCasa             || {} );
    const editHouseUbication            = useSelector( state => state.casas?.editarCasaUbicacion    || {} );
    const editHouseExtra                = useSelector( state => state.casas?.editarCasaExtra        || {} );
    const editHousePrice                = useSelector( state => state.casas?.editarCasaPrecio       || {} );
    const editHouseGeneral              = useSelector( state => state.casas?.editarCasaGeneral      || {} );
    // const editHouseImage                = useSelector( state => state.casas?.casa_image?.data_image || {} );
    const houseViewImage                = useSelector( state => state.casas?.casaImagen || {} );
    const realoadImage                  = useSelector( state => state.casas?.flagReload);


    const updateHouseInit               = (house)       => dispatch( updateHouseAction( house, id_casa ) );
    const addCreateHouseUbication       = (ubication)   => dispatch( createHouseUbicationAction(ubication, id_casa) );
    const addCreateHouseExtra           = (extra)       => dispatch( createHouseExtraAction(extra, id_casa) );
    const addCreateHousePrice           = (price)       => dispatch( createHousePriceAction(price, id_casa) );
    const addCreateHouseGeneral         = (general)     => dispatch( createHouseGeneralAction(general, id_casa) );
    const addCreateHouseImage           = (image, data)       => dispatch( createHouseImageAction(image, data) );

    // IMAGE
    const [ selectedFile, setSelectedFile ]     = useState(null);
    const [ selectImage, setSelectImage]        = useState('');
    const [general, setHouseGeneral]            = useState(editHouseGeneral);
    const [ubication, setHouseUbication]        = useState(editHouseUbication);
    const [extra, setHouseExtra]                = useState(editHouseExtra);
    const [price, setHousePrice]                = useState(editHousePrice);
    const [houses, setHouses]                   = useState(editHouse);

    useEffect( () => {

        setTimeout(() => {
            const loadingHouse                  = ()            => dispatch( getHouseAction(id_casa) );
            const loadingHouseUbication         = ()            => dispatch( getHouseUbicationAction(id_casa) );
            const loadingHouseExtra             = ()            => dispatch( getHouseExtraAction(id_casa) );
            const loadingHousePrice             = ()            => dispatch( getHousePriceAction(id_casa) );
            const loadingHouseGeneral           = ()            => dispatch( getHouseGeneralAction(id_casa) );
            const loadingHouseImage             = ()            => dispatch( getHouseImageAction(id_casa) );
        
            loadingHouse();
            loadingHouseUbication();
            loadingHouseExtra();
            loadingHousePrice();
            loadingHouseGeneral();
            loadingHouseImage();
           
        }, 500);
        
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
  

    const onChangeForm = ( e ) => {
        setHouses({
            ...houses,
            [e.target.name]: e.target.value
        });
    }

    const handleEstado = ( e ) => {
        setHouses({
            ...houses,
            id_estado: e.target.value
        });
    };

    const onChangeFormGeneral = ( event ) => {
        setHouseGeneral({
            ...general,
            [event.target.name]: event.target.value
        })
    }

    const onChangeFormUbication = ( event ) => {
        setHouseUbication({
            ...ubication,
            [event.target.name]: event.target.value
        })
    }

    const onChangeFormExtra = ( event ) => {
        setHouseExtra({
            ...extra,
            [event.target.name]: event.target.value
        })
    }

    const onChangeFormPrice = ( event ) => {
        setHousePrice({
            ...price,
            [event.target.name]: event.target.value
        })
    }
    

    const updateHouse = e => {
        e.preventDefault();
        let dataHouse = Object.entries(houses).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
        console.log(dataHouse);
        updateHouseInit(dataHouse, id_casa);
    }

    const createHouseDetailGeneral  = e => {
        e.preventDefault();
        let dataHouseGeneral                        = Object.entries(general).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
        dataHouseGeneral.nombre_casa_detalle        = "s/n";
        addCreateHouseGeneral(dataHouseGeneral,id_casa);
    }
    
    const createHouseUbication  = e => {
        e.preventDefault();
        let dataHouseUbication      = Object.entries(ubication).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
        addCreateHouseUbication(dataHouseUbication,id_casa);
    }

    const createHouseExtra  = e => {
        e.preventDefault();
        let dataHouseExtra      = Object.entries(extra).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
        addCreateHouseExtra(dataHouseExtra, id_casa);
    }

    const createHousePrice  = e => {
        e.preventDefault();
        let dataHousePrice  = Object.entries(price).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});

        // Se eliminan valores para no actualizar en la BD
        delete dataHousePrice.created_at;
        delete dataHousePrice.updated_at;
        delete dataHousePrice.id_casa;
        delete dataHousePrice.id_casa_precio;

        addCreateHousePrice(dataHousePrice, id_casa);
    }

    const createImages = (e) =>{

        e.preventDefault()
        const formData = new FormData();
        formData.append("image_file", selectedFile);
        let dataImage = {
            name_image : selectImage,
            id_casa : id_casa
        }    
        addCreateHouseImage(formData,dataImage);
    }

    // IMAGE
    const handleFileSelect = (event) => {
        setSelectImage(event.target.name);
        setSelectedFile(event.target.files[0]);
    }

    // INPUT 
    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            width: '100%',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    }));

    const classes   = useStyles();

 
    return ( 
        <DivCenter >
                    <>
                    {/* Datos Generales */}
                    {
                        editHouse ? 
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={updateHouse}

                        >
                            <TitleUser>Datos Generales</TitleUser>
                            <RowUser>
                                <TextField  
                                    id="outlined-basic" 
                                    variant="outlined"
                                    type="text"
                                    label="Nombre"
                                    name="nombre_casa"
                                    value       ={ houses.nombre_casa || editHouse.nombre_casa }
                                    onChange    ={ onChangeForm }
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />

                                
                                <TextField  
                                    id="outlined-basic" 
                                    variant="outlined"
                                    type="text"
                                    name="calle_casa"
                                    label="Calle"
                                    value={ houses.calle_casa || editHouse.calle_casa}
                                    onChange    ={ onChangeForm }
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField  
                                    id="outlined-basic" 
                                    variant="outlined"
                                    name="cp_casa"
                                    label="Código Postal"
                                    value={ houses.cp_casa || editHouse.cp_casa}
                                    onChange    ={ onChangeForm }
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                
                                <TextField  
                                    id="outlined-basic" 
                                    variant="outlined"
                                    name="correo_casa"
                                    label="Correo"
                                    fullWidth
                                    value={ houses.correo_casa || editHouse.correo_casa}
                                    onChange    ={ onChangeForm }
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    variant="outlined"
                                    type="text"
                                    name="imagen_principal_casa"
                                    label="ID Easy Broker"
                                    value={ houses.imagen_principal_casa || editHouse.imagen_principal_casa}
                                    onChange    ={ onChangeForm }
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />


                                <TextField  
                                    id="outlined-basic" 
                                    variant="outlined"
                                    name="telefono_cel_casa"
                                    type="number"
                                    label="Télefono Celular"
                                    value={ houses.telefono_cel_casa || editHouse.telefono_cel_casa}
                                    fullWidth
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    onChange    ={ onChangeForm }
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />

                                <FormControl fullWidth>
                                    <select 
                                        label="Estadop"
                                        name="id_estado"
                                        className="selectInput"
                                        onChange={handleEstado}
                                    >
                                        <option className="optionInput" selected >
                                            {editHouse.id_estado } 
                                            {
                                                editHouse.id_estado  ? null :
                                                'Cargando...'
                                            }
                                        </option>
                                        <option value="1">Aguascalientes</option>
                                        <option value="2">Baja California</option>
                                        <option value="3">Baja California Sur</option>
                                        <option value="4">Campeche</option>
                                        <option value="5">Coahuila</option>
                                        <option value="6">Colima</option>
                                        <option value="7">Chiapas</option>
                                        <option value="8">Chihuahua</option>
                                        <option value="9">Durango</option>
                                        <option value="10">Ciudad de México</option>
                                        <option value="11">Guanajuato</option>
                                        <option value="12">Guerrero</option>
                                        <option value="13">Hidalgo</option>
                                        <option value="14">Jalisco</option>
                                        <option value="15">Estado de México</option>
                                        <option value="16">Michoacán</option>
                                        <option value="17">Morelos</option>
                                        <option value="18">Nayarit</option>
                                        <option value="19">Nuevo León</option>
                                        <option value="20">Oaxaca</option>
                                        <option value="21">Puebla</option>
                                        <option value="22">Querétaro</option>
                                        <option value="23">Quintana Roo</option>
                                        <option value="24">San Luis Potosí</option>
                                        <option value="25">Sinaloa</option>
                                        <option value="26">Sonora</option>
                                        <option value="27">Tabasco</option>
                                        <option value="28">Tamaulipas</option>
                                        <option value="29">Tlaxcala</option>
                                        <option value="30">Veracruz</option>
                                        <option value="31">Yucatán</option>
                                        <option value="32">Zacatecas</option>
                                    </select>
                                </FormControl>

                            
                                
                        
                                <FormControl fullWidth>
                                    <select 
                                        label="tipo_casa"
                                        name="tipo_casa"
                                        className="selectInput"
                                        onChange={onChangeForm}
                                    >
                                        <option className="optionInput" selected >
                                            {editHouse.tipo_casa } 
                                            {
                                                editHouse.tipo_casa  ? null :
                                                'Cargando...'
                                            }
                                        </option>
                            
                                        {tipoCasaJson.map((tipo) => (
                                            <option
                                                key={tipo.value}
                                                value={tipo.value}
                                            >
                                            {tipo.name}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>

                            
                            </RowUser>
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                    
                                    <Button 
                                        variant     ="contained" 
                                        size        ="small" 
                                        type        ="submit"
                                        startIcon   ={<SaveIcon />}
                                    >
                                        Actualizar Datos Generales
                                    </Button>
                                </Stack>
                            </DivButtons>
                            
                        </Box>
                        :
                        <>
                            <TitleUser>Datos Generales</TitleUser>
                            <RowUser>
                                <ProgressBarData/>
                            </RowUser>
                        </>
                    }

                    {/* DATOS CASA */}
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={createHouseDetailGeneral}

                        >
                            <TitleUser>Datos  Casa</TitleUser>

                                    <RowUser>

                                        <FormControl fullWidth>
                                            <select 
                                                label="Recamaras"
                                                name="recamaras_casa_detalle"
                                                className="selectInput"
                                                onChange={onChangeFormGeneral}
                                            >
                                                {
                                                    editHouseGeneral.recamaras_casa_detalle ? 
                                                    <option className="optionInput" selected >
                                                        {editHouseGeneral.recamaras_casa_detalle === -1 ? 'No aplica' : editHouseGeneral.recamaras_casa_detalle  } 
                                                        {
                                                            editHouseGeneral.recamaras_casa_detalle ? ' Recamaras':
                                                            'Cargando...'
                                                        }
                                                    </option>
                                                    : <option> Selecciona Recamaras</option>

                                                }
                                                
                                                <option value="-1"> No aplica</option>
                                                <option value="1"> 1 Recamaras</option>
                                                <option value="2"> 2 Recamaras</option>
                                                <option value="3"> 3 Recamaras</option>
                                                <option value="4"> 4 Recamaras</option>
                                                <option value="5"> 5 Recamaras</option>
                                                <option value="6"> 6 Recamaras</option>
                                                <option value="7"> 7 Recamaras</option>
                                            </select>
                                        </FormControl>

                                        
                                        <FormControl fullWidth>
                                            <select 
                                                label="Banios"
                                                name="banios_casa_detalle"
                                                className="selectInput"
                                                onChange={onChangeFormGeneral}
                                            >
                                                {
                                                    editHouseGeneral.banios_casa_detalle ?
                                                      <option className="optionInput" selected >
                                                        {editHouseGeneral.banios_casa_detalle === -1 ? 'No aplica' : editHouseGeneral.banios_casa_detalle  } 
                                                        {
                                                            editHouseGeneral.banios_casa_detalle ? ' Baños':
                                                            'Cargando...'
                                                        }
                                                    </option>
                                                    : <option> Selecciona Baños</option>
                                                }
                                              
                                                <option value="-1"> No aplica</option>
                                                <option value="1"> 1 Baños</option>
                                                <option value="2"> 2 Baños</option>
                                                <option value="3"> 3 Baños</option>
                                                <option value="4"> 4 Baños</option>
                                                <option value="5"> 5 Baños</option>
                                                <option value="6"> 6 Baños</option>
                                                <option value="7"> 7 Baños</option>
                                            </select>
                                        </FormControl>

                                      
                                        <FormControl fullWidth>
                                            <select 
                                                label="mediosBanios"
                                                name="medio_banios_casa_detalle"
                                                className="selectInput"
                                                onChange={onChangeFormGeneral}
                                            >
                                                {
                                                    editHouseGeneral.medio_banios_casa_detalle ?
                                                    <option className="optionInput" selected >
                                                        {editHouseGeneral.medio_banios_casa_detalle === -1 ? 'No aplica' : editHouseGeneral.medio_banios_casa_detalle  } 
                                                        {
                                                            editHouseGeneral.medio_banios_casa_detalle ? 
                                                            editHouseGeneral.medio_banios_casa_detalle === 1 ? ' Medio Baño' : ' Medios Baños'
                                                            :
                                                            'Cargando...'
                                                        }
                                                    </option>
                                                    :<option> Selecciona Medios Baños</option>
                                                }
                                                


                                                <option value="-1"> No aplica</option>
                                                <option value="1"> 1 Medio Baño</option>
                                                <option value="2"> 2 Medio Baños</option>
                                                <option value="3"> 3 Medio Baños</option>
                                                <option value="4"> 4 Medio Baños</option>
                                                <option value="5"> 5 Medio Baños</option>
                                                <option value="6"> 6 Medio Baños</option>
                                                <option value="7"> 7 Medio Baños</option>
                                            </select>
                                        </FormControl>


                                        <FormControl fullWidth>
                                            <select 
                                                label="Estacionamiento"
                                                name="estacionamiento_casa_detalle"
                                                className="selectInput"
                                                onChange={onChangeFormGeneral}
                                            >
                                                {editHouseGeneral.estacionamiento_casa_detalle ?
                                                    <option className="optionInput" selected >
                                                        {editHouseGeneral.estacionamiento_casa_detalle === -1 ? 'No aplica' : editHouseGeneral.estacionamiento_casa_detalle  } 
                                                        {
                                                            editHouseGeneral.estacionamiento_casa_detalle ? 
                                                            editHouseGeneral.estacionamiento_casa_detalle === 1 ? ' Estacionamiento' : ' Estacionamientos'
                                                            :
                                                            'Cargando...'
                                                        }
                                                    </option>
                                                    : <option> Selecciona Estacionamientos</option>
                                                    
                                                }
                                                
                                                <option value="-1"> No aplica</option>
                                                <option value="1"> 1 Estacionamiento </option>
                                                <option value="2"> 2 Estacionamientos </option>
                                                <option value="3"> 3 Estacionamientos </option>
                                                <option value="4"> 4 Estacionamientos </option>
                                                <option value="5"> 5 Estacionamientos </option>
                                                <option value="6"> 6 Estacionamientos </option>
                                                <option value="7"> 7 Estacionamientos </option>
                                                <option value="8"> 8 Estacionamientos </option>
                                                <option value="9"> 9 Estacionamientos </option>
                                                <option value="10"> 10 Estacionamientos </option>
                                            </select>
                                        </FormControl>


                                        <FormControl fullWidth>
                                            <select 
                                                label="Sala"
                                                name="sala_casa_detalle"
                                                className="selectInput"
                                                onChange={onChangeFormGeneral}
                                            >
                                                {
                                                    editHouseGeneral.sala_casa_detalle ?
                                                    <option className="optionInput" selected >
                                                        {editHouseGeneral.sala_casa_detalle === -1 ? 'No aplica' : editHouseGeneral.sala_casa_detalle  } 
                                                        {
                                                            editHouseGeneral.sala_casa_detalle ? 
                                                            editHouseGeneral.sala_casa_detalle === 1 ? ' Sala' : ' Salas'
                                                            :
                                                            'Cargando...'
                                                        }
                                                    </option>
                                                    : <option> Selecciona Salas</option>
                                                }
                                                
                                                <option value="-1"> No aplica</option>
                                                <option value="1"> 1 Sala </option>
                                                <option value="2"> 2 Salas </option>
                                                <option value="3"> 3 Salas </option>
                                                <option value="4"> 4 Salas </option>
                                                <option value="5"> 5 Salas </option>
                                                <option value="6"> 6 Salas </option>
                                                <option value="7"> 7 Salas </option>
                                                <option value="8"> 8 Salas </option>
                                                <option value="9"> 9 Salas </option>
                                                <option value="10"> 10 Salas </option>
                                            </select>
                                        </FormControl>


                                        <FormControl fullWidth>
                                            <select 
                                                label="Comedor"
                                                name="comedor_casa_detalle"
                                                className="selectInput"
                                                onChange={onChangeFormGeneral}
                                            >
                                                {   
                                                    editHouseGeneral.comedor_casa_detalle ? 
                                                    <option className="optionInput" selected >
                                                        {editHouseGeneral.comedor_casa_detalle === -1 ? 'No aplica' : editHouseGeneral.comedor_casa_detalle  } 
                                                        {
                                                            editHouseGeneral.comedor_casa_detalle ? 
                                                            editHouseGeneral.comedor_casa_detalle === 1 ? ' Comedor' : ' Comedores'
                                                            :
                                                            'Cargando...'
                                                        }
                                                    </option>
                                                    : <option> Selecciona Comedores</option>
                                                
                                                
                                                }
                                              
                                                <option value="-1"> No aplica</option>
                                                <option value="1"> 1 Comedor </option>
                                                <option value="2"> 2 Comedores </option>
                                                <option value="3"> 3 Comedores </option>
                                                <option value="4"> 4 Comedores </option>
                                                <option value="5"> 5 Comedores </option>
                                                <option value="6"> 6 Comedores </option>
                                                <option value="7"> 7 Comedores </option>
                                                <option value="8"> 8 Comedores </option>
                                                <option value="9"> 9 Comedores </option>
                                                <option value="10"> 10 Comedores </option>
                                            </select>
                                        </FormControl>


                                        <FormControl fullWidth>
                                            <select 
                                                label="Cocina"
                                                name="cocina_casa_detalle"
                                                className="selectInput"
                                                onChange={onChangeFormGeneral}
                                            >
                                                {
                                                    editHouseGeneral.cocina_casa_detalle ?
                                                    <option className="optionInput" selected >
                                                        {editHouseGeneral.cocina_casa_detalle === -1 ? 'No aplica' : editHouseGeneral.cocina_casa_detalle  } 
                                                        {
                                                            editHouseGeneral.cocina_casa_detalle ? 
                                                            editHouseGeneral.cocina_casa_detalle === 1 ? ' Cocina' : ' Cocinas'
                                                            :
                                                            'Cargando...'
                                                        }
                                                    </option>
                                                    : <option> Selecciona Cocinas</option>

                                                }
                                               
                                                <option value="-1"> No aplica</option>
                                                <option value="1"> 1 Cocina </option>
                                                <option value="2"> 2 Cocinas </option>
                                                <option value="3"> 3 Cocinas </option>
                                                <option value="4"> 4 Cocinas </option>
                                                <option value="5"> 5 Cocinas </option>
                                                <option value="6"> 6 Cocinas </option>
                                                <option value="7"> 7 Cocinas </option>
                                                <option value="8"> 8 Cocinas </option>
                                                <option value="9"> 9 Cocinas </option>
                                                <option value="10"> 10 Cocinas </option>
                                            </select>
                                        </FormControl>


                                        <FormControl fullWidth>
                                            <select 
                                                label="Patio"
                                                name="patio_casa_detalle"
                                                className="selectInput"
                                                onChange={onChangeFormGeneral}
                                            >
                                                {
                                                    editHouseGeneral.patio_casa_detalle ? 
                                                    <option className="optionInput" selected >
                                                        {editHouseGeneral.patio_casa_detalle === -1 ? 'No aplica' : editHouseGeneral.patio_casa_detalle  } 
                                                        {
                                                            editHouseGeneral.patio_casa_detalle ? 
                                                            editHouseGeneral.patio_casa_detalle === 1 ? ' Patio' : ' Patios'
                                                            :
                                                            'Cargando...'
                                                        }
                                                    </option>
                                                    : <option> Selecciona Patios </option>
                                                }
                                                
                                                <option value="-1"> No aplica</option>
                                                <option value="1"> 1 Patio </option>
                                                <option value="2"> 2 Patios </option>
                                                <option value="3"> 3 Patios </option>
                                                <option value="4"> 4 Patios </option>
                                                <option value="5"> 5 Patios </option>
                                                <option value="6"> 6 Patios </option>
                                                <option value="7"> 7 Patios </option>
                                                <option value="8"> 8 Patios </option>
                                                <option value="9"> 9 Patios </option>
                                                <option value="10"> 10 Patios </option>
                                            </select>
                                        </FormControl>

                                    </RowUser>
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                
                                    <Button 
                                        variant="contained" 
                                        size="small" 
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                    >
                                        Actualizar Datos Casa
                                    </Button>
                                </Stack>
                            </DivButtons>
                        </Box>

                        {/* DATOS UBICACION */}
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={createHouseUbication}
                            
                        >
                            <TitleUser>Ubicación</TitleUser>

                                    <RowUser>
                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="referencia_casa_ubicacion"
                                            label           ="Referencia de la casa"
                                            value           ={ ubication.referencia_casa_ubicacion || editHouseUbication.referencia_casa_ubicacion}
                                            onChange        ={ onChangeFormUbication }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />
                                        <TextField  
                                            id          ="outlined-basic" 
                                            variant     ="outlined"
                                            label       ="Latitud de la casa"
                                            name        ="latitud_casa_ubicacion"
                                            value       ={ ubication.latitud_casa_ubicacion || editHouseUbication.latitud_casa_ubicacion}
                                            onChange    ={ onChangeFormUbication }
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />
                                        <TextField  
                                            id="outlined-basic" 
                                            
                                            variant="outlined"
                                            name="longitud_casa_ubicacion"
                                            label="Longitud de la casa"
                                            value       ={ ubication.longitud_casa_ubicacion || editHouseUbication.longitud_casa_ubicacion}
                                            onChange    ={ onChangeFormUbication }
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />
                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="ubicacion_casa_ubicacion"
                                            label           ="Ubicacion de la casa"
                                            value           ={ ubication.ubicacion_casa_ubicacion || editHouseUbication.ubicacion_casa_ubicacion}
                                            onChange        ={ onChangeFormUbication }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />
                                    </RowUser>
                                
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                
                                    <Button 
                                        variant="contained" 
                                        size="small" 
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                    >
                                        Actualizar Ubicación
                                    </Button>
                                </Stack>
                            </DivButtons>
                        </Box>


                        {/* DATOS Imagenes
                         <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={createHouseDetailGeneral}
                            
                        >
                            <TitleUser>Imagenes</TitleUser>

                                    <RowUser>
                                    </RowUser>
                                
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                
                                    <Button 
                                        variant="contained" 
                                        size="small" 
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                    >
                                        Actualizar Imagenes
                                    </Button>
                                </Stack>
                            </DivButtons>
                        </Box> */}

                          {/* DATOS IMAGENES */}
                        
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)':{},
                                        }}
                                        noValidate
                                        autoComplete="off"
                                        onSubmit={createImages}
                                        
                                    >
                                        <TitleUser>Imagenes</TitleUser>
                                        {
                                                realoadImage ? 
                                                <RowReload>
                                                    <ProgressBarReload title="Actualizando imagenes ..."/>
                                                </RowReload>
                                            :
                                        <RowUser>
                                           
                                            <ImageDiv>
                                                <TitleImage>Imagen Principal</TitleImage>
                                                <br/>
                                                <input 
                                                    type="file" 
                                                    multiple
                                                    name="2" 
                                                    accept="image/png, image/jpeg" 
                                                    onChange={ handleFileSelect }
                                                >
                                                </input>
                                                {
                                                    houseViewImage.imagen_2_casa_imagen ? 
                                                        <Image src={houseViewImage.imagen_2_casa_imagen}/>
                                                    :
                                                        <ImageNotFound>No hay imagen</ImageNotFound>
                                                }
                                            
                                                <Stack direction="row" spacing={2}>
                                                
                                                    <Button 
                                                        variant="contained" 
                                                        size="small" 
                                                        type="submit"
                                                        startIcon={<SaveIcon />}
                                                    >
                                                        Subir Imagen
                                                    </Button>
                                                </Stack>
                                            </ImageDiv>
                                            <ImageDiv>
                                                <TitleImage>Imagen Secundaria</TitleImage>
                                                <br/>
                                                <input 
                                                    type="file" 
                                                    multiple
                                                    name="3" 
                                                    accept="image/png, image/jpeg" 
                                                    onChange={ handleFileSelect }
                                                >
                                                </input>
                                                {
                                                    houseViewImage.imagen_3_casa_imagen ? 
                                                        <Image src={houseViewImage.imagen_3_casa_imagen}/>
                                                    :
                                                        <ImageNotFound>No hay imagen</ImageNotFound>
                                                }
                                            
                                                <Stack direction="row" spacing={2}>
                                                
                                                    <Button 
                                                        variant="contained" 
                                                        size="small" 
                                                        type="submit"
                                                        startIcon={<SaveIcon />}
                                                    >
                                                        Subir Imagen
                                                    </Button>
                                                </Stack>
                                            </ImageDiv>
                                            <ImageDiv>
                                                <TitleImage>Imagen Tercera</TitleImage>
                                                <br/>
                                                <input 
                                                    type="file" 
                                                    multiple
                                                    name="4" 
                                                    accept="image/png, image/jpeg" 
                                                    onChange={ handleFileSelect }
                                                >
                                                </input>
                                                {
                                                    houseViewImage.imagen_4_casa_imagen ? 
                                                        <Image src={houseViewImage.imagen_4_casa_imagen}/>
                                                    :
                                                        <ImageNotFound>No hay imagen</ImageNotFound>
                                                }
                                            
                                                <Stack direction="row" spacing={2}>
                                                
                                                    <Button 
                                                        variant="contained" 
                                                        size="small" 
                                                        type="submit"
                                                        startIcon={<SaveIcon />}
                                                    >
                                                        Subir Imagen
                                                    </Button>
                                                </Stack>
                                            </ImageDiv>
                                            
                                            <ImageDiv>
                                                <TitleImage>Imagen Cuarta</TitleImage>
                                                <br/>
                                                <input 
                                                    type="file" 
                                                    multiple
                                                    name="5" 
                                                    accept="image/png, image/jpeg" 
                                                    onChange={ handleFileSelect }
                                                >
                                                </input>
                                                {
                                                    houseViewImage.imagen_5_casa_imagen ? 
                                                        <Image src={houseViewImage.imagen_5_casa_imagen}/>
                                                    :
                                                        <ImageNotFound>No hay imagen</ImageNotFound>
                                                }
                                            
                                                <Stack direction="row" spacing={2}>
                                                
                                                    <Button 
                                                        variant="contained" 
                                                        size="small" 
                                                        type="submit"
                                                        startIcon={<SaveIcon />}
                                                    >
                                                        Subir Imagen
                                                    </Button>
                                                </Stack>
                                            </ImageDiv>
                                            
                                            <ImageDiv>
                                                <TitleImage>Imagen Quinta</TitleImage>
                                                <br/>
                                                <input 
                                                    type="file" 
                                                    multiple
                                                    name="6" 
                                                    accept="image/png, image/jpeg" 
                                                    onChange={ handleFileSelect }
                                                >
                                                </input>
                                                {
                                                    houseViewImage.imagen_6_casa_imagen ? 
                                                        <Image src={houseViewImage.imagen_6_casa_imagen}/>
                                                    :
                                                        <ImageNotFound>No hay imagen</ImageNotFound>
                                                }
                                            
                                                <Stack direction="row" spacing={2}>
                                                
                                                    <Button 
                                                        variant="contained" 
                                                        size="small" 
                                                        type="submit"
                                                        startIcon={<SaveIcon />}
                                                    >
                                                        Subir Imagen
                                                    </Button>
                                                </Stack>
                                            </ImageDiv>
                                            
                                            <ImageDiv>
                                                <TitleImage>Imagen Sexta</TitleImage>
                                                <br/>
                                                <input 
                                                    type="file" 
                                                    multiple
                                                    name="7" 
                                                    accept="image/png, image/jpeg" 
                                                    onChange={ handleFileSelect }
                                                >
                                                </input>
                                                {
                                                    houseViewImage.imagen_7_casa_imagen ? 
                                                        <Image src={houseViewImage.imagen_7_casa_imagen}/>
                                                    :
                                                        <ImageNotFound>No hay imagen</ImageNotFound>
                                                }
                                            
                                                <Stack direction="row" spacing={2}>
                                                
                                                    <Button 
                                                        variant="contained" 
                                                        size="small" 
                                                        type="submit"
                                                        startIcon={<SaveIcon />}
                                                    >
                                                        Subir Imagen
                                                    </Button>
                                                </Stack>
                                            </ImageDiv>
                                            
                                    
                                        </RowUser>
                                        }
                                        
                                            
                                        <DivButtons >
                                        
                                        </DivButtons>
                                    </Box>
                           

                         {/* DATOS EXTRAS */}
                         <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={createHouseExtra}
                            
                        >
                            <TitleUser>Extras</TitleUser>

                                    <RowUser>

                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="nombre_casa_extra"
                                            label           ="Nombre de casa extra"
                                            value           ={ extra.nombre_casa_extra  || editHouseExtra.nombre_casa_extra}
                                            onChange        ={ onChangeFormExtra }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />

                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="extra_dos_casa_extra"
                                            label           ="Extra dos de casa "
                                            value           ={ extra.extra_dos_casa_extra  || editHouseExtra.extra_dos_casa_extra}
                                            onChange        ={ onChangeFormExtra }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />

                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="tipo_casa_extra"
                                            label           ="Tipo de casa extra"
                                            value           ={ extra.tipo_casa_extra  || editHouseExtra.tipo_casa_extra}
                                            onChange        ={ onChangeFormExtra }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />

                                        <TextareaAutosize  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="descripcion_casa_extra"
                                            label           ="Tipo de casa extra"
                                            value           ={ extra.descripcion_casa_extra  || editHouseExtra.descripcion_casa_extra}
                                            onChange        ={ onChangeFormExtra }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            style={{ height: 100 }}
                                            fullWidth
                                        />
                                    </RowUser>
                                
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                
                                    <Button 
                                        variant="contained" 
                                        size="small" 
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                    >
                                        Actualizar Extras
                                    </Button>
                                </Stack>
                            </DivButtons>
                        </Box>

                         {/* DATOS Precio */}
                         <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={createHousePrice}
                            
                        >

        
                            <TitleUser>Precio</TitleUser>

                                    <RowUser>

                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            label           ="Precio total de la casa"
                                            name            ="precio_total_casa_precio"
                                            value           ={ price.precio_total_casa_precio  || editHousePrice.precio_total_casa_precio}
                                            onChange        ={ onChangeFormPrice }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                            
                                        />

                                        <FormControl fullWidth>
                                            <select 
                                                label="tipo_precio_casa_precio"
                                                name="tipo_precio_casa_precio"
                                                className="selectInput"
                                                onChange={onChangeFormPrice}
                                            >   
                                                 {price.tipo_precio_casa_precio } 
                                                {
                                                    price.tipo_precio_casa_precio ?
                                                    null
                                                    : 'Cargando...'
                                                }
                                            
                                                {monedaJson.map((tipo) => (
                                                    <option
                                                        key={tipo.value}
                                                        value={tipo.value}
                                                    >
                                                    {tipo.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </FormControl>

                                    
                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="descripcion_precio_casa_precio"    
                                            label           ="Descripcion de precio de la casa"
                                            value           ={ price.descripcion_precio_casa_precio  || editHousePrice.descripcion_precio_casa_precio}
                                            onChange        ={ onChangeFormPrice }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />

                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="precio_m2_casa_precio"
                                            label           ="Precio por metro cuadrado de la casa"
                                            value           ={ price.precio_m2_casa_precio  || editHousePrice.precio_m2_casa_precio}
                                            onChange        ={ onChangeFormPrice }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />

                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="precio_terreno_casa_precio"
                                            label           ="Precio del terreno de la casa"
                                            value           ={ price.precio_terreno_casa_precio  || editHousePrice.precio_terreno_casa_precio}
                                            onChange        ={ onChangeFormPrice }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />

                                        <form className={classes.container} noValidate>
                                            <TextField
                                                id="anio_construccion_casa_precio"
                                                name="anio_construccion_casa_precio"
                                                label="Ingrese el año de construcción"
                                                type="date"
                                                defaultValue={Date.now()}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                value       = { price.anio_construccion_casa_precio  || editHousePrice.anio_construccion_casa_precio}
                                                onChange    = { onChangeFormPrice }
                                                fullWidth
                                            />
                                        </form>
                                       

                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="metros_construidos_casa_precio"
                                            label           ="Metros construidos de la casa"
                                            value           ={ price.metros_construidos_casa_precio  || editHousePrice.metros_construidos_casa_precio}
                                            onChange        ={ onChangeFormPrice }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />

                                        <TextField  
                                            id              ="outlined-basic" 
                                            variant         ="outlined"
                                            name            ="metros_terreno_casa_precio"
                                            label           ="Metros de terreno de la casa"
                                            value           ={ price.metros_terreno_casa_precio  || editHousePrice.metros_terreno_casa_precio}
                                            onChange        ={ onChangeFormPrice }
                                            InputLabelProps ={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />

                                    </RowUser>
                                
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                
                                    <Button 
                                        variant="contained" 
                                        size="small" 
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                    >
                                        Actualizar Precio
                                    </Button>
                                </Stack>
                            </DivButtons>
                        </Box>

                    </>
            {
                houseUpdateSuccess ?
                    <Navigate to='/casas/actualizada/exitoso' />
                :
                null
            }
            
            
        </DivCenter>
     );
}
 
export default EditHouse;