import React,{ useEffect } from 'react'
import Button                       from '@mui/material/Button';
import AddCircleIcon                from '@mui/icons-material/AddCircle';
import AutorenewIcon                from '@mui/icons-material/Autorenew';
import ProgressBar                  from '../Progress/ProgressBar.component';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { 
    ColumnsClientsSales,
    ColumnsClientsVisualize,
    ColumnsClientsSalesLider 
}                                   from '../../utils/rowData';
import{ 
        createClientInitFn, 
        getAllClientsByTeamAction 
    }                               from '../../actions/clientActions';
import{    
        TitleAndButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';
import { 
    DivGridSpecialist
} from './Clients.styles';

import { 
    DivButtonUpdate
}                                   from './Clients.styles';


const ClientsSpecialist = () => {


    const dispatch          = useDispatch();
    const addCreateInit     = (client) => dispatch( createClientInitFn(client) );
    const navigate          = useNavigate();
    const clientSuccess     = useSelector( state => state.clientes?.clientesEquipo);
    const loading           = useSelector( state => state.clientes?.loading);
    const id_team           = useSelector( state => state.login?.user?.id_equipo );
    const id_rol            = useSelector( state => state.login?.user?.rol);

    
    function loadGrid(){
        if( id_team){
            const loadingClientsByTeam = () => dispatch( getAllClientsByTeamAction(id_team) );
            loadingClientsByTeam();
        }else{
            let id_team_token = JSON.parse(localStorage.getItem('user'));
            const loadingClientsByTeam = () => dispatch( getAllClientsByTeamAction(id_team_token.equipo) );
            loadingClientsByTeam();
        }
    }

    // USE EFFECT LOADER
    useEffect( () => {
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    // Onclick User Init action
    function newClient() {
        addCreateInit(false);
        navigate('/clientes/crear');
    }

    function updateGrid(){
        loadGrid();
    }

    return ( 
        <>
              {
                loading ? 
                    <ProgressBar title="Cargando Clientes..."/>
                :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Clientes</TitleUserGrid>

                            <DivButtonUpdate>
                                <Button
                                    variant="contained"
                                    onClick={updateGrid}
                                    startIcon={<AutorenewIcon />}
                                    size="small"
                                    color="secondary"
                                    style={{
                                        marginRight:'10px'
                                    }}
                                >
                                    Actualizar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={newClient}
                                    startIcon={<AddCircleIcon />}
                                    size="small"
                                >
                                    Crear Cliente
                                </Button>
                            </DivButtonUpdate>
                        </TitleAndButton>

                        {
                            [5].includes(id_rol) ?  
                                <DivGridSpecialist>
                                    <DataGrid
                                        rows                ={clientSuccess}
                                        columns             ={ColumnsClientsVisualize}
                                        pageSize            ={11}
                                        rowsPerPageOptions  ={[11]}
                                        getRowId            ={clientSuccess => clientSuccess.id_cliente}
                                    />
                                </DivGridSpecialist>
                            :
                                null
                        }
                        {
                            [2].includes(id_rol) ?  
                                <DivGridSpecialist>
                                    <DataGrid
                                        rows                ={clientSuccess}
                                        columns             ={ColumnsClientsSales}
                                        pageSize            ={11}
                                        rowsPerPageOptions  ={[11]}
                                        getRowId            ={clientSuccess => clientSuccess.id_cliente}
                                    />
                                </DivGridSpecialist>
                            :
                                null
                        }
                        {
                            [6,1].includes(id_rol) ?  
                                <DivGridSpecialist>
                                    <DataGrid
                                        rows                ={clientSuccess}
                                        columns             ={ColumnsClientsSalesLider}
                                        pageSize            ={11}
                                        rowsPerPageOptions  ={[11]}
                                        getRowId            ={clientSuccess => clientSuccess.id_cliente}
                                    />
                                </DivGridSpecialist>
                            :
                                null
                        }
                </>
            }
        </>
     );
}
 
export default ClientsSpecialist;