import React, { useState,useEffect }            from 'react'
import { useNavigate, Navigate, useParams }     from 'react-router-dom';
import ProgressBar                              from '../Progress/ProgressBar.component';
import { useDispatch, useSelector }             from 'react-redux';
import { SweetAlertBasic }                      from '../../utils/sweetAlert';
import Box                                      from '@mui/material/Box';
import TextField                                from '@mui/material/TextField';
import InputLabel                               from '@mui/material/InputLabel';
import MenuItem                                 from '@mui/material/MenuItem';
import Select                                   from '@mui/material/Select';
import FormControl                              from '@mui/material/FormControl';
import Button                                   from '@mui/material/Button';
import SaveIcon                                 from '@mui/icons-material/Save';
import Stack                                    from '@mui/material/Stack';
import AdapterDateFns                           from '@mui/lab/AdapterDateFns';
import LocalizationProvider                     from '@mui/lab/LocalizationProvider';
import DatePicker                               from '@mui/lab/DatePicker';
import{  
    getUserAction,
    updateUserAction,
    updatePasswordAction
}                                               from '../../actions/userActions';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
}                                               from './Users.styles';


const NewUser = () => {

    const dispatch          = useDispatch();
    const navigate          = useNavigate();
    const { id_usuario }    = useParams();
    const loading           = useSelector( state => state.usuarios?.loading);
    const loadingUpdate     = useSelector( state => state.usuarios?.loadingUpdate);
    const userUpdateSuccess = useSelector( state => state.usuarios?.flagUpdate);
    const teamSuccess       = useSelector( state => state.equipos?.equipos);
    const editUser          = useSelector( state => state.usuarios?.editarUsuario || {} );
    const loadingUser       = () => dispatch( getUserAction(id_usuario) );
    const updateUserInit    = (user) => dispatch( updateUserAction( user, id_usuario ) );
    const updatePasswordInit= (user) => dispatch( updatePasswordAction( user ) );

    const [usuarios, setUsuarios] = useState({
        nombre_usuario              : '',
        apellido_materno_usuario    : '',
        apellido_paterno_usuario    : '',
        curp_usuario                : '',
        email_usuario               : '',
        estado_civil_usuario        : '',
        fecha_baja_usuario          : '',
        fecha_ingreso_usuario       : '',
        fecha_nacimiento_usuario    : '',
        id_equipo                   : '',
        id_rol                      : '',
        nss_usuario                 : '',
        sexo_usuario                : '',
        telefono_usuario            : '',
    });

    const [ passwordUsuario, setPasswordUsuario ]               = useState('');
    const [ repeatPasswordUsuario, setRepeatPasswordUsuario ]   = useState('');
    

    useEffect( () => {
        loadingUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );


    function goUsers() {
        navigate('/usuarios');
    }

    // SELECTS
    const handleRol = (event) => {
        setUsuarios({
            [event.target.name]: event.target.value
        });
    };

    const handleSexoUsuario = (event) => {
        setUsuarios({
            [event.target.name]: event.target.value
        });
    };

    const handleEstadoCivil = (event) => {
        setUsuarios({
            [event.target.name]: event.target.value
        });
    };

    // Change Value TextField
    const onChangeForm = ( event ) => {
        setUsuarios({
            ...usuarios,
            [event.target.name]: event.target.value
        })
    }
    
    const updateUser = e => {
        e.preventDefault();
       
        // Quita los valores vacios ""
        let dataUser = Object.entries(usuarios).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
     
        if( nombre_usuario.trim() === '' || telefono_usuario.length === 0 || email_usuario.trim() === ''){
            SweetAlertBasic("error","Ups","Unos campos son obligatorios");
            return;
        }

        updateUserInit(dataUser, id_usuario);

    }

    //UpdatePassword
    const updatePassword = e => {

        e.preventDefault();
        if ( (passwordUsuario.trim() === '' || repeatPasswordUsuario.trim() === '') || ( passwordUsuario !== repeatPasswordUsuario) ) {
            SweetAlertBasic("error","Ups","El password no coincide");
            return;
        }

        // Update Password
        updatePasswordInit({
            id_usuario: id_usuario,
            new_password: passwordUsuario,
        });
    }
    
    const{ 
            apellido_materno_usuario,
            apellido_paterno_usuario,
            curp_usuario,
            email_usuario,
            estado_civil_usuario,
            id_equipo,
            id_rol,
            nombre_usuario,
            nss_usuario,
            sexo_usuario,
            telefono_usuario,
    } = editUser;

    return ( 
        <DivCenter >
            {  
                // UPDATE USER
                loadingUpdate ?
                        // LOADING USER
                    loading ? 
                    <ProgressBar title="Cargando Usuario..."/>
                :
                    <div>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={updateUser}
                            
                        >
                            <TitleUser>Editar usuario</TitleUser>

                                    <RowUser>
                                        <TextField  
                                            id          = "outlined-basic" 
                                            label       = "Ingresa el nombre del usuario *" 
                                            variant     = "outlined"
                                            type        = "text"
                                            name        = "nombre_usuario"
                                            defaultValue={nombre_usuario}
                                            onChange    = { onChangeForm }
                                            fullWidth
                                        />
                                        <TextField  
                                            id          ="outlined-basic" 
                                            label       ="Ingresa el apellido paterno del usuario" 
                                            variant     ="outlined"
                                            name        ="apellido_paterno_usuario"
                                            defaultValue={apellido_paterno_usuario}
                                            onChange    ={ onChangeForm }
                                            fullWidth
                                        />
                                        <TextField  
                                            id          ="outlined-basic" 
                                            label       ="Ingresa el apellido materno del usuario" 
                                            variant     ="outlined"
                                            name        ="apellido_materno_usuario"
                                            defaultValue={apellido_materno_usuario}
                                            onChange    ={ onChangeForm }
                                            fullWidth
                                        />
                                
                                        <TextField  
                                            id          ="outlined-basic" 
                                            label       ="Ingresa el telefono del usuario" 
                                            variant     ="outlined"
                                            name        ="telefono_usuario"
                                            defaultValue={telefono_usuario}
                                            type        ="number"
                                            onInput = {(e) =>{
                                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                            }}
                                            onChange={ onChangeForm }
                                            fullWidth
                                        />

                                        <TextField  
                                            id          ="outlined-basic" 
                                            label       ="Ingresa el email del usuario" 
                                            variant     ="outlined"
                                            name        ="email_usuario"
                                            defaultValue={email_usuario}
                                            onChange    ={ onChangeForm }
                                            fullWidth
                                        />
                                
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                            <Select
                                                labelId     ="equipo_rol"
                                                id          ="demo-simple-select"
                                                label       ="Rol"
                                                name        ="id_rol"
                                                defaultValue={id_rol}
                                                onChange={handleRol}
                                            >
                                                <MenuItem value={1}>Administrativos</MenuItem>
                                                <MenuItem value={2}>Especialista</MenuItem>
                                                <MenuItem value={3}>Inventario</MenuItem>
                                                <MenuItem value={4}>RH</MenuItem>
                                                <MenuItem value={5}>Recepcion</MenuItem>
                                                <MenuItem value={6}>Lider de Equipo</MenuItem>
                                                <MenuItem value={7}>Contratos</MenuItem>
                                                <MenuItem value={8}>Marketing</MenuItem>
                                            </Select>
                                        </FormControl>


                                
                                        {
                                            teamSuccess ? 
                                            <>
                                            <FormControl >
                                                <InputLabel id="demo-controlled-open-select-label">Equipo</InputLabel>
                                                <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                name="id_equipo"
                                                label="Equipo"
                                                defaultValue={id_equipo}
                                                onChange={onChangeForm}
                                                >
                                                {teamSuccess.map((team) => (
                                                    <MenuItem
                                                        key={team.id_equipo}
                                                        value={team.id_equipo}
                                                    >
                                                    {team.nombre_equipo}
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                            </>
                                            :
                                            null

                                        }

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                                            <Select
                                                labelId     ="sexo_usuario"
                                                id          ="demo-simple-select"
                                                label       ="Sexo"
                                                name        ="sexo_usuario"
                                                defaultValue={sexo_usuario  || "" }
                                                onChange    ={handleSexoUsuario}
                                            >
                                                <MenuItem value={1}>Masculino</MenuItem>
                                                <MenuItem value={2}>Femenino</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Estado Civil</InputLabel>
                                            <Select
                                                labelId     ="estado_civil_usuario"
                                                id          ="demo-simple-select"
                                                name        ="estado_civil_usuario"
                                                label       ="Estado Civil"
                                                defaultValue={estado_civil_usuario  || ""}
                                                onChange    ={handleEstadoCivil}
                                            >
                                                <MenuItem value={"Soltero"}>Soltero</MenuItem>
                                                <MenuItem value={"Casado"}>Casado</MenuItem>
                                                <MenuItem value={"Viudo"}>Viudo</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField  
                                            id          ="outlined-basic" 
                                            label       ="Ingresa el curp del usuario" 
                                            variant     ="outlined"
                                            name        ="curp_usuario"
                                            defaultValue={curp_usuario}
                                            onChange    ={ onChangeForm }
                                            fullWidth
                                        />

                                        <TextField  
                                            id          ="outlined-basic" 
                                            label       ="Ingresa el nss del usuario" 
                                            variant     ="outlined"
                                            name        ="nss_usuario"
                                            defaultValue={nss_usuario}
                                            onChange    ={ onChangeForm }
                                            fullWidth
                                        />

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack>
                                            <DatePicker
                                                id          = "demo-simple-select"
                                                views       ={ ['day', 'month', 'year'] }
                                                label       ="Fecha de nacimiento"
                                                onChange    ={(newValue) => {
                                                    setUsuarios({
                                                        fecha_nacimiento_usuario: newValue
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText="Ingrese la fecha dando clic en calendario" />}
                                            />
                                            </Stack>
                                        </LocalizationProvider>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack>
                                            <DatePicker
                                                id          = "demo-simple-select"
                                                views       ={ ['day', 'month', 'year'] }
                                                label       ="Fecha de Baja"
                                                onChange={(newValue) => {
                                                    setUsuarios({
                                                        fecha_baja_usuario: newValue
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText="Ingrese la fecha dando clic en calendario" />}
                                            />
                                            </Stack>
                                        </LocalizationProvider>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack>
                                                <DatePicker
                                                    views       = {['day', 'month', 'year']}
                                                    label       = "Fecha de Ingreso"
                                                    onChange    ={(newValue) => {
                                                        setUsuarios({
                                                            fecha_ingreso_usuario: newValue
                                                        });
                                                    }}
                                                    renderInput={(params) => <TextField {...params} helperText="Ingrese la fecha dando clic en calendario" />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>


                                    </RowUser>
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        onClick ={goUsers}
                                        variant ="contained" 
                                        size    ="large"
                                        color   ="error"
                                    >
                                        Cancelar
                                    </Button>
                                
                                    <Button 
                                        variant     ="contained" 
                                        size        ="small" 
                                        type        ="submit"
                                        startIcon   ={<SaveIcon />}
                                    >
                                        Actualizar Usuario
                                    </Button>
                                </Stack>
                            </DivButtons>
                            
                        </Box>
                        <Box
                                component="form"
                                sx={{
                                    '& > :not(style)':{},
                                }}
                                noValidate
                                autoComplete="off"
                                onSubmit={updatePassword}
                                
                            >
                            <TitleUser>Actualizar contraseña</TitleUser>

                                    <RowUser>
                                    <TextField  
                                        id="outlined-basic" 
                                        label="Ingresa el password del usuario" 
                                        variant="outlined"
                                        name="passwordUsuario"
                                        value={passwordUsuario}
                                        onChange={ e => setPasswordUsuario(e.target.value) }
                                        fullWidth
                                    />  
                                    
                                    <TextField  
                                        id="outlined-basic" 
                                        label="Ingresa nuevamente el password del usuario" 
                                        variant="outlined"
                                        name="repeatPasswordUsuario"
                                        value={repeatPasswordUsuario}
                                        onChange={ e => setRepeatPasswordUsuario(e.target.value) }
                                        fullWidth
                                    />   

                                    </RowUser>
                            <DivButtons >
                                <Stack direction="row" spacing={2}>
                                    <Button 
                                        variant     ="contained" 
                                        size        ="small" 
                                        type        ="submit"
                                        startIcon   ={<SaveIcon />}
                                    >
                                        Actualizar Password
                                    </Button>
                                </Stack>
                            </DivButtons>
                        </Box>
                    </div>
                    :
                    <ProgressBar title="Actualizando Usuario..."/>       
                   
            }
            {
                userUpdateSuccess ?
                    <Navigate to='/usuario/actualizado/exitoso' />
                :
                null
            }
            
            
        </DivCenter>
     );
}
 
export default NewUser;