import clientAxios                  from '../config/axios';
import imageAxiosAxios              from '../config/image';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    HOUSE_CREATE,
    HOUSE_CREATE_SUCCESS,
    HOUSE_CREATE_ERROR,
    HOUSE_CREATE_INIT,
    HOUSES_LOADING,
    HOUSES_LOADING_SUCCESS,
    HOUSES_LOADING_ERROR,
    HOUSE_DESACTIVE,
    HOUSE_DESACTIVE_ERROR,
    HOUSE_LOAD,
    HOUSE_LOAD_SUCCESS,
    HOUSE_LOAD_ERROR,
    HOUSE_UPDATE_SUCCESS,
    HOUSE_UPDATE_ERROR,
    HOUSE_UPDATE_INIT,
    HOUSE_CREATE_SUCCESS_UBICATION,
    HOUSE_CREATE_UBICATION,
    HOUSE_CREATE_ERROR_UBICATION,
    HOUSE_LOAD_UBICATION,
    HOUSE_LOAD_SUCCESS_UBICATION,
    HOUSE_LOAD_ERROR_UBICATION,
    HOUSE_CREATE_SUCCESS_EXTRA,
    HOUSE_CREATE_EXTRA,
    HOUSE_CREATE_ERROR_EXTRA,
    HOUSE_LOAD_EXTRA,
    HOUSE_LOAD_SUCCESS_EXTRA,
    HOUSE_LOAD_ERROR_EXTRA,
    HOUSE_CREATE_SUCCESS_PRICE,
    HOUSE_CREATE_PRICE,
    HOUSE_CREATE_ERROR_PRICE,
    HOUSE_LOAD_PRICE,
    HOUSE_LOAD_SUCCESS_PRICE,
    HOUSE_LOAD_ERROR_PRICE,
    HOUSE_CREATE_SUCCESS_GENERAL,
    HOUSE_CREATE_GENERAL,
    HOUSE_CREATE_ERROR_GENERAL,
    HOUSE_LOAD_GENERAL,
    HOUSE_LOAD_SUCCESS_GENERAL,
    HOUSE_LOAD_ERROR_GENERAL,
    HOUSE_CREATE_IMAGE,
    HOUSE_CREATE_SUCCESS_IMAGE,
    HOUSE_CREATE_ERROR_IMAGE,
    HOUSE_LOAD_IMAGE,
    HOUSE_LOAD_SUCCESS_IMAGE,
    HOUSE_LOAD_ERROR_IMAGE,
    OPTION_TEAM_LOAD_SUCCESS
} from '../types';



/* Create HOUSE*/ 
    export function createHouseAction(house) {

        return async ( dispatch ) => {
            
            dispatch( createHouse() );

            try{
                let houseSuccess = await clientAxios.post('/casas/crear/', house);
                
                if(houseSuccess.data.status) {
                    await clientAxios.post('/casa_extra/crear/',       { id_casa: houseSuccess.data.id_casa, nombre_casa_extra: "ID: " + houseSuccess.data.id_casa } );
                    await clientAxios.post('/casa_detalle/crear/',     { id_casa: houseSuccess.data.id_casa, nombre_casa_detalle: "ID: " + houseSuccess.data.id_casa } );
                    await clientAxios.post('/casa_precio/crear/',      { id_casa: houseSuccess.data.id_casa, precio_total_casa_precio: 0, metros_construidos_casa_precio: 0,metros_terreno_casa_precio: 0 } );
                    await clientAxios.post('/casa_ubicacion/crear/',    { id_casa: houseSuccess.data.id_casa, latitud_casa_ubicacion:"19.432343165444294", longitud_casa_ubicacion: "-99.13295619816144" });
                    dispatch( createHouseSuccess(house, houseSuccess.data.id_casa) );
                }
                
            }catch( error ){
                dispatch( createHouseError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el casas");
            }
        }

    }

    // Create House init action
    export function createHouseInitFn(flag){
        return ( dispatch ) => {
            dispatch( createHouseInit(flag));
        }
    }

    const createHouse = ( ) => ({
        type    : HOUSE_CREATE
    });

    const createHouseSuccess = (house, id) => ({
        type    : HOUSE_CREATE_SUCCESS,
        payload : house,
        flag    : true,
        idCasa  :  id 
    })

    const createHouseError = () => ({
        type    : HOUSE_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createHouseInit = (flag) =>({
        type    : HOUSE_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* GRID HOUSE */
    export function getAllHousesAction() {
        return async ( dispatch ) => {
            dispatch( downloadHouse() );

            try{
                const responseHouses = await clientAxios.get('/casas' );
                dispatch( downloadHouseSuccess (responseHouses.data.data) );
                dispatch( loadTeamOptionSuccess () );

            }catch( error ){
                dispatch( downloadHouseError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los casas");   
            }
        }
    }

    const downloadHouse = () => ({
        type: HOUSES_LOADING,
        payload: true
    })

    const downloadHouseSuccess = ( houses ) => ({
        type: HOUSES_LOADING_SUCCESS,
        payload: houses
    })

    const downloadHouseError = (error) => ({
        type: HOUSES_LOADING_ERROR,
        error: error
    });

    const loadTeamOptionSuccess = () => ({
        type: OPTION_TEAM_LOAD_SUCCESS,
        opcionEquipo: [],
        opcionUsuario: [],
        opcionCliente:[],
        flagViewOpcionesTeam: false,
    })

/* DESACTIVE HOUSE */
    export const desactiveHouseAction = (id) => {

        return async (dispatch)=>{
            dispatch( getHouseDesactive(id) );

            try{
                let response = await clientAxios.post(`/casas/change-active/?id_casa=${id}`);
              
                // ALL HOUSES UPDATE ACTIVE
                if(response.status === 200){
                    dispatch( downloadHouse() );
                    let responseHousesActive = await clientAxios.get('/casas', );
                    dispatch( downloadHouseSuccess (responseHousesActive.data.data) );
                }
                
            }catch( error ){
                dispatch( desactiveHouseError() );
                SweetAlertBasic("error","Ups","Hubo un error al desactivar/activar el casas");  
            }
        }

    }

    const getHouseDesactive = (id)=>({
        type: HOUSE_DESACTIVE,
        payload: id
    })

    const desactiveHouseError = ( ) =>({
        type: HOUSE_DESACTIVE_ERROR,
        error: true,
        flag: false
    }) 

/* EDIT HOUSE */
    export const getHouseAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadHouse() );

            try{
                let response = await clientAxios.get(`/casas/${id}`);
                dispatch( loadHouseSuccess (response.data.data) );
            }catch( error ){
                dispatch( loadHouseError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar la casa");  
            }
        }   
    }

    const getLoadHouse = () => ({
        type: HOUSE_LOAD,
        payload: true
    })

    const loadHouseSuccess = (house) => ({
        type: HOUSE_LOAD_SUCCESS,
        editarCasa: house
    })

    const loadHouseError = () => ({
        type: HOUSE_LOAD_ERROR,
        error: true
    })
    

/* UPDATE HOUSE */
    export const updateHouseAction = (house,id) => {
       
        return async (dispatch)=>{
            dispatch( updateHouseInit(house) );

            try{
                await clientAxios.put(`/casas/${id}/`, house);
                dispatch( updateHouseSuccess(house) );

            }catch( error ){
                dispatch( updateHouseError() );
                SweetAlertBasic("error","Ups",`Hubo un error al actualizar el casas ${error}`);
            }
        }

    }

    const updateHouseInit = () => ({
        type: HOUSE_UPDATE_INIT,
        payload: true
    })

    const updateHouseSuccess = (house)=>({
        type: HOUSE_UPDATE_SUCCESS,
        casasActualizado: house
    })

    const updateHouseError = ( ) =>({
        type: HOUSE_UPDATE_ERROR,
        error: true,
        flag: false
    }) 

/*************************************************************************************************/
/*                                     CASA UBICACION                                            */
/*************************************************************************************************/

    /* Update HOUSE UBICATION*/ 
    export function createHouseUbicationAction(houseUbication, id) {

        return async ( dispatch ) => {
            
            dispatch( createHouseUbication() );

            try{
                let responseUbication = await clientAxios.put(`/casa_ubicacion/${id}/`, houseUbication);
                console.log(responseUbication);
                dispatch( createHouseUbicationSuccess(houseUbication) );

            }catch( error ){
                dispatch( createHouseUbicationError() );
                SweetAlertBasic("error","Ups","Hubo un error al actualizar la ubicación de la casa");
            }
        }
    }

  
    const createHouseUbication = ( ) => ({
        type    : HOUSE_CREATE_UBICATION
    });

    const createHouseUbicationSuccess = (houseUbication) => ({
        type            : HOUSE_CREATE_SUCCESS_UBICATION,
        casaUbicacion   : houseUbication,
        flag            : true
    });

    const createHouseUbicationError = () => ({
        type    : HOUSE_CREATE_ERROR_UBICATION,
        error   : true,
        flag    : false
    });

    /* EDIT HOUSE UBICACION */ 
    export const getHouseUbicationAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadHouseUbication() );

            try{
                let response = await clientAxios.get(`/casa_ubicacion/${id}`);
                dispatch( loadHouseUbicationSuccess (response.data.data) );
            }catch( error ){
                dispatch( loadHouseUbicationError() );
                // SweetAlertBasic("error","Ups","Hubo un error al cargar la ubicacion de la casa");  
            }
        }   
    }

    const getLoadHouseUbication = () => ({
        type: HOUSE_LOAD_UBICATION,
        payload: true
    })

    const loadHouseUbicationSuccess = (ubication) => ({
        type                : HOUSE_LOAD_SUCCESS_UBICATION,
        editarCasaUbicacion : ubication
    })

    const loadHouseUbicationError = () => ({
        type: HOUSE_LOAD_ERROR_UBICATION,
        error: true
    })


/*************************************************************************************************/
/*                                     CASA EXTRA                                            */
/*************************************************************************************************/

    /* UPDATE HOUSE EXTRA*/ 
    export function createHouseExtraAction(houseExtra, id) {
       
        return async ( dispatch ) => {
            
            dispatch( createHouseExtra() );

            try{
                let responseExtra = await clientAxios.put(`/casa_extra/${id}/`, houseExtra);
                console.log(responseExtra);
                dispatch( createHouseExtraSuccess(houseExtra) );

            }catch( error ){
                dispatch( createHouseExtraError() );
                SweetAlertBasic("error","Ups","Hubo un errora al actualizar la casa extra de la casa");
            }
        }
    }

  
    const createHouseExtra = ( ) => ({
        type    : HOUSE_CREATE_EXTRA
    });

    const createHouseExtraSuccess = (houseExtra) => ({
        type            : HOUSE_CREATE_SUCCESS_EXTRA,
        casaExtra       : houseExtra,
        flag            : true
    });

    const createHouseExtraError = () => ({
        type    : HOUSE_CREATE_ERROR_EXTRA,
        error   : true,
        flag    : false
    });

    /* EDIT HOUSE EXTRA */ 
    export const getHouseExtraAction = ( id ) =>{
        return async (dispatch)=>{
            dispatch( getLoadHouseExtra() );

            try{
                let response = await clientAxios.get(`/casa_extra/${id}`);
                dispatch( loadHouseExtraSuccess (response.data.data) );
            }catch( error ){
                dispatch( loadHouseExtraError() );
                // SweetAlertBasic("error","Ups","Hubo un error al cargar la ubicacion de la casa");  
            }
        }   
    }

    const getLoadHouseExtra = () => ({
        type: HOUSE_LOAD_EXTRA,
        payload: true
    })

    const loadHouseExtraSuccess = (extra) => ({
        type                : HOUSE_LOAD_SUCCESS_EXTRA,
        editarCasaExtra     : extra
    })

    const loadHouseExtraError = () => ({
        type: HOUSE_LOAD_ERROR_EXTRA,
        error: true
    })

/*************************************************************************************************/
/*                                     CASA PRECIO                                            */
/*************************************************************************************************/

    /* Update HOUSE PRICE*/ 
    export function createHousePriceAction(housePrice,id) {

        return async ( dispatch ) => {
            
            dispatch( createHousePrice() );

            try{
                let responsePrecio = await clientAxios.put(`/casa_precio/${id}/`, housePrice);
                console.log(responsePrecio)
                dispatch( createHousePriceSuccess(housePrice) );

            }catch( error ){
                dispatch( createHousePriceError() );
                SweetAlertBasic("error","Ups","Hubo un error al actualizar los precios de la casa");
            }
        }
    }

  
    const createHousePrice = ( ) => ({
        type    : HOUSE_CREATE_PRICE
    });

    const createHousePriceSuccess = (housePrice) => ({
        type            : HOUSE_CREATE_SUCCESS_PRICE,
        casaPrecio      : housePrice,
        flag            : true
    });

    const createHousePriceError = () => ({
        type    : HOUSE_CREATE_ERROR_PRICE,
        error   : true,
        flag    : false
    });

    /* EDIT HOUSE PRICE */ 
    export const getHousePriceAction = ( id ) =>{
        return async (dispatch)=>{
            dispatch( getLoadHousePrice() );

            try{
                let response = await clientAxios.get(`/casa_precio/${id}`);
                dispatch( loadHousePriceSuccess (response.data.data) );
            }catch( error ){
                dispatch( loadHousePriceError() );
                // SweetAlertBasic("error","Ups","Hubo un error al cargar la ubicacion de la casa");  
            }
        }   
    }

    const getLoadHousePrice = () => ({
        type: HOUSE_LOAD_PRICE,
        payload: true
    })

    const loadHousePriceSuccess = (extra) => ({
        type                : HOUSE_LOAD_SUCCESS_PRICE,
        editarCasaPrecio    : extra
    })

    const loadHousePriceError = () => ({
        type: HOUSE_LOAD_ERROR_PRICE,
        error: true
    })


    
/*************************************************************************************************/
/*                                     CASA GENERAL                                            */
/*************************************************************************************************/

    /* Create HOUSE GENERAL*/ 
    export function createHouseGeneralAction(houseGeneral, id) {
        console.log(houseGeneral)
        return async ( dispatch ) => {
            
            dispatch( createHouseGeneral() );

            try{
                let response = await clientAxios.put(`/casa_detalle/${id}/`, houseGeneral);
                console.log(response);
                dispatch( createHouseGeneralSuccess(houseGeneral) );

            }catch( error ){
                dispatch( createHouseGeneralError() );
                SweetAlertBasic("error","Ups","Hubo un errora al actualizar los datos generales de la casa");
            }
        }
    }

  
      
    const createHouseGeneral = ( ) => ({
        type    : HOUSE_CREATE_GENERAL
    });

    const createHouseGeneralSuccess = (houseGeneral) => ({
        type            : HOUSE_CREATE_SUCCESS_GENERAL,
        casaGeneral     : houseGeneral,
        flag            : true
    });

    const createHouseGeneralError = () => ({
        type    : HOUSE_CREATE_ERROR_GENERAL,
        error   : true,
        flag    : false
    });

    /* EDIT HOUSE GENERAL */ 
    export const getHouseGeneralAction = ( id ) =>{
        return async (dispatch)=>{
            dispatch( getLoadHouseGeneral() );

            try{
                let response = await clientAxios.get(`/casa_detalle/${id}`);
                dispatch( loadHouseGeneralSuccess (response.data.data) );
            }catch( error ){
                dispatch( loadHouseGeneralError() );
                // SweetAlertBasic("error","Ups","Hubo un error al cargar la ubicacion de la casa");  
            }
        }   
    }

    const getLoadHouseGeneral = () => ({
        type: HOUSE_LOAD_GENERAL,
        payload: true
    })

    const loadHouseGeneralSuccess = (extra) => ({
        type                : HOUSE_LOAD_SUCCESS_GENERAL,
        editarCasaGeneral   : extra
    })

    const loadHouseGeneralError = () => ({
        type: HOUSE_LOAD_ERROR_GENERAL,
        error: true
    })

/*************************************************************************************************/
/*                                     CASA IMAGE                                            */
/*************************************************************************************************/

    /* CREATE HOUSE IMAGE*/ 
    export function createHouseImageAction(house, data) {
        return async ( dispatch ) => {
            dispatch( createHouseImage() );
            try{
                let responseImage = await imageAxiosAxios.post(`/casa_imagen/upload-image/${data.id_casa}/${data.name_image}`,house );
        
                if(responseImage.status === 200 ){
                   
                    // OBJECT IN ACTION
                    let dataActionImage = {
                        id_casa : data.id_casa,
                        name_image: data.name_image,
                        url_image: responseImage.data.url_image
                    }

                    dispatch( createHouseImageSuccess( dataActionImage ) );
                    SweetAlertBasic("success","Buen trabajo!","Se ha subido exitosamente la imagen");

                    // CONSUMIR SERVICIO PARA ACTUALIZAR IMAGENES DE CASAS
                    let response = await clientAxios.get(`/casa_imagen/${data.id_casa}`);
                    console.log("first");
                    console.log(response);
                    console.log("first");
                    dispatch( loadHouseImageSuccess (response.data.data) );
                }
               
            }catch( error ){
                console.log(error)
                dispatch( createHouseImageError() );
                SweetAlertBasic("error","Ups","Hubo un al crear las imagenes de la casa :(");
            }
        }
    }
  
    const createHouseImage = ( ) => ({
        type    : HOUSE_CREATE_IMAGE
    });

    const createHouseImageSuccess = (data) => ({
        type            : HOUSE_CREATE_SUCCESS_IMAGE,
        imagen_data     : data,
        flag            : true
    });

    const createHouseImageError = () => ({
        type    : HOUSE_CREATE_ERROR_IMAGE,
        error   : true,
        flag    : false
    });

     /* GET HOUSE  */ 
     export const getHouseImageAction = ( id ) =>{
        return async (dispatch)=>{
            dispatch( getLoadHouseImage() );

            try{
                let response = await clientAxios.get(`/casa_imagen/${id}`);
                dispatch( loadHouseImageSuccess (response.data.data) );
            }catch( error ){
                console.error(error)
                dispatch( loadHouseImageError() );
                // SweetAlertBasic("error","Ups","Hubo un error al cargar las imagenes de la casa");  
            }
        }   
    }

    const getLoadHouseImage = () => ({
        type: HOUSE_LOAD_IMAGE,
        payload: true
    })

    const loadHouseImageSuccess = (imagen) => ({
        type                : HOUSE_LOAD_SUCCESS_IMAGE,
        casaImagen          : imagen
    })

    const loadHouseImageError = () => ({
        type: HOUSE_LOAD_ERROR_IMAGE,
        error: true
    })