import React, { useEffect, useState }   from 'react'
import { useParams }                    from 'react-router-dom';
import { useDispatch, useSelector }     from 'react-redux';
import { Helmet }                       from "react-helmet";
import { SweetAlertBasic }              from '../../utils/sweetAlert';
import{  
    getHouseAction,
    getHouseUbicationAction,
    getHouseExtraAction,
    getHousePriceAction,
    getHouseGeneralAction,
    getHouseImageAction
}                                   from '../../actions/houseActions';
import {
    createScheduleAction
}                                   from '../../actions/agendaActions'
import {
    createOptionAction
}                                   from '../../actions/optionsActions';

import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import WhatsAppIcon                 from '@mui/icons-material/WhatsApp';
import Image                        from '../../Images/home-button.png';
import IconNombre                   from '../../Images/nombre_house.svg';
import IconRecamara                 from '../../Images/recamara_house.svg'
import IconBanio                    from '../../Images/banio_house.svg';
import IconSala                     from '../../Images/sala_house.svg';
import IconComedor                  from '../../Images/comedor_house.svg';
import IconCocina                   from '../../Images/cocina_house.svg';
import IconPatio                    from '../../Images/patio_house.svg';
import IconEstacionamiento          from '../../Images/estacionamiento_house.svg';
import IconDireccion                from '../../Images/direccion_house.svg';
import IconCP                       from '../../Images/cp_house.svg';
import IconPrecio                   from '../../Images/precio_house.svg';
import IconMetros                   from '../../Images/metrosConstruidos_house.svg';
import IconDescripcion              from '../../Images/descripcion_house.svg';
import SuccessSchedule              from '../../Images/success_schedule.svg';
import ErrorSchedule                from '../../Images/error_schedule.svg';

// SLIDER
import SimpleImageSlider            from "react-simple-image-slider";
import {
    DivOpcionesCasaId,
    TitleGeneral,
    DivSubOpciones,
    SubtitleGeneral,
    DetalleCasa,
    DivFormSchedule,
    DivButtonSchedule,
    ImageShare,
    DivDataGeneral,
    TitleGeneralDetail,
    DivRow,
    SubtitleGeneralPrice,
    WrapperInformation,
    WrapperSchedule,
    DetailIcons,
    DetailImage,
    WrapperData,
    WrapperDetailHouse,
    TitleGeneralDetailPrice,
    WrapperSuccess
}                                       from './Opciones.styles';

import {
    DataImage
}                                       from '../../utils/opciones.js';


// MODAL
import Dialog                                   from '@mui/material/Dialog';
import DialogActions                            from '@mui/material/DialogActions';
import DialogContentText                        from '@mui/material/DialogContentText';
import DialogTitle                              from '@mui/material/DialogTitle';
import Divider                                  from '@mui/material/Divider';
import FormControl                              from '@mui/material/FormControl';
// MODAL


const OpcionesCasaId = () => {

    let userLocal = JSON.parse(localStorage.getItem('user'))

    const dispatch                      = useDispatch();
    const { id_casa }                   = useParams();
    const editHouse                     = useSelector( state => state.casas?.editarCasa             || {} );
    const editHouseUbication            = useSelector( state => state.casas?.editarCasaUbicacion    || {} );
    const editHousePrice                = useSelector( state => state.casas?.editarCasaPrecio       || {} );
    const editHouseGeneral              = useSelector( state => state.casas?.editarCasaGeneral      || {} );
    const houseViewImage                = useSelector( state => state.casas?.casaImagen || {} );
    const scheduleSuccess               = useSelector( state => state.agendas || {} );
    // const realoadImage               = useSelector( state => state.casas?.flagReload);
    // const editHouseExtra             = useSelector( state => state.casas?.editarCasaExtra        || {} );
    // const houseUpdateSuccess         = useSelector( state => state.casas?.flagUpdate);


    const addCreate                     = (schedule)    => dispatch( createScheduleAction(schedule) );
    const addCreateOption               = (option)      => dispatch( createOptionAction(option) );

    const [ nombreCliente, setNombreCliente ]                   = useState('');
    const [ telefonoCliente, setTelefonoCliente ]               = useState('');
    const [ emailCliente, setEmailCliente ]                     = useState('');
    const [ enabledForm, setEnabledForm]                        = useState(false);
    
    useEffect( () => {

        setTimeout(() => {
            const loadingHouse                  = ()            => dispatch( getHouseAction(id_casa) );
            const loadingHouseUbication         = ()            => dispatch( getHouseUbicationAction(id_casa) );
            const loadingHouseExtra             = ()            => dispatch( getHouseExtraAction(id_casa) );
            const loadingHousePrice             = ()            => dispatch( getHousePriceAction(id_casa) );
            const loadingHouseGeneral           = ()            => dispatch( getHouseGeneralAction(id_casa) );
            const loadingHouseImage             = ()            => dispatch( getHouseImageAction(id_casa) );
        
            loadingHouse();
            loadingHouseUbication();
            loadingHouseExtra();
            loadingHousePrice();
            loadingHouseGeneral();
            loadingHouseImage();
           
        }, 100);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    let imageView = DataImage(houseViewImage);


    // Create of User
    const createClient = (e, tipo) => {

        setEnabledForm(true);
        e.preventDefault();

         //Validate form
        if( nombreCliente.trim() === '' || telefonoCliente.length === 0 || emailCliente.trim() === ''){
            SweetAlertBasic("error","Ups","Todos los campos son obligatorios para agendar una visita.");
            return;
        }

        
        
        addCreate({
            datos_agenda: `Agenda de: ${nombreCliente} - ${emailCliente} - ${telefonoCliente} `,
            horario_agenda_casa: "10:00",
            dia_agenda_casa: "2022-04-16",
            tipo_agenda_casa: tipo,
            origen_agenda_casa: "CRM AGENDA",
            id_casa: id_casa,
            nombre_cliente: nombreCliente,
            email_cliente: emailCliente,
            telefono_cliente: telefonoCliente
        })
        setNombreCliente('');
        setEmailCliente('');
        setTelefonoCliente('');
    }

    

    let loginUser       = JSON.parse( localStorage.getItem('user') );

    const [openModal, setOpenModal]     = useState(false);

    const handleClickOpenModal = ( ) => {
        setOpenModal(true);
        setEnabledForm(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setEnabledForm(false);
    };

    const clickWhatsapp = ( ) => {

        addCreateOption({
            tipo_opcion: "Envió de Opciones vía CRM",
            tipo_opcion_casa: "Ficha Técnica",
            descripcion_opcion_casa: "Opción enviada desde la ficha técnica de la casa",
            ruta_opcion_casa: "sin ruta",
            id_casa: parseInt(id_casa),
            id_usuario: userLocal?.id ? userLocal?.id : 1,
            id_cliente: 1
        })

        window.open(`https://api.whatsapp.com/send?text=${window.location.hostname+'/opciones/' + id_casa}` )
    }

    return ( 
        <>
            {/* Meta Tags */}
            <Helmet>‍
                <title>{editHouse.nombre_casa}</title>
                <meta property="og:title"       content={editHouse.nombre_casa} />‍
                <meta property="og:url"         content={ window.location.hostname+'/opciones/' + id_casa} />
                <meta property="og:image"       content={houseViewImage.imagen_2_casa_imagen} />
                <meta property="og:description" content={editHouse.nombre_casa + ' en ' +  editHouseUbication.ubicacion_casa_ubicacion + ' precio de: ' + editHousePrice.precio_total_casa_precio?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')+ ' MX'}  />
                <meta property="og:site_name"   content="Plus Varq" />
                <meta property="og:locale"      content="es_MX" />
                <meta property="og:type"        content="website" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="150" />
                <meta property="og:image:height" content="120" />
            </Helmet>

            {/* RESPONSIVE */}
                <WrapperInformation>
                    <div className="detail_house">
                        <DetailImage>
                            <img
                                src={IconPrecio}
                                alt="recamaras"
                                style={{
                                    filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)',
                                }}
                            /> 
                        </DetailImage>
                        <div className="price_total">
                            ${editHousePrice.precio_total_casa_precio?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                    </div>
                    <div>
                        <div className="house_name">
                            {editHouse.nombre_casa}
                        </div>
                    </div>
                    <div>
                        <div className="house_direction">
                            {editHouseUbication.ubicacion_casa_ubicacion}
                        </div>
                    </div>
                </WrapperInformation>
                
                <WrapperSchedule>
                    <div className="button_schedule">
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="primary"
                            type="submit"
                            startIcon={<SaveIcon />}
                            fullWidth
                            style={{backgroundColor: "white", color:"#004274", fontWeight:"bold"}}
                            onClick={ () =>  handleClickOpenModal() }
                        >
                            Agendar Visita
                        </Button>
                    </div>
                </WrapperSchedule>

                <WrapperDetailHouse>
                    
                </WrapperDetailHouse>
            {/* RESPONSIVE */}

            {/* MODAL */}

            <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth={"lg"}>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => createClient( e,"Mobile")}                
                >
                    <DialogTitle
                        style={{
                           textAlign: "center",
                           color: '#004274' 
                        }}
                    >
                        <strong> 
                        {
                        enabledForm ?
                            <> 
                                {
                                
                                    scheduleSuccess.flag ? 
                                    '¡Felicidades!'
                                    :
                                    'Ouu'
                                }
                            </>
                            : 
                        'Agenda'
                        }  
                        </strong> 
                    </DialogTitle>
                    <Divider sx={{ my: 0.5 }} />
                    <DialogContentText style={{ marginTop:'20px', marginLeft: '25px', marginBottom: '10px'}}>
                        <strong></strong>
                    </DialogContentText>
                    <FormControl sx={{ width:300, margin: '0 auto',marginTop:'30px', display: 'flex'}} >
                    {
                        enabledForm ?
                                <> 
                                    {
                                        scheduleSuccess.flag ?
                
                                        <WrapperSuccess>
                                            <img
                                                src={SuccessSchedule}
                                                alt="agenda registrada exitosamente"
                                                style={{ 
                                                    height: '150px',
                                                    width: '150px',
                                                }}
                                            /> 
                                            <div className="text_success">
                                                Tu agenda se ha registrado exitosamente, en unos momentos
                                                uno de nuestros especialistas se comunicará contigo.
                                            </div>
                
                                        </WrapperSuccess>
                                        :
                                        <WrapperSuccess>
                                            <img
                                                src={ErrorSchedule}
                                                alt="error en agendar la visita"
                                                style={{ 
                                                    height: '150px',
                                                    width: '150px',
                                                }}
                                            /> 
                                            <div className="text_success">
                                                Hubo un error al agendar tu visita, intenta más tarde.
                                            </div>
                                        </WrapperSuccess>
                                    }
                                </>    
                        
                        :
                        <>
                            <>
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa tu nombre" 
                                    variant="outlined"
                                    type="text"
                                    name="nombreCliente"
                                    value={nombreCliente}
                                    onChange={ e => setNombreCliente(e.target.value) }
                                    fullWidth
                                    style={{ backgroundColor:'white', marginBottom: '2rem'}}

                                />
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el telefono del cliente" 
                                    variant="outlined"
                                    name="telefonoCliente"
                                    value={telefonoCliente}
                                    type="number"
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    onChange={ e => setTelefonoCliente( Number(e.target.value) ) }
                                    style={{ backgroundColor:'white', marginBottom: '2rem'}}
                                    fullWidth
                                />

                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el email del cliente" 
                                    variant="outlined"
                                    name="emailCliente"
                                    value={emailCliente}
                                    onChange={ e => setEmailCliente(e.target.value) }
                                    style={{ backgroundColor:'white', marginBottom: '2rem'}}
                                    fullWidth
                                />
                            </>
                        </>
                    }
                    
                  
                    </FormControl>
                    {
                        enabledForm ?
                        null
                        :
                        <>
                            <Divider sx={{ my: 0.5 }} style={{ marginBottom: '10px'}}/>
                            <DialogActions
                                style={{ marginBottom: '10px'}}
                            >
                                <Button
                                    variant     ="contained" 
                                    size        ="large"
                                    type="submit"
                                    // startIcon   ={<SaveIcon />}
                                    color="success"
                                    fullWidth
                                    style={{fontWeight:"bold"}}
                                >
                                    Enviar
                                </Button>
                            </DialogActions>
                        </>
                    }   
                </Box>
            </Dialog>
            {/* MODAL */}

            <DivOpcionesCasaId>
              
                <div>
                    <SimpleImageSlider
                        width={window.screen.width <= 768 ? window.screen.width : 1000}
                        height={window.screen.height <= 1079 ? window.screen.height - 380 : 590 }
                        images={imageView}
                        showBullets={Object.keys(houseViewImage).length === 0 ? false : true}
                        showNavs={ Object.keys(houseViewImage).length === 0 ? false : true}
                    />
                </div>
                <DivSubOpciones>
                    <TitleGeneral>
                        {
                            loginUser?.id ?  '¡ Compartela en las redes !' : '¡ Agenda con nosotros una visita !'
                        }
                     
                    </TitleGeneral>
                    <DivFormSchedule>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => createClient(e, "Desktop")}
                            style={{ width:'90%', marginTop: '4rem' }}
                            
                        >
                            {
                                loginUser?.id ? 
                                <div
                                    style={{ marginTop: '5rem'}}
                                >
                                    <ImageShare src={Image}/>
                                </div>
                                :
                                <>
                                    <TextField  
                                        id="outlined-basic" 
                                        label="Ingresa tu nombre" 
                                        variant="outlined"
                                        type="text"
                                        name="nombreCliente"
                                        value={nombreCliente}
                                        onChange={ e => setNombreCliente(e.target.value) }
                                        fullWidth
                                        style={{ backgroundColor:'white', marginBottom: '2rem'}}

                                    />
                                    <TextField  
                                        id="outlined-basic" 
                                        label="Ingresa el telefono del cliente" 
                                        variant="outlined"
                                        name="telefonoCliente"
                                        value={telefonoCliente}
                                        type="number"
                                        onInput = {(e) =>{
                                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                        }}
                                        onChange={ e => setTelefonoCliente( Number(e.target.value) ) }
                                        style={{ backgroundColor:'white', marginBottom: '2rem'}}
                                        fullWidth
                                    />

                                    <TextField  
                                        id="outlined-basic" 
                                        label="Ingresa el email del cliente" 
                                        variant="outlined"
                                        name="emailCliente"
                                        value={emailCliente}
                                        onChange={ e => setEmailCliente(e.target.value) }
                                        style={{ backgroundColor:'white', marginBottom: '2rem'}}
                                        fullWidth
                                    />

                                    <Button 
                                        variant="contained" 
                                        size="large" 
                                        color="primary"
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                        fullWidth
                                    >
                                        Agendar Visita
                                    </Button>
                                    <DivButtonSchedule>
                                        <Button 
                                            variant="contained" 
                                            size="large" 
                                            type="button"
                                            startIcon={<WhatsAppIcon />}
                                            fullWidth
                                            style={{ backgroundColor:"#42ba96"}}
                                            onClick={ () => clickWhatsapp()}
                                        >
                                            Compartir
                                        </Button>
                                    </DivButtonSchedule>
                                </>
                            }
                            {
                                loginUser?.id ? 
                                <DivButtonSchedule>
                                    <Button 
                                        variant="contained" 
                                        size="large" 
                                        type="submit"
                                        startIcon={<WhatsAppIcon />}
                                        fullWidth
                                        style={{ backgroundColor:"#42ba96", marginTop: '2rem'}}
                                        onClick={ () => clickWhatsapp()}
                                    >
                                        Compartir
                                    </Button>
                                </DivButtonSchedule>
                                : null
                            }
                            
                          
                        </Box>
                    </DivFormSchedule>
                </DivSubOpciones>
            </DivOpcionesCasaId>
            <DivDataGeneral>
                    
                    <DivRow>
                        {/* DETAIL FIRST  */}
                        <div>
                            <TitleGeneralDetail> 
                                Datos Principales
                            </TitleGeneralDetail>
                            <div>
                            <WrapperData>
                                <DetailImage>
                                    <img
                                        src={IconNombre}
                                        alt="recamaras"
                                        style={{
                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)',
                                            marginBottom: '10px',
                                            marginRight: '10px',
                                        }}
                                    /> 
                                </DetailImage>
                                <DetailIcons>
                                    <SubtitleGeneral
                                        style={{
                                            width: '220px',
                                        }}
                                    >{editHouse.nombre_casa}</SubtitleGeneral>
                                </DetailIcons>
                            </WrapperData>
                            <WrapperData>
                                <DetailImage>
                                    <img
                                        src={IconDireccion}
                                        alt="recamaras"
                                        style={{
                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)',
                                            marginBottom: '10px',
                                            marginRight: '10px',
                                        }}
                                    /> 
                                </DetailImage>
                                <DetailIcons>
                                    <SubtitleGeneral>{editHouse.calle_casa}</SubtitleGeneral>
                                </DetailIcons>
                            </WrapperData>
                            <WrapperData>
                                <DetailImage>
                                    <img
                                        src={IconCP}
                                        alt="recamaras"
                                        style={{
                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)',
                                            marginBottom: '10px',
                                            marginRight: '10px',
                                        }}
                                    /> 
                                </DetailImage>
                                <DetailIcons>
                                    <SubtitleGeneral>CP: {editHouse.cp_casa}</SubtitleGeneral>
                                </DetailIcons>
                            </WrapperData>    
                            </div>
                        </div>
                        <br/>
                        {/* DETAIL HOUSE */}
                        <div>
                            <TitleGeneralDetail> 
                                Detalles
                            </TitleGeneralDetail>
                            <DetalleCasa>
                                {
                                    Object.keys(editHouseGeneral).length === 0 ? <SubtitleGeneral> Sin detalles </SubtitleGeneral>
                                    :
                                    <>
                                        
                                        {
                                            editHouseGeneral.recamaras_casa_detalle !== -1 &&
                                            <>
                                                <DetailImage>
                                                    <img
                                                        src={IconRecamara}
                                                        alt="recamaras"
                                                        style={{
                                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)'
                                                        }}
                                                    /> 
                                                </DetailImage>
                                            
                                                <DetailIcons>
                                                    <SubtitleGeneral>Recamaras: {editHouseGeneral.recamaras_casa_detalle}</SubtitleGeneral>
                                                </DetailIcons>
                                            </>
                                        }
                                        
                                        {
                                            editHouseGeneral.banios_casa_detalle !== -1 &&
                                            <>
                                                <DetailImage>
                                                    <img
                                                        src={IconBanio}
                                                        alt="baños"
                                                        style={{
                                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)'
                                                        }}
                                                    /> 
                                                </DetailImage>
                                                <DetailIcons>
                                                    <SubtitleGeneral>Baños: {editHouseGeneral.banios_casa_detalle}</SubtitleGeneral>
                                                </DetailIcons>
                                            </>
                                        }

                                        
                                        {
                                            editHouseGeneral.comedor_casa_detalle !== -1 &&
                                            <>
                                                <DetailImage>
                                                    <img
                                                        src={IconComedor}
                                                        alt="comedores"
                                                        style={{
                                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)'
                                                        }}
                                                    /> 
                                                </DetailImage>
                                                <DetailIcons>
                                                    <SubtitleGeneral>Comedor: {editHouseGeneral.comedor_casa_detalle}</SubtitleGeneral>
                                                </DetailIcons>
                                            </>

                                        }
                                        
                                        {
                                            editHouseGeneral.sala_casa_detalle !== -1 &&
                                            <>
                                                <DetailImage>
                                                    <img
                                                        src={IconSala}
                                                        alt="Sala"
                                                        style={{
                                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)'
                                                        }}
                                                    /> 
                                                </DetailImage>
                                                <DetailIcons>
                                                    <SubtitleGeneral>Sala: {editHouseGeneral.sala_casa_detalle}</SubtitleGeneral>
                                                </DetailIcons>
                                            </>
                                        }
                                        

                                        {
                                            editHouseGeneral.cocina_casa_detalle !== -1 &&
                                            <>
                                                <DetailImage>
                                                    <img
                                                        src={IconCocina}
                                                        alt="cocina"
                                                        style={{
                                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)'
                                                        }}
                                                    /> 
                                                </DetailImage>
                                                <DetailIcons>
                                                    <SubtitleGeneral>Cocina: {editHouseGeneral.cocina_casa_detalle}</SubtitleGeneral>
                                                </DetailIcons>
                                            </>
                                        }
                                        
                                        {
                                            editHouseGeneral.patio_casa_detalle !== -1 &&
                                            <>
                                                <DetailImage>
                                                    <img
                                                        src={IconPatio}
                                                        alt="patio"
                                                        style={{
                                                            filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)'
                                                        }}
                                                    /> 
                                                </DetailImage>
                                                <DetailIcons>
                                                    <SubtitleGeneral>Patio: {editHouseGeneral.patio_casa_detalle}</SubtitleGeneral>
                                                </DetailIcons>
                                            </>
                                        }
                                       

                                        {
                                            editHouseGeneral.estacionamiento_casa_detalle !== -1 &&
                                            <>
                                                <DetailImage>
                                                <img
                                                    src={IconEstacionamiento}
                                                    alt="estacionamiento"
                                                    style={{
                                                        filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)'
                                                    }}
                                                /> 
                                                </DetailImage>
                                                <DetailIcons>
                                                    <SubtitleGeneral>Estacionamiento: {editHouseGeneral.estacionamiento_casa_detalle}</SubtitleGeneral>
                                                </DetailIcons>
                                            </>
                                        }
                                        
                                    </>
                                }
                            </DetalleCasa>
                        </div>
                        <div>
                            <TitleGeneralDetailPrice> 
                                Precio
                            </TitleGeneralDetailPrice>
                            {
                                Object.keys(editHousePrice).length === 0 ? <SubtitleGeneral> Sin precio </SubtitleGeneral>
                                :

                                <div>
                                     <WrapperData>
                                        <DetailImage>
                                            <img
                                                src={IconPrecio}
                                                alt="recamaras"
                                                style={{
                                                    filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)',
                                                    marginBottom: '10px',
                                                    marginRight: '10px',
                                                }}
                                            /> 
                                        </DetailImage>
                                        <DetailIcons>
                                            <SubtitleGeneralPrice> <strong>Precio total:   ${editHousePrice.precio_total_casa_precio?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}  </strong></SubtitleGeneralPrice>
                                        </DetailIcons>
                                    </WrapperData>  

                                    <WrapperData>
                                        <DetailImage>
                                            <img
                                                src={IconMetros}
                                                alt="recamaras"
                                                style={{
                                                    filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)',
                                                    marginBottom: '10px',
                                                    marginRight: '10px',
                                                }}
                                            /> 
                                        </DetailImage>
                                        <DetailIcons>
                                            <SubtitleGeneralPrice> <strong>Metros Construidos: {editHousePrice.metros_construidos_casa_precio} m² </strong> </SubtitleGeneralPrice>
                                        </DetailIcons>
                                    </WrapperData>  

                                    <WrapperData>
                                        <DetailImage>
                                            <img
                                                src={IconDescripcion}
                                                alt="recamaras"
                                                style={{
                                                    filter: 'invert(17%) sepia(90%) saturate(1190%) hue-rotate(181deg) brightness(101%) contrast(104%)',
                                                    marginBottom: '10px',
                                                    marginRight: '10px',
                                                }}
                                            /> 
                                        </DetailImage>
                                        <DetailIcons>
                                            <SubtitleGeneralPrice> <strong>Descripcion:  {editHousePrice.descripcion_precio_casa_precio} </strong> </SubtitleGeneralPrice>
                                        </DetailIcons>
                                    </WrapperData>  
                            
                                </div>
                            } 
                        </div>
                    </DivRow>
                        
            </DivDataGeneral>
        </>
     );
}
 
export default OpcionesCasaId;