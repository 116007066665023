import { combineReducers }      from 'redux';
import usuariosReducer          from './usuariosReducer';
import loginReducer             from './loginReducer';
import clientesReducer          from './clientesReducer';
import rolesReducer             from './rolesReducer';
import statusReducer            from './statusReducer';
import equiposReducer           from './equiposReducer';
import calendariosReducer       from './calendariosReducer';
import registrosReducer         from './registrosReducer';
import filtrosReducer           from './filtrosReducer';
import casasReducer             from './casasReducer';
import desarrolloReducer        from './desarrolloReducer';
import desarrolladorReducer     from './desarrolladorReducer';
import seguimientoReducer       from './seguimientoReducer';
import agendasReducer           from './agendasReducer';
import opcionesReducer          from './opcionesReducer';

export default combineReducers({
    usuarios        : usuariosReducer,
    login           : loginReducer,
    clientes        : clientesReducer,
    roles           : rolesReducer,
    status          : statusReducer,
    equipos         : equiposReducer,
    calendarios     : calendariosReducer,
    filtros         : filtrosReducer,
    registros       : registrosReducer,
    casas           : casasReducer,
    desarrollo      : desarrolloReducer,
    desarrollador   : desarrolladorReducer,
    seguimiento     : seguimientoReducer,
    agendas         : agendasReducer,
    opciones        : opcionesReducer
})