import { 
    SEGUIMIENTO_CREATE,
    SEGUIMIENTO_CREATE_SUCCESS,
    SEGUIMIENTO_CREATE_ERROR,
    SEGUIMIENTO_CREATE_INIT,
    SEGUIMIENTO_LOAD,
    SEGUIMIENTO_LOAD_SUCCESS,
    SEGUIMIENTO_LOAD_ERROR,
} from '../types';

/*
    FLAG
        Revisamos si esta ok  lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
*/
const initialState = {
    seguimientos: [],
    error: null,
    loading: false,
    flag: false, 
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case SEGUIMIENTO_CREATE:
        case SEGUIMIENTO_LOAD:
            return{
                ...state,
                loading: true,
                flag: false,
            }

        case SEGUIMIENTO_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case SEGUIMIENTO_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                seguimientos: [ ...state.seguimientos, action.payload ]
            }

        case SEGUIMIENTO_CREATE_ERROR:
        case SEGUIMIENTO_LOAD_ERROR:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case SEGUIMIENTO_LOAD_SUCCESS:
            return{
                ...state,
                seguimientos: action.seguimientos ,
                flag: false,
                loading: false
            }
        default:
            return state;
    }
}