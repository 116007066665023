import React, { useState,useEffect }    from 'react'
import { useParams }                    from 'react-router-dom';
import { useDispatch, useSelector }     from 'react-redux';
import ModalStatus                      from '../Modal/ModalStatus';
import EditClient                       from './EditForm/EditClient.component';
import SeguimientosCliente              from '../Seguimientos/SeguimientosCliente.component';
import OpcionesResponsive               from '../Opciones/OpcionesResponsive.component';
import ScheduleResponsive               from '../Agendas/ScheduleResponsive.component';
import Link                             from "@material-ui/core/Link";
import Avatar                           from '@mui/material/Avatar';
import Accordion                        from '@mui/material/Accordion';
import AccordionSummary                 from '@mui/material/AccordionSummary';
import AccordionDetails                 from '@mui/material/AccordionDetails';
import Typography                       from '@mui/material/Typography';
import ExpandMoreIcon                   from '@mui/icons-material/ExpandMore';
import Button                           from '@mui/material/Button';
import Divider                          from '@mui/material/Divider';
import FileCopyIcon                     from '@mui/icons-material/FileCopy';
import MoreHorizIcon                    from '@mui/icons-material/MoreHoriz';
import MenuItem                         from '@mui/material/MenuItem';
import TelIcon                          from '../../Images/llamada_icon.png';
import WhatsIcon                        from '../../Images/whatsapp_icon.png';
import EmailIcon                        from '../../Images/email_icon.png';
import ScheduleImg                      from '../../Images/schedule_responsive.png';
import { StyledMenu }                   from '../../utils/menu.js';
import { status_button }                from '../../utils/status.js';
import { getClientAction }              from '../../actions/clientActions';
import { getSeguimientoAction }         from '../../actions/seguimientoActions';
import  { getOptionClientAction }       from '../../actions/optionsActions';
import  { getAllSchedulesAction }       from '../../actions/agendaActions';
import {
    Image,
}                                               from './Clients.styles';
import {
    WrapperContact
} from './style/ClientResponsive.styles';


const EditClientResponsive = () => {

    const dispatch                  = useDispatch();
    const { id_cliente }            = useParams();
    const loadingClient             = () => dispatch( getClientAction(id_cliente) );
    const loadingSeguimiento        = () => dispatch( getSeguimientoAction(id_cliente) );
    const loadingOptionClient       = (id_cliente) => dispatch( getOptionClientAction(id_cliente) );
    const loadingSchedules          = () => dispatch( getAllSchedulesAction() );

    const editClient                = useSelector( state => state.clientes?.editarCliente || {} );
    const seguimientos              = useSelector( state => state.seguimiento?.seguimientos || {});
    const optionsClientSuccess      = useSelector( state => state?.opciones?.opcionClienteSales  || {});
    const scheduleSuccess           = useSelector( state => state?.agendas?.agendas || {} );


    const data_client               = Object.keys(editClient).length;
    const data_seguimiento          = Object.keys(seguimientos).length;
    const data_options              = Object.keys(optionsClientSuccess).length;
    const data_schedule             = Object.keys(scheduleSuccess).length;

    const {
        nombre_cliente,
        email_cliente,
        nombre_status,
        telefono_cliente,
        id_status,
    } = editClient;
    
    const stringAvatar              = (name) => { return { children: `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`} }
        
    useEffect( () => {
        loadingClient();
        loadingSeguimiento();
        loadingOptionClient();
        loadingSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    const [anchorEl, setAnchorEl]   = useState(null);
    const open                      = Boolean(anchorEl);

   
    const [openModal, setOpenModal]     = useState(false);
    const [idStatus, setIdStatus]       = useState(null);
    const [titleModal, setTitleModal]   = useState(null);

    const data_status   = status_button.find(btn => btn.value === id_status);

    const handleClickOpenModal = ( status, id ) => {
        setIdStatus(id)
        setTitleModal(status);
        setOpenModal(true);
        setAnchorEl(null);
    };

    const handleClick               = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const scheduleModal = () =>{
        console.log("CLICL SCHEDULE")
    }

    const handleClose               = () => {
        setAnchorEl(null);
    };

    
    return ( 
        <>
            <WrapperContact>

                <div className="actions_contact">
                    <Button
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        startIcon={<MoreHorizIcon style={{ marginLeft: '30%'}}/>}
                        style ={{ 
                            backgroundColor:" #004274",
                            minWidth: '30px', 
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                        }}
                    >
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        fullWidth  
                        maxWidth={"lg"} 
                    >
                        <MenuItem onClick={ () => handleClickOpenModal("Seguimiento", id_status)} disableRipple>
                            <FileCopyIcon />
                            Seguimiento
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                                <MenuItem><strong>Pasarlo al status:</strong></MenuItem>
                                {data_status?.status_button.map((status) => (
                                    <MenuItem
                                        key={status.value}
                                        value={status.value}
                                        disableRipple
                                        onClick={ () =>  handleClickOpenModal(status.name, status.value)}
                                    >
                                        {status.name} 
                                    </MenuItem>
                                    ))
                                }
                            
                    </StyledMenu>
                </div>
                
                <div className="avatar_contact">  
                    {/* Avatar */}
                    <Avatar 
                        {...stringAvatar(nombre_cliente)}
                        style={{ width:'60px', height:'60px' }}
                    >
                    </Avatar>

                   
                </div>
                <div className="data_contact">
                    <h4>
                        {nombre_cliente}
                    </h4>
                    <h4
                        style={{
                            color: '#004274'
                        }}
                    >
                        {nombre_status}
                    </h4>
                </div>
                <div className="information_contact">
                        <Link
                            href={"tel:"+telefono_cliente}
                        >
                            <Image  src={TelIcon} alt="logo" />
                        </Link>
                        <Link
                            href={`https://wa.me/52${telefono_cliente}?text=Hola%20te%20contacto%20por%20este%20medio%20somos%20la%20inmobiliaria%20plusvarq`}
                            target="_blank"
                        >
                            <Image  src={WhatsIcon} alt="logo" />
                        </Link>
                        <Link
                            href={"mailto:"+email_cliente}
                        >
                            <Image  src={EmailIcon} alt="logo" />
                        </Link>
                        <Link
                            onClick={scheduleModal}
                        >
                            <Image style={{ height: "31px"}}  src={ScheduleImg} alt="agendar visita" />
                        </Link>
                </div>
                <div className="accordion_contact">
                    <Accordion 
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                                backgroundColor:'#004274',
                                fontWeight:'bold',
                                color: "#FFFF",
                            }}
                        >
                        <Typography>Datos Personales</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                data_client >= 1 &&
                                <EditClient data={editClient}/>
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            style={{
                                backgroundColor:'#004274',
                                fontWeight:'bold',
                                color: "#FFFF",
                            }}
                        >
                        <Typography>Seguimiento</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                data_seguimiento >= 1 ?
                                    <SeguimientosCliente data={seguimientos}/>
                                :
                                <div>
                                    <strong>No hay seguimientos...</strong>
                                </div>
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                            style={{
                                backgroundColor:'#004274',
                                fontWeight:'bold',
                                color: "#FFFF",
                            }}
                        >
                        <Typography>Opciones Enviadas</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                data_options >= 1 ?
                                    <OpcionesResponsive data={optionsClientSuccess}/>
                                :
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <strong>No hay opciones enviadas...</strong>
                                </div>
                            }
                           
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                            style={{
                                backgroundColor:'#004274',
                                fontWeight:'bold',
                                color: "#FFFF",
                            }}
                        >
                        <Typography>Agendas</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                data_schedule >= 1 &&
                                <ScheduleResponsive data={scheduleSuccess} id_cliente={id_cliente}/>
                            }
                        </AccordionDetails>
                    </Accordion>
                </div>
            </WrapperContact>

            {
                openModal &&
                <ModalStatus  setOpenModal={setOpenModal} titleModal={titleModal} editClient={editClient} idStatus = {idStatus}/>
            }
        </>
     );
}
 
export default EditClientResponsive;