import { 
    REGISTER_CREATE,
    REGISTER_CREATE_SUCCESS,
    REGISTER_CREATE_ERROR,
    REGISTER_CREATE_INIT,
    REGISTERS_LOADING,
    REGISTERS_LOADING_SUCCESS,
    REGISTERS_LOADING_ERROR,
    REGISTER_LOAD,
    REGISTER_LOAD_SUCCESS,
    REGISTER_LOAD_ERROR,
    REGISTER_DESACTIVE,
    REGISTER_DESACTIVE_ERROR
} from '../types';

/*
    FLAG
        Revisamos si esta ok  lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
*/
const initialState = {
    registros: [],
    error: null,
    loading: false,
    flag: false, 
    desactiveRegistro: null,
    editarRegistro: null,
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case REGISTER_CREATE:
        case REGISTER_LOAD:
        case REGISTERS_LOADING:
            return{
                ...state,
                loading: true,
                flag: false,
            }

        case REGISTER_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case REGISTER_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                registros: [ ...state.registros, action.payload ]
            }

        case REGISTER_CREATE_ERROR:
        case REGISTER_DESACTIVE_ERROR:
        case REGISTERS_LOADING_ERROR:
        case REGISTER_LOAD_ERROR:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case REGISTER_DESACTIVE:
            return{
                ...state,
                desactiveRegistro: action.payload
            }
        
        case REGISTERS_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                registros: action.payload,
                flag: false,
                desactiveRegistro: null
            }

        case REGISTER_LOAD_SUCCESS:
            return{
                ...state,
                registros: [...state.registros],
                editarRegistro: action.editarRegistro,
                flag: true,
                loading: false
            }
        default:
            return state;
    }
}