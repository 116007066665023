import React, {useEffect, useState}           from 'react';
import Table            from '@mui/material/Table';
import TableBody        from '@mui/material/TableBody';
import TableContainer   from '@mui/material/TableContainer';
import TableHead        from '@mui/material/TableHead';
import TableRow         from '@mui/material/TableRow';
import Paper            from '@mui/material/Paper';
import TableData        from '../Table/TableData.component';
import Divider          from '@mui/material/Divider';
import DialogActions    from '@mui/material/DialogActions';
import Button           from '@mui/material/Button';
import { CSVLink}       from "react-csv";


import {
    TableCellModal,
    StyledTableCell
}                       from '../../utils/table.js'



const TableModal = ({ data, tableCell }) => {

    let dataObject = Object.keys(data).length;
    const [enabled, setEnabled ] = useState(false);
 
    useEffect( () => {
        dataObject >= 1  ? setEnabled(false) : setEnabled(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );  

    return (
        <>
             <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{fontWeight: 'bold'}}>
                            {
                                TableCellModal?.[tableCell].map((data) => (
                                    <StyledTableCell key={data.id}>{data.name}</StyledTableCell>  
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataObject >= 1 ?
                                data.map((row) => (
                                    <TableData row={row} table={tableCell} />
                                ))
                            : 
                            <div 
                                style={{
                                    fontWeight:"bold", 
                                    textAlign:"center",
                                    width:"100%", 
                                    display: "flex",
                                    justifyContent:"center",
                                    alignItems: "center",
                                    height:'50px', 
                                }}
                            >
                                No hay datos que mostrar...
                            </div>
                        }
                        
                    </TableBody>
                
                      
                </Table>
                <Divider sx={{ my: 0.5 }} style={{ marginBottom: '10px'}}/>
                <DialogActions
                    style={{ 
                        marginBottom: '10px',
                        marginRight: '10px',
                        width: '150px',
                        float: 'right',
                    }}
                >
                    <Button
                        variant     ="contained" 
                        size        ="small"
                        type        ="button"
                        color       ="success"
                        fullWidth   
                        style={{fontWeight:"bold"}}
                        disabled = {enabled}
                    >
                        <CSVLink data={data} style={{textDecoration: "none", color:"white"}}> Exportar datos</CSVLink>
                    </Button>
                </DialogActions>
                    
            </TableContainer>
        </>
      );
}
 
export default TableModal;