import React from 'react'
import Sidebar    from '../../components/Sidebar/Sidebar.component';

const ScheduleSpecialist = () => {
    return ( 
        <>
              <Sidebar/>
        </>
     );
}
 
export default ScheduleSpecialist;