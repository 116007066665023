import React, { useEffect, useState }         from 'react';
import ImageUser                    from '../../Images/users_goals.png';
import ImageUserCheck               from '../../Images/user_check.png';
import ImageSchedule                from '../../Images/schedule_icon.png';
import ImageHouse                   from '../../Images/house_dashboard.png';
import EmptyGoals                   from '../Metas/EmptyGoals.component';
import ModalGlobal                        from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginUserFn }           from '../../actions/loginActions';
import {
    DivGrid,
    BoxGoals,
    TitleGoal,
    DivGridBox,
    UsersGoals,
    SubtitleGoal,
    ThreeGoal,
    UsersProspect,
    ScheduleImg,
} from './styles/Especialista.styles';
import {
    result_month_success,
    result_week_success
}        from '../../utils/dashboard/dashboard';

import{ 
    getAllClientsAction 
}                               from '../../actions/clientActions';
import{ 
    getAllHousesAction 
}                               from '../../actions/houseActions';
import { 
    getAllSchedulesAction
}                               from '../../actions/agendaActions';
import { 
    getAllOptionsAction
}                               from '../../actions/optionsActions';


const DashboardMarketing = () => {

    const dispatch              = useDispatch();
    const loginUser             = () => dispatch( getLoginUserFn() );
    const loadingClients        = () => dispatch( getAllClientsAction() );
    const loadingHouses         = () => dispatch( getAllHousesAction() );
    const loadingSchedules      = () => dispatch( getAllSchedulesAction() );
    const loadingOptions        = () => dispatch( getAllOptionsAction() );
    const clientSuccess         = useSelector( state => state.clientes?.clientes);
    const houseSuccess          = useSelector( state => state.casas.casas);
    const scheduleSuccess       = useSelector( state => state?.agendas?.agendas);
    const optionsSuccess        = useSelector( state => state?.opciones?.opciones);
    const data_client_month     = result_month_success(clientSuccess,'created_at');
    const data_house_month      = result_month_success(houseSuccess,'created_at');
    const data_client_week      = result_week_success(clientSuccess,'created_at');
    const data_house_week       = result_week_success(houseSuccess,'created_at');
    const data_schedule_month   = result_month_success(scheduleSuccess,'created_at')
    const data_schedule_week    = result_week_success(scheduleSuccess,'created_at')
    const data_options_week     = result_week_success(optionsSuccess,'created_at')
    const data_options_month    = result_month_success(optionsSuccess,'created_at')


    // USE EFFECT LOGIN
    useEffect( () => {

        loginUser();
        loadingClients();
        loadingHouses();
        loadingSchedules();
        loadingOptions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    let goal                    = true;

    const [isOpen, setIsOpen] = useState(false);
    const [ dataModal, setDataModal ] = useState(null);
    const [ tableCell, setTableCell ] = useState(null);

    const clickModal = ( data, cell ) => {
        setIsOpen(true);
        setDataModal(data);
        setTableCell(cell);
    }

    return ( 
        <>
           {
            goal ? 
            <>  
            <DivGridBox>
                <>
                    <TitleGoal>Esta Semana</TitleGoal>
                    <DivGrid> 
                        <BoxGoals
                            onClick={() => clickModal(data_client_week,"client")}
                        >
                            <UsersGoals  src={ImageUser} alt="Usuarios" />
                            <SubtitleGoal>Clientes Nuevos</SubtitleGoal>
                            <ThreeGoal>{Object.keys(data_client_week).length}</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals
                            onClick={() => clickModal(data_house_week,"houses")}
                        >
                            <UsersProspect  src={ImageHouse} alt="Casas" />
                            <SubtitleGoal>Casas Creadas</SubtitleGoal>
                            <ThreeGoal>{Object.keys(data_house_week).length}</ThreeGoal>
                        </BoxGoals>
                        
                        <BoxGoals
                            onClick={() => clickModal(data_schedule_week,"schedules")}
                        >
                            <ScheduleImg  src={ImageSchedule} alt="Agendas" />
                            <SubtitleGoal>Agendas</SubtitleGoal>
                            <ThreeGoal>{Object.keys(data_schedule_week).length}</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals
                            onClick={() => clickModal(data_options_week,"options")}
                        >
                            <ScheduleImg  src={ImageUserCheck} alt="Opciones" />
                            <SubtitleGoal>Opciones</SubtitleGoal>
                            <ThreeGoal>{Object.keys(data_options_week).length}</ThreeGoal>
                        </BoxGoals>
                    </DivGrid>
                </>
                <>
                    <TitleGoal>Este Mes</TitleGoal>
                    <DivGrid> 
                        <BoxGoals
                           onClick={() => clickModal(data_client_month,"client")}
                        >
                            <UsersGoals  src={ImageUser} alt="Usuarios" />
                            <SubtitleGoal>Clientes Nuevos</SubtitleGoal>
                            <ThreeGoal>{Object.keys(data_client_month).length}</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals
                            onClick={() => clickModal(data_house_month,"houses")}
                        >
                            <UsersProspect  src={ImageHouse} alt="Casas" />
                            <SubtitleGoal>Casas Creadas</SubtitleGoal>
                            <ThreeGoal>{Object.keys(data_house_month).length}</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals
                            onClick={() => clickModal(data_schedule_month,"schedules")}
                        >
                            <ScheduleImg  src={ImageSchedule} alt="Agendas" />
                            <SubtitleGoal>Agendados</SubtitleGoal>
                            <ThreeGoal>{Object.keys(data_schedule_month).length}</ThreeGoal>
                        </BoxGoals>
                        <BoxGoals
                            onClick={() => clickModal(data_options_month,"options")}
                        >
                            <ScheduleImg  src={ImageUserCheck} alt="Opciones" />
                            <SubtitleGoal>Opciones</SubtitleGoal>
                            <ThreeGoal>{Object.keys(data_options_month).length}</ThreeGoal>
                        </BoxGoals>
                    </DivGrid>
                </>
            </DivGridBox>
        </>
        :
         <EmptyGoals
             message={'Vaya, no tenemos metricas... por ahora.'}
         />

        }
        {isOpen && <ModalGlobal setIsOpen={setIsOpen} data={dataModal} tableCell={tableCell} />}
        </>
     );
}
 
export default DashboardMarketing;