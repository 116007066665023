import styled from 'styled-components';

export const SidebarBox = styled.div`
    position: absolute;
    height: 92.7%;
`

export const WrapperOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const TitleOptions = styled.div`
    font-weight: bold;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
`

export const ImageOptions = styled.img`
    width: 60px;
    height: 60px;
    margin-top: 3rem;

`