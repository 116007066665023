import React,{useState}         from 'react';
import  { useSelector }         from 'react-redux';
import { useNavigate }          from 'react-router-dom';
import BottomNavigation         from '@mui/material/BottomNavigation';
import BottomNavigationAction   from '@mui/material/BottomNavigationAction';
import Paper                    from '@mui/material/Paper';
import MapIcon                  from '@mui/icons-material/Map';
import PersonIcon               from '@mui/icons-material/Person';
import HomeIcon                 from '@mui/icons-material/Home';
import {
    Navigate
} from './style';


const Navigation = () => {

    const [value, setValue] = useState(null);
    const navigate          = useNavigate();


    function goNavigate(url) {         
      navigate(url)
    }

    const goContact = () =>{
        console.log({id_rol});
        if(id_rol === 2) navigate('/clientes/especialista');
        if(id_rol === 6) navigate('/clientes/lider');
        if(id_rol === 1) navigate('/clientes');
    }

    const   user_local              = JSON.parse(localStorage.getItem('user'));
    const   loginSuccess            = useSelector( state => state.login.user || user_local );
    const   id_rol                  = useSelector( state => state.login?.user?.rol);

    return ( 
        <>
            {
                loginSuccess &&
                <Navigate>
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,  zIndex:'1', }} elevation={3}>
                        <BottomNavigation
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                        >
                            <BottomNavigationAction label="Mapa"        icon={<MapIcon style={{ color: 'rgba(0, 0, 0, 0.6)'}} />} onClick={() => goNavigate('/mapa')}/>
                            <BottomNavigationAction label="Contactos"   icon={<PersonIcon  style={{ color: 'rgba(0, 0, 0, 0.6)'}} onClick={() =>goContact()} />} />
                            <BottomNavigationAction label="Opciones"    icon={<HomeIcon  style={{ color: 'rgba(0, 0, 0, 0.6)'}}  onClick={() =>goNavigate('/opciones/casas')} />} />
                        </BottomNavigation>
                    </Paper>
                </Navigate>
            }
        </>
     );
}
 
export default Navigation;