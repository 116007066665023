let date_get    = new Date();

//WEEK deprecated
// Date.prototype.getWeek = function () {
//     let oneJan          = new Date(date_get.getFullYear(),0,1);
//     let numberOfDays    = Math.floor((date_get - oneJan) / (24 * 60 * 60 * 1000));
//     return  Math.ceil(( date_get.getDay() + 1 + numberOfDays) / 7);
// };

// WEEK
let oneJan          = new Date(date_get.getFullYear(),0,1);
let numberOfDays    = Math.floor((date_get - oneJan) / (24 * 60 * 60 * 1000));
let week            = Math.ceil(( date_get.getDay() + 1 + numberOfDays) / 7);

export const  objectData  ={
    date_year       : date_get.getUTCFullYear(),
    date_mont       : date_get.getMonth(),
    date_day        : date_get.getDate(),
    date_hours      : date_get.getHours(),
    date_minutes    : date_get.getMinutes(),
    date_seconds    : date_get.getSeconds(),
    date_week       : week, 
}

export const    result_complete     = `"${objectData.date_year}-${objectData.date_mont}-${objectData.date_day} ${objectData.date_hours}:${objectData.date_minutes}:${objectData.date_seconds}"`;
export const    result_sub_complete = `"${objectData.date_year}-${objectData.date_mont}-${objectData.date_day}"`;
export const    result_month        = parseInt(`${objectData.date_mont}`);
export const    result_day          = parseInt(`${objectData.date_day}`);
export const    result_week         = parseInt(`${objectData.date_week}`);
