import React, { useState }  from 'react';
import Box                  from '@mui/material/Box';
import TextField            from '@mui/material/TextField';
import LoginSuccess         from './LoginSuccess.component';
import ProgressBar          from '../Progress/ProgressBar.component';
import { SweetAlertBasic }  from '../../utils/sweetAlert';
import { loginUserFn }      from '../../actions/loginActions';
import Button               from '@mui/material/Button';
import { 
        useDispatch, 
        useSelector 
    }                       from 'react-redux';
import {
        DivLogin, 
        DivLoginCenter, 
        LoginTitle, 
        DivFlexCenter, 
        ButtonLogin
    } from './Login.styles';



const Login = () => {

    // Dispatch
    const dispatch      = useDispatch();
    const loginUser     = (user) => dispatch( loginUserFn(user) );
    const loginSuccess  = useSelector( state => state.login?.login );
    const loginLoading  = useSelector( state => state.login?.loading);

    const [ correo, setCorreo ]         = useState('');
    const [ password, setPaswword ]     = useState('');


    // Create of User
    const loginUserForm = e => {
        e.preventDefault();

        //Validate form
        if( correo.trim() === '' || password.trim() === '' ){
            SweetAlertBasic("error","Ups","Todos los datos son obligatorios");
            return;
        }
        loginUser({
            email_usuario: correo, 
            password_usuario: password
        })

    } 

    return (  
        <>
            {
                loginSuccess ?
                    <LoginSuccess user={loginSuccess}/>
                :
                null
            }
            {
                loginLoading  ?
                    <ProgressBar title="Iniciando sesión..."/> 

                  :

                  <DivLoginCenter>
                  <DivLogin>
                      <LoginTitle>
                          CRM Plusvarq
                      </LoginTitle>
                      <DivFlexCenter
                          
                      >
                          <Box
                              component="form"
                              sx={{
                                  '& > :not(style)': { m: 2, width: '300px' },
                              }}
                              noValidate
                              autoComplete="off"
                              onSubmit={loginUserForm}
                          >
                              <TextField 
                                  id          ="outlined-basic" 
                                  label       ="Ingrese su correo" 
                                  variant     ="outlined"
                                  value       ={correo}
                                  onChange    ={ e => setCorreo(e.target.value) }
                              />
                              <TextField  
                                  id          ="outlined-basic" 
                                  label       ="Ingrese su contraseña" 
                                  variant     ="outlined"
                                  value       ={password}
                                  type        ="password"
                                  autoComplete="off"
                                  onChange    ={ e => setPaswword(e.target.value) }
                              />
                               {
                                  loginSuccess ?
                                      <Button 
                                          type="submit"
                                          disabled
                                      >
                                          Iniciar Sesión
                                      </Button>
                                  :
                                  
                                      <ButtonLogin 
                                          type="submit"
                                          >
                                          Iniciar Sesión
                                      </ButtonLogin>
                              }
                          </Box>
                      </DivFlexCenter>
                  </DivLogin>
                  
              </DivLoginCenter>
            }
           
        </>
    );
}
 
export default Login;