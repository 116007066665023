import axios from "axios";

let url = window.location.hostname;
let imageAxios = ({});
console.log(url);
if(url === 'localhost'  || url === 'crmplusvarq-dev.netlify.app'  ){
    imageAxios = axios.create({
        baseURL: "https://plusvarq-api-dev.herokuapp.com/api/v1/",
        headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "X-API-Key": "YfrT98cGcv90h5DVVBGDD8oDfdvbWPvcbn3RvGc3pl",
        },
    });
    
}else{
    imageAxios = axios.create({
        baseURL: "https://plusvarq-api-dev.herokuapp.com/api/v1/",
        headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "X-API-Key": "YfrT98cGcv90h5DVVBGDD8oDfdvbWPvcbn3RvGc3pl",
        },
    });
    // imageAxios = axios.create({
    //     baseURL: "https://plusvarq-api-prod.herokuapp.com/api/v1/",
    //     headers: {
    //         "Content-Type": "multipart/form-data",
    //         "Access-Control-Allow-Origin": "*",
    //         "X-API-Key": "kjchukldurlXuovwbZqgxfwvnk_zdza2bfhrfjbi__Trz",
    //     },
    // });
}
export default imageAxios;

