import { 
    DESARROLLADOR_CREATE,
    DESARROLLADOR_CREATE_SUCCESS,
    DESARROLLADOR_CREATE_ERROR,
    DESARROLLADOR_CREATE_INIT,
    DESARROLLADOR_LOADING,
    DESARROLLADOR_LOADING_SUCCESS,
    DESARROLLADOR_LOADING_ERROR,
    DESARROLLADOR_DESACTIVE,
    DESARROLLADOR_DESACTIVE_ERROR,
    DESARROLLADOR_LOAD,
    DESARROLLADOR_LOAD_SUCCESS,
    DESARROLLADOR_LOAD_ERROR,
    DESARROLLADOR_UPDATE_SUCCESS,
    DESARROLLADOR_UPDATE_ERROR,
    DESARROLLADOR_UPDATE_INIT,
} from '../types';

/*
    FLAG
        Revisamos si esta ok el desarrollador y lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
        Loading: false(enseña el cargando)
    
    LOADING
        True: Quitamos el componente loading(cargando) 
        False: Se enseña el componente cargando
*/
const initialState = {
    desarrolladores             : [],
    error                       : null,
    loading                     : false,
    loadingUpdate               : false,  
    flag                        : false, 
    desactiveDesarrollador      : null,
    editarDesarrollador         : null,
    desarrolladorActualizado    : null,
    flagUpdate                  : false
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case    DESARROLLADOR_CREATE:
        case    DESARROLLADOR_LOAD:
        case    DESARROLLADOR_LOADING:
        case    DESARROLLADOR_UPDATE_INIT:

            return{
                ...state,
                loading: true,
                loadingUpdate: true,
                flag: false,
            }

        case DESARROLLADOR_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case DESARROLLADOR_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                desarrolladores: [ ...state.desarrolladores, action.payload ]
            }

        case DESARROLLADOR_CREATE_ERROR:
        case DESARROLLADOR_LOADING_ERROR:
        case DESARROLLADOR_DESACTIVE_ERROR:
        case DESARROLLADOR_LOAD_ERROR:
        case DESARROLLADOR_UPDATE_ERROR:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case DESARROLLADOR_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                desarrolladores: action.payload,
                flag: false,
                desactiveDesarrollador: null
            }

        case DESARROLLADOR_DESACTIVE:
            return{
                ...state,
                desactiveDesarrollador: action.payload
            }

        case DESARROLLADOR_LOAD_SUCCESS:
            return{
                ...state,
                desarrolladores: [...state.desarrolladores],
                editarDesarrollador: action.editarDesarrollador,
                flag: true,
                loading: false
            }
        
        case DESARROLLADOR_UPDATE_SUCCESS:
            return{
                ...state,
                desarrolladorActualizado: action.desarrolladorActualizado,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
        default:
            return state;
    }
}