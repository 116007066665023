import React            from 'react';
import Card             from '@mui/material/Card';
import CardActions      from '@mui/material/CardActions';
import CardContent      from '@mui/material/CardContent';
import Typography       from '@mui/material/Typography';
import Button           from '@mui/material/Button';


const SeguimientosCliente = ({data}) => {

    return ( 
        <>
            {data.map((seguimiento, i) => (
                <Card 
                    sx={{ maxWidth: '100%', marginBottom: 5}}
                    key={i}
                >
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Creado por: {seguimiento?.nombre_usuario}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Descripción: </strong><br/>
                            {seguimiento?.descripcion_nota_seguimiento_cliente}
                        </Typography><br/>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Actualización: </strong><br/>
                            {seguimiento?.descripcion_seguimiento_cliente}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Fecha de creación:  {seguimiento?.created_at}</Button>
                        <Button size="small">Tipo de seguimiento: {seguimiento?.tipo_seguimiento_cliente}</Button><br/>
                    </CardActions>
                </Card>  
                ))
            }       
        </>
     );
}
 
export default SeguimientosCliente;