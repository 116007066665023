import { 
    ROLES_LOADING,
    ROLES_LOADING_SUCCESS,
    ROLES_LOADING_ERROR,
    ROL_LOAD,
    ROL_LOAD_SUCCESS,
    ROL_LOAD_ERROR,
} from '../types';

const initialState = {
    roles       : [],
    error       : null,
    loading     : false,
    flag        : false,
    editarRol   : null,
    desactiveRol: null
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case ROL_LOAD:
        case ROLES_LOADING:
            return{
                ...state,
                loading : true,
                flag    : false,
            }

        case ROLES_LOADING_ERROR:
        case ROL_LOAD_ERROR:
            return{
                ...state,
                loading : false,
                flag    : action.flag,
                error   : action.error
            }
        
        case ROLES_LOADING_SUCCESS:
            return{
                ...state,
                loading             : false,
                error               : false,
                roles               : action.payload,
                flag                : false,
                desactiveCliente    : null
            }

        case ROL_LOAD_SUCCESS:
            return{
                ...state,
                roles           : [...state.roles],
                editarRol       : action.editarRol,
                flag            : true,
                loading         : false
            }
        default:
            return state;
    }
}