import styled from 'styled-components';


//Pantallas grandes (desktops de menos de 1200px)
// @media (max-width: 1199.98px) { ... }

//Pantallas medianas (tablets de menos de 992px)
// @media (max-width: 991.98px) { ... }

//Pantallas pequeñas (móviles en landscape de menos de 768px)
// @media (max-width: 767.98px) { ... }

//Pantallas muy pequeñas (móviles en portrait de menos de 576px)
// @media (max-width: 575.98px) { ... }

export const WrapperInformation = styled.div`
    
    display: none;

    @media (max-width: 767.98px) { 
        display: grid;
        grid-template-columns: .2fr 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 25px;
        grid-row-gap: 0px;
        background-color: #ece2c666;
        border-radius: 5px;
        margin: 1rem 1rem 2rem 1rem;
        height: 5rem;
        padding: 1rem;

        .house_name {
            font-size:14px;
            color: #004274;
            font-weight: bold;
            height: 100%;
            width: 100%;
        }
        .house_direction{
            width: 200px;
            height:60px;
            margin-top: 5px;
            font-size:14px;
            color: #004274;
            overflow-y: scroll;
        }
        .house_cp{
            width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .detail_house{
            grid-area: 1 / 1 / 4 / 2;
            font-weight: bold;
            color: #004274;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
        }

        .price_total{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: #004274;
            font-weight: bold;     
            margin-top: 15px;

        }
        
    }

`

export const WrapperSchedule = styled.div`

    display: none;

    @media (max-width: 767.98px) { 
        display: block;
        width: 100%;
        height: 5rem;
        background-color:  #004274;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 2;
    }

    .button_schedule{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        height: 100%;
        margin: 0 auto;
        font-weight: bold;
    }

`;

export const WrapperDetailHouse = styled.div`
    

`

export const DivOpciones = styled.div`
    display: flex;
    width: 82%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin:10px;
    height: 43rem;
    overflow-y: auto;
    margin: 0 auto;
    margin-left: 19rem;
    background-color: #E5E5E5;
    @media only screen and (max-width: 768px) {
        display: block;
        width:100%;
        margin-left: 0;
        height: 55rem;
        overflow-y: auto;
    }

`;
export const DivOpcionesClient = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin:10px;
    height: 40rem;
    overflow-y: auto;
    margin: 0 auto;
    background-color: #E5E5E5;

`;



export const DivCasas = styled.div`
    width: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
`
export const ButtonsCasa = styled.div`
    display: flex;
    align-items: flex-end;
    width: 60%;
    flex-direction: initial;
    justify-content: end;
    margin: 15px;

    @media only screen and (max-width: 768px) {
        display: flex;
        justify-content: space-around;
        width: 90%;
    }
`

export const TitleRed = styled.div`
    color: #c62828;
    font-weight: bold;
`

export const TitleGreen = styled.div`
    color: #2e7d32;
    font-weight: bold;
`

export const DivOpcionesCasaId = styled.div`
    margin-top: 5%;
    margin-left: 10%;
    display: flex;
    width: 80%;
    background-color: #ece2c666;
    border-radius: 20px;
    @media (max-width: 767.98px) { 
        margin-left: 0%;
        margin-top: 0%;
    }
`
export const DivSubOpciones = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 767.98px) { 
      display:none;
    }
`
export const TitleGeneral = styled.div`
    font-size: 24px;
    color: #004274;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
`

export const TitleGeneralDetail = styled.div`
    font-size: 24px;
    color: #004274;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    @media (max-width: 767.98px) { 
        margin-bottom: 2rem;
    }
`

export const TitleGeneralDetailPrice = styled.div`
    font-size: 24px;
    color: #004274;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    @media (max-width: 767.98px) { 
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
`

export const SubtitleGeneral = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #004274;
`
export const DetailIcons = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const DetailImage = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const SubtitleGeneralPrice = styled.div`
    font-size: 22px;
    color: #004274;
`

export const DetalleCasa = styled.div`
    display: grid;
    grid-template-columns: .5fr 1.3fr .5fr 1.3fr;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 25px;
`

export const DivFormSchedule = styled.div`
    display: flex;
    width: 32rem;
    justify-content: center;
    margin-bottom: 1rem;
`

export const DivButtonSchedule  = styled.div`
    margin-top: 5%;
`

export const ImageShare = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`

export const DivDataGeneral = styled.div`
    margin-top: 3%;
    margin-bottom:5%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 22rem;
    background-color: #ece2c666;
    border-radius: 20px;
    @media (max-width: 767.98px) { 
        width:90%;
        height: 100%;
        background-color: #ffff;
        margin-left: 5%;
        margin-top: 0%;
        margin-bottom:15%;
    }

`

export const DivRow = styled.div`
    margin:30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media (max-width: 767.98px) { 
        flex-direction: column;
    }
`
export const WrapperData = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2rem;
`

export const WrapperSuccess = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; 
    flex-direction: column;

    .text_success{
        display: flex;
        align-items: center;
        justify-content:center;
        flex-direction: column;
        font-size:16px;
        height: 100%;
        width: 90%;
        margin: 30px;
        font-weight: bold;
        color: #004274;
    }
`

export const TitleAndButton = styled.div`
    display: flex;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    margin-left: 19.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 3rem;
`

export const TitleHouseGrid = styled.h1`
    font-size: 36px;
    font-weight: bold;
    color: #004274;
    margin-left:0%;
`

export const TextEmpty = styled.div`
    font-weight: bold;
    color: #004274;
    font-size: 28px;
    text-align: center;
    margin-top: 20px;
}
`

export const Image = styled.img`
    width: 250px;
    height: 250px;
`
export const WrapperEmpty = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`