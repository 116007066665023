import styled from 'styled-components';


export const WrapperContact = styled.div`
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: .1fr 1fr 1.2fr 1.5fr 5fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .actions_contact{
        grid-area: 1 / 1 / 2 / 6;
        display: flex;
        justify-content: end;
        align-items: end;
        margin-right: 10%;
        margin-top: 1rem;
    }

    .avatar_contact{
        grid-area: 2 / 1 / 3 / 6;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0rem 2rem 0rem 2rem;
    }
    .data_contact{
        grid-area: 3 / 1 / 4 / 6;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        h4{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
        }
    }
    .information_contact{
        grid-area: 4 / 1 / 5 / 6;
        background-color: #004274;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 70%;
        margin: 0 auto;
        border-radius: 10px;
        padding: 5px 10px 10px 10px;
    }
    .accordion_contact{
        grid-area: 5 / 1 / 6 / 6;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
`