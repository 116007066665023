import React from 'react';

const FilterSuccess = () => {
    return ( 
        <>
            <h1>Filter Success</h1>
        </>
     );
}
 
export default FilterSuccess;