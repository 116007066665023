import 'react-pro-sidebar/dist/css/styles.css';
import React, { useState,useEffect }            from 'react'
import InputLabel                               from '@mui/material/InputLabel';
import MenuItem                                 from '@mui/material/MenuItem';
import Select                                   from '@mui/material/Select';
import FormControl                              from '@mui/material/FormControl';
import Box                                      from '@mui/material/Box';
import { ProSidebar }                           from 'react-pro-sidebar';
import { useDispatch, useSelector }             from 'react-redux';
import SearchOptions                            from '../../Images/search_opciones.png';
import Button                                   from '@mui/material/Button';
import SearchIcon                               from '@mui/icons-material/Search';
import  { getAllTeamsAction }                   from '../../actions/teamActions';
import  { getAllUsersAction }                   from '../../actions/userActions';
import { 
    getOptionTeamAction,
    getOptionUserAction,
    getOptionClientAction,
}                                               from '../../actions/optionsActions';
import{ 
    getAllClientsByUserAction 
}                                               from '../../actions/clientActions';

import{
    SidebarBox,
    ImageOptions,
    WrapperOptions,
    TitleOptions
} from './Sidebar.styles';

const SidebarOpciones = () => {

    const [options, setOptions]             = useState({});
    const [enabledButton, setEnabledButton] = useState(false);


    const clientSuccess             = useSelector( state => state.clientes?.clientesEspecialistas);
    const teamSuccess               = useSelector( state => state.equipos?.equipos);
    const userSuccess               = useSelector( state => state.usuarios.usuarios);
    const id_user                   = useSelector( state => state.login.user?.id );

    const dispatch                  = useDispatch();
    const loadingTeams              = () => dispatch( getAllTeamsAction() );
    const loadingUsers              = () => dispatch( getAllUsersAction() ); 
    const getOptionsTeam            = (id_equipo) => dispatch( getOptionTeamAction(id_equipo) );
    const getOptionUser             = (id_user) => dispatch( getOptionUserAction(id_user) );
    const getOptionClient           = (id_client) => dispatch( getOptionClientAction(id_client) );


    
    let userLocal   = JSON.parse(localStorage.getItem('user'))
    let rol         = userLocal?.rol

    function loadGrid(){
        if( id_user){
            const loadingClientsByUser = () => dispatch( getAllClientsByUserAction(id_user) );
            loadingClientsByUser();
        }else{
            const loadingClientsByUser = () => dispatch( getAllClientsByUserAction(userLocal?.id) );
            loadingClientsByUser();
        }
    }

    // Change Value TextField
    const onChangeForm = ( event ) => {
        setEnabledButton(false);
        setOptions({[event.target.name]: event.target.value})
    }

    useEffect( () => {
        loadingTeams();
        loadingUsers();
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );    

    const searchOption = e => {
        setEnabledButton(true);
        e.preventDefault();
        console.log(options);
        if(options.id_usuario)getOptionUser(options.id_usuario);
        if(options.id_equipo)getOptionsTeam(options.id_equipo)
        if(options.id_cliente)getOptionClient(options.id_cliente);
     
    }

    return ( 
        <SidebarBox>
            <ProSidebar>
                <WrapperOptions>
                    <ImageOptions
                        src={SearchOptions}
                    />
                    <TitleOptions>Buscar opciones </TitleOptions>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)':{},
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={searchOption}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}

                        >
                            {
                                [6].includes(rol) ?   
                                    <>
                                        <FormControl 
                                            style={{ 
                                                width: '90%',
                                                marginTop: '30px',
                                                marginBottom: '30px',
                                                backgroundColor : 'white',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            <InputLabel id="demo-controlled-open-select-label">Especialista</InputLabel>
                                            <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            name="id_usuario"
                                            label="Especialista"
                                            // defaultValue={id_usuario}
                                            onChange={onChangeForm}
                                            >
                                            {
                                                userSuccess
                                                .filter(specialist => specialist.id_equipo === userLocal?.equipo)
                                                .map((user) => (
                                                <MenuItem
                                                    key={user.id_usuario}
                                                    value={user.id_usuario}
                                                >
                                               {user.email_usuario}
                                                </MenuItem>
                                                ))
                                            }
                                            </Select>
                                        </FormControl>
                                    </>
                                :
                                null
                            }
                            {
                                [1].includes(rol) ?   
                                    <>
                                    <FormControl 
                                        style={{ 
                                            width: '90%',
                                            marginTop: '30px',
                                            marginBottom: '30px',
                                            backgroundColor : 'white',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <InputLabel id="demo-controlled-open-select-label">Equipo</InputLabel>
                                        <Select
                                            id="demo-controlled-open-select"
                                            name="id_equipo"
                                            defaultValue={''}
                                            onChange={onChangeForm}
                                        >
                                        {teamSuccess.map((team) => (
                                            <MenuItem
                                                key={team.id_equipo}
                                                value={team.id_equipo}
                                            >
                                            {team.nombre_equipo}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    </>
                                :
                                    null

                            }
                            {
                                [2].includes(rol) ?   
                                    <>
                                    <FormControl 
                                        style={{ 
                                            width: '90%',
                                            marginTop: '30px',
                                            marginBottom: '30px',
                                            backgroundColor : 'white',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <InputLabel id="demo-controlled-open-select-label">Clientes</InputLabel>
                                        <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        name="id_cliente"
                                        label="Cliente"
                                        // defaultValue={id_cliente}
                                        onChange={onChangeForm}
                                        
                                        >
                                        {clientSuccess?.map((client) => (
                                            <MenuItem
                                                key={client.id_cliente}
                                                value={client.id_cliente}
                                            >
                                            {client.nombre_cliente}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    </>
                                :
                                    null

                            }
                            <Button 
                                variant     ="contained" 
                                color       ="success"
                                type        ="submit"
                                startIcon   ={<SearchIcon />}
                                disabled    = {enabledButton}
                                style       ={{backgroundColor: enabledButton ? 'gray': null}}
                                
                            >
                            Buscar Opciones
                            </Button>
                        </Box>
                </WrapperOptions>
            </ProSidebar>
        </SidebarBox>
     );
}
 
export default SidebarOpciones;