import clientAxios                  from '../config/axios';
import { SweetAlertBasic }           from '../utils/sweetAlert';
import { 
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_INIT,
    LOGIN_ACCESS,
    LOGIN_DENEGED,
    LOGOUT_INIT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
} from '../types';


// LOGIN
    export function loginUserFn(user) {

        return async ( dispatch ) => {
            dispatch( loginInit() );

            try{
           
                let response = await clientAxios.post('/usuarios/login', user);
                dispatch( loginUserSuccess(response.data.data) );

                // Guardamos el usuario en localStorage
                let dataStorage ={
                    id: response.data.data.id_usuario,
                    nombre: `${response.data.data.nombre_usuario} ${response.data.data.apellido_paterno_usuario} ${response.data.data.apellido_materno_usuario} `,
                    rol: response.data.data.id_rol,
                    equipo: response.data.data.id_equipo
                }
                localStorage.setItem('user', JSON.stringify(dataStorage));

            }catch( error ){
                console.log(error);
                dispatch( loginUserError(true) );
                SweetAlertBasic("error","Ups","Hubo un error al iniciar sesión.");
            }
        }

    }

        const loginInit = () =>({
            type: LOGIN_INIT
        })

        const loginUserSuccess = (user) =>({
            type: LOGIN_SUCCESS,
            login: user
        })

        const loginUserError = () =>({
            type:LOGIN_ERROR,
            error: true
        }) 
    
// LOGIN ACCESS
    export function getLoginUserFn() {

        return async ( dispatch  ) => {
            dispatch( loginInit() );
            let userLocal = JSON.parse(localStorage.getItem('user'))
            try {
                dispatch( loginAccess(userLocal) );
            } catch (error) {
                console.log("denegado->", error);
                dispatch( loginDeneged() );
            }
        }
    }
        const loginAccess = (user) =>({
            type: LOGIN_ACCESS,
            user: user
        })

        const loginDeneged = () =>({
            type:LOGIN_DENEGED,
            error: true
        }) 

// LOGOUT
export function logoutUserFn() {

    return async ( dispatch  ) => {
        
        dispatch( logoutInit() );
        try {
            console.log("ENTRO LOGOUT");
            localStorage.removeItem('user');
            dispatch( logoutSucces() );
        } catch (error) {
            dispatch( logoutError() );
        }
    }
}

    const logoutInit = () =>({
        type: LOGOUT_INIT
    });

    const logoutSucces = () =>({
        type: LOGOUT_SUCCESS
    })

    const logoutError = () =>({
        type:LOGOUT_ERROR,
        error: true
    }) 