import React, { useState }          from 'react'
import { createCalendaryActionFn }     from '../../actions/calendaryActions';
import { useDispatch, useSelector } from 'react-redux';
import { SweetAlertBasic }          from '../../utils/sweetAlert';
import { useNavigate, Navigate }    from 'react-router-dom';
import ProgressBar                  from '../Progress/ProgressBar.component';
import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import InputLabel                   from '@mui/material/InputLabel';
import MenuItem                     from '@mui/material/MenuItem';
import Select                       from '@mui/material/Select';
import FormControl                  from '@mui/material/FormControl';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import Stack                        from '@mui/material/Stack';
import AdapterDateFns               from '@mui/lab/AdapterDateFns';
import LocalizationProvider         from '@mui/lab/LocalizationProvider';
import TimePicker                   from '@mui/lab/TimePicker';

import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
} from '../Usuarios/Users.styles';

const NewCalendary = () => {

    const [ nombreCalendario, setNombreCalendario ]                     = useState('');
    const [ tipoCalendario, setTipoCalendario ]                         = useState('');
    const [ horarioInicioCalendario, setHorarioInicioCalendario]        = useState(Date.now());
    const [ horarioFinalCalendario, setHorarioFinalCalendario]          = useState(Date.now());
    const [ prioridadCalendario, setPrioridadCalendario]                = useState('');
    const [ loginCalendario, setLoginCalendario]                        = useState('');


    const handleInicioCalendario = (event) => {
        setHorarioInicioCalendario(event);
    };

    const handleFinalCalendario = (event) => {
        setHorarioFinalCalendario(event);
    };

    const handleTipoCalendario = (event) => {
        setTipoCalendario(event.target.value);
    };

    const handlePrioridadCalendario = (event) => {
        setPrioridadCalendario(event.target.value);
    };

    const handleLoginCalendario = (event) => {
        setLoginCalendario(event.target.value);
    };
    
    const dispatch              = useDispatch();
    const addCreateCalendary    = (calendary) => dispatch( createCalendaryActionFn(calendary) );
    const loading               = useSelector( state => state.calendarios?.loading);
    const calendarySuccess      = useSelector( state => state.calendarios?.flag);

    const navigate = useNavigate();

    function goCalendarys() {
        navigate('/calendarios');
    }

    const createCalendary = e => {
        e.preventDefault();

        //Validate form
        if( nombreCalendario.trim() === '' ){
            SweetAlertBasic("error","Ups","Le falta llenar campos");
            return;
        }

      
        // Create Calendary
        addCreateCalendary({
            nombre_calendario           : nombreCalendario,
            horario_inicio_calendario   : horarioInicioCalendario,
            horario_final_calendario    : horarioFinalCalendario,
            tipo_calendario             : tipoCalendario,
            prioridad_calendario        : prioridadCalendario,
            login_calendario            : loginCalendario
        });

    
        // setNombreCalendario('');
        // setHorarioInicioCalendario('');
        // setHorarioFinalCalendario('');
        // setTipoCalendario('');
        // setPrioridadCalendario('');
        // setLoginCalendario('');
    }

    return ( 
        <DivCenter >
            {
                loading ? 
                    <ProgressBar title="Creando calendario..."/>
                :
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)':{},
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={createCalendary}
                    
                >
                    <TitleUser>Crear Calendario</TitleUser>

                            <RowUser>
                                <TextField  
                                    id              = "outlined-basic" 
                                    label           = "Ingresa el nombre del calendario"  
                                    variant         = "outlined"
                                    type            = "text"
                                    name            = "nombreCalendario"
                                    value           = {nombreCalendario}
                                    onChange        = { e => setNombreCalendario(e.target.value) }
                                    fullWidth
                                />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack>
                                        <TimePicker
                                            label       ="Inicio de Horario"
                                            value       ={horarioInicioCalendario}
                                            onChange    ={handleInicioCalendario}
                                            renderInput ={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack>
                                        <TimePicker
                                            label       ="Fin de Horario"
                                            value       ={horarioFinalCalendario}
                                            onChange    ={handleFinalCalendario}
                                            renderInput ={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tipo de Calendario</InputLabel>
                                    <Select
                                        labelId     = "tipo_calendario"
                                        id          = "demo-simple-select"
                                        label       = "Tipo de Calendario"
                                        value       = {tipoCalendario}
                                        onChange    = {handleTipoCalendario}
                                    >
                                        <MenuItem value="Venta">Venta</MenuItem>
                                        <MenuItem value="Renta">Renta</MenuItem>
                                        <MenuItem value="Compra">Compra</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Prioridad de Calendario</InputLabel>
                                    <Select
                                        labelId     = "prioridad_calendario"
                                        id          = "demo-simple-select"
                                        label       = "Prioridad de Calendario"
                                        value       = {prioridadCalendario}
                                        onChange    = {handlePrioridadCalendario}
                                    >
                                        <MenuItem value={1}>Alta</MenuItem>
                                        <MenuItem value={2}>Media</MenuItem>
                                        <MenuItem value={3}>Baja</MenuItem>
                                        <MenuItem value={4}>Sin Prioridad</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Login</InputLabel>
                                    <Select
                                        labelId     ="login_calendario"
                                        id          ="demo-simple-select"
                                        label       ="Login de Calendario"
                                        value       ={loginCalendario}
                                        onChange    ={handleLoginCalendario}
                                    >
                                        <MenuItem value={true}>Con Login</MenuItem>
                                        <MenuItem value={false}>Sin Login</MenuItem>
                                    </Select>
                                </FormControl>
                            </RowUser>
                    
                    <DivButtons >
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick     ={goCalendarys}
                                variant     ="contained" 
                                size        ="large"
                                color       ="error"
                            >
                                Cancelar
                            </Button>
                        
                            <Button 
                                variant     ="contained" 
                                size        ="small" 
                                type        ="submit"
                                startIcon   ={<SaveIcon />}
                            >
                                Crear Calendario
                            </Button>
                        </Stack>
                    </DivButtons>
                </Box>
            }
            {
                calendarySuccess ?
                    <Navigate to='/calendarios/exitoso'/>
                :
                null
            }
            
            
        </DivCenter>  
     );
}
 
export default NewCalendary;