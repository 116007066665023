import { 
    OPTION_CREATE,
    OPTION_CREATE_SUCCESS,
    OPTION_CREATE_ERROR,
    OPTION_CREATE_INIT,
    OPTIONS_LOADING,
    OPTIONS_LOADING_SUCCESS,
    OPTIONS_LOADING_ERROR,
    OPTION_CLIENT_LOAD,
    OPTION_CLIENT_LOAD_SUCCESS,
    OPTION_CLIENT_LOAD_ERROR,
    OPTION_TEAM_LOAD,
    OPTION_TEAM_LOAD_SUCCESS,
    OPTION_TEAM_LOAD_ERROR,
    OPTION_USER_LOAD,
    OPTION_USER_LOAD_SUCCESS,
    OPTION_USER_LOAD_ERROR,
    OPTION_HOUSE_LOAD,
    OPTION_HOUSE_LOAD_SUCCESS,
    OPTION_HOUSE_LOAD_ERROR,
} from '../types';

/*
    FLAG
        Revisamos si esta ok el usuario y lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
        Loading: false(enseña el cargando)
    
    LOADING
        True: Quitamos el componente loading(cargando) 
        False: Se enseña el componente cargando
*/
const initialState = {
    opciones            : [],
    error               : null,
    loading             : false,
    loadingUpdate       : false,  
    flag                : false, 
    flagUpdate          : false,
    flagViewOpcionesUser        :false,
    flagViewOpcionesClient      : false,
    flagViewOpcionesTeam    : false,
    opcionUsuario       : null,
    opcionCliente       : null,
    opcionEquipo        : null,
    opionCasa           : null,
    opcionClienteSales : null,
    
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case    OPTION_CREATE:
        case    OPTIONS_LOADING:
        case    OPTION_HOUSE_LOAD:
        case    OPTION_TEAM_LOAD:
        case    OPTION_CLIENT_LOAD:
        case    OPTION_USER_LOAD:
 
            return{
                ...state,
                loading: true,
                loadingUpdate: true,
                flag: false,
                flagViewOpciones    :false,
            }

        case OPTION_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case OPTION_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                opciones: [ ...state.opciones, action.payload ]
            }

        case OPTION_CREATE_ERROR:
        case OPTIONS_LOADING_ERROR:
        case OPTION_TEAM_LOAD_ERROR:
        case OPTION_USER_LOAD_ERROR:
        case OPTION_CLIENT_LOAD_ERROR: 
        case OPTION_HOUSE_LOAD_ERROR:           

            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case OPTIONS_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                opciones: action.payload,
                flag: false
            }
        
        case OPTION_CLIENT_LOAD_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                opcionCliente: action.opcionCliente,
                opcionClienteSales: action.opcionClienteSales,
                flag: false,
                flagViewOpcionesClient    :action.flagViewOpcionesClient,
                flagViewOpcionesTeam    : false,
                flagViewOpcionesUser : false,
            }

        case OPTION_TEAM_LOAD_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                opcionEquipo: action.opcionEquipo,
                opcionUsuario: action.opcionUsuario,
                opcionCliente: action.opcionCliente,
                flag: false,
                flagViewOpcionesTeam    :action.flagViewOpcionesTeam,
                flagViewOpcionesClient    : false,
                flagViewOpcionesUser : false,
            }
        
        case OPTION_USER_LOAD_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                opcionUsuario: action.opcionUsuario,
                flag: false,
                flagViewOpcionesUser    :action.flagViewOpcionesUser,
                flagViewOpcionesTeam    : false,
                flagViewOpcionesClient : false,
            }
    
        case OPTION_HOUSE_LOAD_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                opionCasa: action.opionCasa,
                flag: false
            }

        
        default:
            return state;
    }
}