import React                        from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch }  from 'react-redux';
import {
    ImageUserSuccess,
    TitleUserSuccess,
    TitleUserGreen,
    DivUserSuccess,
    ButtonUserSuccess
}                                   from '../Usuarios/Users.styles';
import ImageUser                    from '../../Images/team_success.png';
import{ 
    getAllTeamsAction 
}                               from '../../actions/teamActions';


const TeamSuccess = () => {

    const navigate          = useNavigate();
    const dispatch          = useDispatch();
    const loadingTeams = () => dispatch( getAllTeamsAction() );
    
    function goTeams() {
        loadingTeams();
        navigate('/equipos');
    }

    return ( 
        <>
            <DivUserSuccess>
                <ImageUserSuccess  src={ImageUser} alt="logo" />
                <TitleUserSuccess>Se ha 
                    <TitleUserGreen>
                        creado
                    </TitleUserGreen> 
                    el 
                    <TitleUserGreen>
                        equipo
                    </TitleUserGreen> 
                    exitosamente.
                </TitleUserSuccess> 
                <ButtonUserSuccess
                    onClick={goTeams}
                >
                    ir al Inicio
                </ButtonUserSuccess>
            </DivUserSuccess>
        </>
     );
}
 
export default TeamSuccess;