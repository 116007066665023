import styled from 'styled-components';


export const DivLoginCenter = styled.div`
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

`

export  const DivLogin = styled.div`
    background-color: #FFFF;
    border-radius: 10px;
    border: 2px solid #004274;
    max-width:450px;
    text-align: center;
`;

export const LoginTitle = styled.div`
    background-color: #004274;
    height: 55px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: bold;

    /* top-left | top-right | bottom-right | bottom-left */
    border-radius: 5px 5px 0px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const DivFlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height:270px;
    flex-direction: column;
`

export const InputLogin = styled.input`
    width: 80%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    margin-bottom: 30px;
    
    ::placeholder {
        color: #C4C4C4;
        padding-left:10px;
        font-size: 14px;
    }
`

export const ButtonLogin = styled.button`
    width: 80%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    background-color:  #004274;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;

`

