import React                        from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch }              from 'react-redux';

import {
    ImageUserSuccess,
    TitleUserSuccess,
    TitleUserGreen,
    DivUserSuccess,
    ButtonUserSuccess
} from './Users.styles';
import ImageUser                    from '../../Images/user_success.png';

import{ getAllUsersAction }         from '../../actions/userActions';


const UserUpdateSuccess = () => {

    const navigate          = useNavigate();
    const dispatch          = useDispatch();
    const loadingUsers      = () => dispatch( getAllUsersAction() );
    
    
    function goUsers() {
        loadingUsers();
        navigate('/usuarios');
    }

    return ( 
        <>
           
            <DivUserSuccess>
                <ImageUserSuccess  src={ImageUser} alt="logo" />
                <TitleUserSuccess>Se ha 
                    <TitleUserGreen>
                    actualizado
                    </TitleUserGreen> 
                    el 
                    <TitleUserGreen>
                        usuario
                    </TitleUserGreen> 
                    exitosamente.
                </TitleUserSuccess> 
                <ButtonUserSuccess
                    onClick={goUsers}
                >
                    ir al Inicio
                </ButtonUserSuccess>
            </DivUserSuccess>
        </>
     );
}
 
export default UserUpdateSuccess;