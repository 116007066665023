import styled from 'styled-components';

//Pantallas grandes (desktops de menos de 1200px)
// @media (max-width: 1199.98px) { ... }

//Pantallas medianas (tablets de menos de 992px)
// @media (max-width: 991.98px) { ... }

//Pantallas pequeñas (móviles en landscape de menos de 768px)
// @media (max-width: 767.98px) { ... }

//Pantallas muy pequeñas (móviles en portrait de menos de 576px)
// @media (max-width: 575.98px) { ... }

export const WrapperRegister = styled.div`
    width: auto;
    height: 40rem;
    overflow: hidden;
    position: relative;

    .form_register{
        display: flex;
        flex-direction: column;
        width: 80%;
       
        margin-top: 5%;
        .input_register{
            margin-top: 3%;
            margin-bottom:3%;
            background-color: white;
            border-radius: 5px;
        }
    }

    .image_register{
        filter: brightness(0.5);
        filter: brightness(0.5);
        position: absolute;
        left: -100%;
        right: -100%;
        bottom: -38%;
        margin: auto;
        min-height: 100%;
        min-width: 100%;
    }
    .image_section{
        display: flex;
    }
    .first_section{
        position: absolute;
        z-index: 1;
        color: white;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 5%;
        margin-top: 1%;

        h3{
            font-weight: normal;
        }
    }

    .button_register{
        margin-top:3%;
        width: 80%;
    }

    @media (max-width: 991.98px) { 
        width: auto;
        height: 38rem;
        overflow: hidden;
        position: relative;
    }

    @media (max-width: 575.98px) { 
        height: 38rem;

        .form_register{
            width: 100%;
        }

        .first_section{
            margin: 0 auto;
            width: 70%;
            text-align: center;
            margin: auto;
            margin: 0 auto;
            margin-left: 15%;
            margin-right: 15%;
            h3{
                margin-top: 0;
            }
        }

        .image_register{
            height: 54rem;
        }
        .button_register{
            width: 100%;
        }
        
    }
`

export const WrapperSection = styled.div`


     .landing_section_first{
        display: flex;
        flex-direction: row;
        width: 100%;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 1%;

        .landing_text{
            width: 25%;
        }

        .landing_text_first{
            font-size: 32px;
            font-weight: bold;
            text-align: center;
        }
        .landing_text_second{
            font-size: 16px;
            font-weight: normal;
            text-align: center;
        }
    }


    .image_landing{
        width: 200px;
        height: 200px;
    }

    .divider_section{
        margin: 9rem;
    }

    @media (max-width: 575.98px) {
        
        .landing_section_first{
            margin-top: 5%;

            .landing_text{
                width: 100%;
                padding: 5%;
            }
            
            .landing_text_first {
                font-size: 21px;
            }
            .landing_text_second {
                font-size: 14px;
            }
        }
        .divider_section{
            margin: 2rem;
        }
    }


    }
 
`


export const WrapperFooter = styled.div`
    width: auto;
    height: 20rem;
    overflow: hidden;
    position: relative;

    .image_register{
        filter: brightness(0.5);
        filter: brightness(0.5);
        position: absolute;
        left: -100%;
        right: -100%;
        bottom: -160%;
        margin: auto;
        min-height: 100%;
        min-width: 100%;
    }
    .image_section{
        display: flex;
    }
    .first_section{
        position: absolute;
        z-index: 1;
        color: white;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 5%;
        margin-top: 1%;

        h3{
            font-weight: normal;
        }
    }

    @media (max-width: 575.98px) { 
        height: 20rem;

        .first_section{
            margin: 0 auto;
            width: 70%;
            text-align: center;
            margin: auto;
            margin: 0 auto;
            margin-left: 15%;
            margin-right: 15%;
            h3{
                margin-top: 0;
            }
        }

        .image_register{
            height: 55rem;
        }        
    }
`