import { 
    CALENDARY_CREATE,
    CALENDARY_CREATE_SUCCESS,
    CALENDARY_CREATE_ERROR,
    CALENDARY_CREATE_INIT,
    CALENDARYS_LOADING,
    CALENDARYS_LOADING_SUCCESS,
    CALENDARYS_LOADING_ERROR,
    CALENDARY_LOAD,
    CALENDARY_LOAD_SUCCESS,
    CALENDARY_LOAD_ERROR,
    CALENDARY_DESACTIVE,
    CALENDARY_DESACTIVE_ERROR
} from '../types';

/*
    FLAG
        Revisamos si esta ok  lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
*/
const initialState = {
    calendarios: [],
    error: null,
    loading: false,
    flag: false, 
    desactiveCalendario: null,
    editarCalendario: null,
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case CALENDARY_CREATE:
        case CALENDARY_LOAD:
        case CALENDARYS_LOADING:
            return{
                ...state,
                loading: true,
                flag: false,
            }

        case CALENDARY_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case CALENDARY_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                calendarios: [ ...state.calendarios, action.payload ]
            }

        case CALENDARY_CREATE_ERROR:
        case CALENDARY_DESACTIVE_ERROR:
        case CALENDARYS_LOADING_ERROR:
        case CALENDARY_LOAD_ERROR:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case CALENDARY_DESACTIVE:
            return{
                ...state,
                desactiveCalendario: action.payload
            }
        
        case CALENDARYS_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                calendarios: action.payload,
                flag: false,
                desactiveCalendario: null
            }

        case CALENDARY_LOAD_SUCCESS:
            return{
                ...state,
                calendarios: [...state.calendarios],
                editarCalendario: action.editarCalendario,
                flag: true,
                loading: false
            }
        default:
            return state;
    }
}