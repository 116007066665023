import { 
    CLIENT_CREATE,
    CLIENT_CREATE_SUCCESS,
    CLIENT_CREATE_ERROR,
    CLIENT_CREATE_INIT,
    CLIENTS_LOADING,
    CLIENTS_LOADING_SUCCESS,
    CLIENTS_LOADING_ERROR,
    CLIENT_LOAD,
    CLIENT_LOAD_SUCCESS,
    CLIENT_LOAD_ERROR,
    CLIENTS_LOADING_BY_USER,
    CLIENTS_BY_USER_LOADING_SUCCESS,
    CLIENTS_BY_USER_LOADING_ERROR,
    CLIENT_UPDATE_SUCCESS,
    CLIENT_UPDATE_INIT,
    CLIENT_UPDATE_ERROR,
    CLIENTS_LOADING_BY_TEAM,
    CLIENTS_BY_TEAM_LOADING_SUCCESS,
    CLIENTS_BY_TEAM_LOADING_ERROR,
} from '../types';

/*
    FLAG
        Revisamos si esta ok el cliente lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
*/
const initialState = {
    clientes                : [],
    error                   : null,
    loading                 : false,
    flag                    : false, 
    desactiveCliente        : null,
    editarCliente           : null,
    clientesEspecialistas   : null,
    flagUpdate              : false,
    clientesEquipo          : null
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case CLIENT_CREATE:
        case CLIENT_LOAD:
        case CLIENTS_LOADING:
        case CLIENTS_LOADING_BY_USER:
        case CLIENTS_LOADING_BY_TEAM:
            return{
                ...state,
                loading: true,
                flag: false,
            }

        case    CLIENT_CREATE_INIT:
        case    CLIENT_UPDATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case CLIENT_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                clientes: [ ...state.clientes, action.payload ]
            }

        case CLIENT_CREATE_ERROR:
        case CLIENTS_LOADING_ERROR:
        case CLIENT_LOAD_ERROR:
        case CLIENTS_BY_USER_LOADING_ERROR:
        case CLIENT_UPDATE_ERROR:
        case CLIENTS_BY_TEAM_LOADING_ERROR:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case CLIENTS_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                clientes: action.payload,
                flag: false,
                desactiveCliente: null
            }
        
        case CLIENTS_BY_USER_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                clientesEspecialistas: action.clientesEspecialistas,
                flag: false,
                flagUpdate: true
            }
        case CLIENTS_BY_TEAM_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                clientesEquipo: action.clientesEquipo,
                flag: false,
                flagUpdate: true
            }

        case CLIENT_UPDATE_SUCCESS:
            return{
                ...state,
                clienteActualizado: action.clienteActualizado,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
        case CLIENT_LOAD_SUCCESS:
            return{
                ...state,
                clientes: [...state.clientes],
                editarCliente: action.editarCliente,
                flag: true,
                loading: false
            }
        default:
            return state;
    }
}