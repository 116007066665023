import styled from 'styled-components';

export const Progress = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
`

export const TitleProgress = styled.h1`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
`
export const ProgresReload = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
`