import React                        from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch }  from 'react-redux';
import {
    ImageUserSuccess,
    TitleUserSuccess,
    TitleUserGreen,
    DivUserSuccess,
    ButtonUserSuccess
}                                   from '../Usuarios/Users.styles';
import ImageUser                    from '../../Images/calendary_success.png';
import{ 
    getAllCalendarysAction 
}                               from '../../actions/calendaryActions';


const CalendarySuccess = () => {

    const navigate          = useNavigate();
    const dispatch          = useDispatch();
    const loadingCalendarys = () => dispatch( getAllCalendarysAction() );
    
    function goCalendary() {
        loadingCalendarys();
        navigate('/calendarios');
    }

    return ( 
        <>
            <DivUserSuccess>
                <ImageUserSuccess  src={ImageUser} alt="logo" />
                <TitleUserSuccess>Se ha 
                    <TitleUserGreen>
                        creado
                    </TitleUserGreen> 
                    el 
                    <TitleUserGreen>
                        calendario
                    </TitleUserGreen> 
                    exitosamente.
                </TitleUserSuccess> 
                <ButtonUserSuccess
                    onClick={goCalendary}
                >
                    ir al Inicio
                </ButtonUserSuccess>
            </DivUserSuccess>
        </>
     );
}
 
export default CalendarySuccess;