import React from 'react';


const EditRole = () => {
    return ( 
        <>
            <h1>Edit Role</h1>
        </>
     );
}
 
export default EditRole;