import React,{ useEffect }  from 'react';
import Button                       from '@mui/material/Button';
import AddCircleIcon                from '@mui/icons-material/AddCircle';
import AutorenewIcon                from '@mui/icons-material/Autorenew';
import ProgressBar                  from '../Progress/ProgressBar.component';
import { useNavigate }              from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid }                 from '@mui/x-data-grid';
import { 
    ColumnsClientsSales,
    ColumnsClientsVisualize,
    ColumnsClientsSalesLider
}                                   from '../../utils/rowData';
import{ 
        createClientInitFn, 
        getAllClientsAction 
    }                               from '../../actions/clientActions';
import{    
        DivGrid, 
        TitleAndButton, 
        TitleUserGrid 
    }                               from '../Usuarios/Users.styles';
import { 
    DivButtonUpdate
}                                   from './Clients.styles';


const Clients = () => {

    const dispatch          = useDispatch();
    const addCreateInit     = (client) => dispatch( createClientInitFn(client) );
    const navigate          = useNavigate();
    const clientSuccess     = useSelector( state => state.clientes?.clientes);
    const loading           = useSelector( state => state.clientes?.loading);
    const id_rol            = useSelector( state => state.login.user?.rol || {});
    const loadingClients    = () => dispatch( getAllClientsAction() );

    // USE EFFECT LOADER
    useEffect( () => {
        
        loadingClients();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    // Onclick User Init action
    function newClient() {
        addCreateInit(false);
        navigate('/clientes/crear');
    }

    function updateGrid(){
        loadingClients();
    }


    return ( 
        <>
             {
                loading ? 
                    <ProgressBar title="Cargando Clientes..."/>
                :
                <>
                        <TitleAndButton>
                            <TitleUserGrid>Clientes</TitleUserGrid>

                            <DivButtonUpdate>
                                <Button
                                    variant="contained"
                                    onClick={updateGrid}
                                    startIcon={<AutorenewIcon />}
                                    size="small"
                                    color="secondary"
                                    style={{
                                        marginRight:'10px'
                                    }}
                                >
                                    Actualizar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={newClient}
                                    startIcon={<AddCircleIcon />}
                                    size="small"
                                >
                                    Cliente
                                </Button>
                               
                            </DivButtonUpdate>
                        </TitleAndButton>
                        {
                            [5].includes(id_rol) ?  
                                <DivGrid>
                                    <DataGrid
                                        rows                ={clientSuccess}
                                        columns             ={ColumnsClientsVisualize}
                                        pageSize            ={11}
                                        rowsPerPageOptions  ={[11]}
                                        getRowId            ={clientSuccess => clientSuccess.id_cliente}
                                    />
                                </DivGrid>
                            :
                                null
                        }
                        {
                            [2,8].includes(id_rol) ?  
                                <DivGrid>
                                    <DataGrid
                                        rows                ={clientSuccess}
                                        columns             ={ColumnsClientsSales}
                                        pageSize            ={11}
                                        rowsPerPageOptions  ={[11]}
                                        getRowId            ={clientSuccess => clientSuccess.id_cliente}
                                    />
                                </DivGrid>
                            :
                                null
                        }
                        {
                            [6,1].includes(id_rol) ?  
                                <DivGrid>
                                    <DataGrid
                                        rows                ={clientSuccess}
                                        columns             ={ColumnsClientsSalesLider}
                                        pageSize            ={11}
                                        rowsPerPageOptions  ={[11]}
                                        getRowId            ={clientSuccess => clientSuccess.id_cliente}
                                    />
                                </DivGrid>
                            :
                                null
                        }
                </>
            }
        </>
     );
}
 
export default Clients;