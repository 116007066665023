import styled from 'styled-components';

export const DivButton = styled.div`
    display: flex;
`

export const ButtonEdit = styled.button`
   background-color: #FEC808;
   color: #FFFFFF;
   width: 200px;
   height: 35px;
   border-radius: 15px;
   text-align: center;
   font-size: 18px;
   font-weight: bold;
   list-style: none;
   border: none;
   cursor: pointer;
   margin-left: 1rem;
`

export const ImageDiv = styled.div`
    display: flex;
    flex-direction: column;
`

export const TitleImage = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: #004274;
`

export const Image = styled.img`
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
`

export const ImageNotFound = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    padding-top: 40px;
`


export const ProgresReload = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
`
export const RowReload = styled.div`
    
    width: 69%;
    height: 100%;
    border-radius:10px;
    border: 1px solid #004274;
    margin: 0 auto;
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-rows: 100px;
    grid-column-gap: 8rem;
    grid-row-gap: 5rem;
    padding:3%;
    padding-top: 5rem;
    grid: inherit;


    @media (max-width: 860px) {
        height: 100%;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }
`