import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    SCHEDULE_CREATE,
    SCHEDULE_CREATE_SUCCESS,
    SCHEDULE_CREATE_ERROR,
    SCHEDULE_CREATE_INIT,
    SCHEDULES_LOADING,
    SCHEDULES_LOADING_SUCCESS,
    SCHEDULES_LOADING_ERROR,
    SCHEDULE_LOAD,
    SCHEDULE_LOAD_SUCCESS,
    SCHEDULE_LOAD_ERROR,
} from '../types';

/* Create SCHEDULE*/ 
    export function createScheduleAction(schedule) {

        let IdClient;

        let dataClient = {
            nombre_cliente:  schedule.nombre_cliente,
            email_cliente: schedule.email_cliente,
            telefono_cliente : schedule.telefono_cliente
        }

        let scheduleClient ={
            datos_agenda: schedule.datos_agenda,
            horario_agenda_casa: schedule.horario_agenda_casa,
            dia_agenda_casa: schedule.dia_agenda_casa,
            tipo_agenda_casa: schedule.tipo_agenda_casa,
            origen_agenda_casa: schedule.origen_agenda_casa,
            id_casa: schedule.id_casa,
        }
        return async ( dispatch ) => {
            
            dispatch( createSchedule() );

            try{
                let responseClient = await clientAxios.get(`/clientes/notas/${dataClient.telefono_cliente}`);
            
                if(responseClient.status === 200){
                    IdClient = responseClient.data.client.id_cliente;
                }
                scheduleClient.id_cliente = IdClient;
                
                let response        = await clientAxios.post('/agendas/crear/',scheduleClient);

                if(response.status === 200){
                    
                    if( schedule.tipo_agenda_casa === 'Desktop' && schedule.origen_agenda_casa === 'CRM AGENDA OPCIONES'){
                        SweetAlertBasic("success","Felicidades!"," La agenda se ha registrado exitosamente.");
                    }else{
                        SweetAlertBasic("success","Felicidades!","  Tu agenda se ha registrado exitosamente, en unos momentos uno de nuestros especialistas se comunicará contigo.");
                    }
                    dispatch( createScheduleSuccess(schedule) );
                }
    
            }catch( error ){

                let responseClient = await clientAxios.post('/clientes/crear/', dataClient);
                if(responseClient.status){
                    IdClient = responseClient.data.id_cliente;
                    scheduleClient.id_cliente = IdClient;
                    let response        = await clientAxios.post('/agendas/crear/',scheduleClient);
                    if(response.status === 200){
                        if(schedule.tipo_agenda_casa === 'Desktop'){
                            SweetAlertBasic("success","Felicidades!","  Tu agenda se ha registrado exitosamente, en unos momentos uno de nuestros especialistas se comunicará contigo.");
                        }
                        dispatch( createScheduleSuccess(schedule) );
                    }
                }else{
                    dispatch( createScheduleError() );
                    if(schedule.tipo_agenda_casa === 'Desktop'){
                     SweetAlertBasic("error","Ups","Hubo un error al crear la agenda");
                    }
                }
           
            }
        }

    }

    // Create Schedule init action
    export function createScheduleInitFn(flag){
        return ( dispatch ) => {
            dispatch( createScheduleInit(flag));
        }
    }

    const createSchedule = ( ) => ({
        type    : SCHEDULE_CREATE
    });

    const createScheduleSuccess = (schedule) => ({
        type    : SCHEDULE_CREATE_SUCCESS,
        payload : schedule,
        flag    : true,
    })

    const createScheduleError = () => ({
        type    : SCHEDULE_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createScheduleInit = (flag) =>({
        type    : SCHEDULE_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* GRID SCHEDULE */
    export function getAllSchedulesAction() {
        return async ( dispatch ) => {
            dispatch( downloadSchedule() );

            try{
                const responseSchedules = await clientAxios.get('/agendas' );
                dispatch( downloadScheduleSuccess (responseSchedules.data.data) );

            }catch( error ){
                dispatch( downloadScheduleError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los agendas");   
            }
        }
    }

    const downloadSchedule = () => ({
        type: SCHEDULES_LOADING,
        payload: true
    })

    const downloadScheduleSuccess = ( schedules ) => ({
        type: SCHEDULES_LOADING_SUCCESS,
        payload: schedules
    })

    const downloadScheduleError = (error) => ({
        type: SCHEDULES_LOADING_ERROR,
        error: error
    })



/* GET SCHEDULE  ONE*/
    export const getScheduleAction = ( id ) =>{

        return async (dispatch)=>{
            dispatch( getLoadSchedule() );

            try{
                let response = await clientAxios.get(`/agendas/${id}/`);
                dispatch( loadScheduleSuccess (response.data.data) );
            }catch( error ){
                dispatch( loadScheduleError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar la agenda");  
            }
        }   
    }

    const getLoadSchedule = () => ({
        type: SCHEDULE_LOAD,
        payload: true
    })

    const loadScheduleSuccess = (schedule) => ({
        type: SCHEDULE_LOAD_SUCCESS,
        editarUsuario: schedule
    })

    const loadScheduleError = () => ({
        type: SCHEDULE_LOAD_ERROR,
        error: true
    })