import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    STATUS_LOADING,
    STATUS_LOADING_SUCCESS,
    STATUS_LOADING_ERROR,
    STATUS_LOAD,
    STATUS_LOAD_SUCCESS,
    STATUS_LOAD_ERROR,
} from '../types';

/* GRID STATUS */
export function getAllStatusAction() {
    return async ( dispatch ) => {
        dispatch( downloadStatus() );

        try{
            const responseStatus = await clientAxios.get('/status', );
            dispatch( downloadStatusSuccess (responseStatus.data.data) );
        }catch( error ){
            dispatch( downloadStatusError(error) );
            SweetAlertBasic("error","Ups","Hubo un error al cargar los status");   
        }
    }
}

const downloadStatus = () => ({
    type: STATUS_LOADING,
    payload: true
})

const downloadStatusSuccess = ( status ) => ({
    type: STATUS_LOADING_SUCCESS,
    payload: status
})

const downloadStatusError = (error) => ({
    type: STATUS_LOADING_ERROR,
    error: error
})

/* EDIT STATUS */
export const getStatusAction = ( id ) =>{

    return async (dispatch)=>{
        dispatch( getLoadStatus() );

        try{
            let responseStatus = await clientAxios.get(`/status/${id}`);
            dispatch( loadStatusSuccess (responseStatus.data.data) );
        }catch( error ){
            dispatch( loadStatusError() );
            SweetAlertBasic("error","Ups","Hubo un error al cargar el status");  
        }
    }   
}

const getLoadStatus = () => ({
    type: STATUS_LOAD,
    payload: true
})

const loadStatusSuccess = (status) => ({
    type: STATUS_LOAD_SUCCESS,
    editarUsuario: status
})

const loadStatusError = () => ({
    type: STATUS_LOAD_ERROR,
    error: true
})