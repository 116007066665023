import styled from 'styled-components';

export const DivGridSpecialist = styled.div`
    display: flex;
    height:700px;
    justify-content: center;
    width:80%;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
        height: 560px;
    }
`

export const DivCreatedUpdated = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 15rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 768px) {
        margin-left: 2rem;
        margin-bottom: 1.5rem;
    }
`

export const TitleCreated = styled.div`
    font-size:14px;
    font-weight:bold;
    margin-left: .5rem;
`

export const CreatedandUpdated = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size:14px;
    margin-right: 3px;
`

export const DivButtonUpdate = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 1rem 2rem 1rem;
    width:80%;

    @media only screen and (max-width: 768px) {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0;
    }
`


export const DivClientOne = styled.div`
    display: grid;
    grid-template-columns: 1.2fr 1fr repeat(2,1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 35px;
    grid-row-gap: 1px;
    width: 80%;
    height:70px;
    margin: 0 auto;
    background-color: #E5E5E5;
    border-radius: 10px;
    color: #004274;
    margin-top: 2rem;
    margin-left: 20rem;
`

export const DivClientBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr 2fr 0.5fr 2fr 0fr;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
`
export const DateClient = styled.div`
    grid-area: 1 / 2 / 5 / 3;
    width: 73%;
    height: 50%;
    margin-left: 1%;
    margin-top: 2rem;
`
export const Buttons = styled.div`
    grid-area: 5 / 1 / 6 / 3;
`

export const TitleDetail = styled.div`
    height:40px;
    margin: 0 auto;
    background-color: #E5E5E5;
    border-radius: 10px;
    color: #004274;
    margin-top: 2rem;
    margin-left: 20rem;
    font-weight: bold;
    font-size: 22px;
    padding: 10px 0px 0px 10px;
    width: 450px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const DetailClient = styled.div`
    height:250px;
    margin: 0 auto;
    background-color: #E5E5E5;
    border-radius: 10px;
    color: #004274;
    margin-left: 20rem;
    font-weight: bold;
    font-size: 18px;
    width: 450px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const TitleHouse = styled.div`
    width: 57%;
    height:40px;
    margin: 0 auto;
    background-color: #E5E5E5;
    border-radius: 10px;
    color: #004274;
    margin-top: 2rem;
    margin-left: 20rem;
    font-weight: bold;
    font-size: 22px;
    padding: 10px 0px 0px 10px;
`

export const DetailHouse = styled.div`
    width: 57%;
    height:220px;
    margin: 0 auto;
    background-color: #E5E5E5;
    border-radius: 10px;
    color: #004274;
    margin-top: 2rem;
    margin-left: 20rem;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`


export const DivClientChild = styled.div`
    font-size:18px;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    margin-left: 1rem;
`
export const StatusClientSales = styled.div`
    font-size:16px;
    font-weight: bold;
    display;flex;
    align-items: flex;
    color: black;
`

export const DivClientThree = styled.div`
    height:50px;
    margin-top:0.7rem;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    background-color: #004274;
    display:flex;
    flex-direction: row;
`
export const DivClientFour = styled.div`
    height:50px;
    width:40%;
    margin-top:1rem;
    margin-left: 5rem;
    border-radius: 10px;
`
export const Image = styled.img`
    display: flex;
    height: 38px;
    padding-left: 5px;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`
export const ClientP = styled.div`
    font-size: 16px;
    margin: 10px;
`

export const RowClient = styled.div`
    
    width: 100%;
    height: 100%;
    border-radius:10px;
    margin: 0 auto;
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: .5fr repeat(2, 1fr);
    grid-auto-rows: 100px;
    grid-column-gap: 8rem;
    grid-row-gap: 5rem;
    margin-top: 2rem;
    padding-top: 5px;
    @media only screen and (max-width: 768px) {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        grid-row-gap: 2rem;
    }
`

export const TitleNote = styled.div`
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    margin:0 auto;
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 15%;
`
export const TitleOpciones = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #004274;
`
export const DivOpciones = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 30rem;
    flex-direction: column;
`

