import React, { useState, useEffect }          from 'react'
import { createClientActionFn }     from '../../actions/clientActions';
import { useDispatch, useSelector } from 'react-redux';
import { SweetAlertBasic }          from '../../utils/sweetAlert';
import { useNavigate, Navigate }    from 'react-router-dom';
import ProgressBar                  from '../Progress/ProgressBar.component';
import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import InputLabel                   from '@mui/material/InputLabel';
import MenuItem                     from '@mui/material/MenuItem';
import Select                       from '@mui/material/Select';
import FormControl                  from '@mui/material/FormControl';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import Stack                        from '@mui/material/Stack';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
} from '../Usuarios/Users.styles';
import{ 
    getAllRegistersAction 
}                               from '../../actions/registerActions';

const NewClient = () => {

    const [ nombreCliente, setNombreCliente ]                   = useState('');
    const [ apellidoPaternoCliente, setApellidoPaternoCliente ] = useState('');
    const [ apellidoMaternoCliente, setApellidoMaternoCliente]  = useState('');
    const [ telefonoCliente, setTelefonoCliente ]               = useState('');
    const [ emailCliente, setEmailCliente ]                     = useState('');
    const [ tipoCredito, setTipoCredito]                        = useState('');  
    const [ registro, setRegistro]                              = useState('');                        

 
    // Dispatch
    const dispatch      = useDispatch();

    // Call Action
    const addCreateClient     = (client) => dispatch( createClientActionFn(client) );
    const loadingRegisters      = () => dispatch( getAllRegistersAction() );

    // Loading
    const loading           = useSelector( state => state.clientes?.loading);
    const clientSuccess     = useSelector( state => state.clientes?.flag);
    const id_rol            = useSelector( state => state.login?.user?.rol);
    const id_user           = useSelector( state => state.login?.user?.id );
    const registers         = useSelector( state => state.registros?.registros);

    useEffect( () => {
        loadingRegisters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

   
    //History
    const navigate = useNavigate();


    function goClients() {
        if(id_rol === 1 || id_rol === 8) navigate('/clientes');
        if(id_rol === 2) navigate('/clientes/especialista');
        if(id_rol === 4) navigate('/dashboard/recepcion');
        if(id_rol === 6) navigate('/clientes/lider');
    }

    const handleCredito = (event) => {
        setTipoCredito(event.target.value);
    };

    // SELECTS
    const handleRegister = (event) => {
        setRegistro(event.target.value);
    };

    // Create of Client
    const createClient = e => {
        e.preventDefault();

        //Validate form
        if( nombreCliente.trim() === '' || telefonoCliente.length === 0 ){
            SweetAlertBasic("error","Ups","EL nombre y el télefono son obligatorios");
            return;
        }

        let emailFake;
        if(emailCliente.trim() === ''){
            let nameTrim        = nombreCliente.replace(/ /g, "");
            emailFake           = `${nameTrim}.${telefonoCliente}@plusvarq.com`;
        }
        let id_user_token   = JSON.parse(localStorage.getItem('user'));
        let userId          = id_user ? id_user : id_user_token.id;
        // Create Client
        addCreateClient({
            nombre_cliente              :   nombreCliente,
            apellido_materno_cliente    :   apellidoMaternoCliente,
            apellido_paterno_cliente    :   apellidoPaternoCliente,
            telefono_cliente            :   telefonoCliente,
            email_cliente               :   emailCliente ? emailCliente : emailFake,
            tipo_credito                :   tipoCredito ? tipoCredito : 4,
            id_usuario                  :   userId,
            id_registro                 :   registro ? registro : 1
        });

    }


    return ( 
        <DivCenter >
            {
                loading ? 
                    <ProgressBar title="Creando Cliente..."/>
                :
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)':{},
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={createClient}
                    
                >
                    <TitleUser>Crear cliente</TitleUser>

                            <RowUser>
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el nombre del cliente" 
                                    variant="outlined"
                                    type="text"
                                    name="nombreCliente"
                                    value={nombreCliente}
                                    onChange={ e => setNombreCliente(e.target.value) }
                                    fullWidth
                                />
                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el apellido paterno del cliente" 
                                    variant="outlined"
                                    name="apellidoPaternoCliente"
                                    value={apellidoPaternoCliente}
                                    onChange={ e => setApellidoPaternoCliente(e.target.value) }
                                    fullWidth
                                />
                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el apellido materno del cliente" 
                                    variant="outlined"
                                    name="apellidoMaternoCliente"
                                    value={apellidoMaternoCliente}
                                    onChange={ e => setApellidoMaternoCliente(e.target.value) }
                                    fullWidth
                                />
                           
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el telefono del cliente" 
                                    variant="outlined"
                                    name="telefonoCliente"
                                    value={telefonoCliente}
                                    type="number"
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    onChange={ e => setTelefonoCliente( Number(e.target.value) ) }
                                    fullWidth
                                />

                                 <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el email del cliente" 
                                    variant="outlined"
                                    name="emailCliente"
                                    value={emailCliente}
                                    onChange={ e => setEmailCliente(e.target.value) }
                                    fullWidth
                                />
                          
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tipo de Crédito</InputLabel>
                                    <Select
                                        labelId="equipo_rol"
                                        id="demo-simple-select"
                                        label="Rol"
                                        value={tipoCredito}
                                        onChange={handleCredito}
                                    >
                                        <MenuItem value={1}>Infonavit</MenuItem>
                                        <MenuItem value={2}>Bancario</MenuItem>
                                        <MenuItem value={3}>Fovissste</MenuItem>
                                        <MenuItem value={4}>Otro</MenuItem>
                                    </Select>
                                </FormControl>

                                {
                                    registers ? 
                                    <FormControl >
                                        <InputLabel id="demo-controlled-open-select-label">Registro</InputLabel>
                                        <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        name="id_registro"
                                        label="Registro"
                                        defaultValue={registro}
                                        onChange={handleRegister}
                                        >
                                        {registers.map((registro) => (
                                            <MenuItem
                                                key={registro.id_registro}
                                                value={registro.id_registro}
                                            >
                                            {registro.nombre_registro}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    : null

                                }
                            </RowUser>
                           
                    <DivButtons >
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={goClients}
                                variant="contained" 
                                size="large"
                                color="error"
                            >
                                Cancelar
                            </Button>
                        
                            <Button 
                                variant="contained" 
                                size="small" 
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Crear Cliente
                            </Button>
                        </Stack>
                    </DivButtons>
                </Box>
            }
            {
                clientSuccess ?
                    <Navigate to='/cliente/exitoso'/>
                :
                null
            }
            
            
        </DivCenter>  
     );
}
 
export default NewClient;