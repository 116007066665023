import React from 'react';

const Logout = () => {
    return ( 
        <>
            <h1>Hasta pronto</h1>
        </>
     );
}
 
export default Logout;