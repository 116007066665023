import React                        from 'react';
import { useNavigate }              from 'react-router-dom';
import { useDispatch }              from 'react-redux';
import {
    ImageUserSuccess,
    TitleUserSuccess,
    TitleUserGreen,
    DivUserSuccess,
    ButtonUserSuccess
}                                       from '../Usuarios/Users.styles';
import DesarrolladorSuccess             from '../../Images/desarrollador_success.png';
import{ getAllDesarrolladorAction }     from '../../actions/desarrolladorActions';


const HouseSuccess = () => {

    const navigate                  = useNavigate();
    const dispatch                  = useDispatch();
    const loadingDesarrollador      = () => dispatch( getAllDesarrolladorAction() );
    
    
    function goDesarrollador() {
        loadingDesarrollador();
        navigate('/desarrolladores');
    }

    return ( 
        <>
           
            <DivUserSuccess>
                <ImageUserSuccess  src={DesarrolladorSuccess} alt="logo" />
                <TitleUserSuccess>Se ha 
                    <TitleUserGreen>
                    creado
                    </TitleUserGreen> 
                    el  
                    <TitleUserGreen>
                        desarrollador
                    </TitleUserGreen> 
                    exitosamente.
                </TitleUserSuccess> 
                <ButtonUserSuccess
                    onClick={goDesarrollador}
                >
                    ir al Inicio
                </ButtonUserSuccess>
            </DivUserSuccess>
        </>
     );
}
 
export default HouseSuccess;