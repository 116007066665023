import React, { useEffect } from 'react';
import  { useDispatch }            from 'react-redux';
import  { getLoginUserFn }                      from '../../actions/loginActions';
import SidebarContratos from '../Sidebar/SidebarContratos.component';

const DashboardContratos = () => {

    const dispatch          = useDispatch();

    // USE EFFECT LOGIN
    useEffect( () => {
        const loginUser = () => dispatch( getLoginUserFn() );
        loginUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );


    return (  
        <>
            <SidebarContratos/>
        </>
    );
}
 
export default DashboardContratos;