import { 
    HOUSE_CREATE,
    HOUSE_CREATE_SUCCESS,
    HOUSE_CREATE_ERROR,
    HOUSE_CREATE_INIT,
    HOUSES_LOADING,
    HOUSES_LOADING_SUCCESS,
    HOUSES_LOADING_ERROR,
    HOUSE_DESACTIVE,
    HOUSE_DESACTIVE_ERROR,
    HOUSE_LOAD,
    HOUSE_LOAD_SUCCESS,
    HOUSE_LOAD_ERROR,
    HOUSE_UPDATE_SUCCESS,
    HOUSE_UPDATE_ERROR,
    HOUSE_UPDATE_INIT,
    HOUSE_CREATE_SUCCESS_UBICATION,
    HOUSE_CREATE_UBICATION,
    HOUSE_CREATE_ERROR_UBICATION,
    HOUSE_LOAD_UBICATION,
    HOUSE_LOAD_SUCCESS_UBICATION,
    HOUSE_LOAD_ERROR_UBICATION,
    HOUSE_CREATE_SUCCESS_EXTRA,
    HOUSE_CREATE_EXTRA,
    HOUSE_CREATE_ERROR_EXTRA,
    HOUSE_LOAD_EXTRA,
    HOUSE_LOAD_SUCCESS_EXTRA,
    HOUSE_LOAD_ERROR_EXTRA,
    HOUSE_CREATE_SUCCESS_PRICE,
    HOUSE_CREATE_PRICE,
    HOUSE_CREATE_ERROR_PRICE,
    HOUSE_LOAD_PRICE,
    HOUSE_LOAD_SUCCESS_PRICE,
    HOUSE_LOAD_ERROR_PRICE,
    HOUSE_CREATE_SUCCESS_GENERAL,
    HOUSE_CREATE_GENERAL,
    HOUSE_CREATE_ERROR_GENERAL,
    HOUSE_LOAD_GENERAL,
    HOUSE_LOAD_SUCCESS_GENERAL,
    HOUSE_LOAD_ERROR_GENERAL,
    HOUSE_CREATE_IMAGE,
    HOUSE_CREATE_SUCCESS_IMAGE,
    HOUSE_CREATE_ERROR_IMAGE,
    HOUSE_LOAD_IMAGE,
    HOUSE_LOAD_SUCCESS_IMAGE,
    HOUSE_LOAD_ERROR_IMAGE
} from '../types';

/*
    FLAG
        Revisamos si esta ok el casa y lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
        Loading: false(enseña el cargando)
    
    LOADING
        True: Quitamos el componente loading(cargando) 
        False: Se enseña el componente cargando
    
    FLAGRELOAD
        Se hace un reloading a componentes sin redirigir a otra página
*/
const initialState = {
    casas               : [],
    casa_image          : null,
    error               : null,
    loading             : false,
    loadingUpdate       : false,  
    flag                : false, 
    desactiveCasa       : null,
    editarCasa          : null,
    casaActualizada     : null,
    editarCasaUbicacion : null,
    editarCasaPrecio    : null,
    editarCasaExtra     : null,
    editarCasaGeneral   : null,
    flagUpdate          : false,
    idCasa              : null,
    casaImagen          : null,
    flagReload          : false,
    flagViewOpcionesUser        :false,
    flagViewOpcionesClient      : false,
    flagViewOpcionesTeam    : false,
    opcionUsuario       : null,
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case    HOUSE_CREATE:
        case    HOUSE_LOAD:
        case    HOUSES_LOADING:
        case    HOUSE_UPDATE_INIT:
        case    HOUSE_LOAD_GENERAL:
        case    HOUSE_CREATE_GENERAL:
        case    HOUSE_LOAD_PRICE:
        case    HOUSE_CREATE_PRICE:
        case    HOUSE_LOAD_EXTRA: 
        case    HOUSE_CREATE_EXTRA:
        case    HOUSE_CREATE_UBICATION:
        case    HOUSE_LOAD_UBICATION:
        case    HOUSE_LOAD_IMAGE:

            return{
                ...state,
                loading: true,
                loadingUpdate: true,
                flag: false,
                flagUpdate:false,
                flagViewOpciones: false,
            }
        
        // ACTUALIZAR IMAGENES
        case HOUSE_CREATE_IMAGE:   
            return{
                ...state,
                loading: true,
                loadingUpdate: true,
                flag: false,
                flagUpdate:false,
                flagReload: true
            }

        case HOUSE_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                idCasa: null
            }
    
        case HOUSE_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                casas: [ ...state.casas, action.payload ],
                idCasa: action.id
            }

        case HOUSE_CREATE_ERROR:
        case HOUSES_LOADING_ERROR:
        case HOUSE_DESACTIVE_ERROR:
        case HOUSE_LOAD_ERROR:
        case HOUSE_UPDATE_ERROR:
        case HOUSE_CREATE_ERROR_UBICATION:
        case HOUSE_LOAD_ERROR_UBICATION:
        case HOUSE_CREATE_ERROR_EXTRA:
        case HOUSE_LOAD_ERROR_EXTRA:
        case HOUSE_CREATE_ERROR_PRICE:
        case HOUSE_LOAD_ERROR_PRICE:
        case HOUSE_CREATE_ERROR_GENERAL:
        case HOUSE_LOAD_ERROR_GENERAL:
        case HOUSE_CREATE_ERROR_IMAGE:
        case HOUSE_LOAD_ERROR_IMAGE:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error,
                flagReload: false,
            }
        
        case HOUSES_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                casas: action.payload,
                flag: false,
                desactiveCasa: null,
                flagViewOpcionesUser    :false,
                flagViewOpcionesTeam    : action.flagViewOpcionesTeam,
                flagViewOpcionesClient : false,
            }

        case HOUSE_DESACTIVE:
            return{
                ...state,
                desactiveCasa: action.payload
            }

        case HOUSE_LOAD_SUCCESS:
            return{
                ...state,
                casas: [...state.casas],
                editarCasa: action.editarCasa,
                flag: true,
                loading: false
            }
        
        case HOUSE_LOAD_SUCCESS_UBICATION:
            return{
                ...state,
                casas: [...state.casas],
                editarCasaUbicacion: action.editarCasaUbicacion,
                flag: true,
                loading: false,
                loadingUpdate: true,
            }
        
        case HOUSE_LOAD_SUCCESS_EXTRA:
            return{
                ...state,
                casas: [...state.casas],
                editarCasaExtra: action.editarCasaExtra,
                flag: true,
                loading: false
            }
        
        case HOUSE_LOAD_SUCCESS_PRICE:
            return{
                ...state,
                casas: [...state.casas],
                editarCasaPrecio: action.editarCasaPrecio,
                flag: true,
                loading: false
            }
        
        case HOUSE_LOAD_SUCCESS_GENERAL:
            return{
                ...state,
                casas: [...state.casas],
                editarCasaGeneral: action.editarCasaGeneral,
                flag: true,
                loading: false
            }
        
        case HOUSE_UPDATE_SUCCESS:
            return{
                ...state,
                casaActualizada: action.casaActualizada,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
        
        case HOUSE_CREATE_SUCCESS_UBICATION:
            return{
                ...state,
                editarCasaUbicacion: action.editarCasaUbicacion,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
            
        case HOUSE_CREATE_SUCCESS_EXTRA:
            return{
                ...state,
                editarCasaExtra: action.editarCasaExtra,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
        
        case HOUSE_CREATE_SUCCESS_PRICE:
            return{
                ...state,
                editarCasaPrecio: action.editarCasaPrecio,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
        case HOUSE_CREATE_SUCCESS_GENERAL:
            return{
                ...state,
                editarCasaExtra: action.editarCasaExtra,
                flagUpdate: true,
                loading: false,
                loadingUpdate: false,
            }
        case HOUSE_CREATE_SUCCESS_IMAGE:
            return{
                ...state,
                casa_image: action.imagen_data,
                loading: false,
                loadingUpdate: false,
                flagReload:false,
            }
        
        case HOUSE_LOAD_SUCCESS_IMAGE:
            return{
                ...state,
                casaImagen: action.casaImagen,
                loading: false,
                loadingUpdate: false,
                flagReload: false
            }
        default:
            return state;
    }
}