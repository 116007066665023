import Swal                             from 'sweetalert2';
import store                            from '../store';
import  { desactiveUserAction }         from '../actions/userActions';
import  { desactiveTeamAction }         from '../actions/teamActions';
import  { desactiveCalendaryAction }    from '../actions/calendaryActions';
import  { desactiveRegisterAction }     from '../actions/registerActions';
import  { desactiveFilterAction }       from '../actions/filterActions';
import  { desactiveHouseAction}         from '../actions/houseActions';
import  { desactiveDesarrolloAction }   from '../actions/desarrolloActions';
import { desactiveDesarrolladorAction}  from '../actions/desarrolladorActions';

export const  SweetAlertBasic = (icon, title, text) => {
    return Swal.fire({
        icon: icon,
        title: title,
        text: text
    })
}


export const SweetAlertConfirm = (data,title,textTitle) =>{
    return Swal.fire({
        title: textTitle,
        text: title + data.nombre_usuario,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch( desactiveUserAction(data.id_usuario) );
        }
    })
}

export const SweetAlertConfirmTeam = (data,title,textTitle) =>{
    return Swal.fire({
        title: textTitle,
        text: title + data.nombre_equipo ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch( desactiveTeamAction(data.id_equipo) );
        }
    })
}

export const SweetAlertConfirmCalendary = (data,title,textTitle) =>{
    return Swal.fire({
        title: textTitle,
        text: title + data.nombre_calendario ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch( desactiveCalendaryAction(data.id_calendario) );
        }
    })
}

export const SweetAlertConfirmRegister = (data,title,textTitle) =>{
    return Swal.fire({
        title: textTitle,
        text: title + data.nombre_registro ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch( desactiveRegisterAction(data.id_registro) );
        }
    })
}

export const SweetAlertConfirmFilter = (data,title,textTitle) =>{
    return Swal.fire({
        title: textTitle,
        text: title + data.nombre_filtro ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch( desactiveFilterAction(data.id_filtro) );
        }
    })
}

export const SweetAlertConfirmHouse = (data,title,textTitle) =>{
    return Swal.fire({
        title: textTitle,
        text: title + data.nombre_casa ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch( desactiveHouseAction(data.id_casa) );
        }
    })
}

export const SweetAlertConfirmDesarrollo = (data,title,textTitle) =>{
    return Swal.fire({
        title: textTitle,
        text: title + data.nombre_desarrollo ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch( desactiveDesarrolloAction(data.id_desarrollo) );
        }
    })
}

export const SweetAlertConfirmDesarrollador = (data,title,textTitle) =>{
    return Swal.fire({
        title: textTitle,
        text: title + data.nombre_desarrollador ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            store.dispatch( desactiveDesarrolladorAction(data.id_desarrollador) );
        }
    })
}