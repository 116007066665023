import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    SEGUIMIENTO_CREATE,
    SEGUIMIENTO_CREATE_SUCCESS,
    SEGUIMIENTO_CREATE_ERROR,
    SEGUIMIENTO_CREATE_INIT,
    SEGUIMIENTO_LOAD,
    SEGUIMIENTO_LOAD_SUCCESS,
    SEGUIMIENTO_LOAD_ERROR,
} from '../types';



/*  Create Seguimiento
    function que crea el seguimiento
*/ 
    export function createSeguimientoActionFn(seguimiento, id) {

        return async ( dispatch ) => {
            
            dispatch( createSeguimiento(seguimiento) );

            try{
                let responseSeguimiento = await clientAxios.post('/seguimiento/cliente/crear/', seguimiento);
                dispatch( createSeguimientoSuccess(responseSeguimiento.data.data) );
                SweetAlertBasic("success","Buen trabajo!","Se ha actualizado correctamente el cliente.");
                if(responseSeguimiento.status === 200){
                    let responseSeguimiento = await clientAxios.get(`/seguimiento/cliente/${seguimiento.id_cliente}`);
                    dispatch( loadSeguimientoSuccess (responseSeguimiento.data.data) );
                }
            }catch( error ){
                console.log("ERROR");
                console.log(error);
                console.log("ERROR");
                dispatch( createSeguimientoError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear el seguimiento");
            }
        }

    }

    // Create Seguimiento init action(se activa el flag a true para iniciar la creación)
    export function createSeguimientoInitFn(flag){
        return ( dispatch ) => {
            dispatch( createSeguimientoInit(flag));
        }
    }

    const createSeguimiento = ( ) => ({
        type    : SEGUIMIENTO_CREATE,
        flag: false
    });

    const createSeguimientoSuccess = (seguimiento) => ({
        type    : SEGUIMIENTO_CREATE_SUCCESS,
        payload : seguimiento,
        flag    : true,
    })

    const createSeguimientoError = () => ({
        type    : SEGUIMIENTO_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createSeguimientoInit = (flag) =>({
        type    : SEGUIMIENTO_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/*  SEGUIMIENTOS 
    function que trae la información del un solo seguimiento para visualizarla
*/
export const getSeguimientoAction = ( id ) =>{

    return async (dispatch)=>{
        dispatch( getLoadSeguimiento() );

        try{
            let responseSeguimiento = await clientAxios.get(`/seguimiento/cliente/${id}`);
            dispatch( loadSeguimientoSuccess (responseSeguimiento.data.data) );
        }catch( error ){
            console.error(error);
            dispatch( loadSeguimientoError() );
            // SweetAlertBasic("error","Ups","Hubo un error al cargar los seguimientos del cliente");  
        }
    }   
}

const getLoadSeguimiento = () => ({
    type: SEGUIMIENTO_LOAD,
    payload: true
})

const loadSeguimientoSuccess = (seguimientos) => ({
    type: SEGUIMIENTO_LOAD_SUCCESS,
    seguimientos: seguimientos
})

const loadSeguimientoError = () => ({
    type: SEGUIMIENTO_LOAD_ERROR,
    error: true
})
