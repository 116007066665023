import React            from 'react';
import { Navigate }     from 'react-router-dom';

// 1 = Administrativos
// 2 = Especialista
// 3 = Inventario
// 4 = RH
// 5 = Recepcion
// 6 = Lider de Equipo
// 7 = Contratos
// 8 = Markenting

const LoginSuccess = (user) => {

    const urlRedirect = () => {
        switch (user.user.id_rol) {
            case 1:
                return <Navigate to='/dashboard'/>;
            case 2:
                return <Navigate to='/dashboard/especialista'/>;
            case 3:
                return <Navigate to='/dashboard/inventario'/>;
            case 4: 
                return <Navigate to='/dashboard/rh'/>;
            case 5:
                return <Navigate to='/dashboard/recepcion'/>;
            case 6:
                return <Navigate to='/dashboard/lider'/>
            case 7:
                return <Navigate to='/dashboard/contratos'/>
            case 8:
                return <Navigate to='/dashboard/marketing'/>
            default: 
                return  <Navigate to='/'/>
        }
    }
    
    return ( 

        <>
            {urlRedirect()}
        </>

     );
}
 
export default LoginSuccess;