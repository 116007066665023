import React, { useState }          from 'react'
import { createTeamActionFn }     from '../../actions/teamActions';
import { useDispatch, useSelector } from 'react-redux';
import { SweetAlertBasic }          from '../../utils/sweetAlert';
import { useNavigate, Navigate }    from 'react-router-dom';
import ProgressBar                  from '../Progress/ProgressBar.component';
import Box                          from '@mui/material/Box';
import TextField                    from '@mui/material/TextField';
import InputLabel                   from '@mui/material/InputLabel';
import MenuItem                     from '@mui/material/MenuItem';
import Select                       from '@mui/material/Select';
import FormControl                  from '@mui/material/FormControl';
import Button                       from '@mui/material/Button';
import SaveIcon                     from '@mui/icons-material/Save';
import Stack                        from '@mui/material/Stack';
import {
    TitleUser,
    RowUser,
    DivButtons,
    DivCenter
} from '../Usuarios/Users.styles';


const NewTeam = () => {

    
    const [ nombreEquipo, setNombreEquipo ]             = useState('');
    const [ tipoEquipo, setTipoEquipo ]                 = useState('');
    const [ prioridadEquipo, setPrioridadEquipo]        = useState('');


    const handleTipoEquipo = (event) => {
        setTipoEquipo(event.target.value);
    };

    const handlePrioridadEquipo = (event) => {
        setPrioridadEquipo(event.target.value);
    };
    
    const dispatch              = useDispatch();
    const addCreateTeam         = (team) => dispatch( createTeamActionFn(team) );
    const loading               = useSelector( state => state.equipos?.loading);
    const teamSuccess           = useSelector( state => state.equipos?.flag);

    const navigate = useNavigate();

    function goTeams() {
        navigate('/equipos');
    }

    const createTeam = e => {
        e.preventDefault();

        //Validate form
        if( nombreEquipo.trim() === '' ){
            SweetAlertBasic("error","Ups","Le falta llenar campos");
            return;
        }

        // Create Client
        addCreateTeam({
            nombre_equipo:nombreEquipo,
            tipo_equipo: tipoEquipo,
            prioridad_equipo: prioridadEquipo,
            activo_equipo: true,
        });

    
        //Clean Info on input 
        setNombreEquipo('');
        setTipoEquipo('');
        setPrioridadEquipo('');      
    }

    return ( 
        <DivCenter >
            {
                loading ? 
                    <ProgressBar title="Creando equipo..."/>
                :
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)':{},
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={createTeam}
                    
                >
                    <TitleUser>Crear Equipo</TitleUser>

                            <RowUser>
                                <TextField  
                                    id="outlined-basic" 
                                    label="Ingresa el nombre del equipo"  
                                    variant="outlined"
                                    type="text"
                                    name="nombreEquipo"
                                    value={nombreEquipo}
                                    onChange={ e => setNombreEquipo(e.target.value) }
                                    fullWidth
                                />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tipo de Equipo</InputLabel>
                                    <Select
                                        labelId="tipo_equipo"
                                        id="demo-simple-select"
                                        label="tipoEquipo"
                                        value={tipoEquipo}
                                        onChange={handleTipoEquipo}
                                    >
                                        <MenuItem value="Admin">Admin</MenuItem>
                                        <MenuItem value="Ventas">Ventas</MenuItem>
                                        <MenuItem value="Contratos">Contratos</MenuItem>
                                        <MenuItem value="PostVenta">PostVenta</MenuItem>
                                        <MenuItem value="Renta">Renta</MenuItem>
                                        <MenuItem value="Compra de Casa">Compra de Casas</MenuItem>
                                        <MenuItem value="RH">RH</MenuItem>
                                    </Select>
                                </FormControl>
                                
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Prioridad Equipo</InputLabel>
                                    <Select
                                        labelId="prioridad_equipo"
                                        id="demo-simple-select"
                                        label="Rol"
                                        value={prioridadEquipo}
                                        onChange={handlePrioridadEquipo}
                                    >
                                        <MenuItem value={1}>Alta</MenuItem>
                                        <MenuItem value={2}>Media</MenuItem>
                                        <MenuItem value={3}>Baja</MenuItem>
                                        <MenuItem value={4}>Sin Prioridad</MenuItem>
                                    </Select>
                                </FormControl>
                            </RowUser>
                           
                    <DivButtons >
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={goTeams}
                                variant="contained" 
                                size="large"
                                color="error"
                            >
                                Cancelar
                            </Button>
                        
                            <Button 
                                variant="contained" 
                                size="small" 
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Crear Equipo
                            </Button>
                        </Stack>
                    </DivButtons>
                </Box>
            }
            {
                teamSuccess ?
                    <Navigate to='/equipos/exitoso'/>
                :
                null
            }
            
            
        </DivCenter>  
     );
}
 
export default NewTeam;