import  React, { useEffect, useState }          from 'react';
import  { useDispatch, useSelector }            from 'react-redux';
import  ProgressBar                             from '../Progress/ProgressBar.component';
import Moment                                   from 'react-moment';
import ImageSearchImg                           from '../../Images/plusvarq-image.jpg';
import ImageOptions                             from '../../Images/bienes_raices.png';
import ImageEmpty                               from '../../Images/option_empty.png';
import Box                                      from '@mui/material/Box';
import Card                                     from '@mui/material/Card';
import CardContent                              from '@mui/material/CardContent';
import CardMedia                                from '@mui/material/CardMedia';
import Typography                               from '@mui/material/Typography';
import Button                                   from '@mui/material/Button';
import HomeIcon                                 from '@mui/icons-material/Home';
import Link                                     from "@material-ui/core/Link";
import SidebarOpciones                          from '../../components/Sidebar/SidebarOpciones.component';
import{ 
    getAllHousesAction 
}                                               from '../../actions/houseActions';
import {
    createOptionAction
}                                               from '../../actions/optionsActions';
import{    
 
}                                               from '../Dashboards/styles/Inventario.styles';

import {
        DivOpciones,
        DivCasas,
        ButtonsCasa,
        TitleRed,
        TitleGreen,
        TitleAndButton, 
        TitleHouseGrid,
        TextEmpty,
        Image,
        WrapperEmpty
}                                       from './Opciones.styles';



const OpcionesDesktop = () => {

    const[ enabled, setEnabled ] = useState(false)
    const dispatch          = useDispatch();
    const houseSuccess      = useSelector( state => state.casas.casas || []);
    const loading           = useSelector( state => state.casas?.loading);
    const optionTeamSuccess = useSelector( state => state.opciones?.opcionEquipo || []);
    const optionUserSuccess = useSelector( state => state.opciones?.opcionUsuario || []);
    const optionClientSuccess = useSelector( state => state.opciones?.opcionCliente || []);
    const loadingOption     = useSelector( state => state);
    const loadingHouses     = () => dispatch( getAllHousesAction() );
    const addCreateOption   = (option) => dispatch( createOptionAction(option) );
    let userLocal           = JSON.parse(localStorage.getItem('user'))

    // USE EFFECT LOGIN
    useEffect( () => {
        loadingHouses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    function goOption(id){
        window.open(`/opciones/${id}` );
    }

    const goHouses = () => {
        loadingHouses();
        console.log(enabled);
        setEnabled(false);
    }

    
    const clickWhatsapp = ( id_casa) => {

        addCreateOption({
            tipo_opcion: "Envió de Opciones vía CRM",
            tipo_opcion_casa: "Opciones de Casa",
            descripcion_opcion_casa: "Opción enviada desde opciones de casa",
            ruta_opcion_casa: "sin ruta",
            id_casa: parseInt(id_casa),
            id_usuario: userLocal?.id ? userLocal?.id : 1,
            id_cliente: 1
        })

        window.open(`https://api.whatsapp.com/send?text=${window.location.hostname+'/opciones/' + id_casa}` )
    }



    return ( 
        <>
            <SidebarOpciones/>
            
            {
                loading ? 
                    <ProgressBar title="Cargando Casas..."/>
                :
                    <div
                    >
                                {
                                    houseSuccess?.length >= 1 ?
                                    <>
                                        <TitleAndButton>
                                            <TitleHouseGrid>Opciones de casas</TitleHouseGrid>
                                        </TitleAndButton>
                                        <DivOpciones
                                        >
                                                <div style={{ height: '100%' }}>
                                                {houseSuccess
                                                .slice()
                                                .sort((a,b) =>  b.activo_casa - a.activo_casa)
                                                .map( (house) => (
                                                    <DivCasas>
                                                        <Card sx={{ display: 'flex', m: 3, width: 1500}} >
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ width: 180, height: 215 }}
                                                                image={ImageSearchImg}
                                                                alt="Live from space album cover"
                                                            />
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                                <Typography component="div" variant="h5">
                                                                {house.nombre_casa}
                                                                </Typography>
                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                <strong>Dirección:</strong> {house.calle_casa}
                                                                </Typography>
                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                <strong>Tipo de casa:</strong> {house.tipo_casa}
                                                                </Typography>
                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                <strong>CP:</strong> {house.cp_casa}
                                                                </Typography>
                                                                {
                                                                    house.activo_casa ? 
                                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                        <TitleGreen>Casa Activada</TitleGreen>
                                                                    </Typography> 
                                                                    :
                                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                        <TitleRed>Casa Desactivada</TitleRed>
                                                                    </Typography>
                                                                }
                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                <strong>ID:</strong> {house.id_casa}
                                                                </Typography>
                                                            
                                                                </CardContent>
                                                            </Box>
                                                            <ButtonsCasa >
                                                                <Button 
                                                                    variant     ="contained" 
                                                                    size        ="small" 
                                                                    onClick     ={() =>goOption(house.id_casa)}
                                                                >
                                                                    Ver más
                                                                </Button>
            
                                                                <Button 
                                                                    variant     ="contained" 
                                                                    size        ="small" 
                                                                    color       ="success" 
                                                                    style       ={{marginLeft:10}}
                                                                    disabled    ={house.activo_casa ? false : true}
                                                                    onClick={ () => clickWhatsapp(house.id_casa)}
                                                                >
                                                                Compartir
                                                                </Button> 
                                                            </ButtonsCasa>
                                                        </Card>
                                                    </DivCasas>
                                                
                                                ))
                                        
                                                
                                                }  
                                                </div>
                                        </DivOpciones>
                                    </>
                                    : 
                                null
                                
                                }
                                {
                                    optionTeamSuccess.length >= 1 ?
                                    <>
                                        <TitleAndButton>
                                            <TitleHouseGrid>Opciones Enviadas</TitleHouseGrid>
                                            <Button 
                                                variant     ="contained" 
                                                size        ="small" 
                                                onClick     ={() =>goHouses()}
                                                startIcon   ={<HomeIcon />}
                                                color = "secondary"
                                            >
                                            Ver Opciones
                                            </Button>

                                        </TitleAndButton>
                                        <DivOpciones>
                                                <div style={{ height: '100%', width: '100%' }}>
                                                    {
                                                        optionTeamSuccess ? 
                                                        optionTeamSuccess.map( (option) => (
                                                                <DivCasas>
                                                                    <Card sx={{ 
                                                                            m: 3, 
                                                                            width: 1500,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            height: '200px',
                                                                        }} >
                                                                        <CardMedia
                                                                            component="img"
                                                                            sx={{ 
                                                                                width: 100, 
                                                                                height: 65,
                                                                            }}
                                                                            image={ImageOptions}
                                                                            alt="imagen de opciones"
                                                                            style={{ 
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                margin: '10px',
                                                                                marginLeft: '20px'
                                                                            }}
                                                                        />
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                                <Typography 
                                                                                    component="div" 
                                                                                    variant="h5"
                                                                                    style={{
                                                                                        width:'450px',
                                                                                        whiteSpace: 'nowrap',
                                                                                        textOverflow: 'ellipsis',
                                                                                        overflow: 'hidden'
                                                                                    }}
                                                                                >
                                                                                    {option.tipo_opcion}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Descripción:</strong> {option.descripcion_opcion_casa}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Tipo de opción:</strong> {option.tipo_opcion}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Fecha de creación:</strong> 
                                                                                <Moment format="YYYY-MM-DD HH:mm">
                                                                                    {option.created_at}
                                                                                </Moment>
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                    <strong>Especialista:</strong>
                                                                                    <Link
                                                                                            href={`/usuario/${option.id_usuario}`}
                                                                                            target="_blank"
                                                                                        > {option.id_usuario}
                                                                                    </Link>
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                    <strong>Cliente:</strong> 
                                                                                    <Link
                                                                                            href={`/cliente/ventas/${option.id_cliente}`}
                                                                                            target="_blank"
                                                                                        > {option.id_cliente}
                                                                                    </Link>
                                                                                </Typography>
                                                        
                                                                            </CardContent>
                                                                        </Box>
                                                                        <ButtonsCasa >
                                                                            <Button 
                                                                                variant     ="contained" 
                                                                                size        ="small" 
                                                                                onClick     ={() =>goOption(option.id_casa)}
                                                                                startIcon   ={<HomeIcon />}
                                                                            >
                                                                                Ver casa
                                                                            </Button>

                                                                            {/* <Button 
                                                                                variant     ="contained" 
                                                                                size        ="small" 
                                                                                color       ="success" 
                                                                                style       ={{marginLeft:10}}
                                                                                disabled    ={house.activo_casa ? false : true}
                                                                                onClick={ () => clickWhatsapp(house.id_casa)}
                                                                            >
                                                                            Compartir
                                                                            </Button>  */}
                                                                        </ButtonsCasa>
                                                                    </Card>
                                                                </DivCasas>
                                                            
                                                            ))
                                                        
                                                        : 
                                                    null
                                                    }
                                                
                                                </div>
                                        </DivOpciones>
                                    </>
                                :
                                    loadingOption.opciones?.flagViewOpcionesTeam === true &&  loadingOption.casas?.flagViewOpciones === false  ?
                                        <>
                                        <TitleAndButton>
                                                <TitleHouseGrid>Opciones Enviadas</TitleHouseGrid>
                                                <Button 
                                                    variant     ="contained" 
                                                    size        ="small" 
                                                    onClick     ={() =>goHouses()}
                                                    startIcon   ={<HomeIcon />}
                                                    color = "secondary"
                                                >
                                                Ver Opciones
                                                </Button>

                                            </TitleAndButton>
                                            <DivOpciones
                                                // style ={{
                                                //     marginTop: '5rem',
                                                // }}
                                            >

                                                <WrapperEmpty> 
                                                    <Image 
                                                        src={ImageEmpty}
                                                    />
                                                    <TextEmpty>No se tiene opciones enviadas</TextEmpty>
                                                </WrapperEmpty>
                                            </DivOpciones>
                                        </>
                                    : null
                                }
                                {
                                    optionUserSuccess.length >= 1 ?
                                    <>
                                        <TitleAndButton>
                                            <TitleHouseGrid>Opciones Enviadas</TitleHouseGrid>
                                            <Button 
                                                variant     ="contained" 
                                                size        ="small" 
                                                onClick     ={() =>goHouses()}
                                                startIcon   ={<HomeIcon />}
                                                color = "secondary"
                                            >
                                            Ver Opciones
                                            </Button>

                                        </TitleAndButton>
                                        <DivOpciones>
                                                <div style={{ height: '100%', width: '100%' }}>
                                                    {
                                                        optionUserSuccess ? 
                                                        optionUserSuccess.map( (option) => (
                                                                <DivCasas>
                                                                    <Card sx={{ 
                                                                            m: 3, 
                                                                            width: 1500,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            height: '200px',
                                                                        }} >
                                                                        <CardMedia
                                                                            component="img"
                                                                            sx={{ 
                                                                                width: 100, 
                                                                                height: 65,
                                                                            }}
                                                                            image={ImageOptions}
                                                                            alt="imagen de opciones"
                                                                            style={{ 
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                margin: '10px',
                                                                                marginLeft: '20px'
                                                                            }}
                                                                        />
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                                <Typography 
                                                                                    component="div" 
                                                                                    variant="h5"
                                                                                    style={{
                                                                                        width:'450px',
                                                                                        whiteSpace: 'nowrap',
                                                                                        textOverflow: 'ellipsis',
                                                                                        overflow: 'hidden'
                                                                                    }}
                                                                                >
                                                                                    {option.tipo_opcion}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Descripción:</strong> {option.descripcion_opcion_casa}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Tipo de opción:</strong> {option.tipo_opcion}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Fecha de creación:</strong> 
                                                                                <Moment format="YYYY-MM-DD HH:mm">
                                                                                    {option.created_at}
                                                                                </Moment>
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                    <strong>Especialista:</strong>
                                                                                    <Link
                                                                                            href={`/usuario/${option.id_usuario}`}
                                                                                            target="_blank"
                                                                                        > {option.id_usuario}
                                                                                    </Link>
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                    <strong>Cliente:</strong> 
                                                                                    <Link
                                                                                            href={`/cliente/ventas/${option.id_cliente}`}
                                                                                            target="_blank"
                                                                                        > {option.id_cliente}
                                                                                    </Link>
                                                                                </Typography>
                                                        
                                                                            </CardContent>
                                                                        </Box>
                                                                        <ButtonsCasa >
                                                                            <Button 
                                                                                variant     ="contained" 
                                                                                size        ="small" 
                                                                                onClick     ={() =>goOption(option.id_casa)}
                                                                                startIcon   ={<HomeIcon />}
                                                                            >
                                                                                Ver casa
                                                                            </Button>

                                                                            {/* <Button 
                                                                                variant     ="contained" 
                                                                                size        ="small" 
                                                                                color       ="success" 
                                                                                style       ={{marginLeft:10}}
                                                                                disabled    ={house.activo_casa ? false : true}
                                                                                onClick={ () => clickWhatsapp(house.id_casa)}
                                                                            >
                                                                            Compartir
                                                                            </Button>  */}
                                                                        </ButtonsCasa>
                                                                    </Card>
                                                                </DivCasas>
                                                            
                                                            ))
                                                        
                                                        : 
                                                    null
                                                    }
                                                
                                                </div>
                                        </DivOpciones>
                                    </>
                                :
                                    loadingOption.opciones?.flagViewOpcionesUser === true &&  loadingOption.casas?.flagViewOpciones === false  ?
                                        <>
                                        <TitleAndButton>
                                                <TitleHouseGrid>Opciones Enviadas</TitleHouseGrid>
                                                <Button 
                                                    variant     ="contained" 
                                                    size        ="small" 
                                                    onClick     ={() =>goHouses()}
                                                    startIcon   ={<HomeIcon />}
                                                    color = "secondary"
                                                >
                                                Ver Opciones
                                                </Button>

                                            </TitleAndButton>
                                            <DivOpciones
                                                // style ={{
                                                //     marginTop: '5rem',
                                                // }}
                                            >

                                                <WrapperEmpty> 
                                                    <Image 
                                                        src={ImageEmpty}
                                                    />
                                                    <TextEmpty>No se tiene opciones enviadas</TextEmpty>
                                                </WrapperEmpty>
                                            </DivOpciones>
                                        </>
                                        : null
                                }
                                {
                                    optionClientSuccess.length >= 1 ?
                                    <>
                                        <TitleAndButton>
                                            <TitleHouseGrid>Opciones Enviadas</TitleHouseGrid>
                                            <Button 
                                                variant     ="contained" 
                                                size        ="small" 
                                                onClick     ={() =>goHouses()}
                                                startIcon   ={<HomeIcon />}
                                                color = "secondary"
                                            >
                                            Ver Opciones
                                            </Button>

                                        </TitleAndButton>
                                        <DivOpciones>
                                                <div style={{ height: '100%', width: '100%' }}>
                                                    {
                                                        optionClientSuccess ? 
                                                        optionClientSuccess.map( (option) => (
                                                                <DivCasas>
                                                                    <Card sx={{ 
                                                                            m: 3, 
                                                                            width: 1500,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            height: '150px',
                                                                        }} >
                                                                        <CardMedia
                                                                            component="img"
                                                                            sx={{ 
                                                                                width: 120, 
                                                                                height: 65,
                                                                            }}
                                                                            image={ImageOptions}
                                                                            alt="imagen de opciones"
                                                                            style={{ 
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                margin: '10px',
                                                                                marginLeft: '20px'
                                                                            }}
                                                                        />
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                                <Typography 
                                                                                    component="div" 
                                                                                    variant="h5"
                                                                                    style={{
                                                                                        width:'450px',
                                                                                        whiteSpace: 'nowrap',
                                                                                        textOverflow: 'ellipsis',
                                                                                        overflow: 'hidden'
                                                                                    }}
                                                                                >
                                                                                    {option.tipo_opcion}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Descripción:</strong> {option.descripcion_opcion_casa}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Tipo de opción:</strong> {option.tipo_opcion}
                                                                                </Typography>
                                                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                <strong>Fecha de creación:</strong> 
                                                                                <Moment format="YYYY-MM-DD HH:mm">
                                                                                    {option.created_at}
                                                                                </Moment>
                                                                                </Typography>
                                                        
                                                                            </CardContent>
                                                                        </Box>
                                                                        <ButtonsCasa >
                                                                            <Button 
                                                                                variant     ="contained" 
                                                                                size        ="small" 
                                                                                onClick     ={() =>goOption(option.id_casa)}
                                                                                startIcon   ={<HomeIcon />}
                                                                            >
                                                                                Ver casa
                                                                            </Button>

                                                                            {/* <Button 
                                                                                variant     ="contained" 
                                                                                size        ="small" 
                                                                                color       ="success" 
                                                                                style       ={{marginLeft:10}}
                                                                                disabled    ={house.activo_casa ? false : true}
                                                                                onClick={ () => clickWhatsapp(house.id_casa)}
                                                                            >
                                                                            Compartir
                                                                            </Button>  */}
                                                                        </ButtonsCasa>
                                                                    </Card>
                                                                </DivCasas>
                                                            
                                                            ))
                                                        
                                                        : 
                                                    null
                                                    }
                                                
                                                </div>
                                        </DivOpciones>
                                    </>
                                :
                                    loadingOption.opciones?.flagViewOpcionesClient === true &&  loadingOption.casas?.flagViewOpciones === false  ?
                                        <>
                                        <TitleAndButton>
                                                <TitleHouseGrid>Opciones Enviadas</TitleHouseGrid>
                                                <Button 
                                                    variant     ="contained" 
                                                    size        ="small" 
                                                    onClick     ={() =>goHouses()}
                                                    startIcon   ={<HomeIcon />}
                                                    color = "secondary"
                                                >
                                                Ver Opciones
                                                </Button>

                                            </TitleAndButton>
                                            <DivOpciones
                                                // style ={{
                                                //     marginTop: '5rem',
                                                // }}
                                            >

                                                <WrapperEmpty> 
                                                    <Image 
                                                        src={ImageEmpty}
                                                    />
                                                    <TextEmpty>No se tiene opciones enviadas</TextEmpty>
                                                </WrapperEmpty>
                                            </DivOpciones>
                                        </>
                                        : null
                                }
                            
                    </div>
            }
        </>
     );
}
 
export default OpcionesDesktop;