import { 
    STATUS_LOADING,
    STATUS_LOADING_SUCCESS,
    STATUS_LOADING_ERROR,
    STATUS_LOAD,
    STATUS_LOAD_SUCCESS,
    STATUS_LOAD_ERROR,
} from '../types';

const initialState = {
    status              : [],
    error               : null,
    loading             : false,
    flag                : false,
    editarStatus        : null,
    desactiveStatus     : null
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case STATUS_LOAD:
        case STATUS_LOADING:
            return{
                ...state,
                loading : true,
                flag    : false,
            }

        case STATUS_LOADING_ERROR:
        case STATUS_LOAD_ERROR:
            return{
                ...state,
                loading : false,
                flag    : action.flag,
                error   : action.error
            }
        
        case STATUS_LOADING_SUCCESS:
            return{
                ...state,
                loading             : false,
                error               : false,
                status               : action.payload,
                flag                : false,
                desactiveStatus     : null
            }

        case STATUS_LOAD_SUCCESS:
            return{
                ...state,
                status           : [...state.status],
                editarStatus    : action.editarStatus,
                flag            : true,
                loading         : false
            }
        default:
            return state;
    }
}