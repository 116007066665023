import React            from 'react';
import Card             from '@mui/material/Card';
import CardActions      from '@mui/material/CardActions';
import CardContent      from '@mui/material/CardContent';
import Typography       from '@mui/material/Typography';
import Button           from '@mui/material/Button';


const ScheduleResponsive = ({data, id_cliente}) => {

    function goOption(id_casa){
        // navigate(`/opciones/${id}`);
        window.open(`/opciones/${id_casa}` )
    }

    return ( 
        <>
            {   data.filter(schedule => parseInt(schedule.id_cliente) === parseInt(id_cliente) )
                .map((agenda) => (
                <Card 
                    sx={{ maxWidth: '100%', marginBottom: 2}}
                >
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Casa: {agenda?.nombre_casa}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Día de la agenda: </strong><br/>
                            {agenda?.dia_agenda_casa}
                        </Typography><br/>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Hora de la agenda: </strong><br/>
                            {agenda?.horario_agenda_casa}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Fecha de creación:  {agenda?.created_at}</Button>
                        <Button size="small" onClick={() =>goOption(agenda?.id_casa)} >Id casa: {agenda?.id_casa}</Button><br/>
                    </CardActions>
                </Card>  
                ))
            }          
        </>
     );
}
 
export default ScheduleResponsive;