import { 
    SCHEDULE_CREATE,
    SCHEDULE_CREATE_SUCCESS,
    SCHEDULE_CREATE_ERROR,
    SCHEDULE_CREATE_INIT,
    SCHEDULES_LOADING,
    SCHEDULES_LOADING_SUCCESS,
    SCHEDULES_LOADING_ERROR,
    SCHEDULE_LOAD,
    SCHEDULE_LOAD_SUCCESS,
    SCHEDULE_LOAD_ERROR,
} from '../types';

/*
    FLAG
        Revisamos si esta ok el usuario y lo redireccionamos 
        True: Correcto(redirecciona) 
        False: Se limpia(no redirecciona)
        Loading: false(enseña el cargando)
    
    LOADING
        True: Quitamos el componente loading(cargando) 
        False: Se enseña el componente cargando
*/
const initialState = {
    agendas            : [],
    error               : null,
    loading             : false,
    loadingUpdate       : false,  
    flag                : false, 
    flagUpdate          : false
}   


export default function init( state = initialState, action ) {
    
    switch ( action.type ) {

        case    SCHEDULE_CREATE:
        case    SCHEDULE_LOAD:
        case    SCHEDULES_LOADING:
 
            return{
                ...state,
                loading: true,
                loadingUpdate: true,
                flag: false,
            }

        case SCHEDULE_CREATE_INIT:
            return{
                ...state,
                loading: false,
                flag: action.flag
            }
    
        case SCHEDULE_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                flag: action.flag,
                agendas: [ ...state.agendas, action.payload ]
            }

        case SCHEDULE_CREATE_ERROR:
        case SCHEDULES_LOADING_ERROR:
        case SCHEDULE_LOAD_ERROR:

            return{
                ...state,
                loading: false,
                flag: action.flag,
                error: action.error
            }
        
        case SCHEDULES_LOADING_SUCCESS:
            return{
                ...state,
                loading: false,
                error: false,
                agendas: action.payload,
                flag: false
            }

        case SCHEDULE_LOAD_SUCCESS:
            return{
                ...state,
                agendas: [...state.agendas],
                editarUsuario: action.editarUsuario,
                flag: true,
                loading: false
            }
        
        default:
            return state;
    }
}