import styled from 'styled-components';

export  const DivHeader = styled.div`
    background-color: #004274;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Image = styled.img`
    margin: 10px 10px 10px 10px;
    height: 50px;
`
export const TitleHeader = styled.p`
    font-size: 14px;
    color: #fff;
    margin-right: 2rem;
    font-weight: bold;    
    text-decoration: none;
    pointer-events: none;
`

export const DivLink = styled.div`
    justify-content: flex-end;
    flex-direction: row;
    display: flex;
    a{
        text-decoration: none;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
`
export const DivAvatar = styled.div`
    display: flex;
    margin-right: 2.5rem;
    margin-top: 0.2rem;

    @media only screen and (max-width: 768px) {
        margin-right: 1rem;
    }
`
