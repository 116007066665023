import React                 from 'react';
import { Outlet, Navigate, useLocation }        from 'react-router-dom';

// 1 = Administrativos
// 2 = Especialista
// 3 = Inventario
// 4 = RH
// 5 = Recepcion
// 6 = Lider de Equipo

const RouterClients = () => {

    let location    = useLocation();
    let userLocal   = JSON.parse(localStorage.getItem('user'));

    if( userLocal != null ){
        if( [1,2,5,6,8].includes(userLocal.rol) ){
            return <Outlet />;
        }else{
            return <Navigate to="/errorRole" state={{ from: location }} />;
        }
    }else{
        return <Navigate to="/" state={{ from: location }} />;
    }
}
 
export default RouterClients;