import React            from 'react';
import Button           from '@mui/material/Button';
import Link             from "@material-ui/core/Link";
import Moment           from 'react-moment';
import { 
    StyledTableCell,
    StyledTableRow
}                       from '../../utils/table.js';


const TableData = ({row, table})  => {

    return ( 
        <>
            {
                ['client'].includes(table) &&
                <>
                    <StyledTableRow
                        key={row.id_cliente}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <StyledTableCell  component="th" scope="row">
                            <Moment format="YYYY-MM-DD HH:mm">
                                {row.created_at}
                            </Moment>
                        </StyledTableCell>
                        <StyledTableCell>{row.nombre_cliente} {row.apellido_materno_cliente} {row.apellido_paterno_cliente}</StyledTableCell>
                        <StyledTableCell>{row.telefono_cliente}</StyledTableCell>
                        <StyledTableCell>{row.email_cliente}</StyledTableCell>
                        <StyledTableCell>{row.nombre_status ? row.nombre_status : 'Sin status' }</StyledTableCell>
                        <StyledTableCell>
                            <Button
                                variant     ="contained" 
                                color       ="warning"
                                size        ="small"
                            >
                                <Link 
                                    style   ={ {color:"#ffff", textDecoration:"none"} } 
                                    href    ={`/cliente/ventas/${row.id_cliente}`}
                                >
                                    Editar
                                </Link>
                            </Button>
                        </StyledTableCell>
                    </StyledTableRow>
                </>
            }
            {
                ['schedules'].includes(table) &&
                <>
                    <StyledTableRow
                        key={row.id_casa}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <StyledTableCell component="th" scope="row">
                            <Moment format="YYYY-MM-DD HH:mm">
                                {row.created_at}
                            </Moment>
                        </StyledTableCell>
                        <StyledTableCell>{row.id_casa}</StyledTableCell>
                        <StyledTableCell>{row.dia_agenda_casa} {row.horario_agenda_casa}</StyledTableCell>
                        <StyledTableCell>{row.nombre_cliente}</StyledTableCell>
                        <StyledTableCell>{row.datos_agenda}</StyledTableCell>
                    </StyledTableRow>
                </>
            }
            {
                ['options'].includes(table) &&
                <>
                    <StyledTableRow
                        key={row.id_casa}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <StyledTableCell component="th" scope="row">
                        <Moment format="YYYY-MM-DD HH:mm">
                            {row.created_at}
                        </Moment>
                    </StyledTableCell>
                        <StyledTableCell>{row.id_casa}</StyledTableCell>
                        <StyledTableCell>{row.tipo_opcion}</StyledTableCell>
                        <StyledTableCell>{row.tipo_opcion_casa}</StyledTableCell>
                        <StyledTableCell>{row.descripcion_opcion_casa}</StyledTableCell>
                        <StyledTableCell>
                            <Button
                                variant     ="contained" 
                                color       ="warning"
                                size        ="small"
                            >
                                <Link 
                                    style   ={ {color:"#ffff", textDecoration:"none"} } 
                                    href    ={`/cliente/ventas/${row.id_cliente}`}
                                >
                                Ver cliente
                                </Link>
                            </Button>
                        </StyledTableCell>
                    </StyledTableRow>
                </>
            }
            {
                ['houses'].includes(table) &&
                <>
                    <StyledTableRow
                        key={row.id_casa}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <StyledTableCell component="th" scope="row">
                            <Moment format="YYYY-MM-DD">
                                {row.created_at}
                            </Moment>
                        </StyledTableCell>
                        <StyledTableCell>{row.id_casa}</StyledTableCell>
                        <StyledTableCell>{row.nombre_casa}</StyledTableCell>
                        <StyledTableCell>{row.telefono_cel_casa}</StyledTableCell>
                        <StyledTableCell>{row.cp_casa}</StyledTableCell>
                    </StyledTableRow>
                </>
            }
        
        </>
     );
}
 
export default TableData;