import ImageSearchImg                   from '../Images/logo-casas.png';
let imageSlider ;

export const DataImage = ( image ) =>{
    
    if(Object.keys(image).length === 0){
        imageSlider = [
            {
                url: ImageSearchImg
            }
        ]
    }else{
        imageSlider = [
            {
                url : image.imagen_2_casa_imagen,
            }, 
            {
                url : image.imagen_3_casa_imagen,
            }, 
            {
                url : image.imagen_4_casa_imagen,
            },
            {
                url : image.imagen_5_casa_imagen,
            }, 
            {
                url : image.imagen_6_casa_imagen,
            }, 
            {
                url : image.imagen_7_casa_imagen,
            }
        ]
    }

    return imageSlider;
}