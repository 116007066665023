import clientAxios                  from '../config/axios';
import { SweetAlertBasic }          from '../utils/sweetAlert';
import { 
    OPTION_CREATE,
    OPTION_CREATE_SUCCESS,
    OPTION_CREATE_ERROR,
    OPTION_CREATE_INIT,
    OPTIONS_LOADING,
    OPTIONS_LOADING_SUCCESS,
    OPTIONS_LOADING_ERROR,
    OPTION_CLIENT_LOAD,
    OPTION_CLIENT_LOAD_SUCCESS,
    OPTION_CLIENT_LOAD_ERROR,
    OPTION_USER_LOAD,
    OPTION_USER_LOAD_SUCCESS,
    OPTION_USER_LOAD_ERROR,
    OPTION_TEAM_LOAD,
    OPTION_TEAM_LOAD_SUCCESS,
    OPTION_TEAM_LOAD_ERROR,
    OPTION_HOUSE_LOAD,
    OPTION_HOUSE_LOAD_SUCCESS,
    OPTION_HOUSE_LOAD_ERROR,
    HOUSES_LOADING_SUCCESS,
} from '../types';

/* Create OPTION*/ 
    export function createOptionAction(option) {

        return async ( dispatch ) => {
            
            dispatch( createOption() );

            try{
               
                let response        = await clientAxios.post('/opciones/crear/',option);

                if(response.status === 200 && option.id_usuario !== 1 ){
                    SweetAlertBasic("success","Felicidades!",`  
                    🏠 Tu envio de opción se ha enviado exitosamente 🏠
                    Casa ID:${option.id_casa}`);
                    dispatch( createOptionSuccess(option) );
                }
    
            }catch( error ){
                dispatch( createOptionError() );
                SweetAlertBasic("error","Ups","Hubo un error al crear la opción");
            }
        }

    }

    // Create Option init action
    export function createOptionInitFn(flag){
        return ( dispatch ) => {
            dispatch( createOptionInit(flag));
        }
    }

    const createOption = ( ) => ({
        type    : OPTION_CREATE
    });

    const createOptionSuccess = (option) => ({
        type    : OPTION_CREATE_SUCCESS,
        payload : option,
        flag    : true,
    })

    const createOptionError = () => ({
        type    : OPTION_CREATE_ERROR,
        error   : true,
        flag    : false
    })

    const createOptionInit = (flag) =>({
        type    : OPTION_CREATE_INIT,
        error   : false,
        flag    : flag
    })

/* GRID OPTION */
    export function getAllOptionsAction() {
        return async ( dispatch ) => {
            dispatch( downloadOption() );

            try{
                const responseOptions = await clientAxios.get('/opciones' );
                dispatch( downloadOptionSuccess (responseOptions.data.data) );

            }catch( error ){
                dispatch( downloadOptionError(error) );
                SweetAlertBasic("error","Ups","Hubo un error al cargar los envio de opciones");   
            }
        }
    }

    const downloadOption = () => ({
        type: OPTIONS_LOADING,
        payload: true
    })

    const downloadOptionSuccess = ( options ) => ({
        type: OPTIONS_LOADING_SUCCESS,
        payload: options
    })

    const downloadOptionError = (error) => ({
        type: OPTIONS_LOADING_ERROR,
        error: error
    })



/* GET OPTION  USER*/
    export const getOptionUserAction = ( id_user ) =>{
        return async (dispatch)=>{
            dispatch( getLoadOption() );

            try{
                await clientAxios.get(`/opciones/usuario/${id_user}/`)
                .then((res) => {
                    dispatch( loadOptionSuccess (res.data.data) );
                    dispatch(downloadHouseSuccess ()); 
                })
                .catch(function (error) {
                    dispatch(downloadHouseSuccess ()); 
                    dispatch( loadOptionSuccess (error.response.data.data) );
                });
            }catch( error ){
                dispatch( loadOptionError() );
                SweetAlertBasic("error","Ups","Hubo un error al cargar las opciones enviadas");  
            }
        }   
    }

    const getLoadOption = () => ({
        type: OPTION_USER_LOAD,
        payload: true
    })

    const loadOptionSuccess = (option) => ({
        type: OPTION_USER_LOAD_SUCCESS,
        opcionUsuario: option,
        flagViewOpcionesUser: true,
    })

    const loadOptionError = () => ({
        type: OPTION_USER_LOAD_ERROR,
        error: true
    })

/* GET OPTION  CLIENT*/
export const getOptionClientAction = ( id_client ) =>{

    return async (dispatch)=>{
        dispatch( getLoadClientOption() );

        try{
            await clientAxios.get(`/opciones/cliente/${id_client}/`)
            .then((res) => {
                dispatch( loadClientOptionSuccess (res.data.data) );
                dispatch(downloadHouseSuccess ()); 
            })
            .catch(function (error) {
                dispatch(downloadHouseSuccess ()); 
                dispatch( loadClientOptionSuccess (error.response.data.data) );
            });
        }catch( error ){
            dispatch( loadClientOptionError() );
            // SweetAlertBasic("error","Ups","Hubo un error al cargar las opciones enviadas");  
        }
    }   
}

    const getLoadClientOption = () => ({
        type: OPTION_CLIENT_LOAD,
        payload: true
    })

    const loadClientOptionSuccess = (option) => ({
        type: OPTION_CLIENT_LOAD_SUCCESS,
        opcionCliente: option,
        opcionClienteSales: option,
        flagViewOpcionesClient: true,
    })

    const loadClientOptionError = () => ({
        type: OPTION_CLIENT_LOAD_ERROR,
        error: true
    })


/* GET OPTION  TEAM*/
export const getOptionTeamAction = ( id_equipo ) =>{

    return async (dispatch)=>{
        dispatch( getLoadTeamOption() );

        try{
            await clientAxios.get(`/opciones/equipo/${id_equipo}/`)
            .then((res) => {
                dispatch( loadTeamOptionSuccess (res.data.data) );
                dispatch(downloadHouseSuccess ()); 
            })
            .catch(function (error) {
                dispatch(downloadHouseSuccess ()); 
                dispatch( loadTeamOptionSuccess (error.response.data.data) );
            });
        }catch( error ){
            console.log(error);
            dispatch( loadTeamOptionError() );
            // SweetAlertBasic("error","Ups","Hubo un error al cargar las opciones enviadas del equipo");  
        }
    }   
}

    const getLoadTeamOption = () => ({
        type: OPTION_TEAM_LOAD,
        payload: true
    })

    const loadTeamOptionSuccess = (option) => ({
        type: OPTION_TEAM_LOAD_SUCCESS,
        opcionEquipo: option,
        flagViewOpcionesTeam: true,
    })

    const downloadHouseSuccess = (  ) => ({
        type: HOUSES_LOADING_SUCCESS,
        payload: []
    })

    const loadTeamOptionError = () => ({
        type: OPTION_TEAM_LOAD_ERROR,
        error: true
    })


/* GET OPTION  HOUSE*/
export const getOptionHouseAction = ( id_casa ) =>{
    return async (dispatch)=>{
        dispatch( getLoadHouseOption() );

        try{
            let response = await clientAxios.get(`/opciones/casas/${id_casa}/`);
            dispatch( loadHouseOptionSuccess (response.data.data) );
        }catch( error ){
            dispatch( loadHouseOptionError() );
            SweetAlertBasic("error","Ups","Hubo un error al cargar las opciones enviadas del equipo");  
        }
    }   
}

    const getLoadHouseOption = () => ({
        type: OPTION_HOUSE_LOAD,
        payload: true
    })

    const loadHouseOptionSuccess = (option) => ({
        type: OPTION_HOUSE_LOAD_SUCCESS,
        opcionEquipo: option
    })

    const loadHouseOptionError = () => ({
        type: OPTION_HOUSE_LOAD_ERROR,
        error: true
    })


    